import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { Location } from "@angular/common";
import { Store } from "@ngrx/store";
import {
  addVehicle,
  clearAddVehicle,
  getAccountInfo,
  selectAccountAndMembershipStatus,
  selectAddVehicle,
} from "src/app/core/ngrx/myqq";
import { environment } from "src/environments/environment";
import { delay, first, map } from "rxjs/operators";
import { isSuccess } from "@nll/datum/DatumEither";

import {
  getMakeFromMakeId,
  rationalizeLicense,
  rationalizeVIN,
} from "src/lib/util";
import { Vehicle } from "src/app/core/services/myqq";
import { errors } from "src/app/core/services/myqq/myqq.errors";
import { Router } from "@angular/router";

type VehicleData =
  | Vehicle
  | { type: string; make?: string; model?: string; year?: string };

@Component({
  selector: "myqq-add-vehicle-page",
  templateUrl: "./add-vehicle.page.html",
  styleUrls: ["./add-vehicle.page.scss"],
})
export class AddVehicleModalPage implements OnDestroy, OnInit {
  constructor(
    readonly store$: Store<any>,
    private readonly location: Location,
    readonly cdr: ChangeDetectorRef,
    readonly router: Router
  ) {}

  readonly phoneNumber = environment.supportNumber;

  readonly submitVehicle$ = this.store$.select(selectAddVehicle);

  readonly hasMembership$ = this.store$
    .select(selectAccountAndMembershipStatus)
    .pipe(
      map((valueDE) => {
        if (isSuccess(valueDE)) {
          return valueDE?.value.right?.hasMembership;
        } else return false;
      })
    );

  @ViewChild("footer", { static: false }) private _footerTemplate: TemplateRef<
    any
  >;
  readonly footerTemplate = this.submitVehicle$.pipe(
    delay(1),
    map(() => this._footerTemplate)
  );

  private submitVehicleSub = this.submitVehicle$
    .pipe(first(isSuccess))
    .subscribe(() => this.handleSuccess());
  maxVehicles: number = environment.limits.maxVehiclesOnAccount;
  maxFlock: number = environment.limits.maxVehiclesOnSubscription;

  readonly errVehicleAlreadyOnAccount =
    errors.myqqApi.errVehicleAlreadyOnAccount;

  readonly errVehicleOwnership = errors.myqqApi.errVehicleOwnership;

  getMakeModel(vehicle: VehicleData) {
    const make = getMakeFromMakeId(vehicle?.make);
    const model = make?.models?.find(
      (mdl) => mdl?.name?.toLowerCase() === vehicle?.model?.toLowerCase()
    );
    return [make?.makeId, model?.name];
  }

  ngOnInit() {
    this.store$.dispatch(clearAddVehicle(null));
    this.store$.dispatch(getAccountInfo.pending(null));
  }

  ngOnDestroy() {
    this.submitVehicleSub?.unsubscribe();
    this.store$.dispatch(clearAddVehicle(null));
  }

  handleSubmit({ license, vin, ...data }: Vehicle) {
    license = rationalizeLicense(license);
    vin = vin?.length > 0 ? rationalizeVIN(vin) : null;
    const vehicle: Vehicle = {
      license,
      vin,
      ...data,
    };
    this.store$.dispatch(addVehicle.pending(vehicle));
  }

  handleCancel() {
    this.close();
  }

  handleSuccess() {
    this.close();
  }

  close() {
    this.location.back();
  }
  handleRelink() {
    this.router.navigateByUrl("/relink");
  }
  handleDismissErrorBanner() {
    this.store$.dispatch(clearAddVehicle(null));
  }
}
