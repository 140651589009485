import { Injectable } from "@angular/core";
import { NavigationStart, Router } from "@angular/router";
import { filter, map } from "rxjs/operators";

/** A router wrapper, adding extra functions. */
@Injectable()
export class RouteHistoryService {
  private previousUrl: string = undefined;
  private currentUrl: string = undefined;

  constructor(readonly router: Router) {
    this.router.events
      .pipe(
        filter((evt: any) => evt instanceof NavigationStart),
        map((evt) => evt.url)
      )
      .subscribe((url) => {
        this.previousUrl = this.currentUrl;
        this.currentUrl = url;
      });
  }

  public getPreviousUrl() {
    return this.previousUrl;
  }

  public getCurrentUrl() {
    return this.currentUrl;
  }
}
