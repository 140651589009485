import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { Observable, of } from "rxjs";
import { tap, map, catchError, startWith } from "rxjs/operators";

import { EvoxConfig, EVOX_CONFIG_TOKEN } from "./evox.consts";

export type Vehicle = {
  readonly make?: string;
  readonly model?: string;
  readonly year?: string;
};

const isNotNil = <A>(a: A): a is NonNullable<A> =>
  a !== null && a !== undefined;

const vehicleIndex = ({ make, model, year }: Vehicle): string =>
  `${year}/${encodeURIComponent(make)}/${encodeURIComponent(model)}`;

const formatUrl = (url: string, vehicle: Vehicle): string =>
  `${url}/${vehicleIndex(vehicle)}`;

const isVehicle = (vehicle?: Vehicle): vehicle is Required<Vehicle> =>
  isNotNil(vehicle) && !!vehicle.make && !!vehicle.model && !!vehicle.year;

@Injectable()
export class EvoxImageService {
  // Currently only cache "session"
  readonly cache = new Map<string, SafeUrl>();
  fallbackImage = this.sanitizer.bypassSecurityTrustUrl("");

  constructor(
    readonly http: HttpClient,
    readonly sanitizer: DomSanitizer,
    @Inject(EVOX_CONFIG_TOKEN) readonly config: EvoxConfig
  ) {
    this.fallbackImage = this.sanitizer.bypassSecurityTrustUrl(
      this.config.fallbackImage
    );
  }

  readonly getImage = (vehicle?: Vehicle): Observable<SafeUrl> => {
    // Validate input and fallback if bad
    if (!isVehicle(vehicle)) {
      return of(this.fallbackImage);
    }

    // Create index and check cache
    const index = vehicleIndex(vehicle);
    if (this.cache.has(index)) {
      return of(this.cache.get(index));
    }

    // Get image if not cached
    return this.http
      .get(formatUrl(this.config.url, vehicle), { responseType: "blob" }) // Try to get image
      .pipe(
        map((blob) => URL.createObjectURL(blob)), // Convert to blob url
        map((url) => this.sanitizer.bypassSecurityTrustUrl(url)), // Sanitize blob url
        catchError(() => of(this.fallbackImage)), // Fallback to fallback image
        tap((safeUrl) => this.cache.set(index, safeUrl)), // Add safeUrl to cache at index (using fallbackImage too)
        startWith(this.fallbackImage) // Render the fallbackImage while loading
      );
  };
}
