import { filter, map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Actions, createEffect } from "@ngrx/effects";

import { filterActions } from "src/app/shared/utilities/state/Operators";

import {
  setAccountSetupStep,
  setCancelMembershipStep,
  setUpdateFlockPresaleVehicleStep,
  setVehicleSwapStep,
  stepChange,
} from "./ui.reducers";
import {
  AccountSetupSteps,
  CancelMembershipSteps,
  UIStepperActions,
  VehicleSwapSteps,
} from "./ui.models";
import { uiFeatureKey } from "./ui.consts";

@Injectable()
export class UIChainEffects {
  constructor(private readonly actions$: Actions) {}

  setAccountSetupStep = createEffect(() =>
    this.actions$.pipe(
      filterActions(setAccountSetupStep),
      filter(({ value }) => value !== AccountSetupSteps.enter_account_details),
      map(({ type, value: step }) =>
        stepChange({
          step: _normalizeStep({ step, type }),
          type: _normalizeType(type),
        })
      )
    )
  );

  setVehicleSwapStep = createEffect(() =>
    this.actions$.pipe(
      filterActions(setVehicleSwapStep),
      filter(({ value }) => value !== VehicleSwapSteps.initial),
      map(({ type, value: step }) =>
        stepChange({
          step: _normalizeStep({ step, type }),
          type: _normalizeType(type),
        })
      )
    )
  );

  setCancelStep = createEffect(() =>
    this.actions$.pipe(
      filterActions(setCancelMembershipStep),
      filter(({ value }) => value !== CancelMembershipSteps.initial),
      map(({ type, value: step }) =>
        stepChange({
          step: _normalizeStep({ step, type }),
          type: _normalizeType(type),
        })
      )
    )
  );
  setUpdateFlockPresaleStep = createEffect(() =>
    this.actions$.pipe(
      filterActions(setUpdateFlockPresaleVehicleStep),
      map(({ type, value: step }) =>
        stepChange({
          step: _normalizeStep({ step, type }),
          type: _normalizeType(type),
        })
      )
    )
  );
}

function _normalizeType(type: string) {
  return _titlecase(
    type.replace(
      new RegExp(`^${uiFeatureKey}/`),
      ""
    ) as keyof typeof UIStepperActions
  );
}

function _normalizeStep({ type, step }: { type: string; step: number }) {
  return _titlecase(
    UIStepperActions[type.replace(new RegExp(`^${uiFeatureKey}/`), "")][step]
  );
}

function _titlecase(str: string) {
  return str
    .split("_")
    .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
}
