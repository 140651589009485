import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MyqqStripeModule } from "src/app/core/services/myqq-stripe/myqq-stripe.module";
import { MyqqButtonModule } from "src/app/shared/components/button/button.module";

import { ModalRouteModule } from "src/app/shared/components/routed-modal";
import { DatumModule } from "src/app/shared/directives/datum";
import { MapPipeModule } from "src/app/shared/pipes/map";
import { SharedModule } from "src/app/shared/shared.module";

import { AddPaymentMethodComponent } from "./add-payment-method.component";
import { AddPaymentMethodPage } from "./add-payment-method.page";

@NgModule({
  imports: [
    CommonModule,
    DatumModule,
    FormsModule,
    MapPipeModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MyqqButtonModule,
    ModalRouteModule,
    MyqqStripeModule,
    ReactiveFormsModule,
    SharedModule,
  ],
  exports: [AddPaymentMethodComponent],
  declarations: [AddPaymentMethodPage, AddPaymentMethodComponent],
  providers: [],
})
export class AddPaymentMethodModule {}
