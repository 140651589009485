import { Component, Input } from "@angular/core";
import { toArray } from "@qqcw/qqsystem-util";
import { PostCalculateOrderResponse } from "src/app/core/services/myqq";

@Component({
  selector: "myqq-cart-holds",
  templateUrl: "./cart-holds.component.html",
  styleUrls: ["./cart-holds.component.scss"],
})
export class CartHoldsComponent {
  @Input() holds: PostCalculateOrderResponse["holds"];

  readonly holdMessagesToDisplay = (
    holds: PostCalculateOrderResponse["holds"]
  ) => {
    return toArray(holds || [])
      .filter(({ holdCode }) => holdCode !== "NOTPAID")
      .map(({ message }) => message);
  };
}
