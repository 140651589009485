import { APP_INITIALIZER, NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { A11yModule } from "@angular/cdk/a11y";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatInputModule } from "@angular/material/input";

import { DatumModule } from "./directives/datum";
import { LayoutsModule } from "./components/layouts/layouts.module";
import { ErrorComponent } from "./components/error/error.component";
import { GenericConfirmComponent } from "./components/generic-confirm/generic-confirm.component";
import { LoadingComponent } from "./components/loading/loading.component";
import { ModalHeaderComponent } from "./components/modal-header/modal-header.component";
import { PriceDisplayComponent } from "./components/price-display/price-display.component";
import { PriceDisplayModule } from "./components/price-display";

import { StripeModule } from "./modules/stripe";
import { MapPipeModule } from "./pipes/map";

import { DisplayPersonNamePipe } from "./pipes/display-person-name.pipe";
import { DisplayVehicleDescriptionPipe } from "./pipes/display-vehicle-description.pipe";
import { DisplayVehiclePlatePipe } from "./pipes/display-vehicle-plate.pipe";
import { DisplayOrderVehiclePlatePipe } from "./pipes/display-vehicle-plate.pipe";
import { ParenthesizeNegativeNumbersPipe } from "./pipes/parenthesize-negative-numbers.pipe";
import { PhonePipe } from "./pipes/phone.pipe";

import { SnippetService } from "./services/snippet.service";
import { WINDOW_PROVIDERS } from "../core/services/window.service";
import { HeaderComponent } from "./components/header/header.component";
import { FourohfourComponent } from "./components/error/fourohfour/fourohfour.component";
import { BannerComponent } from "./components/banner/banner.component";
import { MyqqButtonModule } from "src/app/shared/components/button/button.module";
import { AccountSetupModalComponent } from "./components/account-setup-modal/account-setup-modal.component";
import { UpdateAppComponent } from "./components/update-app/update-app.component";
import { IsDatumPendingPipe } from "./pipes/is-pending.pipe";
import { SmallLoaderComponent } from "./components/small-loader/small-loader.component";

@NgModule({
  declarations: [
    AccountSetupModalComponent,
    GenericConfirmComponent,
    IsDatumPendingPipe,
    LoadingComponent,
    ModalHeaderComponent,
    HeaderComponent,
    ErrorComponent,
    DisplayPersonNamePipe,
    DisplayVehicleDescriptionPipe,
    DisplayVehiclePlatePipe,
    DisplayOrderVehiclePlatePipe,
    ParenthesizeNegativeNumbersPipe,
    PhonePipe,
    FourohfourComponent,
    BannerComponent,
    UpdateAppComponent,
    SmallLoaderComponent,
  ],
  imports: [
    A11yModule,
    LayoutsModule,
    CommonModule,
    DatumModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MyqqButtonModule,
    ReactiveFormsModule,
    RouterModule,
    PriceDisplayModule,
    MatInputModule,
    MapPipeModule,
  ],
  exports: [
    AccountSetupModalComponent,
    A11yModule,
    GenericConfirmComponent,
    DisplayPersonNamePipe,
    DisplayVehicleDescriptionPipe,
    DisplayVehiclePlatePipe,
    DisplayOrderVehiclePlatePipe,
    ParenthesizeNegativeNumbersPipe,
    LoadingComponent,
    ModalHeaderComponent,
    HeaderComponent,
    ErrorComponent,
    StripeModule,
    PriceDisplayComponent,
    LayoutsModule,
    PhonePipe,
    IsDatumPendingPipe,
    BannerComponent,
    UpdateAppComponent,
    SmallLoaderComponent,
  ],
  providers: [
    SnippetService,
    {
      provide: APP_INITIALIZER,
      useFactory: (service: SnippetService) => () => service.generateSnippets(),
      deps: [SnippetService],
      multi: true,
    },
    WINDOW_PROVIDERS,
  ],
})
export class SharedModule {}
