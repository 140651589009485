import {
  OnInit,
  OnChanges,
  SimpleChanges,
  OnDestroy,
  ElementRef,
  Output,
  EventEmitter,
  Directive,
  DoCheck,
} from "@angular/core";

import {
  Element,
  ElementType,
  ElementOptions,
  ChangeEventObject,
} from "./stripe-definitions/element";
import { StripeError } from "./stripe-definitions/error";
import { StripeElements } from "./stripe-elements";
import { StripeConfig } from "./stripe-factory";

/** StripeElement types */
export type StripeElementType = Exclude<ElementType, "paymentRequestButton">;

const isFunction = (fn: unknown): fn is Function => typeof fn === "function";

/**
 * Abstract generic class turning a StripeElement into an Angular component with basic features
 * To be used as the base class for all the Stripe related specific components: StripeCard...
 */
@Directive()
export abstract class StripeElementDirective<T extends StripeElementType>
  implements OnInit, OnChanges, OnDestroy, DoCheck {
  constructor(
    readonly elementType: T,
    private elements: StripeElements,
    private config: StripeConfig<T>,
    private ref: ElementRef<HTMLElement>
  ) {}

  /**
   * Implement this getter to provide component specific options during element creation and update
   */
  protected abstract get options(): ElementOptions<T>;

  /** The stripe element */
  public element: Element<T>;

  /** The latest change value */
  public value: ChangeEventObject<T>;

  /** True whenever the element is fully loaded */
  public ready = false;

  /** True whenever the element is focused */
  public focused: boolean;

  /** True whenever the element is disabled */
  public disabled: boolean;

  public locale: string;

  /** True whenever the element is empty */
  public get empty(): boolean {
    return !this.value || this.value.empty;
  }

  /** True whenever the element is complete and valid */
  public get complete(): boolean {
    return !!this.value && this.value.complete;
  }

  /** The StripeError or null */
  public get error(): StripeError | null {
    return (!!this.value && this.value.error) || null;
  }

  ngOnInit() {
    // Keeps track of the current Elements locale
    this.locale = this.elements.locale;

    // Resets the local variables
    this.focused = this.disabled = this.value = undefined;

    // Creates the requested Stripe element
    this.element = this.elements.create(this.elementType, {
      ...this.config.elementOptions,
      ...this.options,
    });

    // Hooks on the element's events
    this.element.on("ready", (value) => {
      this.ready = true;
      this.readyChange.emit(value);
    });
    this.element.on("focus", (value) => {
      this.focused = true;
      this.focusChange.emit(value);
    });
    this.element.on("blur", (value) => {
      this.focused = false;
      this.blurChange.emit(value);
    });
    this.element.on("change", (value: ChangeEventObject<T>) =>
      this.valueChange.emit((this.value = value))
    );

    // Mounts the element on the DOM
    this.element.mount(this.ref.nativeElement);
  }

  ngOnChanges(_: SimpleChanges) {
    // Updates the element on input changes
    this.update(this.options);
  }

  ngDoCheck() {
    // Whenever the StripeElements locale has changed...
    if (this.locale !== this.elements.locale) {
      // Disposed of the current element
      this.ngOnDestroy();
      // Creates a ne element
      this.ngOnInit();
      // Updates the locale
      this.locale = this.elements.locale;
    }
  }

  ngOnDestroy() {
    // Resets the ready flag
    this.ready = false;
    // Disposes of the element
    if (typeof this.element?.destroy === "function") {
      this.element.destroy();
    }
  }

  /** Updates the element */
  public update(options: ElementOptions<T>) {
    if (!this.element) {
      return;
    }

    // Ensures to correctly reflect the disabled status
    if ("disabled" in options) {
      this.disabled = options.disabled;
    }

    // Updates the element
    this.element.update(options);
  }

  /** Focus the element */
  public focus() {
    if (isFunction(this.element?.focus)) {
      this.element.focus();
    }
  }

  /** Blurs the element */
  public blur() {
    if (isFunction(this.element?.blur)) {
      this.element.blur();
    }
  }

  /** Clears the element */
  public clear() {
    if (isFunction(this.element?.clear)) {
      this.element.clear();
    }
  }

  /** Emits when fully loaded */
  // tslint:disable-next-line
  @Output("ready")
  readyChange = new EventEmitter();

  /** Emits when focused */
  // tslint:disable-next-line
  @Output("focus")
  focusChange = new EventEmitter();

  /** Emits when blurred */
  // tslint:disable-next-line
  @Output("blur")
  blurChange = new EventEmitter();

  /** Emits on status changes */
  // tslint:disable-next-line
  @Output("change")
  valueChange = new EventEmitter<ChangeEventObject<T>>();
}
