import { InjectionToken } from "@angular/core";
import { ActionReducerMap } from "@ngrx/store";

import { Action } from "src/app/shared/utilities/state/Actions";
import { RequireSome } from "@qqcw/qqsystem-util";

export const LOGGING_FEATURE_KEY = "LOGGING";

/**
 * LogItem Types
 */

export enum LogLevels {
  FATAL = "Fatal",
  ERROR = "Error",
  WARNING = "Warning",
  INFO = "Info",
  DEBUG = "Debug",
  TRACE = "Trace",
}

export interface ActionType {
  name: string;
  type?: string;
  namespace?: string;
}

export interface LogItem {
  // General LogItem Information
  level: LogLevels;
  timestamp: number;
  message: string;
  component: string;
  alert?: boolean; // Flag to send notification to on-call

  // Action Specific LogItem Information
  actionType: ActionType;
  data: Record<string, any>;

  // Application Specific LogItem Information
  app: string;
  version: string;
  commit: string;
}

export type LogArray = LogItem[];
export type ActionSerializer = (a: Action<any>) => LogItem;

/**
 * Logging Module Config
 */
export interface LoggingConfig {
  apiUrl: string;
  sendInterval: number;
  throttleSend: number;
  disableLogging: boolean;
}

export const LOGGING_REDUCER_TOKEN = new InjectionToken<ActionReducerMap<any>>(
  LOGGING_FEATURE_KEY
);
export const LOGGING_CONFIG_PROVIDER = new InjectionToken<LoggingConfig>(
  "LOGGING_CONFIG"
);

/**
 * Logging Service Interfaces
 */
export interface BatchLogRequest {
  logs: LogArray;
}

export type BatchLogResponse = object;

/**
 * Logging API Interfaces
 */
export type ApiBatchLogRequest = LogArray;
export type ApiBatchLogResponse = unknown;

/**
 * Other Log Actions
 */
export type LogAction = RequireSome<LogItem, "message">;
export type AddLog = LogItem;
