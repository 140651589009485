<mat-icon
  class="myqq-button-icon"
  *ngIf="matIcon"
  [color]="iconColor || color || ''"
>
  {{ matIcon }}
</mat-icon>

<mat-icon
  class="myqq-button-icon"
  *ngIf="svgIcon"
  [color]="iconColor || color || ''"
  [svgIcon]="svgIcon"
></mat-icon>

<span class="myqq-button-content">
  <ng-content></ng-content>
</span>
