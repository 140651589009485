<ul>
  <li class="fld-row flg-5 jc-spb fs-u1 fw-u3">
    <span>Due today</span>
    <span>{{ (priceOverride ?? orderTotal) | currency }}</span>
  </li>

  <li
    class="fld-row flg-5 jc-spb pwy-5"
    *ngIf="!!currentSubscription?.renewalAmount"
  >
    <span>Previous billing amount</span>
    <span>{{ currentSubscription?.renewalAmount | currency }}</span>
  </li>

  <li class="fld-col flg-3">
    <span>
      Your new billing amount is
      <strong>{{ nextBillingAmount | currency }}</strong
      >.
    </span>
    <span>
      It is due every month on the
      <strong>{{
        currentSubscription?.renewalDate | map: getNextBillingDate | ordinal
      }}</strong
      >.
    </span>
    <small *ngIf="promoMessage" id="promo-message" class="mwt-5">{{
      promoMessage
    }}</small>
  </li>
</ul>
