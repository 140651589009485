import { NgModule } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";

import { MyqqButtonModule } from "../button/button.module";
import { RoutedModalComponent } from "./routed-modal.component";
import { A11yModule } from "@angular/cdk/a11y";
import { RouteHistoryService } from "../../services/route-history.service";

@NgModule({
  imports: [MatIconModule, MyqqButtonModule, A11yModule],
  exports: [RoutedModalComponent],
  declarations: [RoutedModalComponent],
  providers: [RouteHistoryService],
})
export class ModalRouteModule {}
