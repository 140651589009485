import { NgModule, ErrorHandler } from "@angular/core";
import { RouterModule } from "@angular/router";

import { GlobalErrorHandler } from "./global-error-handler.service";

@NgModule({
  imports: [RouterModule],
  exports: [],
  declarations: [],
  providers: [{ provide: ErrorHandler, useClass: GlobalErrorHandler }],
})
export class GlobalErrorHandlerModule {}
