<myqq-routed-modal [hideFooter]="true">
  <span modal-header>{{ header }}</span>
  <ng-container [useDatumEither]="submitOrder$ | async">
    <ng-container *onInitial [useOption]="pendingOrder$ | async">
      <ng-container *onNone [useOption]="membershipPurchase$ | async">
        <ng-container *onSome="let membershipPurchase">
          <myqq-cart-new-membership
            [membershipPurchase]="membershipPurchase"
            [promoDetails]="chains.deletedPromo ? {} : (promoDetails$ | async)"
            (addVehicle)="handleCreateVehicle()"
          ></myqq-cart-new-membership>
          <footer>
            <button
              myqq-button
              aria-label="purchase"
              id="purchase-btn"
              class="save-btn raised"
              color="primary"
              disabled="true"
            >
              Purchase
            </button>
          </footer>
        </ng-container>
        <ng-container *onNone>
          <ng-container *ngTemplateOutlet="defaultErrorTemplate">
          </ng-container>
        </ng-container>
      </ng-container>
      <ng-container
        *onSome="let pendingOrder"
        [useDatumEither]="contextData$ | async"
      >
        <myqq-loading *onInitial class="fls-1-1">
          <h3>Loading Cart...</h3>
        </myqq-loading>
        <myqq-loading *onPending class="fls-1-1">
          <h3>Checking Order...</h3>
        </myqq-loading>
        <ng-container
          *onFailure="let errorResponse; let refreshingContextData = refreshing"
        >
          <myqq-error
            title="Order Setup Error"
            description="We encountered an error while getting the data
                  necessary
                  to process your order."
            [error]="errorResponse"
            class="fls-1-1 qq-buttons"
          >
            <footer>
              <button
                myqq-button
                aria-label="refresh"
                id="refresh-button"
                class="save-btn raised"
                color="primary"
                [disabled]="refreshingContextData"
                (click)="handleGetData()"
                (keyup.enter)="handleGetData()"
                (keydown.enter)="$event.preventDefault()"
              >
                {{ refreshingContextData ? "Refreshing..." : "Refresh" }}
              </button>
            </footer>
          </myqq-error>
        </ng-container>
        <ng-container
          *onSuccess="let contextData; let refreshingContextData = refreshing"
        >
          <ng-container
            *ngrxLet="({vehicles: vehicles$ | async, calculateOrder: calculateOrder$ | async, newSubscriptionPlan: newSubscriptionPlan$ | async}) as vm;"
          >
            <div
              *ngIf="!!calculateOrderError && calculateOrderError !== invalidCouponErr"
            >
              We ran into an issue calculating your order. Please try again, or
              contact Customer Care at
              <a href="tel:1{{ phoneNumber }}">{{ phoneNumber | phone }}</a>.
              <button
                myqq-button
                aria-label="refresh"
                id="refresh-button"
                class="raised"
                color="primary"
                (click)="handleGetData()"
                (keyup.enter)="handleGetData()"
                (keydown.enter)="$event.preventDefault()"
              >
                Refresh
              </button>
            </div>
            <myqq-cart-details-display
              #cart
              [membershipPurchaseOption]="membershipPurchase$ | async"
              [currentSubscription]="contextData.currentSubscription"
              [modifySubscriptionPlan]="contextData.modifySubscriptionPlan"
              [currentSubscriptionPlan]="
        contextData | map: getCurrentSubscriptionPlan
      "
              [newSubscriptionPlan]="vm.newSubscriptionPlan"
              [refreshing]="refreshingContextData"
              [order]="vm.calculateOrder"
              [paymentMethods]="contextData.paymentMethods"
              [priceTable]="contextData.priceTable"
              [vehicles]="vm.vehicles"
              [promoDetails]="chains.deletedPromo ? {} : (promoDetails$ | async)"
              [calculateOrderError]="calculateOrderError"
              (clearPromoCode)="handleClearPromoCode()"
              (createPaymentMethod)="handleCreatePaymentMethod()"
              (createVehicle)="handleCreateVehicle()"
              (tryPromoCode)="handleTryPromo($event)"
              (recalculateOrderWithVehicles)="
        handleRecalculateOrderWithVehicles($event, vm.calculateOrder, vm.vehicles, vm.newSubscriptionPlan, contextData)"
            >
            </myqq-cart-details-display>
            <footer *ngIf="vm.calculateOrder?.orderType" class="qq-buttons">
              <button
                myqq-button
                aria-label="submit"
                id="submit-button"
                class="save-btn raised"
                color="primary"
                aria-label="Purchase"
                (click)="
          handleSubmitOrder(
            vm.calculateOrder,
            contextData.paymentMethods,
            pendingOrder,
            contextData.salesPipeline
          )
        "
                (keyup.enter)="
          handleSubmitOrder(
            vm.calculateOrder,
            contextData.paymentMethods,
            pendingOrder,
            contextData.salesPipeline
          )
        "
                (keydown.enter)="$event.preventDefault()"
                [disabled]="
          refreshingContextData || !contextData.paymentMethods?.length || !!calculateOrderError
        "
              >
                {{ refreshingContextData ? "" : ( vm.calculateOrder | map:
                getActionText) }}
                <mat-spinner
                  diameter="24"
                  *ngIf="refreshingContextData"
                ></mat-spinner>
              </button>
            </footer>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
    <myqq-loading *onPending class="fls-1-1">
      <h3>Submitting Order...</h3>
    </myqq-loading>
    <myqq-error
      *onFailure="let error; let refreshingSubmitOrder = refreshing"
      title="Order Submission Error"
      description="We encountered an issue while trying to submit your order."
      [error]="error"
    >
      <footer class="qq-buttons">
        <button
          myqq-button
          aria-label="Try Again"
          class="raised"
          color="primary"
          [disabled]="refreshingSubmitOrder"
          (click)="handleResubmitOrder()"
          (keyup.enter)="handleResubmitOrder()"
          (keydown.enter)="$event.preventDefault()"
        >
          {{ refreshingSubmitOrder ? "Submitting..." : "Try Again" }}
        </button>
      </footer>
    </myqq-error>
  </ng-container>
  <ng-template #defaultErrorTemplate>
    <myqq-error
      *ngIf="showErrorComponent"
      title="Empty Order Error"
      description="There is no pending order for your account. If this is
      unexpected please contact Customer Care."
      class="fls-1-1"
    >
    </myqq-error>
  </ng-template>
</myqq-routed-modal>
