import { Injectable, ErrorHandler, NgZone, Inject } from "@angular/core";
import { Router, RoutesRecognized } from "@angular/router";

import { filter, map, pairwise } from "rxjs/operators";

import { PwaUpdatePromptService } from "src/app/shared/services/pwa-update-prompt.service";
import { LoggingService } from "../ngrx/logger/logging.service";

import { WINDOW } from "../services/window.service";

const ignoreOffline = ["Error: Unable to load StripeJS"];
const chunkLoadError = /Loading chunk [\d]+ failed/;
const maxHistory = 10;

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {
  navPath: string[] = [];
  constructor(
    readonly router: Router,
    readonly zone: NgZone,
    readonly pwaUpdatePrompt: PwaUpdatePromptService,
    readonly log: LoggingService,
    @Inject(WINDOW) private window: Window
  ) {
    super();
    // Keep track of navigation history to assist in troubleshooting errors
    this.router.events
      .pipe(
        filter((evt: any) => evt instanceof RoutesRecognized),
        map(({ url }) => url),
        pairwise()
      )
      .subscribe(([prev, cur]) => {
        // Makeshift queue to prevent history from growing indefinitely
        if (
          this.navPath.push(`${this.timestamp()}: ${prev} => ${cur}`) >
          maxHistory
        ) {
          this.navPath.shift();
        }
      });
  }

  handleError(error: unknown) {
    console.warn("Global error handler caught an unhandled error!");
    console.error(error);

    let errorString: string;
    try {
      errorString = error.toString();
    } catch (_) {
      errorString = JSON.stringify(error);
    }

    // Handle rare case where new build causes chunk names to change
    // and this is not properly handled by the service workers
    if (chunkLoadError.test(errorString)) {
      const m = "Error due to mismatch in build files, prompt to update";
      console.warn(m);

      this.pwaUpdatePrompt.promptForUpdate();

      return;
    }

    // Ignore some errors while app is offline
    if (!this.window.navigator.onLine && ignoreOffline.includes(errorString)) {
      console.log("Offline, ignoring this error");

      return;
    }
    this.zone.run(() =>
      this.router.navigate(["/error"], {
        state: {
          title: "Application Error",
          description: "An unknown error occurred that we did not foresee.",
          error: errorString,
          history: this.navPath.join("\n"),
          userAgent: this.window?.navigator?.userAgent,
        },
      })
    );
  }

  timestamp() {
    try {
      return new Date().toLocaleTimeString("en-US", {
        timeZone: "America/Los_Angeles",
        hour12: false,
        timeZoneName: "short",
      });
    } catch {
      return new Date().toISOString();
    }
  }
}
