import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { BehaviorSubject } from "rxjs";
import { log, LogLevels } from "src/app/core/ngrx/logger";
import { OfflineAuthService } from "src/app/core/services/offline-auth.service";
import { PwaService } from "src/app/shared/services/pwa.service";

interface ErrorRouteExtras {
  title?: string;
  description?: string;
  error?: unknown;
}

/**
 * This is a fallback page for errors that we can't handle. It should
 * supply a means of contacting support as well as error details that
 * will be useful in troubleshooting.
 *
 * To pass an error via navigation use the router NavigationExtras. We
 * use navigation extras instead of a service because an error caught
 * by zone.js (ie. unhandled) can break the service dependencies. The
 * angular router itself is unlikely to throw so it is safe to pass the
 * actual error message through it.
 *
 * @example
 * this.router.navigate(['/error'], { state: { error: 'Unable to login.' }});
 */
@Component({
  templateUrl: "./error.page.html",
  styleUrls: ["./error.page.scss"],
})
export class ErrorPage implements OnInit {
  readonly state?: ErrorRouteExtras = this.router.getCurrentNavigation()?.extras
    ?.state;

  online: BehaviorSubject<boolean> = new BehaviorSubject(true);

  constructor(
    readonly router: Router,
    readonly store$: Store<any>,
    readonly offlineAuth: OfflineAuthService,
    readonly pwa: PwaService
  ) {
    this.online = this.offlineAuth.online$;
  }

  ngOnInit() {
    // If there is no error state set (e.g. if user navigates directly to
    // /error) just redirect to home.
    if (!this.state) {
      log({
        level: LogLevels.ERROR,
        message: "Error page accessed without error data.",
      });

      return this.handleRefresh();
    }

    this.store$.dispatch(
      log({
        level: LogLevels.FATAL,
        data: this.state,
        message: this.online.value
          ? "An unknown error occurred that we did not foresee."
          : "An error occured because app was offline.",
      })
    );
  }

  handleRefresh() {
    try {
      this.pwa.updateAndReload();
    } catch (e) {
      console.error(e);
      this.router.navigate(["/"]);
    }
  }
}
