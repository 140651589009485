import { NgModule } from "@angular/core";

import { OrdinalPipe } from "./ordinal.pipe";

@NgModule({
  imports: [],
  exports: [OrdinalPipe],
  declarations: [OrdinalPipe],
  providers: [],
})
export class OrdinalPipeModule {}
