import { Component, ChangeDetectionStrategy } from "@angular/core";
import { fadeInOut } from "../../utilities/animations";

@Component({
  selector: "myqq-loading",
  templateUrl: "./loading.component.html",
  styleUrls: ["./loading.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInOut("fadeInOut", 350)],
})
export class LoadingComponent {
  constructor() {}
}
