import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import {
  selectAccountQuota,
  getAccountQuota,
  selectEditVehicleIdentifier,
  editVehicleIdentifier,
  clearEditVehicleIdentifier,
  selectVehicles,
  getVehicles,
  selectAccountAndMembershipStatus,
  getAccountInfo,
  selectPriceTable,
  getPriceTable,
} from "src/app/core/ngrx/myqq";
import {
  OrderTypesEnum,
  RegionMenu,
  Vehicle,
} from "src/app/core/services/myqq";
import { combineS } from "src/lib/datum-either";
import { getPlanMembershipBadgeColor } from "src/lib/util";

import { map, first } from "rxjs/operators";
import { map as mapDE, isSuccess } from "@nll/datum/DatumEither";
import { Subscription } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { GenericAlertComponent } from "src/app/shared/components/generic-alert/generic-alert.component";
import { quotaDisclaimer } from "src/app/shared/constants";
import { errors } from "src/app/core/services/myqq/myqq.errors";

@Component({
  templateUrl: "./edit-vehicle-identifiers.page.html",
})
export class EditVehicleIdentifiersPage implements OnInit, OnDestroy {
  currentVehicle: Vehicle = this.router.getCurrentNavigation()?.extras.state
    ?.vehicle;
  readonly postEditVehicle$ = this.store$.select(selectEditVehicleIdentifier);
  readonly contextData$ = combineS({
    accountQuota: this.store$.select(selectAccountQuota).pipe(
      map(
        mapDE((data) => ({
          ...data,
          quotaRemaining: data.quotaAllowed - data.quotaConsumed,
        }))
      )
    ),
    vehicles: this.store$.select(selectVehicles),
    menu: this.store$.select(selectPriceTable),
  });
  readonly errVehicleOwnership = errors.myqqApi.errVehicleOwnership;
  readonly hasMembership$ = this.store$
    .select(selectAccountAndMembershipStatus)
    .pipe(
      map((valueDE) => {
        if (isSuccess(valueDE)) {
          return valueDE?.value.right?.hasMembership;
        } else return false;
      })
    );
  showConfirmStep = false;
  showVehicleOnAccountError = false;
  showQuotaWillBeConsumed = true;
  modVehicle: Vehicle;
  private subs: Subscription[] = [];

  ngOnInit() {
    this.showConfirmStep = false;
    if (this.currentVehicle) {
      this.handleGetData();
    } else {
      this.router.navigateByUrl("/vehicles");
    }
  }
  ngOnDestroy(): void {
    this.store$.dispatch(clearEditVehicleIdentifier(null));
    this.subs?.forEach((s) => s.unsubscribe());
  }

  readonly getMembershipBadgeColor = (
    vehicle: Vehicle,
    menu: RegionMenu
  ): string => {
    return getPlanMembershipBadgeColor(vehicle.washLevelId, menu.plans);
  };

  handleGetData() {
    this.store$.dispatch(getAccountQuota.pending(null));
    this.store$.dispatch(getVehicles.pending(null));
    this.store$.dispatch(getAccountInfo.pending(null));
    this.store$.dispatch(getPriceTable.pending(null));
  }

  onSuccess() {
    this.store$.dispatch(clearEditVehicleIdentifier(null));
    this.router.navigate([{ outlets: { primary: "thank-you", modal: null } }], {
      state: {
        orderType: OrderTypesEnum.ADDMEMBERSHIP,
        showSubscriptionInfo: false,
      },
    });
  }

  handleSubmit() {
    this.store$.dispatch(editVehicleIdentifier.pending(this.modVehicle));
    this.subs.push(
      this.postEditVehicle$
        .pipe(first(isSuccess))
        .subscribe(() => this.onSuccess())
    );
  }

  handleShowDisclaimer() {
    this.dialog.open(GenericAlertComponent, {
      disableClose: false,
      data: {
        title: "Disclaimer",
        message: quotaDisclaimer,
      },
    });
  }
  handleSetModVehicle(newVehicle: Vehicle, vehicles: Vehicle[]) {
    if (
      this.isVehicleOnAccountAndMembership(newVehicle, vehicles) &&
      !this.isCurrentVehicleWithIdentifiersAdded(newVehicle)
    ) {
      this.showVehicleOnAccountError = true;
    } else {
      this.modVehicle = newVehicle;
      this.showConfirmStep = true;
    }
    this.showQuotaWillBeConsumed = !(
      this.isVehicleOnAccountAndMembership &&
      this.isCurrentVehicleWithIdentifiersAdded(newVehicle)
    );
  }

  isVehicleOnAccountAndMembership(newVehicle, vehicles): boolean {
    const vehicleIsOnAccount = vehicles.find((oldVehicle) => {
      return (
        oldVehicle.hasMembership && this.isSameVehicle(newVehicle, oldVehicle)
      );
    });
    return !!vehicleIsOnAccount;
  }

  isSameVehicle(newVehicle, oldVehicle): boolean {
    if (!!oldVehicle.state && !!oldVehicle.license && !!oldVehicle.vin) {
      return (
        oldVehicle.state?.toLowerCase() === newVehicle.state?.toLowerCase() &&
        oldVehicle.license?.toLowerCase() ===
          newVehicle.license?.toLowerCase() &&
        oldVehicle.vin?.toLowerCase() === newVehicle.vin?.toLowerCase()
      );
    }
    if (!!oldVehicle.state && !!oldVehicle.license) {
      return (
        oldVehicle.state?.toLowerCase() === newVehicle.state?.toLowerCase() &&
        oldVehicle.license?.toLowerCase() === newVehicle.license?.toLowerCase()
      );
    }
    if (!!oldVehicle.vin) {
      return oldVehicle.vin?.toLowerCase() === newVehicle.vin?.toLowerCase();
    }
    return false;
  }

  isCurrentVehicleWithIdentifiersAdded(newVehicle: Vehicle): boolean {
    const isCurrentVehicle = this.isSameVehicle(
      newVehicle,
      this.currentVehicle
    );
    const vinAdded = !this.currentVehicle.vin && !!newVehicle.vin;
    const lpStateAdded =
      (!this.currentVehicle.license && !!newVehicle.license) ||
      (!this.currentVehicle.state && !!newVehicle.state);
    return isCurrentVehicle && (vinAdded || lpStateAdded);
  }

  handleRefresh() {
    this.showVehicleOnAccountError = false;
    this.store$.dispatch(clearEditVehicleIdentifier(null));
    this.handleGetData();
  }

  handleDismissErrorBanner() {
    this.showVehicleOnAccountError = false;
    this.store$.dispatch(clearEditVehicleIdentifier(null));
  }

  handleRelink() {
    this.router.navigateByUrl("/relink");
  }

  handleBack() {
    this.showConfirmStep = false;
    this.handleDismissErrorBanner();
  }

  constructor(
    readonly store$: Store<any>,
    readonly router: Router,
    readonly dialog: MatDialog
  ) {}
}
