import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { ModalRouteModule } from "src/app/shared/components/routed-modal";
import { DatumModule } from "src/app/shared/directives/datum";
import { SharedModule } from "src/app/shared/shared.module";

import { VehicleSwapPage } from "./vehicle-swap.page";
import { MyqqButtonModule } from "src/app/shared/components/button/button.module";
import { MatIconModule } from "@angular/material/icon";
import { MatCardModule } from "@angular/material/card";
import { VehicleSwapConfirmComponent } from "./vehicle-swap-confirm/vehicle-swap-confirm.component";
import { VehicleSwapReasonComponent } from "./vehicle-swap-reason/vehicle-swap-reason.component";
import { VehicleSwapInitialComponent } from "./vehicle-swap-initial/vehicle-swap-initial.component";
import { MatButtonModule } from "@angular/material/button";
import { MatDividerModule } from "@angular/material/divider";
import { VehicleDisplayModule } from "src/app/shared/components/vehicle-display/vehicle-display.module";
import { MapPipeModule } from "src/app/shared/pipes/map";
import { AccountQuotaMessageModule } from "src/app/shared/components/account-quota-message/account-quota-message.module";
import { GenericAlertModule } from "src/app/shared/components/generic-alert/generic-alert.module";

@NgModule({
  declarations: [
    VehicleSwapConfirmComponent,
    VehicleSwapInitialComponent,
    VehicleSwapPage,
    VehicleSwapReasonComponent,
  ],
  imports: [
    CommonModule,
    DatumModule,
    MatButtonModule,
    MatCardModule,
    MatDividerModule,
    MatIconModule,
    ModalRouteModule,
    MyqqButtonModule,
    SharedModule,
    VehicleDisplayModule,
    MapPipeModule,
    AccountQuotaMessageModule,
    GenericAlertModule,
  ],
  exports: [VehicleSwapPage],
})
export class VehicleSwapModule {}
