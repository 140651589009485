import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptors,
} from "@angular/common/http";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";

import { logger } from "src/app/shared/utilities/state/Logger";
import { environment } from "src/environments/environment";

// Ngrx
import { NgrxMyQQModule } from "./ngrx/myqq";
import { NgrxLoggingModule } from "./ngrx/logger";

// External Modules
import { KeycloakModule } from "./keycloak/keycloak.module";

// Interceptors
import { RetryNetworkErrorInterceptor } from "./interceptors/retry-network-error.interceptor";

// Error Handlers
import { GlobalErrorHandlerModule } from "./global-error-handler";
import { NgrxUIModule } from "./ngrx/ui";

// Window object service
import { WINDOW_PROVIDERS } from "./services/window.service";

// Router NGRX module
import { RouterModule } from "./ngrx/router/router.module";

// Segment
import { SegmentModule } from "./ngrx/segment/segment.module";
import { loggerInterceptor } from "./interceptors/http-logger.interceptor";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    KeycloakModule, // Has APP_INITIALIZER

    // Wireup Root Ngrx Store
    StoreModule.forRoot({}, { metaReducers: [logger("SHOW_LOG")] }),
    EffectsModule.forRoot([]),
    environment.production ? [] : StoreDevtoolsModule.instrument(),

    // Wireup Feature Ngrx Stores
    NgrxLoggingModule.forRoot({
      apiUrl: environment.URLs.MyQQ,
      sendInterval: 10 * 1000,
      throttleSend: 5 * 1000,
      disableLogging: environment.disableRemoteLogging,
    }),
    NgrxMyQQModule,
    NgrxUIModule,
    RouterModule,

    // Global Error Handler
    GlobalErrorHandlerModule,

    SegmentModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RetryNetworkErrorInterceptor,
      multi: true,
    },
    provideHttpClient(withInterceptors([loggerInterceptor])),
    WINDOW_PROVIDERS,
  ],
})
export class CoreModule {}
