import { FocusableOption, FocusMonitor, FocusOrigin } from "@angular/cdk/a11y";
import { coerceBooleanProperty } from "@angular/cdk/coercion";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  ViewEncapsulation,
} from "@angular/core";

@Component({
  selector: "button[myqq-button]",
  exportAs: "myqqButton",
  // tslint:disable-next-line: no-host-metadata-property
  host: {
    "[attr.disabled]": "disabled || null",
    // Add a class for disabled button styling instead of the using attribute
    // selector or pseudo-selector.  This allows users to create focusabled
    // disabled buttons without recreating the styles.
    "[class.myqq-button-disabled]": "disabled",
    "[class.myqq-button]": "true",
  },
  templateUrl: "./button.component.html",
  styleUrls: ["./button.component.scss"],
  // tslint:disable-next-line: use-component-view-encapsulation
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
// tslint:disable-next-line: component-class-suffix
export class MyqqButton implements AfterViewInit, OnDestroy, FocusableOption {
  constructor(
    private _elementRef: ElementRef,
    private _focusMonitor: FocusMonitor
  ) {}

  @Input() color?: string;
  @Input() iconColor?: string;
  @Input() matIcon?: string;
  @Input() svgIcon?: string;

  private _disabled = false;
  @Input()
  get disabled() {
    return this._disabled;
  }
  set disabled(value: any) {
    this._disabled = coerceBooleanProperty(value);
  }

  ngAfterViewInit() {
    this._focusMonitor.monitor(this._elementRef, true);
  }

  ngOnDestroy() {
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

  /** Focuses the button. */
  focus(origin: FocusOrigin = "program", options?: FocusOptions): void {
    this._focusMonitor.focusVia(this._getHostElement(), origin, options);
  }

  private _getHostElement() {
    return this._elementRef.nativeElement;
  }
}
