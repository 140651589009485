import { EnvironmentConfig } from "./config.interface";
import {
  globalConfig,
  segmentProxyUrl,
  segmentSnippetVersion,
} from "./global-config";
import { registerUrl } from "./env-utils";

const origin = "https://myqq.dontdrivedirty.com";
const home = origin;
const keycloakUrl = "https://auth.dontdrivedirty.com";
const unleashClientKey =
  "myqq:production.87f531c727a800b7240a9a73a3bcc24de51deda374288014756fc16a";
const segmentWriteKey = "iVHEhfxtMiZJiMUWPpzjGXL3vhjD7zqP";

const googleApiKey = "AIzaSyBZgha-I7wSnX0hXNlIcA1tEWqrxWmZcEY";

const refinerProject = "d9b980b0-7119-11ed-ad55-ebcf019590b9";

export const environment: EnvironmentConfig = {
  ...globalConfig,
  // the environment can  include a URLs dictionary to look up microservice URLS
  // (so that microservice URLs can be defined independently)
  URLs: {
    MyQQ: "https://myqq-api.dontdrivedirty.com",
    origin: origin,
  },
  unleash: {
    enable: true,
    unleashUrl: "https://unleash.prd.qqcw.us/api/frontend",
    unleashEnvironment: "production",
    clientKey: unleashClientKey,
  },
  keycloak: {
    config: {
      realm: "myqq",
      url: keycloakUrl + "/auth",
      clientId: "myqq",
    },
    registerUrl: registerUrl(keycloakUrl),
    initOptions: {
      onLoad: "check-sso",
      redirectUri: home,
      checkLoginIframe: false,
      pkceMethod: "S256",
    },
    loginOptions: {
      redirectUri: home,
    },
    logoutOptions: {
      redirectUri: home,
    },
    changePassword: keycloakUrl + "/auth/realms/myqq/account/password/",
    offlineTokenDuration: 60,
  },

  stripeConfig: {
    publishableKey: "pk_live_3IgIz6payNrhntkdc1KHEkIT00PM2oEWc7",
  },
  production: true, // should we run production optimisations?
  useMockHttpServices: false,
  showErrorDetail: false,

  segmentConfig: {
    snippet: `
!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="${segmentProxyUrl}/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="${segmentWriteKey}";;analytics.SNIPPET_VERSION="${segmentSnippetVersion}";
analytics.load("${segmentWriteKey}");
}}();`,
    track: true,
  },

  googleApiConfig: {
    url: `https://maps.googleapis.com/maps/api/js?key=${googleApiKey}&region=US`,
  },

  twaId: "com.dontdrivedirty.myqq.twa",

  refinerConfig: {
    snippet: `
    window._refinerQueue = window._refinerQueue || []; function _refiner(){_refinerQueue.push(arguments);} _refiner('setProject', '${refinerProject}'); (function(){var a=document.createElement("script");a.type="text/javascript";a.async=!0;a.src="https://js.refiner.io/v001/client.js";var b=document.getElementsByTagName("script")[0];b.parentNode.insertBefore(a,b)})();
    `,
    enable: false,
  },
};
