import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { KeycloakService } from "keycloak-angular";
import { selectKeycloakInitialized } from "src/app/core/ngrx/ui";
import { environment } from "src/environments/environment";
import { IMutableContext, IToggle, UnleashClient } from "unleash-proxy-client";
import { filter, map, startWith, take } from "rxjs/operators";
import {
  AccountInfoResponse,
  SubscriptionDetail,
  SubscriptionItemTypeEnum,
} from "src/app/core/services/myqq";
import { KeycloakProfile } from "keycloak-js";
import { BehaviorSubject, Observable } from "rxjs";
import { isEqual } from "lodash";
import { LogAction, LogLevels, log } from "src/app/core/ngrx/logger";
import { version } from "src/environments/version";

@Injectable({
  providedIn: "root",
})
export class UnleashService {
  private unleash: UnleashClient;
  private keycloakProfile: KeycloakProfile;
  private user: IMutableContext;
  private flagsBS = new BehaviorSubject<IToggle[]>(null);

  readonly enableApplePay$: Observable<boolean> = this.flagsBS.pipe(
    map((flags) => {
      const enabled = flags.find(({ name }) => name == "enableApplePay")
        ?.enabled;
      return !!enabled ? enabled : false;
    }),
    startWith(false)
  );

  readonly enableSurveys$: Observable<boolean> = this.flagsBS.pipe(
    map((flags) => {
      const enabled = flags.find(({ name }) => name == "enable-surveys")
        ?.enabled;
      return !!enabled ? enabled : false;
    }),
    startWith(false)
  );

  readonly enableB2b$: Observable<boolean> = this.flagsBS.pipe(
    map((flags) => {
      const enabled = flags.find(({ name }) => name == "b2bLaunch")?.enabled;
      return !!enabled ? enabled : false;
    }),
    startWith(false)
  );

  readonly enableAndroidRelease$: Observable<boolean> = this.flagsBS.pipe(
    map((flags) => {
      const enabled = flags?.find(({ name }) => name == "enableAndroidRelease")
        ?.enabled;
      return !!enabled ? enabled : false;
    })
  );

  constructor(private keycloak: KeycloakService, private store$: Store<any>) {
    this.unleash = new UnleashClient({
      url: environment.unleash.unleashUrl,
      clientKey: environment.unleash.clientKey,
      environment: environment.unleash.unleashEnvironment,
      appName: "myqq",
      context: {
        properties: {
          callingClient: "WEB",
          clientVersion: version.version,
        },
      },
    });

    this.store$
      .select(selectKeycloakInitialized)
      .pipe(
        filter((init) => init !== null),
        take(1)
      )
      .subscribe((init) => {
        this.keycloakProfile = this.keycloak.getKeycloakInstance()?.profile;
        if (init && this.keycloakProfile) {
          this.user = {
            userId: this.keycloakProfile.id,
            properties: {
              callingClient: "WEB",
              clientVersion: version.version,
              hasSubscription: "false",
              hasQsysAccount: "false",
            },
          };
          this.unleash.updateContext(this.user);
        }
      });
    this.unleash.start();
    this.unleash.on("initialized", this.onReadyAndChange.bind(this));
    this.unleash.on("ready", this.onReadyAndChange.bind(this));
    this.unleash.on("update", this.onReadyAndChange.bind(this));
    this.unleash.on("error", (err) =>
      this.onError(err, "something unexpected happened on unleash")
    );
  }

  onReadyAndChange() {
    this.flagsBS.next(this.unleash.getAllToggles());
    const logContents: LogAction = {
      level: LogLevels.TRACE,
      message: "unleash initialized/ready or updated",
      actionType: {
        name: "UNLEASH_SERVICE",
        type: "SUCCESS",
        namespace: "MYQQ",
      },
    };
    this.store$.dispatch(log(logContents));
  }

  onError(err, message) {
    const logContents: LogAction = {
      level: LogLevels.WARNING,
      message: message,
      actionType: {
        name: "UNLEASH_SERVICE",
        type: "FAILURE",
        namespace: "MYQQ",
      },
    };

    if (err) {
      logContents.data = {
        error: err,
      };
    }
    this.store$.dispatch(log(logContents));
  }

  updateUnleashContextOnGetSubscription(subscription: SubscriptionDetail) {
    // this is only triggered when there is some subscription
    const updatedUser: IMutableContext = {
      ...this.user,
      properties: {
        ...this.user.properties,
        hasSubscription: "true",
        hasQsysAccount: "true",
        regionNumber: subscription.regionNumber,
        subscriptionSku: subscription.subscriptionItems?.find(
          (item) => item.itemType === SubscriptionItemTypeEnum.base
        )?.sku,
        stripeSubscriptionStatus: subscription.stripeSubscription?.status,
        cancelAtPeriodEnd: subscription.stripeSubscription?.cancel_at_period_end.toString(),
      },
    };
    if (!isEqual(updatedUser.properties, this.user.properties)) {
      this.user = updatedUser;
      this.unleash
        .updateContext(updatedUser)
        .catch((err) =>
          this.onError(
            err,
            "error updating users context with subscription data"
          )
        );
    }
  }

  updateUnleashContextOnGetAccount(accountInfo: AccountInfoResponse) {
    const updatedUser: IMutableContext = {
      ...this.user,
      properties: {
        ...this.user.properties,
        hasSubscription: (
          accountInfo.account?.stripeSubscription.status === "active"
        ).toString(),
        hasQsysAccount: (
          !!accountInfo.profile?.info?.qsysAccount &&
          accountInfo.profile.info.qsysAccount != ""
        ).toString(),
      },
    };
    if (!isEqual(updatedUser.properties, this.user.properties)) {
      this.user = updatedUser;
      this.unleash
        .updateContext(updatedUser)
        .catch((err) =>
          this.onError(err, "error updating user's context with account data")
        );
    }
  }
}
