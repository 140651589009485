import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";

import { ModalRouteModule } from "src/app/shared/components/routed-modal";
import { DatumModule } from "src/app/shared/directives/datum";
import { MapPipeModule } from "src/app/shared/pipes/map";
import { SharedModule } from "src/app/shared/shared.module";
import { EditAccountInfoPage } from "./edit-account-info.page";
import { EditAccountInfoComponent } from "./edit-account-info.component";
import { DatepickerModule } from "src/app/shared/components/datepicker/datepicker.module";
import { MyqqButtonModule } from "src/app/shared/components/button/button.module";
import { NgxMaskDirective, provideNgxMask } from "ngx-mask";
import { GenericAlertModule } from "src/app/shared/components/generic-alert/generic-alert.module";
import { MatDialogModule } from "@angular/material/dialog";

@NgModule({
  imports: [
    CommonModule,
    DatepickerModule,
    DatumModule,
    FormsModule,
    MapPipeModule,
    MyqqButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    ModalRouteModule,
    ReactiveFormsModule,
    SharedModule,
    NgxMaskDirective,
    GenericAlertModule,
  ],
  exports: [EditAccountInfoComponent],
  declarations: [EditAccountInfoPage, EditAccountInfoComponent],
  providers: [provideNgxMask()],
})
export class EditAccountInfoModule {}
