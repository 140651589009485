import { fromPairs as _fromPairs, pick as _pick, zip as _zip } from "lodash-es";
import { Replete } from "@nll/datum/Datum";
import {
  datumEither,
  DatumEither,
  failure,
  isSuccess,
  pending,
  success,
  toRefresh,
} from "@nll/datum/DatumEither";
import { sequenceS, sequenceT } from "fp-ts/es6/Apply";
import { Either } from "fp-ts/es6/Either";
import { combineLatest, isObservable, Observable, of } from "rxjs";
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  startWith,
  switchMap,
} from "rxjs/operators";

export const withDatumEither = <E, T>(
  start?: Replete<Either<E, T>>
): ((obs: Observable<T>) => Observable<DatumEither<E, T>>) => (
  obs: Observable<T>
) =>
  obs.pipe(
    map((result) => success(result)),
    startWith(start ? toRefresh<E, T>(start) : pending),
    catchError((e: E) => of(failure(e)))
  );

export const filterSuccessMapToRightValue = <T, E = unknown>(
  obs: Observable<DatumEither<E, T>>
) =>
  obs.pipe(
    filter(isSuccess),
    map((de) => de.value.right)
  );

export const chainSwitchMap = <E, A, B>(
  fn: (a: A) => Observable<DatumEither<E, B>>
) => (obs: Observable<DatumEither<E, A>>): Observable<DatumEither<E, B>> =>
  obs.pipe(
    switchMap((de) =>
      isSuccess(de) ? fn(de.value.right) : of(de as DatumEither<E, B>)
    )
  );

export const sequenceDES = sequenceS(datumEither);
export const sequenceDET = sequenceT(datumEither);

type ToResult<T> = T extends Observable<DatumEither<infer _, infer R>>
  ? R
  : never;

export const combineS = <
  T extends Record<string, Observable<DatumEither<any, any>>>
>(
  data: T
) =>
  <Observable<DatumEither<Error, { [key in keyof T]: ToResult<T[key]> }>>>(
    combineLatest(
      Object.values(data).filter((value) => isObservable(value))
    ).pipe(map((DEs) => sequenceDES(_fromPairs(_zip(Object.keys(data), DEs)))))
  );

export type PickAndCombine<
  T extends Record<string, Observable<DatumEither<Error, any>>>,
  K extends keyof T
> = Observable<DatumEither<Error, Pick<{ [k in keyof T]: ToResult<T[k]> }, K>>>;

export const pickAndCombineS = <
  T extends Record<string, Observable<DatumEither<Error, any>>>,
  K extends keyof T
>(
  data: T,
  ...fields: K[]
): PickAndCombine<T, typeof fields[number]> =>
  combineS(_pick(data, ...fields)).pipe(distinctUntilChanged());
