<div id="banner-container">
  <div id="banner-content">
    <img
      id="banner-image"
      src="{{ cdnHost }}/img/doctor-quackals.png"
      alt="Duck doctor"
    />
    <div id="banner-message">
      <p>{{ message }}</p>
      <p *ngIf="showContactCustomerSupport">
        If the problem persists, please contact Customer Care at
        {{ supportNumber | phone }}
      </p>
      <ng-content></ng-content>
    </div>
  </div>
  <div id="button-container">
    <button id="dismiss-button" (click)="dismiss.emit()">
      DISMISS
    </button>
  </div>
</div>
