import { format, isBefore, isValid, parseISO, subYears } from "date-fns";
import { Subscription } from "rxjs";
import { Component, OnInit, Input, OnDestroy } from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { States, PhoneRegexp, notNil, ZipRegexp } from "@qqcw/qqsystem-util";

import { Account } from "src/app/core/services/myqq";
import { phoneMask, zipMask } from "src/app/shared/utilities/ngx-mask-custom";

@Component({
  selector: "myqq-edit-account-info",
  templateUrl: "./edit-account-info.component.html",
  styleUrls: ["./edit-account-info.component.scss"],
})
export class EditAccountInfoComponent implements OnDestroy, OnInit {
  constructor(private readonly fb: FormBuilder) {}

  readonly _18YearsAgo = subYears(new Date(), 18);

  public get valid() {
    return this.form.valid && !this.form.pristine;
  }

  public get value() {
    // we are using getRawValue() instead of just value
    // because the birthDate field can be disabled. When
    // a field is disabled, Angular doesn't return the value
    // when using form.value

    const birthDate: Date | string =
      typeof this.form.getRawValue().birthDate === "string"
        ? parseISO(this.form.getRawValue().birthDate)
        : this.form.getRawValue().birthDate;
    return {
      ...this.form.value,
      birthDate: !!birthDate ? format(birthDate as Date, "yyyy-MM-dd") : null,
    };
  }

  @Input()
  details?: Account;

  form: FormGroup<{
    personId: FormControl<string>;
    firstName: FormControl<string>;
    lastName: FormControl<string>;
    birthDate: FormControl<string>;
    primaryPh: FormControl<string>;
    email: FormControl<string>;
    street1: FormControl<string>;
    street2: FormControl<string>;
    city: FormControl<string>;
    state: FormControl<string>;
    zipCode: FormControl<string>;
  }>;
  subs: Subscription[];

  readonly states = States;
  readonly phoneMask = phoneMask;
  readonly zipMask = zipMask;

  ngOnInit() {
    this.createForm(this.details);
    if (notNil(this.details)) {
      this.resetForm(this.details);
    }
  }

  ngOnDestroy() {
    this.subs.forEach((s) => s.unsubscribe());
  }

  createForm(det: Account) {
    // Create form
    this.form = this.fb.group({
      personId: [],
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
      birthDate: [""],
      primaryPh: ["", Validators.pattern(PhoneRegexp)],
      // Per Joe, email is not editable
      email: this.fb.control({ value: "", disabled: true }),
      street1: ["", this.notNullableIfNotNull(!det.street1)],
      street2: [
        "",
        [
          this.notNullableIfNotNull(!det.street2),
          this.disallowWithOut("street1"),
        ],
      ],
      city: [
        "",
        [this.notNullableIfNotNull(!det.city), this.requireWith("street1")],
      ],
      state: ["", this.requireWith("city")],
      zipCode: ["", [Validators.required, Validators.pattern(ZipRegexp)]],
    });

    // Watch changes that affect other form controls' validity
    const c = this.form.controls;
    this.subs = [
      c.street1.valueChanges.subscribe(() => {
        c.street2.markAsTouched();
        c.street2.updateValueAndValidity();
      }),
      c.street1.valueChanges.subscribe(() => {
        c.city.markAsTouched();
        c.city.updateValueAndValidity();
      }),
      c.city.valueChanges.subscribe(() => {
        c.state.markAsTouched();
        c.state.updateValueAndValidity();
      }),
    ];
  }

  resetForm(details: Account) {
    this.form.patchValue(details);

    // per Joe, birthDate is not editable once it has been set
    if (this.form.controls["birthDate"].value !== null) {
      this.form.controls["birthDate"].disable();
    }

    this.form.markAsUntouched();
  }

  _validDate(date: Date) {
    return isValid(date);
  }

  _before(date: Date, date2: Date) {
    return isBefore(date, date2);
  }

  /**  Validators  */

  /**
   * Currently there are two limitations in QSYS for PATCH calls to update a Person
   * in the database;
   *  1. if we send null, the parameter will be omitted and that column will not update
   *  2. single-character values are not allowed for address fields.
   *
   * So we can't allow null values, unless the are already null. and we can't
   * allow single-character address values
   */
  private notNullableIfNotNull = (initialNull: boolean): ValidatorFn => (
    control: AbstractControl
  ): ValidationErrors | null =>
    (initialNull || !!control.value) && control.value?.length !== 1
      ? null
      : { notNullableIfNotNull: true };

  // If the provided form control has a value, require this one
  private requireWith = (formControlName: string): ValidatorFn => (
    control: AbstractControl
  ): ValidationErrors | null =>
    !this.form?.get(formControlName).value || !!control.value
      ? null
      : { requireWith: true };

  // If the provided form doesn't have a value, don't allow a value for this one
  private disallowWithOut = (formControlName: string): ValidatorFn => (
    control: AbstractControl
  ): ValidationErrors | null =>
    (!!this.form?.get(formControlName).value && !!control.value) ||
    !control.value
      ? null
      : { disallowWithOut: true };
}
