<div
  *ngIf="!!targetSubscriptionPlan && !!currentSubscriptionPlan"
  class="vw-p100 fld-col"
>
  <div class="fs-u3 fw-u3">From</div>

  <div class="fld-row fld-dsk-col vw-p100 pwl-5">
    <span class="fld-col plan-dsc brt-1 fls-1-1">
      <span class="ds-dsk-only fs-d1">your current plan</span>
      <span class="vw-p90 fld-dsk-col fs-u1 fw-u1 plan-title">
        <span>
          {{ currentSubscriptionPlan.myQQShortDisplayName | titlecase }}
        </span>
        <span> &nbsp;{{ currentSubscriptionPlan.flavorText }} </span>
      </span>
    </span>

    <span class="fld-row flg-4 price-dsc brb-1">
      <span class="fls-1-1 ds-dsk-only fs-d1">
        Current Monthly Payment
      </span>
      <myqq-price
        [price]="currentSubscriptionPlan?.base?.price"
        prepend="$"
        class="fs-u1"
      >
      </myqq-price>
    </span>
  </div>
  <div class="fs-u3 fw-u3 pwt-5">To</div>
  <div class="fld-row fld-dsk-col vw-p100 pwl-5">
    <span class="fld-row plan-dsc brt-1 fls-1-1">
      <span class="fld-col fls-1-1">
        <span class="ds-dsk-only fs-d1">your updated plan</span>

        <span class="vw-p90 fld-row fld-dsk-col fs-u1 fw-u1 plan-title">
          <span>
            {{ targetSubscriptionPlan.myQQShortDisplayName | titlecase }}
          </span>
          <span> &nbsp;{{ targetSubscriptionPlan.flavorText }}</span>
        </span>
      </span>
    </span>
    <span class="fld-row flg-4 price-dsc brb-1">
      <span class="fls-1-1 ds-dsk-only fs-d1">
        New Monthly Payment
      </span>
      <myqq-price
        [price]="targetSubscriptionPlan?.base?.price"
        prepend="$"
        class="fs-u1"
      ></myqq-price>
    </span>
  </div>
  <myqq-plans-difference
    class="ds-mbl-only"
    [currentSubscriptionPlan]="currentSubscriptionPlan"
    [targetSubscriptionPlan]="targetSubscriptionPlan"
  ></myqq-plans-difference>
</div>
