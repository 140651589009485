import { GlobalConfig } from "./config.interface";

export const segmentProxyUrl = "https://segment.dontdrivedirty.com";
export const segmentSnippetVersion = "4.15.3";

export const globalConfig: GlobalConfig = {
  findLocationHref: "https://www.dontdrivedirty.com",
  termsOfService: "https://www.dontdrivedirty.com/terms-of-service",
  termsOfUse: "https://www.dontdrivedirty.com/terms-of-use",
  privacyPolicy: "https://www.dontdrivedirty.com/privacy-policy",
  ccpa: "https://www.dontdrivedirty.com/privacy-policy#california",
  locations: "https://www.dontdrivedirty.com/locationsandpricing/",

  limits: {
    maxVehiclesOnAccount: 10,
    maxVehiclesOnSubscription: 5,
  },

  mobileBreakpoint: 768,

  supportNumber: "8887722792",
  supportEmail: "info@dontdrivedirty.com",
  refreshTokenKey: "rtk",

  pwaMode: "standalone",

  featureFlags: {},

  promos: {},
  cdnHost: "https://cdn.dontdrivedirty.com/myqq",
};
