import {
  ActivatedRouteSnapshot,
  Data,
  RouterStateSnapshot,
} from "@angular/router";
import { RouterStateSerializer } from "@ngrx/router-store";

import { MyqqRoute } from "./router.models";

export class MyqqRouterSerializer implements RouterStateSerializer<MyqqRoute> {
  serialize(routerState: RouterStateSnapshot): MyqqRoute {
    return {
      url: routerState.url,
      params: this.getLastChild(routerState.root).params,
      queryParams: routerState.root.queryParams,
      data: this.getData(routerState.root),
    };
  }

  getLastChild = (r: ActivatedRouteSnapshot): ActivatedRouteSnapshot => {
    if (!r) {
      return {} as ActivatedRouteSnapshot;
    }

    if (r.firstChild) this.getLastChild(r.firstChild);
    return r;
  };

  getData = (route: ActivatedRouteSnapshot): Data => {
    if (!route) {
      return {};
    }
    const primaryChild = route.children.find(
      (c) => c.outlet === "primary" || route.firstChild
    );

    return { ...route.data, ...this.getData(primaryChild) };
  };

  constructor() {}
}
