import { A11yModule } from "@angular/cdk/a11y";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { AccountQuotaMessageComponent } from "./account-quota-message.component";

@NgModule({
  imports: [MatIconModule, A11yModule, CommonModule],
  exports: [AccountQuotaMessageComponent],
  declarations: [AccountQuotaMessageComponent],
})
export class AccountQuotaMessageModule {}
