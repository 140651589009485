import { routerStateConfig } from "./router.consts";
import { actionCreatorFactory } from "src/app/shared/utilities/state/Actions";
import { routerStateLens } from "./router.lenses";
import {
  caseFn,
  reducerDefaultFn,
} from "src/app/shared/utilities/state/Reducers";
import { INITIAL_STATE } from "./router.consts";
import { MyqqRouteState } from "./router.models";
import { SerializedRouterStateSnapshot } from "@ngrx/router-store";

export const myqqRouterAction = actionCreatorFactory(
  routerStateConfig.stateKey
);

export const routeChange = myqqRouterAction.simple<
  SerializedRouterStateSnapshot
>("ROUTE_CHANGED");
export const routeChangeReducer = caseFn(
  routeChange,
  (s: MyqqRouteState, { value }) => routerStateLens.set(value)(s)
);

export const routerReducer = reducerDefaultFn(
  INITIAL_STATE,
  routeChangeReducer
);
