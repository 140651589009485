<ng-container *ngIf="currentSubscriptionPlan | map: isUpgrade; else downgrade">
  <span class="fld-row flg-1 ct-primary-rev">
    <mat-icon>add</mat-icon>
    <ul class="fs-d1 fw-u1">
      <li class="pwy-3">Benefits added after update</li>
      <li
        *ngFor="let prop of currentSubscriptionPlan | map: buildList"
        class="pwy-2"
      >
        {{ prop }}
      </li>
    </ul>
  </span>
</ng-container>

<ng-template #downgrade>
  <span class="fld-row flg-1 red-text">
    <mat-icon>remove</mat-icon>
    <ul class="fs-d1 fw-u1">
      <li class="pwy-3">Benefits removed after update</li>
      <li
        *ngFor="let prop of currentSubscriptionPlan | map: buildList"
        class="pwy-2"
      >
        {{ prop }}
      </li>
    </ul>
  </span>
</ng-template>
