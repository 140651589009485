import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";

import { SharedModule } from "../../shared.module";
import { VehicleFormComponent } from "./vehicle-form.component";
import { NgxMaskDirective, provideNgxMask } from "ngx-mask";

@NgModule({
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    NgxMaskDirective,
  ],
  exports: [VehicleFormComponent],
  declarations: [VehicleFormComponent],
  providers: [provideNgxMask()],
})
export class VehicleFormModule {}
