<div
  *ngIf="!customPriceImgUrl && priceOverride; else customPriceOverride"
  class="fld-row"
>
  <div class="override">
    <span *ngIf="!(free$ | async)">
      {{ prepend }}
      <strong>{{ priceOverride | map: dollars }} </strong>
      <small> {{ priceOverride | map: cents }} </small>
    </span>
    <strong *ngIf="free$ | async">
      Free!
    </strong>
  </div>
</div>
<ng-template #customPriceOverride>
  <img
    *ngIf="customPriceImgUrl"
    id="promo-details"
    [src]="customPriceImgUrl"
    alt="promotion"
  />
</ng-template>

<div [class.overriden]="crossOutDefaultPrice">
  <span class="prepend">{{ prepend }}</span>
  <span class="price">
    <strong>{{ price | map: dollars }}</strong>
    <small> {{ price | map: cents }} </small>
  </span>
  <span class="append">{{ append }}</span>
</div>
