import { environment } from "src/environments/environment";
import { log, LogLevels } from "../ngrx/logger";
import { setKeycloakInitialized } from "../ngrx/ui";

import getPkce from "oauth-pkce";
import { Store } from "@ngrx/store";
import { KeycloakService } from "keycloak-angular";
import { getParams } from "src/lib/util";

const defaultRedirectUri = environment.keycloak.initOptions.redirectUri;
const retryDelay = 10; // ms
const maxRetries = 5;

const delay = (retryCount: number) =>
  new Promise((resolve) =>
    setTimeout(resolve, Math.pow(retryCount, 3) * retryDelay)
  );

/**
 * Post-initialization setup steps
 * @param keycloak The Keycloak Service
 * @param store$ Store
 */
export function handleKeycloakAfterInit(
  keycloak: KeycloakService,
  store$: Store
) {
  store$.dispatch(setKeycloakInitialized(true));
  const keycloakInstance = keycloak.getKeycloakInstance();

  if (keycloakInstance.authenticated) {
    handleOnExpired(keycloak, store$);
  }
  sendLog(store$, "Keycloak: initialized", LogLevels.INFO, null, {
    token: keycloakInstance.tokenParsed,
    authenticated: keycloakInstance.authenticated,
  });
}

/**
 * Deal with errors during keycloak init
 * @param e error
 * @param store$ Store
 */
export function handleKeycloakInitError(e: any, store$: Store): void {
  const online = window?.navigator?.onLine === true;
  sendLog(
    store$,
    "Keycloak: initialization returned an error",
    LogLevels.WARNING,
    e
  );
  // Keycloak init will throw an undefined error if the server is down, so ignore those errors
  // Not really sure if keycloak ever throws a sensible error tbh
  if (!!e && online) {
    throw new Error(e);
  }
}

/**
 * Determine what path to redirect back to
 * @returns redirectUri  The url to redirect back to after initializing
 * @returns sales Are they on the sales pipeline?
 */
export function getRedirectUri(): { redirectUri: string; sales: boolean } {
  const { origin, pathname, search } = window?.location ?? {
    origin: null,
    pathname: null,
    search: null,
  };

  // tslint:disable-next-line:prefer-const
  let { path, params } = getParams(pathname + search);
  /**
   * If this is a sales pipeline call, we will short-circuit the initialization
   * and immediately redirect to registation
   */
  let sales = false;
  if (path === "/sales" && params?.zip && params?.sku) {
    sales = true;
    path = "/account-setup";
  }

  const redirectUri =
    origin && path
      ? origin + path + search.split("#")[0] ?? ""
      : defaultRedirectUri;

  return {
    sales: sales,
    redirectUri: redirectUri,
  };
}

/**
 * Since we don't have a keycloak instance yet and want to redirect to register
 * early in the app init process, we need to manually generate the registration url.
 * @param redirectUri string
 * @param store$  Store
 */
export function handleManualRegistration(
  redirectUri: string,
  store$: Store
): void {
  // Uses a small library that uses browser crypto to generate truly random
  // code_challenge hash https://github.com/coolgk/utils/tree/master/oauth-pkce
  getPkce(43, (error, { challenge }) => {
    if (!error) {
      window.location.href = environment.keycloak.registerUrl(
        redirectUri,
        challenge
      );
    } else {
      sendLog(
        store$,
        "Keycloak: Error generating challenge code",
        LogLevels.ERROR,
        error
      );
    }
  });
}

/**
 * What to do when the access token expires
 * @param keycloakService the Keycloak setvice
 * @param store$ Store
 */
function handleOnExpired(keycloakService: KeycloakService, store$: Store) {
  const keycloakInstance = keycloakService.getKeycloakInstance();
  const updateTokenWithRetry = (retryCount = 0) => {
    keycloakInstance.updateToken(30).catch((e) => {
      console.warn("Keycloak update token failed", e);
      sendLog(
        store$,
        "Keycloak: refresh token failed, retrying",
        LogLevels.WARNING,
        e,
        { token: keycloakInstance.tokenParsed }
      );
      if (retryCount < maxRetries) {
        delay(retryCount).then(() => updateTokenWithRetry(retryCount + 1));
      } else {
        sendLog(
          store$,
          `Keycloak: refresh token failed ${retryCount} times in a row`,
          LogLevels.WARNING,
          e
        );
      }
    });
  };

  keycloakInstance.onTokenExpired = () => {
    updateTokenWithRetry();
  };
}

/**
 * Helper function to send logs
 * @param store$
 * @param message
 * @param level
 * @param error
 * @param data
 */
function sendLog(
  store$: Store,
  message: string,
  level: LogLevels,
  error?: Error,
  data?: object
) {
  const failure = [
    LogLevels.FATAL,
    LogLevels.ERROR,
    LogLevels.WARNING,
  ].includes(level);
  store$.dispatch(
    log({
      level: level,
      message: message,
      actionType: {
        name: "KEYCLOAK",
        type: failure ? "FAILURE" : "SUCCESS",
        namespace: "MYQQ",
      },
      data: {
        error: error,
        ...data,
      },
    })
  );
}
