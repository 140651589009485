import { Component, Input } from "@angular/core";

@Component({
  selector: "myqq-small-loader",
  templateUrl: "./small-loader.component.html",
  styleUrls: ["./small-loader.component.scss"],
})
export class SmallLoaderComponent {
  @Input() show?: boolean = false;
  @Input() status?: string;
}
