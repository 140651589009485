import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SocialModule } from "../social/social.module";
import { FooterComponent } from "./footer.component";

@NgModule({
  declarations: [FooterComponent],
  imports: [CommonModule, SocialModule],
  exports: [FooterComponent],
})
export class FooterModule {}
