import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";

import { Stripe } from "src/app/shared/modules/stripe";
import {
  PaymentMethodData,
  PaymentMethodResult,
} from "src/app/shared/modules/stripe/stripe-definitions/payment-method";
import { log, LogLevels } from "src/app/core/ngrx/logger";
import { notNil } from "@qqcw/qqsystem-util";

/**
 * Create a fake action pending log from payment method data
 */
const logPending = (data: PaymentMethodData) =>
  log({
    message: `STRIPE/CREATE_PAYMENT_METHOD/PENDING`,
    data: { type: data.type },
    level: LogLevels.INFO,
  });

/**
 * Create a fake action success/failure log from payment method data
 */
const logResult = (result: PaymentMethodResult) =>
  notNil(result.error)
    ? log({
        message: `STRIPE/CREATE_PAYMENT_METHOD/FAILURE`,
        data: result,
        level: LogLevels.WARNING,
      })
    : log({
        message: `STRIPE/CREATE_PAYMENT_METHOD/SUCCESS`,
        data: result,
        level: LogLevels.INFO,
      });

/**
 * This service wraps the Stripe service with some basic logging so we
 * have a better view of what stripe requests are responding with.
 */
@Injectable()
export class MyqqStripeService {
  /**
   * Wraps the Stripe createPaymentMethod with some logging. Additionally,
   * it will catch any thrown errors and cast them to PaymentMethodResult,
   * which means this method should never throw.
   */
  createPaymentMethod(data: PaymentMethodData) {
    this.store.dispatch(logPending(data));
    return this.stripe
      .createPaymentMethod(data)
      .catch((error): PaymentMethodResult => ({ error }))
      .then((result) => {
        this.store.dispatch(logResult(result));
        return result;
      });
  }

  constructor(readonly stripe: Stripe, readonly store: Store<any>) {}
}
