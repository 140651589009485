export const phoneMask = {
  customPattern: {
    X: { pattern: new RegExp("[0-9]") },
    Y: { pattern: new RegExp("[1-9]") },
  },
  mask: "YXX-XXX-XXXX",
};
export const zipMask = "00000";
export const licenseMask = "A*";

export const vinMask = {
  customPattern: {
    X: { pattern: new RegExp("[a-hj-npr-zA-HJ-NPR-Z0-9]") },
    Y: { pattern: new RegExp("[xX0-9]") },
  },
  mask: "XXXXXXXXYXXXXXXXX",
};

export const promoMask = "A*";
