import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { environment } from "src/environments/environment";

@Component({
  templateUrl: "./update-app.component.html",
  styleUrls: ["./update-app.component.scss"],
})
export class UpdateAppComponent implements OnInit {
  cdnHost = environment.cdnHost;

  constructor(public dialogRef: MatDialogRef<UpdateAppComponent>) {}

  ngOnInit(): void {
    this.dialogRef.addPanelClass("myqq-update-dialog");
  }

  close() {
    this.dialogRef.close();
  }
}
