<div *ngIf="!vehicle" class="cart-vehicle-unselected mwb-4">
  <div class="fls-0-0 ai-ctr fld-row flg-5">
    <div class="fls-3-1 fld-col">
      <strong class="fls-1-1 mwy-2">No Vehicle Added</strong>
      <span class="fls-1-1 mwy-2">Please add a vehicle to get started</span>
    </div>
  </div>
</div>
<div
  *ngIf="vehicle"
  [ngClass]="{ 'cart-vehicle': selected, 'cart-vehicle-unselected': !selected }"
  class="mwb-4"
>
  <div class="fls-0-0 ai-ctr fld-row flg-5 vehicle-details">
    <div class="fls-3-1 fld-col">
      <strong class="fls-1-1 mwy-2">{{ vehicle | map: toMakeModel }}</strong>
      <span *ngIf="vehicle?.license" class="fls-1-1 mwy-2"
        >{{ vehicle?.state }} License Plate:
        <strong>{{ vehicle?.license }}</strong></span
      >
      <span *ngIf="!vehicle?.license" class="fls-1-1 mwy-2 ds-dsk-only"
        >VIN: <strong>{{ vehicle?.vin }}</strong></span
      >
    </div>
    <myqq-small-loader
      class="ds-dsk-only"
      [ngClass]="{ loader: orderType == OrderTypes.NEWMEMBERSHIP }"
      [show]="isLoading"
      [status]="status"
    ></myqq-small-loader>
    <mat-slide-toggle
      *ngIf="!hideToggle"
      color="accent"
      aria-label="select"
      [hideIcon]="true"
      [checked]="selected"
      [disabled]="disabled"
      (change)="handleVehicleSelectionChanged($event)"
    ></mat-slide-toggle>

    <div class="mwl-2 divider"></div>
    <myqq-small-loader
      class="loader ds-mbl-only"
      [show]="isLoading"
      [status]="status"
    ></myqq-small-loader>
    <div class="fld-row flg-5 jc-spb fs-u1 fw-u3 price">
      {{ currentPrice | currency }}
    </div>
  </div>
</div>
