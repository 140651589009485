import { Inject, Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { SwUpdate } from "@angular/service-worker";

import { switchMap } from "rxjs/operators";

import { WINDOW } from "src/app/core/services/window.service";
import { UpdateAppComponent } from "../components/update-app/update-app.component";

@Injectable({
  providedIn: "root",
})
export class PwaUpdatePromptService {
  constructor(
    private matSnackBar: MatSnackBar,
    private swUpdate: SwUpdate,
    private router: Router,
    private dialog: MatDialog,
    @Inject(WINDOW) private window: Window
  ) {}

  promptForUpdate() {
    this.matSnackBar
      .open("An update is available for myQQ!", "Update")
      .onAction()
      .pipe(switchMap(() => this.swUpdate.activateUpdate()))
      .subscribe(() => {
        this.router.navigate(["/"]).then(() => this.window.location.reload());
      });
  }

  promptForAndroidDownload() {
    this.matSnackBar
      .open("Download our new and improved Android app!", "Download")
      .onAction()
      .subscribe(() => {
        this.downloadAndroidApp();
      });
  }

  downloadAndroidApp() {
    window.open(
      "https://play.google.com/store/apps/details?id=com.dontdrivedirty.myqq.twa",
      "_blank"
    );
  }

  forceUpdate() {
    const dialog = this.dialog.open(UpdateAppComponent, {
      disableClose: true,
    });

    dialog
      .afterClosed()
      .pipe(switchMap(() => this.swUpdate.activateUpdate()))
      .subscribe(() => {
        this.router.navigate(["/"]).then(() => this.window.location.reload());
      });
  }
}
