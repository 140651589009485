import { clearLogs, sendLogs } from "./logging.actions";
import { lastLogSentLens, logLens } from "./logging.lenses";
import { INIT_LOGGING_STATE, LoggingState } from "./logging.store";
import {
  Reducer,
  caseFn,
  reducerFn,
  reducerDefaultFn,
} from "src/app/shared/utilities/state/Reducers";
import { Action } from "src/app/shared/utilities/state/Actions";

/**
 * Log Action Reducer Factory
 */
const serializeActionReducer: Reducer<LoggingState, Action<any>> = (
  state,
  action
) => {
  if (
    sendLogs.pending.match(action) ||
    sendLogs.success.match(action) ||
    sendLogs.failure.match(action)
  ) {
    return state;
  }
  return logLens.modify((logs) => logs.concat(state.serializer(action)))(state);
};

/**
 * Log Management Reducers
 */
const loggingManagementReducers = reducerFn(
  caseFn(
    sendLogs.pending,
    logLens.modify((_) => [])
  ),
  caseFn(
    sendLogs.success,
    lastLogSentLens.modify((_) => new Date())
  ),
  caseFn(
    sendLogs.failure,
    (
      state: LoggingState,
      {
        value: {
          params: { logs },
        },
      }
    ) => logLens.modify((l) => logs.concat(l))(state)
  ),
  caseFn(
    clearLogs,
    logLens.modify((_) => [])
  )
);

export const loggingReducer = reducerDefaultFn(
  INIT_LOGGING_STATE,
  serializeActionReducer,
  loggingManagementReducers
);
