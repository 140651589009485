import { format, isAfter, isValid, parse, startOfDay } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { Subject } from "rxjs";
import { FocusMonitor } from "@angular/cdk/a11y";
import { coerceBooleanProperty } from "@angular/cdk/coercion";
import {
  Component,
  Input,
  EventEmitter,
  Output,
  ViewChild,
  OnDestroy,
  HostBinding,
  Optional,
  Self,
  ElementRef,
  ContentChild,
  TemplateRef,
} from "@angular/core";
import { ControlValueAccessor, NgControl } from "@angular/forms";
import { MatFormFieldControl } from "@angular/material/form-field";
import { NgForOfContext } from "@angular/common";

@Component({
  selector: "myqq-datepicker",
  templateUrl: "./datepicker.component.html",
  styleUrls: ["./datepicker.component.scss"],
  providers: [
    { provide: MatFormFieldControl, useExisting: DatepickerComponent },
  ],
})
export class DatepickerComponent
  implements MatFormFieldControl<Date>, ControlValueAccessor, OnDestroy {
  static nextId = 1;

  constructor(
    @Optional()
    @Self()
    public ngControl: NgControl,
    private fm: FocusMonitor,
    private elRef: ElementRef<HTMLElement>
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
    fm.monitor(elRef.nativeElement, true).subscribe((origin) => {
      if (this.focused !== !!origin) {
        this.focused = !!origin;
        this._stateChanges.next();
      }
    });
  }
  dateFormat = "MM / dd / yyyy";

  focused = false;
  errorState = false;
  controlType = "myqq-datepicker";
  haveSent = false; // Have we ever sent a value to the form?

  private _stateChanges = new Subject<void>();

  readonly stateChanges = this._stateChanges.asObservable();

  readonly isValid = isValid;
  readonly dateMask = {
    customPattern: {
      A: { pattern: new RegExp("[0-9]") },
      B: { pattern: new RegExp("[0-1]") },
      C: { pattern: new RegExp("[0-3]") },
      D: { pattern: new RegExp("[1-2]") },
      E: { pattern: new RegExp("[0,9]") },
    },
    mask: "BA / CA / DEAA",
  };
  get empty() {
    return !this.nativeInput.nativeElement.value.trim();
  }

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    const newValue = coerceBooleanProperty(value);

    if (this._disabled !== newValue) {
      this._disabled = newValue;
      this._stateChanges.next();
    }
  }
  private _disabled = false;

  @Input()
  get max(): Date | null {
    return this.maxDate;
  }
  set max(value: Date | null) {
    if (value && typeof value === "string") {
      value = utcToZonedTime(value, this.timeZone || "+00:00");
    }

    if (!value) {
      delete this.maxDate;
      this._stateChanges.next();
      return;
    }

    this.maxDate = value;
    this._stateChanges.next();
  }
  private maxDate?: Date;

  @Input()
  get min(): Date | null {
    return this.minDate;
  }
  set min(value: Date | null) {
    if (value && typeof value === "string") {
      value = utcToZonedTime(value, this.timeZone || "+00:00");
    }

    if (!value) {
      delete this.minDate;
      this._stateChanges.next();
      return;
    }

    this.minDate = value;
    this._stateChanges.next();
  }
  private minDate?: Date;

  @Input()
  get readonly(): boolean {
    return this._readonly;
  }
  set readonly(value: boolean) {
    const newValue = coerceBooleanProperty(value);

    if (this._readonly !== newValue) {
      this._readonly = newValue;
      this._stateChanges.next();
    }
  }
  private _readonly = false;

  @Input()
  get name() {
    return this._name;
  }
  set name(newValue) {
    if (this._name !== newValue) {
      this._name = newValue;
      this._stateChanges.next();
    }
  }
  private _name = "";

  @Input()
  get placeholder() {
    if (this._placeholder === undefined) {
      return this.dateFormat;
    }
    return this._placeholder;
  }
  set placeholder(newValue) {
    if (this._placeholder !== newValue) {
      this._placeholder = newValue;
      this._stateChanges.next();
    }
  }
  private _placeholder?: string;

  @Input()
  get required() {
    return this._required;
  }
  set required(value) {
    const newValue = coerceBooleanProperty(value);

    if (this._required !== newValue) {
      this._required = newValue;
      this._stateChanges.next();
    }
  }
  private _required = false;

  @Input() timeZone?: string;

  @Input()
  get value(): Date | null {
    return startOfDay(
      parse(this.nativeInput.nativeElement.value, this.dateFormat, new Date())
    );
  }

  @ContentChild(TemplateRef) hintsTemplate?: TemplateRef<
    NgForOfContext<Date | null>
  >;

  set value(value: Date | null) {
    if (value && typeof value === "string") {
      value = utcToZonedTime(value, this.timeZone || "+00:00");
    }

    if (!value) {
      this.nativeInput.nativeElement.value = "";
      this._stateChanges.next();
      this.dateChange.next(null);
      this.onChange(null);
      return;
    }

    if (!isValid(value)) {
      return;
    }
    // setTimeout solves timing issue with ngx-mask performing value recalculation after value is set
    setTimeout(() => {
      this.nativeInput.nativeElement.value = format(value, this.dateFormat);
      this._stateChanges.next();
      this.dateChange.next(value);
      this.onChange(value);
    }, 0);
  }

  @Output() dateChange = new EventEmitter<Date | null>();

  @ViewChild("theNativeInput", { static: true })
  private nativeInput!: ElementRef<HTMLInputElement>;

  @HostBinding() id = `example-tel-input-${DatepickerComponent.nextId++}`;

  @HostBinding("class.floating")
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @HostBinding("attr.aria-describedby") describedBy?: string;

  get invalidDate() {
    return (
      this.nativeInput.nativeElement.value.match(/\d\d \/ \d\d \/ \d\d\d\d/) &&
      !isValid(
        parse(this.nativeInput.nativeElement.value, this.dateFormat, new Date())
      )
    );
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(" ");
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  onContainerClick(event: MouseEvent) {
    if ((event.target as Element).tagName.toLowerCase() !== "input") {
      this.nativeInput.nativeElement.focus();
    }
  }

  registerOnChange(fn: (_: any) => void) {
    this.onChange = (...args) => {
      fn(...args);
    };
  }

  private onChange = (_: any) => {};

  registerOnTouched(fn: any) {
    this.onTouched = (...args) => {
      fn(...args);
    };
  }

  onTouched = () => {};

  writeValue(value: Date | null) {
    this.value = value;
  }

  updateValue() {
    const date = parse(
      this.nativeInput.nativeElement.value,
      this.dateFormat,
      new Date()
    );

    if (isValid(date)) {
      this.value = date;
    }
  }

  updateValueOnKeyup() {
    if (
      (this.haveSent && !isValid(this.value)) ||
      this.nativeInput.nativeElement.value === ""
    ) {
      // If we previously sent a valid date and now the value is invalid;
      // or if the form value is now empty, reset
      this.reset();
    } else if (
      this.haveSent ||
      (isValid(this.value) && isAfter(this.value, new Date(1000, 0, 0)))
    ) {
      // Start sending the date to the form on each keystroke when it's *pretty much a date*
      // So the form can be validated (or not)
      // If we've ever sent a value, keep sending them for revalidation.
      this.haveSent = true;
      this.updateValue();
    }
  }

  reset() {
    this.value = null;
    this.haveSent = false;
  }

  ngOnDestroy() {
    this.fm.stopMonitoring(this.elRef.nativeElement);
    this._stateChanges.complete();
  }
}
