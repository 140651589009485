import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";
import { version } from "src/environments/version";

// Set global variable so we can inspect via console
window["__app_version__"] = Object.freeze(version);

if (environment.production) {
  console.log("Running myQQ in production mode.");
  enableProdMode();
} else {
  console.log("Running myQQ in non-production mode.");
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.log(err));
