import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MyqqButtonModule } from "src/app/shared/components/button/button.module";

import { ModalRouteModule } from "src/app/shared/components/routed-modal";
import { VehicleFormModule } from "src/app/shared/components/vehicle-form/vehicle-form.module";
import { DatumModule } from "src/app/shared/directives/datum";
import { OptionModule } from "src/app/shared/directives/option";
import { EvoxImageModule } from "src/app/shared/modules/evox/evox.module";
import { SharedModule } from "src/app/shared/shared.module";
import { EditVehiclePage } from "./edit-vehicle.page";

@NgModule({
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    CommonModule,
    FormsModule,
    MatButtonModule,
    MyqqButtonModule,
    ReactiveFormsModule,
    SharedModule,
    DatumModule,
    OptionModule,
    ModalRouteModule,
    EvoxImageModule,
    VehicleFormModule,
  ],
  exports: [],
  declarations: [EditVehiclePage],
  providers: [],
})
export class EditVehicleModule {}
