import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Link } from "../default-layout.page";

import { environment } from "src/environments/environment";

@Component({
  selector: "myqq-mobile-layout",
  templateUrl: "./mobile-layout.component.html",
  styleUrls: ["./mobile-layout.component.scss"],
})
export class MobileLayoutComponent implements OnInit {
  @Input() homeLink: Link[];
  @Input() locationsLink: Link;
  @Input() accountLinks: Link[];
  @Input() accountLinked = false;
  @Input() authenticated: BehaviorSubject<boolean>;
  @Input() online = true;
  @Input() greenBackground = false;
  @Input() showQuickQuackHeader = false;

  @Output() authenticate: EventEmitter<boolean> = new EventEmitter();
  @Output() register: EventEmitter<void> = new EventEmitter(null);

  readonly termsLink = environment.termsOfUse;
  readonly privacyLink = environment.privacyPolicy;
  readonly ccpaLink = environment.ccpa;
  readonly cdnHost = environment.cdnHost;

  copyYear: string;

  constructor() {}

  handleAuthentication(loggedIn): void {
    this.authenticate.emit(loggedIn);
  }

  handleRegister() {
    this.register.emit();
  }

  ngOnInit(): void {
    this.copyYear = new Date().getFullYear().toString();
  }
}
