<div class="fld-col vh-p100">
  <div class="fld-col flg-3 content">
    <p class="new-label">Current</p>
    <myqq-vehicle-display
      data-cy="current-vehicle"
      [vehicle]="currentVehicle"
      [membershipBadgeColor]="membershipBadgeColor"
    >
    </myqq-vehicle-display>

    <mat-icon class="as-ctr mwt-5 arrow-icon">arrow_downward </mat-icon>
    <ng-container *ngIf="nonMembershipVehicles?.length > 0">
      <span class="new-label" *ngIf="!showVehiclesDropDown"> New </span>
      <ng-container *ngIf="showVehiclesDropDown">
        <div class="select-container">
          <div class="fld-row fls-1-1 vw-p100 ai-ctr pwl-5">
            <span class="fs-u1">Select</span>
            <span class="fls-1-1"></span>
            <button
              mat-icon-button
              aria-label="Close vehicles selection"
              (click)="setShowVehiclesDropdown(false)"
              (keydown.enter)="$event.preventDefault()"
              (keyup.enter)="setShowVehiclesDropdown(false)"
            >
              <mat-icon>close</mat-icon>
            </button>
          </div>
          <mat-divider></mat-divider>
          <myqq-vehicle-display
            *ngFor="let vehicle of nonMembershipVehicles"
            [vehicle]="vehicle"
            [removeBorder]="true"
            [showMembershipBadge]="false"
            tabindex="0"
            data-cy="new-vehicles"
            (click)="selectVehicle(vehicle)"
            (keydown.enter)="$event.preventDefault()"
            (keyup.enter)="selectVehicle(vehicle)"
          ></myqq-vehicle-display>
        </div>
      </ng-container>
      <ng-container *ngIf="!showVehiclesDropDown && !selectedNewVehicle">
        <mat-card
          *ngIf="!showVehiclesDropDown"
          class="ta-c green-txt fw-u3"
          tabindex="0"
          data-cy="select-vehicle"
          aria-label="Tap to select a vehicle"
          (click)="setShowVehiclesDropdown(true)"
          (keydown.enter)="$event.preventDefault()"
          (keyup.enter)="setShowVehiclesDropdown(true)"
        >
          <mat-card-content>
            Please tap and select a vehicle
          </mat-card-content>
        </mat-card>
      </ng-container>

      <myqq-vehicle-display
        *ngIf="!showVehiclesDropDown && !!selectedNewVehicle"
        [vehicle]="selectedNewVehicle"
        [showExpandIcon]="true"
        aria-label="Selected vehicle"
        tabindex="0"
        (click)="setShowVehiclesDropdown(true)"
        (keydown.enter)="$event.preventDefault()"
        (keyup.enter)="setShowVehiclesDropdown(true)"
      >
      </myqq-vehicle-display>
    </ng-container>
    <ng-container
      *ngIf="!nonMembershipVehicles || nonMembershipVehicles?.length == 0"
    >
      <span class="new-label"> New </span>
      <mat-card
        class="green-txt ta-c pwt-7 card"
        tabindex="0"
        aria-label="Add a vehicle"
        (click)="addVehicle.emit()"
        (keydown.enter)="$event.preventDefault()"
        (keydown.up)="addVehicle.emit()"
      >
        <span class="fs-u1 fw-u1">
          <mat-icon class="add-icon mwx-3">add_circle_outline</mat-icon>
          Add a vehicle
        </span>
      </mat-card>
    </ng-container>
  </div>
  <span *ngIf="currentVehicle.isTemporaryIdentifier" class="fls-1-1"> </span>
  <myqq-account-message-quota
    *ngIf="!currentVehicle.isTemporaryIdentifier"
    [accountQuota]="accountQuota"
    [action]="'swap'"
    (showDisclaimer)="showDisclaimer.emit()"
  ></myqq-account-message-quota>
  <footer>
    <button
      myqq-button
      color="primary"
      class="raised"
      aria-label="Next"
      [disabled]="!selectedNewVehicle"
      (click)="nextStep.emit()"
      (keydown.enter)="$event.preventDefault()"
      (keyup.enter)="nextStep.emit()"
    >
      Next
    </button>
  </footer>
</div>
