<header mat-dialog-title>
  <h1>Looks Like myQQ Needs An Update!</h1>
</header>

<mat-dialog-content id="main">
  <p>To use this app, please update to the latest version.</p>
  <img
    id="maintenance-duck"
    src="{{ cdnHost }}/img/manage-account-quackals.svg"
    alt="maintenance-duck"
  />
  <footer>
    <button
      myqq-button
      color="primary"
      class="large raised vw-p100"
      aria-label="update myQQ app"
      (click)="close()"
      (keyup.enter)="close()"
      (keydown.enter)="$event.preventDefault()"
    >
      Update
    </button>
  </footer>
</mat-dialog-content>
