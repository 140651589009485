<div
  class="modal-container"
  (click)="closeModal()"
  cdkTrapFocus
  [cdkTrapFocusAutoCapture]="true"
>
  <article class="routed-modal modal" (click)="articleClick($event)">
    <header
      class="fls-0-0 ct-primary vw-p100 fld-row flg-4 ai-ctr pwl-5 pwr-4 modal-header"
    >
      <h3 class="fls-1-1 mwa-0">
        <ng-content select="[modal-header]"></ng-content>
      </h3>

      <button
        (click)="closeModal()"
        class="close-btn"
        myqq-button
        aria-label="Close Modal"
        matIcon="close"
      ></button>
    </header>

    <section class="modal-body" cdkFocusInitial>
      <ng-content></ng-content>
    </section>

    <footer
      class="fls-0-0 vh-3 ct-primary vw-p100 fld-row flg-4 jc-end pwl-5 pwr-4 modal-footer"
      [hidden]="hideFooter"
    >
      <ng-content select="[modal-footer]"></ng-content>
    </footer>
  </article>
</div>
