import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from "@angular/core";

import { environment } from "src/environments/environment";
import { notNil } from "@qqcw/qqsystem-util";
import { OfflineAuthService } from "src/app/core/services/offline-auth.service";
import { BehaviorSubject } from "rxjs";
import { log, LogLevels } from "src/app/core/ngrx/logger";
import { Store } from "@ngrx/store";

@Component({
  selector: "myqq-error",
  templateUrl: "./error.component.html",
  styleUrls: ["./error.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorComponent implements OnInit {
  @Input()
  title = "An Unexpected Error Occurred";
  @Input()
  description?: string;
  @Input()
  error?: any;
  @Input()
  logLevel?: LogLevels;

  readonly showErrorDetail = environment.showErrorDetail;
  readonly errorImg = environment.cdnHost + "/img/doctor-quackals.png";
  readonly notNil = notNil;

  online: BehaviorSubject<boolean> = new BehaviorSubject(true);

  constructor(private offlineAuth: OfflineAuthService, private store$: Store) {}

  ngOnInit() {
    this.online = this.offlineAuth.online$;
    this.store$.dispatch(
      log({
        level: this.logLevel ?? LogLevels.WARNING,
        message: this.title,
        data: {
          description: this.description ?? this.title,
          error: this.error,
        },
        actionType: {
          name: "ERROR_COMPONENT",
          type: "FAILURE",
          namespace: "MYQQ",
        },
      })
    );
  }
}
