import { Component, EventEmitter, Input, Output } from "@angular/core";
import { environment } from "src/environments/environment";

@Component({
  selector: "myqq-banner",
  templateUrl: "./banner.component.html",
  styleUrls: ["./banner.component.scss"],
})
export class BannerComponent {
  @Input() message: string;
  @Input() showContactCustomerSupport: boolean = true;
  @Output()
  readonly dismiss = new EventEmitter();

  supportNumber = environment.supportNumber;
  cdnHost = environment.cdnHost;
}
