<ng-container [useDatumEither]="subscription$ | async">
  <myqq-loading *onInitial></myqq-loading>
  <myqq-loading *onPending></myqq-loading>
  <myqq-error
    *onFailure="let error"
    title="Error Finding Subscription"
    description="There was an error loading your membership."
    [error]="error"
  ></myqq-error>

  <ng-container *onSuccess="let data" [useOption]="data">
    <h3 *onNone>Subscription not found</h3>
    <p *onNone>
      There is a problem with your subscription but we were unable to load it!
      Please try again, or contact Customer Care at
      <a href="tel:1{{ phoneNumber }}">{{ phoneNumber | phone }}</a>
    </p>
    <ng-container *onSome="let subscription">
      <myqq-past-due
        [subscription]="subscription"
        (editPaymentMethod)="editPaymentMethod()"
      >
      </myqq-past-due>
    </ng-container>
  </ng-container>
</ng-container>
