import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";

import { ModalRouteModule } from "src/app/shared/components/routed-modal";
import { DatumModule } from "src/app/shared/directives/datum";
import { SharedModule } from "src/app/shared/shared.module";
import { AddVehicleModalPage } from "./add-vehicle.page";
import { MyqqButtonModule } from "src/app/shared/components/button/button.module";
import { VehicleFormModule } from "src/app/shared/components/vehicle-form/vehicle-form.module";
@NgModule({
  imports: [
    CommonModule,
    DatumModule,
    FormsModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    ModalRouteModule,
    MyqqButtonModule,
    ReactiveFormsModule,
    SharedModule,
    VehicleFormModule,
  ],
  exports: [AddVehicleModalPage],
  declarations: [AddVehicleModalPage],
  providers: [],
})
export class AddVehicleModalModule {}
