import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SharedModule } from "../../shared/shared.module";

import { ErrorPage } from "./error.page";
import { StoreModule } from "@ngrx/store";
import { MyqqButtonModule } from "src/app/shared/components/button/button.module";

@NgModule({
  imports: [CommonModule, MyqqButtonModule, SharedModule, StoreModule],
  declarations: [ErrorPage],
})
export class ErrorPageModule {}
