import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";

import { CardElement } from "src/app/shared/modules/stripe";
import { PaymentMethodData } from "src/app/shared/modules/stripe/stripe-definitions/payment-method";
import { environment } from "src/environments/environment";

@Component({
  selector: "myqq-add-payment-method",
  templateUrl: "./add-payment-method.component.html",
  styleUrls: ["./add-payment-method.component.scss"],
})
export class AddPaymentMethodComponent {
  @Input()
  description?: string;
  @Input()
  overdueAmount?: string;

  @Output()
  readonly submit = new EventEmitter();

  card?: CardElement;
  cardError: string = null;

  cdnHost = environment.cdnHost;
  readonly form = this.fb.group({
    name: ["", [Validators.required]],
    address_country: ["US", [Validators.required]],
  });

  constructor(readonly fb: FormBuilder) {}

  get formValue(): PaymentMethodData {
    if (!this.form) {
      return undefined;
    }
    return {
      type: "card",
      card: this.card,
      metadata: this.form.value,
    };
  }

  get valid() {
    return !!this.card && !this.cardError && this.form.valid;
  }

  reset() {
    this.form.reset({ address_country: "US" });
  }

  handleChange(value) {
    this.cardError = value.error?.message;
  }
}
