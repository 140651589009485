import { Injectable, OnDestroy } from "@angular/core";
import { ActivatedRouteSnapshot, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { KeycloakService } from "keycloak-angular";
import { from, Observable, Subscription } from "rxjs";
import { map, first, tap } from "rxjs/operators";
import {
  AccountAndMembershipStatus,
  getAccountInfo,
  selectAccountAndMembershipStatus,
} from "src/app/core/ngrx/myqq";
import { filterSuccessMapToRightValue } from "src/lib/datum-either";
import { isInitial } from "@nll/datum/Datum";
import { setUIPromoCode } from "src/app/core/ngrx/ui";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements OnDestroy {
  subs: Subscription[] = [];
  constructor(
    private keycloak: KeycloakService,
    private router: Router,
    readonly store$: Store
  ) {}

  canActivate(
    activatedRoute: ActivatedRouteSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const promo = activatedRoute.params.promo;
    if (promo) {
      this.store$.dispatch(setUIPromoCode(promo));
    }

    const loggedInValue = this.keycloak
      .isLoggedIn()
      .catch((_) => Promise.resolve(true));
    return from(loggedInValue).pipe(
      map((loggedInValue) => {
        if (loggedInValue) {
          this.subs.push(
            this.store$
              .select(selectAccountAndMembershipStatus)
              .pipe(
                tap((account) => {
                  if (isInitial(account)) {
                    this.store$.dispatch(getAccountInfo.pending(null));
                  }
                }),
                filterSuccessMapToRightValue,
                first()
              )
              .subscribe((accountStatus: AccountAndMembershipStatus) =>
                !accountStatus.hasAccount
                  ? this.router.navigateByUrl("/")
                  : this.router.navigateByUrl("/purchase")
              )
          );
          return false;
        } else {
          return true;
        }
      })
    );
  }
  ngOnDestroy(): void {
    this.subs?.forEach((s) => s.unsubscribe());
  }
}
