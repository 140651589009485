import { Inject, Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { KeycloakAuthGuard, KeycloakService } from "keycloak-angular";

import { environment } from "src/environments/environment";
import { WINDOW } from "../services/window.service";
import { OfflineAuthService } from "../services/offline-auth.service";
import { Store } from "@ngrx/store";
import { selectKeycloakInitialized } from "../ngrx/ui";
import { filter, mapTo, switchMap, take } from "rxjs/operators";
import { from, of } from "rxjs";
import { getRedirectUri, handleManualRegistration } from "./keycloak.utils";

const loginOptions = environment.keycloak.loginOptions;

@Injectable({ providedIn: "root" })
export class CanAuthGuard extends KeycloakAuthGuard {
  constructor(
    protected router: Router,
    protected keycloakAngular: KeycloakService,
    protected offlineAuth: OfflineAuthService,
    protected store$: Store<any>,
    @Inject(WINDOW) private window: Window
  ) {
    super(router, keycloakAngular);
  }

  async isAccessAllowed(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    return this.store$
      .select(selectKeycloakInitialized)
      .pipe(
        filter((initialized) => initialized),
        switchMap(() => from(this.keycloakAngular.isLoggedIn())),
        switchMap((loggedIn) => {
          if (loggedIn) {
            const requiredRoles: string[] = route.data.roles;
            if (!requiredRoles || requiredRoles.length === 0) {
              return of(true);
            } else {
              if (!this.roles || this.roles.length === 0) {
                return of(false);
              }

              return of(
                requiredRoles.every((role) => this.roles.indexOf(role) > -1)
              );
            }
          } else if (!!state.url.startsWith("/employeebenefits")) {
            const { redirectUri } = getRedirectUri();
            handleManualRegistration(redirectUri, this.store$);
            return of(false);
          } else {
            const redirectUri = `${this.window.location.origin}/${state.url}`;

            return from(
              this.keycloakAngular.updateToken(-1).catch(() => false)
            ).pipe(
              switchMap((updated) => {
                if (updated) {
                  return of(true);
                } else {
                  return from(
                    this.keycloakAngular.login({
                      ...loginOptions,
                      redirectUri: redirectUri,
                    })
                  ).pipe(mapTo(false));
                }
              })
            );
          }
        }),
        take(1)
      )
      .toPromise();
  }
}
