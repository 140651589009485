import { actionCreatorFactory } from "src/app/shared/utilities/state/Actions";
import {
  caseFn,
  reducerDefaultFn,
} from "src/app/shared/utilities/state/Reducers";

import {
  AccountSetupSteps,
  Location,
  SalesPipeline,
  StepperActionKeys,
  UIState,
  UIStepperAction,
  UIStepperState,
  VehicleSwapSteps,
  ExistingMembership,
  ZipAttempt,
  CancelMembershipSteps,
  UpdateFlockPresaleVehicleSteps,
  b2cSignUpDetails,
} from "./ui.models";
import { INITIAL_STATE, uiFeatureKey } from "./ui.consts";
import * as L from "./ui.lenses";
import { RegionMenu } from "../../services/myqq";

const actionCreator = actionCreatorFactory(uiFeatureKey);

/**
 * UI Step Change
 */
export const stepChange = actionCreator.simple<UIStepperAction>("STEP_CHANGE");
const stepChangeReducer = caseFn(stepChange, (s: UIStepperState, { value }) =>
  L.stepChangeLens.set(value)(s)
);

/**
 * Set Account Setup Step
 */
export const setAccountSetupStep = actionCreator.simple<AccountSetupSteps>(
  StepperActionKeys(AccountSetupSteps)
);
const setAccountSetupStepReducer = caseFn(
  setAccountSetupStep,
  (s: UIState, { value }) => L.accountSetupStepLens.set(value)(s)
);

/**
 * Set Vehicle Add Step
 */
export const setVehicleSwapStep = actionCreator.simple<VehicleSwapSteps>(
  StepperActionKeys(VehicleSwapSteps)
);
const setVehicleSwapStepReducer = caseFn(
  setVehicleSwapStep,
  (s: UIState, { value }) => L.vehicleSwapStepLens.set(value)(s)
);

/**
 * Set Cancel Membership Step
 */
export const setCancelMembershipStep = actionCreator.simple<
  CancelMembershipSteps
>(StepperActionKeys(CancelMembershipSteps));
const setCancelMembershipStepReducer = caseFn(
  setCancelMembershipStep,
  (s: UIState, { value }) => L.cancelMembershipStepLens.set(value)(s)
);

/**
 * Set Update Flock Presale Vehicle Step
 */
export const setUpdateFlockPresaleVehicleStep = actionCreator.simple<
  UpdateFlockPresaleVehicleSteps
>(StepperActionKeys(UpdateFlockPresaleVehicleSteps));

const setUpdateFlockPresaleVehicleStepReducer = caseFn(
  setUpdateFlockPresaleVehicleStep,
  (s: UIState, { value }) => L.updateFlockPresaleVehicleStepLens.set(value)(s)
);

export const setSalesPipeline = actionCreator.simple<SalesPipeline>(
  "SALES_PIPELINE"
);
const setSalesPipelineReducer = caseFn(
  setSalesPipeline,
  (s: UIState, { value }) => {
    return L.salesPipelineLens.set(value)(s);
  }
);
export const setExistingMembership = actionCreator.simple<ExistingMembership>(
  "EXISTING_MEMBERSHIP"
);
const setExistingMembershipReducer = caseFn(
  setExistingMembership,
  (s: UIState, { value }) => L.existingMembershipLens.set(value)(s)
);

export const setB2cSignUpDetails = actionCreator.simple<b2cSignUpDetails>(
  "B2B_SIGNUP"
);
const setB2cSignUpDetailsReducer = caseFn(
  setB2cSignUpDetails,
  (s: UIState, { value }) => L.b2cSignUpDetailsLens.set(value)(s)
);

export const setZipAttempt = actionCreator.simple<ZipAttempt>("ZIP_ATTEMPT");
const setZipAttemptReducer = caseFn(setZipAttempt, (s: UIState, { value }) =>
  L.zipAttemptLens.set(value)(s)
);

export const setKeycloakInitialized = actionCreator.simple<boolean>(
  "KEYCLOAK_INITIALIZED"
);
const setKeycloakInitializedReducer = caseFn(
  setKeycloakInitialized,
  (s: UIState, { value }) => L.keycloakInitializedLens.set(value)(s)
);

export const setLocation = actionCreator.simple<Location>("LOCATION");
const setLocationReducer = caseFn(setLocation, (s: UIState, { value }) =>
  L.locationLens.set(value)(s)
);

export const setUIPromoCode = actionCreator.simple<string>("PROMO_CODE");
const setUIPromoCodeReducer = caseFn(setUIPromoCode, (s: UIState, { value }) =>
  L.promoCode.set(value)(s)
);

export const clearUIPromoCode = actionCreator.simple("CLEAR PROMO_CODE");
const clearUIPromoCodeReducer = caseFn(clearUIPromoCode, (s: UIState, {}) => {
  return L.promoCode.set(null)(s);
});

export const setDefaultPlans = actionCreator.simple<RegionMenu>(
  "SET_DEFAULT_PLANS"
);
const setDefaultPlansReducer = caseFn(
  setDefaultPlans,
  (s: UIState, { value }) => L.defaultPlans.set(value)(s)
);

// dismissible alerts state
export const setDismissAddVehiclePromptInCart = actionCreator.simple<boolean>(
  "SET_DISMISS_ADD_VEHICLE_PROMPT"
);
const setDismissAddVehiclePromptInCartReducer = caseFn(
  setDismissAddVehiclePromptInCart,
  (s: UIState, { value }) => L.dismissAddVehiclePromptInCartLens.set(value)(s)
);
export const setShowFlockPresaleModal = actionCreator.simple<boolean>(
  "SET_SHOW_FLOCK_PRESALE_MODAL"
);
const setShowFlockPresaleModalReducer = caseFn(
  setShowFlockPresaleModal,
  (s: UIState, { value }) => L.showFlockPresaleModalLens.set(value)(s)
);
// orders

export const uiReducer = reducerDefaultFn(
  INITIAL_STATE,
  setAccountSetupStepReducer,
  setVehicleSwapStepReducer,
  setCancelMembershipStepReducer,
  setSalesPipelineReducer,
  setExistingMembershipReducer,
  setZipAttemptReducer,
  setKeycloakInitializedReducer,
  setLocationReducer,
  setUIPromoCodeReducer,
  clearUIPromoCodeReducer,
  setDefaultPlansReducer,
  setDismissAddVehiclePromptInCartReducer,
  setUpdateFlockPresaleVehicleStepReducer,
  setShowFlockPresaleModalReducer,
  setB2cSignUpDetailsReducer
);

export const uiStepReducer = reducerDefaultFn(
  { state: null },
  stepChangeReducer
);
