import { actionCreatorFactory } from "src/app/shared/utilities/state/Actions";
import {
  caseFn,
  reducerDefaultFn,
} from "src/app/shared/utilities/state/Reducers";
import { segmentFeatureKey } from "./segment.consts";
import { pageViewLens } from "./segment.lenses";
import { PageView, SegmentState } from "./segment.models";

const actionCreator = actionCreatorFactory(segmentFeatureKey);

export const pageView = actionCreator.simple<PageView>("PAGE_VIEW");
const pageViewReducer = caseFn(pageView, (s: SegmentState, { value }) =>
  pageViewLens.set(value)(s)
);

export const segmentReducer = reducerDefaultFn(
  { pageView: null },
  pageViewReducer
);
