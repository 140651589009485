<div *ngIf="!!membershipPurchase" class="fld-col fld-dsk-row flg-5">
  <myqq-cart-plan-detail
    class="subscriptionInfo"
    [plan]="membershipPurchase.wash"
    [price]="membershipPurchase.wash?.base?.price"
    [priceOverride]="
      membershipPurchase.wash | map: getPriceOverride:promoDetails
    "
    [crossOutDefaultPrice]="promoDetails?.crossOutDefaultPrice"
    [customOverride]="
      membershipPurchase.wash | map: getCustomPlanOverrideImg:promoDetails
    "
  >
  </myqq-cart-plan-detail>
  <span class="fld-col vw-p100 fls-1-1">
    <myqq-cart-vehicle-empty
      *ngIf="!vehicles?.length"
    ></myqq-cart-vehicle-empty>

    <button
      myqq-button
      class="outlined new-member-btn"
      matIcon="add_circle_outline"
      iconColor="primary"
      cdkFocusInitial
      (click)="addVehicle.emit()"
      (keydown.enter)="$event.preventDefault()"
      (keyup.enter)="addVehicle.emit()"
      aria-label="Add Vehicle"
    >
      Add Vehicle
    </button>

    <span class="divider mwy-5"> </span>

    <myqq-cart-totals
      [orderTotal]="membershipPurchase.wash?.base?.price"
      [nextBillingAmount]="membershipPurchase.wash?.base?.price"
      [priceOverride]="
        membershipPurchase.wash | map: getPriceOverride:promoDetails
      "
      [promoMessage]="promoDetails?.message"
    >
    </myqq-cart-totals>
    <span *ngIf="!!modifySubscriptionPlan" class="divider"></span>
  </span>
</div>
