import { Inject, Injectable } from "@angular/core";

import { fromEvent, animationFrameScheduler, BehaviorSubject } from "rxjs";
import { throttleTime } from "rxjs/operators";

import { WINDOW } from "src/app/core/services/window.service";
import { environment } from "src/environments/environment";

type ResizeListener = (event: Event) => void;

/**
 * Service to add/remove listeners to resize events.
 * Include a default isMobile listener.
 */
@Injectable({
  providedIn: "root",
})
export class ResizeService {
  constructor(@Inject(WINDOW) private window: Window) {
    fromEvent(this.window, "resize")
      .pipe(throttleTime(0, animationFrameScheduler))
      .subscribe(this.runListeners.bind(this));
  }

  _isMobile$ = new BehaviorSubject<boolean>(
    this.window.innerWidth < environment.mobileBreakpoint
  );

  checkMobile = (_) => {
    if (
      this.isMobile$.value !==
      this.window.innerWidth < environment.mobileBreakpoint
    ) {
      this._isMobile$.next(!this.isMobile$.value);
    }
  };

  // tslint:disable-next-line:member-ordering
  listeners: ResizeListener[] = [this.checkMobile];

  get isMobile$() {
    return this._isMobile$;
  }

  addListener(listener: ResizeListener): void {
    this.listeners.push(listener);
  }

  removeListener(listener: ResizeListener): void {
    this.listeners = this.listeners.filter((l) => l !== listener);
  }

  runListeners(event: Event): void {
    this.listeners.forEach((listener) => listener(event));
  }
}
