import { createFeatureSelector, createSelector } from "@ngrx/store";

import { lastLogSentLens, logLens } from "./logging.lenses";
import { LoggingState } from "./logging.store";
import { LOGGING_FEATURE_KEY } from "./logging.models";

export const selectLoggingStore = createFeatureSelector<LoggingState>(
  LOGGING_FEATURE_KEY
);

export const selectLogs = createSelector(selectLoggingStore, logLens.get);
export const selectLastLogSent = createSelector(
  selectLoggingStore,
  lastLogSentLens.get
);
