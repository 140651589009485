import { ModuleWithProviders, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { EvoxImageService } from "./evox.service";

import { EvoxVehicleImageComponent } from "./evox.component";
import { EvoxConfig, EVOX_CONFIG_TOKEN } from "./evox.consts";
import { MapPipeModule } from "../../pipes/map";

@NgModule({
  declarations: [EvoxVehicleImageComponent],
  imports: [CommonModule, MapPipeModule],
  exports: [EvoxVehicleImageComponent],
  providers: [EvoxImageService],
})
export class EvoxImageModule {
  static forRoot(config: EvoxConfig): ModuleWithProviders<EvoxImageModule> {
    return {
      ngModule: EvoxImageModule,
      providers: [{ provide: EVOX_CONFIG_TOKEN, useValue: config }],
    };
  }
}
