<myqq-routed-modal>
  <span modal-header>Add Payment Method</span>

  <ng-container *ngIf="error$ | async">
    <myqq-error
      title="Payment Method Error"
      description="There was a problem updating your payment method."
      [error]="error"
    >
    </myqq-error>

    <p class="pwa-5">{{ error?.message }}</p>

    <button
      myqq-button
      id="add-button"
      class="large raised reset-btn"
      color="danger"
      (click)="handleReset()"
    >
      Try Again
    </button>

    <ng-template #stripeErrorFooter>
      <button
        myqq-button
        id="cancel-button"
        class="cancel-btn"
        (click)="handleCancel()"
      >
        Cancel
      </button>
    </ng-template>
  </ng-container>

  <ng-container
    [useDatumEither]="paymentMethodSaved$ | async"
    *ngIf="!(error$ | async)"
  >
    <ng-container *onInitial>
      <myqq-add-payment-method #addPaymentMethodForm></myqq-add-payment-method>

      <ng-template #initialFooter>
        <button
          myqq-button
          id="cancel-button"
          class="cancel-btn"
          (click)="handleCancel()"
        >
          Cancel
        </button>

        <button
          myqq-button
          id="add-button"
          color="primary"
          (click)="handleSubmit(addPaymentMethodForm.formValue)"
          (keydown.enter)="handleSubmit(addPaymentMethodForm.formValue)"
          (keyup.enter)="$event.preventDefault()"
          [disabled]="!addPaymentMethodForm.valid || stripePending"
        >
          Save
        </button>
      </ng-template>
    </ng-container>

    <myqq-loading *onPending>
      <h3>Saving Payment Method . . .</h3>
    </myqq-loading>

    <ng-container *onFailure="let error">
      <myqq-error
        title="Payment Method Error"
        description="There was a problem updating your payment method"
        [error]="error"
      >
      </myqq-error>

      <p class="pwa-5 ta-c">
        {{ (error?.error?.error?.message || error?.error?.message ||
        error?.message || "" ) | map: trimBlankErrorCode }}
      </p>

      <p class="pwa-5 ta-c">
        If you believe this is an error, please contact Customer Care at {{
        supportNumber | phone }}
      </p>

      <button
        myqq-button
        id="add-button"
        class="large raised reset-btn"
        color="danger"
        (click)="handleReset()"
      >
        Try Again
      </button>

      <ng-template #errorFooter>
        <button
          myqq-button
          id="cancel-button"
          class="cancel-btn"
          (click)="handleCancel()"
        >
          Cancel
        </button>
      </ng-template>
    </ng-container>
  </ng-container>

  <div modal-footer class="qq-buttons">
    <ng-container
      *ngTemplateOutlet=" (footerTemplate | async) || defaultFooter "
    >
    </ng-container>

    <ng-template #defaultFooter></ng-template>
  </div>
</myqq-routed-modal>
