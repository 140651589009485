import { ActionSerializer, LogArray } from "./logging.models";
import { defaultActionSerializer } from "./logging.utils";

export interface LoggingState {
  readonly serializer: ActionSerializer;
  readonly log: LogArray;
  readonly authed: boolean;
  readonly lastLogSent?: Date;
  /**
   * TODO Expand this to define
   * - Session ID
   * - Browser ID
   * - Application ID
   */
}

export const INIT_LOGGING_STATE: LoggingState = {
  serializer: defaultActionSerializer,
  log: [],
  authed: false,
};
