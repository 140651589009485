import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
  HttpStatusCode,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

import jwt_decode from "jwt-decode";

import { Store } from "@ngrx/store";
import { KeycloakService } from "keycloak-angular";
import { Observable, timer, throwError, from } from "rxjs";
import { catchError, switchMap, delay as rxjs_delay } from "rxjs/operators";

import { log, LogLevels } from "src/app/core/ngrx/logger";
import { environment } from "src/environments/environment";
import { OfflineAuthService } from "../services/offline-auth.service";

const MAX_RETRIES = 5;
const BASE_RETRY_DELAY_MS = 150;

@Injectable()
export class RetryNetworkErrorInterceptor implements HttpInterceptor {
  url_pattern: RegExp;
  constructor(
    readonly dialogController: MatDialog,
    readonly offlineAuth: OfflineAuthService,
    private keycloak: KeycloakService,
    readonly store$: Store<any>
  ) {
    this.url_pattern = new RegExp(environment.URLs.MyQQ);
  }
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!req.url.match(this.url_pattern)) {
      // this request is to a URL outside the qq cluster =>
      // pass through
      return next.handle(req);
    }

    /* Attempt to retry failed network requests */
    let retries = 0;

    const cubicBackOffOnNetworkFailure = (
      errorResponse: any,
      originalRequest: Observable<HttpEvent<any>>
    ): Observable<HttpEvent<any>> => {
      if (errorResponse instanceof HttpErrorResponse && retries < MAX_RETRIES) {
        if (!this.offlineAuth.online$.value) {
          // Offline so don't keep retrying
          return;
        }

        // If API returns unauthorized, try to refresh the access token
        if (errorResponse.status === HttpStatusCode.Unauthorized) {
          const delay = Math.pow(++retries, 3) * BASE_RETRY_DELAY_MS;

          const warning = `Failed http request to ${req.url} with status: ${errorResponse.status}. Retrying #${retries} in ${delay}ms`;

          console.warn(warning);

          this.store$.dispatch(
            log({
              level: LogLevels.WARNING,
              message: warning,
              data: errorResponse,
              actionType: {
                name: "RETRY_SERVICE",
                type: "SUCCESS",
                namespace: "MYQQ",
              },
            })
          );

          this.keycloak
            .getToken()
            .then((token) => {
              this.store$.dispatch(
                log({
                  level: LogLevels.WARNING,
                  message:
                    "Got unauthorized response from API, attempting to refresh access token",
                  data: { errorResponse, token: jwt_decode(token) },
                  actionType: {
                    name: "RETRY_SERVICE",
                    type: "SUCCESS",
                    namespace: "MYQQ",
                  },
                })
              );
            })
            .catch(() => null);

          return from(
            this.keycloak
              .updateToken(-1)
              .then((r) => {
                next.handle(req).pipe(catchError(cubicBackOffOnNetworkFailure));
                this.keycloak
                  .getToken()
                  .then((token) => {
                    this.store$.dispatch(
                      log({
                        level: LogLevels.WARNING,
                        message:
                          "Refreshed access token in retry interceptor after receiving unauthorized response from API",
                        data: { refreshed: r, token: jwt_decode(token) },
                        actionType: {
                          name: "RETRY_SERVICE",
                          type: "SUCCESS",
                          namespace: "MYQQ",
                        },
                      })
                    );
                  })
                  .catch(() => null);
              })
              .catch((e) => {
                // I don't think this ever throws . . .
                log({
                  level: LogLevels.ERROR,
                  message: "Failed to refresh access token",
                  data: { errorResponse, e },
                  actionType: {
                    name: "RETRY_SERVICE",
                    type: "FAILURE",
                    namespace: "MYQQ",
                  },
                });
              })
          ).pipe(
            rxjs_delay(delay),
            switchMap(() =>
              originalRequest.pipe(catchError(cubicBackOffOnNetworkFailure))
            )
          );
        }

        if (
          !errorResponse.status ||
          [
            HttpStatusCode.BadGateway,
            HttpStatusCode.ServiceUnavailable,
            HttpStatusCode.GatewayTimeout,
            HttpStatusCode.TooManyRequests,
          ].includes(errorResponse.status)
        ) {
          const delay = Math.pow(++retries, 3) * BASE_RETRY_DELAY_MS;

          const warning = `Failed http request to ${req.url} with status: ${errorResponse.status}. Retrying #${retries} in ${delay}ms`;

          console.warn(warning);

          this.store$.dispatch(
            log({
              level: LogLevels.WARNING,
              message: warning,
              data: errorResponse,
              actionType: {
                name: "RETRY_SERVICE",
                type: "SUCCESS",
                namespace: "MYQQ",
              },
            })
          );

          // retry after increasing delay
          return timer(delay).pipe(
            switchMap(() =>
              originalRequest.pipe(catchError(cubicBackOffOnNetworkFailure))
            )
          );
        }
      }
      const msg = `Failed http request to ${req.url} with status: ${errorResponse.status} after ${retries} retries. Aborting`;

      console.error(msg);

      this.store$.dispatch(
        log({
          level: LogLevels.WARNING,
          message: msg,
          data: errorResponse,
          actionType: {
            name: "RETRY_SERVICE",
            type: "FAILURE",
            namespace: "MYQQ",
          },
        })
      );

      return throwError(errorResponse);
    };

    return next.handle(req).pipe(catchError(cubicBackOffOnNetworkFailure));
  }
}
