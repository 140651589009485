import { groupBy as _groupBy, sortBy as _sortBy } from "lodash-es";
import {
  Component,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChildren,
} from "@angular/core";
import {
  OrderTypesEnum,
  PostCalculateOrderResponse,
  Vehicle,
} from "src/app/core/services/myqq";
import { environment } from "src/environments/environment";
import { CartVehicleComponent } from "./cart-vehicle.component";
import { displayVehiclePlate } from "src/app/shared/pipes/display-vehicle-plate.pipe";

@Component({
  selector: "myqq-cart-vehicles",
  templateUrl: "./cart-vehicles.component.html",
  styleUrls: ["./cart-vehicles.component.scss"],
})
export class CartVehiclesComponent {
  @Input("orderItems") set _orderItems(
    items: PostCalculateOrderResponse["items"]
  ) {
    this.orderItems = _groupBy(items, "vehicleId");
  }
  orderItems: Record<string, PostCalculateOrderResponse["items"]> = {};

  @Input() orderType?: OrderTypesEnum;

  @Input() vehicles: (Vehicle & { selected?: boolean })[] = [];

  @Output() vehiclesInCartDidUpdate = new EventEmitter<Vehicle[]>();
  @Output() createVehicle = new EventEmitter();

  get selectedVehicles() {
    return this.vehicleSelectors
      .filter((selector) => selector.selected)
      .map(({ vehicle }) => vehicle);
  }

  readonly MaxVehiclesOnFlock = environment.limits.maxVehiclesOnSubscription;
  readonly MaxVehiclesOnAccount = environment.limits.maxVehiclesOnAccount;
  readonly OrderTypes = OrderTypesEnum;

  @ViewChildren(CartVehicleComponent) vehicleSelectors?: QueryList<
    CartVehicleComponent
  >;

  readonly vehicleShouldBeDisabled = (
    vehicle: Vehicle,
    vehicles: Vehicle[],
    orderItems: Record<string, PostCalculateOrderResponse["items"]>
  ): boolean => {
    return this.vehicleIsOnOrder(vehicle)
      ? this.vehiclesOnOrder(vehicles).length === 1
      : !this.canAddVehiclesToOrder(vehicles) &&
          this.vehiclesOnOrder(vehicles).length !== 1;
  };

  readonly maxFlockReached = (): boolean => {
    return (
      !this.canAddVehiclesToOrder(this.vehicles) &&
      this.canSelectVehicles(this.orderType)
    );
  };

  readonly canSelectVehicles = (orderType: OrderTypesEnum) =>
    orderType === OrderTypesEnum.ADDMEMBERSHIP ||
    orderType === OrderTypesEnum.NEWMEMBERSHIP;

  readonly canAddVehiclesToOrder = (vehicles: Vehicle[]) =>
    this.canSelectVehicles(this.orderType) &&
    this.numVehiclesSubscribedAndAdded(vehicles) < this.MaxVehiclesOnFlock;

  readonly canAddVehiclesToAccount = (vehicles: Vehicle[]) =>
    this.canSelectVehicles(this.orderType) &&
    vehicles.length < this.MaxVehiclesOnAccount;

  readonly vehicleIsOnMembership = ({ hasMembership }: Vehicle): boolean =>
    !!hasMembership;

  readonly vehicleIsNotOnMembership = (vehicle: Vehicle): boolean =>
    !this.vehicleIsOnMembership(vehicle);

  readonly vehicleIsOnOrder = ({ vehicleId }: Vehicle): boolean =>
    !!this.orderItems[vehicleId];

  readonly vehicleIsNotOnOrder = (vehicle: Vehicle): boolean =>
    !this.vehicleIsOnOrder(vehicle);

  readonly vehiclesOnOrder = (vehicles: Vehicle[]): Vehicle[] =>
    vehicles.filter(this.vehicleIsOnOrder);

  readonly vehiclesNotOnMembership = (vehicles: Vehicle[]): Vehicle[] =>
    this.sortVehicles(vehicles.filter(this.vehicleIsNotOnMembership));

  readonly vehiclesOnMembership = (vehicles: Vehicle[]): Vehicle[] =>
    this.sortVehicles(vehicles.filter(this.vehicleIsOnMembership));

  readonly numVehiclesSubscribedAndAdded = (vehicles: Vehicle[]): number =>
    this.vehiclesOnMembership(vehicles).length +
    this.vehiclesOnOrder(vehicles).length;

  readonly sortVehicles = (vehicles: Vehicle[]) =>
    _sortBy(vehicles, displayVehiclePlate);

  handleVehicleSelection(vehicle: Vehicle, vehicles: Vehicle[]) {
    if (vehicle) {
      const thisVehicleSelector = this.vehicleSelectors.find(
        ({ vehicle: { vehicleId } }) => vehicleId === vehicle.vehicleId
      );
      if (
        thisVehicleSelector &&
        thisVehicleSelector.selected &&
        this.vehicleIsNotOnOrder(vehicle) &&
        this.vehiclesOnMembership(vehicles).length +
          this.vehiclesOnOrder(vehicles).length >=
          this.MaxVehiclesOnFlock
      ) {
        this.vehicleSelectors
          .filter((vehicleSelector) => {
            return (
              vehicleSelector !== thisVehicleSelector &&
              vehicleSelector.selected
            );
          })
          .forEach((vehicleSelector) => {
            vehicleSelector.selected = false;
          });
      }
    }

    this.vehiclesInCartDidUpdate.emit(this.selectedVehicles);
  }

  handleCreateVehicle() {
    this.createVehicle.emit();
  }
}
