import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "map",
})
export class MapPipe implements PipeTransform {
  /**
   * Removed until https://github.com/angular/angular/issues/21224
   * is resolved.
   */
  // transform<T, S>(value: T, fn: (v: T) => S): S {
  transform<T, S>(
    value: T,
    fn: (v: T, ...m: any[]) => S,
    ...extraArgs: any[]
  ): any {
    return fn(value, ...extraArgs);
  }
}
