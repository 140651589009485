import {
  Location,
  UIState,
  AccountSetupSteps,
  VehicleSwapSteps,
  CancelMembershipSteps,
  UpdateFlockPresaleVehicleSteps,
} from "./ui.models";

export const INITIAL_STATE: UIState = {
  accountSetupStep: AccountSetupSteps.enter_account_details,
  vehicleSwapStep: VehicleSwapSteps.initial,
  cancelMembershipStep: CancelMembershipSteps.initial,
  updateFlockPresaleVehicleStep: UpdateFlockPresaleVehicleSteps.alert,
  salesPipeline: {
    isSalesPipeline: false,
    zipcode: null,
    subscriptionSKU: null,
  },
  existingMembership: {
    hasMembership: false,
  },
  zipAttempt: {
    attempt: 0,
  },
  b2cSignUpDetails: null,
  keycloakInitialized: null,
  location: {} as Location,
  promoCode: null,
  defaultPlans: null,
  dismissAddVehiclePromptInCart: false,
  showFlockPresaleModal: true,
};

export const uiFeatureKey = "UI";
