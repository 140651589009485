import { Component, Input } from "@angular/core";
import { SubscriptionPlan } from "src/app/core/services/myqq";

@Component({
  selector: "myqq-cart-membership-edit",
  templateUrl: "./cart-membership-edit.component.html",
  styleUrls: ["./cart-membership-edit.component.scss"],
})
export class CartMembershipEditComponent {
  @Input()
  targetSubscriptionPlan: SubscriptionPlan;
  @Input()
  currentSubscriptionPlan: SubscriptionPlan;
}
