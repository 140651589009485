import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { delay, first, map } from "rxjs/operators";
import { Location } from "@angular/common";

import {
  getProfile,
  selectVehicleById,
  editVehicle,
  selectEditVehicle,
  clearEditVehicle,
  getAccountInfo,
  selectAccountAndMembershipStatus,
} from "src/app/core/ngrx/myqq";
import { isSuccess } from "@nll/datum/DatumEither";

import { Vehicle } from "src/app/core/services/myqq";
import { RoutableId } from "./edit-vehicle.models";
import { merge } from "rxjs";
import { errors } from "src/app/core/services/myqq/myqq.errors";

@Component({
  selector: "myqq-edit-vehicle-page",
  templateUrl: "./edit-vehicle.page.html",
  styleUrls: ["./edit-vehicle.page.scss"],
})
export class EditVehiclePage implements OnInit {
  readonly vehicleId: RoutableId = this.router.getCurrentNavigation()?.extras
    .state?.vehicleId;
  readonly errVehicleAlreadyOnAccount =
    errors.myqqApi.errVehicleAlreadyOnAccount;
  readonly errVehicleOwnership = errors.myqqApi.errVehicleOwnership;
  readonly vehicle$ = this.store$.select(selectVehicleById(this.vehicleId));
  readonly editVehicle$ = this.store$.select(selectEditVehicle);
  readonly hasMembership$ = this.store$
    .select(selectAccountAndMembershipStatus)
    .pipe(
      map((valueDE) => {
        if (isSuccess(valueDE)) {
          return valueDE?.value.right?.hasMembership;
        } else return false;
      })
    );
  private editVehicleSub = this.editVehicle$
    .pipe(first(isSuccess))
    .subscribe(() => this.onSuccess());
  @ViewChild("footer", { static: false }) private _footerTemplate: TemplateRef<
    any
  >;
  readonly footerTemplate = merge(this.vehicle$, this.editVehicle$).pipe(
    delay(1),
    map(() => this._footerTemplate)
  );

  constructor(
    readonly store$: Store<any>,
    readonly location: Location,
    readonly router: Router
  ) {}

  ngOnInit() {
    if (this.vehicleId && this.vehicleId !== "") {
      this.handleGetVehicleData();
    } else {
      this.router.navigateByUrl("/vehicles");
    }
  }

  handleGetVehicleData() {
    this.store$.dispatch(clearEditVehicle(null));
    this.store$.dispatch(getProfile.pending(null));
    this.store$.dispatch(getAccountInfo.pending(null));
  }

  handleEditVehicle(vehicle: Vehicle) {
    this.store$.dispatch(editVehicle.pending(vehicle));
  }

  handleResetEditVehicle() {
    this.store$.dispatch(clearEditVehicle(null));
  }

  handleCancel() {
    this.store$.dispatch(clearEditVehicle(null));
    this.location.back();
  }
  onSuccess() {
    this.handleCancel();
  }
  ngOnDestroy() {
    this.editVehicleSub?.unsubscribe();
    this.store$.dispatch(clearEditVehicle(null));
  }
  handleDismissErrorBanner() {
    this.handleResetEditVehicle();
  }
  handleRelink() {
    this.router.navigateByUrl("/relink");
  }
}
