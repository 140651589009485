<div class="fld-col flg-5 main-content">
  <form [formGroup]="form" class="form-grid">
    <mat-form-field id="state">
      <mat-label>State</mat-label>
      <select
        matNativeControl
        #stateSelect
        formControlName="state"
        placeholder=""
      >
        <mat-select-trigger>
          {{ form.value.state || "" }}
        </mat-select-trigger>
        <option value=""></option>
        <option *ngFor="let state of states" [value]="state.abbreviation">
          {{ state.abbreviation }} - {{ state.name }}
        </option>
      </select>
    </mat-form-field>

    <mat-form-field id="license">
      <mat-label>License Plate</mat-label>
      <input
        id="license-input"
        matInput
        placeholder="ABC123"
        formControlName="license"
        [mask]="licenseMask"
      />
    </mat-form-field>

    <mat-form-field *ngIf="!hideMakeModelYear" id="year">
      <mat-label>Year</mat-label>
      <select matNativeControl formControlName="year">
        <option value=""></option>
        <option *ngFor="let year of years" [value]="year">
          {{ year }}
        </option>
      </select>
    </mat-form-field>

    <mat-form-field *ngIf="!hideMakeModelYear" id="make">
      <mat-label>Make</mat-label>
      <select matNativeControl formControlName="make">
        <option value=""></option>
        <option *ngFor="let make of makes" [value]="make.makeId">
          {{ make.name }}
        </option>
      </select>
    </mat-form-field>

    <mat-form-field *ngIf="!hideMakeModelYear" id="model" class="fls-0-0">
      <mat-label>Model</mat-label>
      <select matNativeControl formControlName="model">
        <option value=""></option>
        <option *ngFor="let model of models" [value]="model.name.toUpperCase()">
          {{ model.name }}
        </option>
      </select>
    </mat-form-field>
    <p class="js-ctr" id="divider">OR</p>

    <mat-form-field id="vin">
      <mat-label>VIN</mat-label>
      <input
        matInput
        id="vin-input"
        placeholder="VIN"
        formControlName="vin"
        maxlength="17"
        [patterns]="vinMask.customPattern"
        [mask]="vinMask.mask"
      />
    </mat-form-field>
  </form>
</div>
