import { map, reduce, groupBy, omit, isString } from "lodash-es";
import { Component, Input } from "@angular/core";

import {
  OrderItem,
  PostCalculateOrderResponse,
} from "src/app/core/services/myqq";
import { Money } from "@qqcw/qqsystem-util";

@Component({
  selector: "myqq-cart-items",
  templateUrl: "./cart-items.component.html",
  styleUrls: ["./cart-items.component.scss"],
})
export class CartItemsComponent {
  constructor() {}
  @Input() items: PostCalculateOrderResponse["items"];

  readonly sortAndGroupByItem = (orderItems: OrderItem[]) => {
    return groupByItem(orderItems).sort(orderItemSort);
  };
}

const isFlock = (a?: string) => isString(a) && a.includes("FLOCK");

function sumOrderItems(acc, next) {
  return {
    ...acc,
    orderedQty: `${(+acc.orderedQty || 0) + +next.orderedQty}`,
    shippedQty: `${(+acc.shippedQty || 0) + +next.shippedQty}`,
    returnedQty: `${(+acc.returnedQty || 0) + +next.returnedQty}`,
    lineTotal: Money.add(acc.lineTotal || 0, next.lineTotal).amount,
    taxTotal: Money.add(acc.taxTotal || 0, next.taxTotal).amount,
    subTotal: Money.add(acc.subTotal || 0, next.subTotal).amount,
    grossTotal: Money.add(acc.grossTotal || 0, next.grossTotal).amount,
    totalMarkDowns: Money.add(acc.totalMarkDowns || 0, next.totalMarkDowns)
      .amount,
    totalMarkUps: Money.add(acc.totalMarkUps || 0, next.totalMarkUps).amount,
    totalTaxable: Money.add(acc.totalTaxable || 0, next.totalTaxable).amount,
  } as OrderItem;
}

function orderItemSort({ sku: a }: OrderItem, { sku: b }: OrderItem) {
  if (isFlock(a) && isFlock(b)) {
    return 0;
  } else if (isFlock(a)) {
    return 1;
  } else {
    return -1;
  }
}

function groupByItem(orderItems: OrderItem[]): OrderItem[] {
  return map(groupBy(orderItems, "itemId"), (items) =>
    reduce(
      items,
      sumOrderItems,
      omit(
        items[0],
        "orderedQty",
        "shippedQty",
        "returnedQty",
        "lineTotal",
        "taxTotal",
        "subTotal",
        "grossTotal",
        "totalMarkDowns",
        "totalMarkUps",
        "totalTaxable"
      ) as Partial<OrderItem>
    )
  );
}
