import { Inject, Injectable } from "@angular/core";
import { Actions, createEffect } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import { interval, merge } from "rxjs";
import { filter, map, throttleTime, withLatestFrom } from "rxjs/operators";
import { sendLogs } from "./logging.actions";

import { LOGGING_CONFIG_PROVIDER, LoggingConfig } from "./logging.models";
import { selectLogs } from "./logging.selectors";
import { LoggingService } from "./logging.service";
import { asyncConcatMap } from "src/app/shared/utilities/state/Operators";
import { Action } from "src/app/shared/utilities/state/Actions";

@Injectable()
export class LoggingEffects {
  public sendLogsTimer$ = createEffect(() =>
    merge(
      this.actions$.pipe(filter((a) => a.error)),
      interval(this.config.sendInterval)
    ).pipe(
      throttleTime(this.config.throttleSend),
      withLatestFrom(this.store$.pipe(select(selectLogs))),
      filter(([_, logs]) => logs.length > 0),
      map(([_, logs]) => sendLogs.pending({ logs }))
    )
  );

  public sendLogs$ = createEffect(() =>
    this.actions$.pipe(asyncConcatMap(sendLogs, (p) => this.svc.sendLogs(p)))
  );

  constructor(
    private store$: Store<any>,
    private actions$: Actions<Action<any>>,
    private svc: LoggingService,
    @Inject(LOGGING_CONFIG_PROVIDER) private config: LoggingConfig
  ) {}
}
