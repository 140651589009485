import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { MatButtonModule } from "@angular/material/button";

import { ModalRouteModule } from "src/app/shared/components/routed-modal";
import { DatumModule } from "src/app/shared/directives/datum";
import { OptionModule } from "src/app/shared/directives/option";
import { SharedModule } from "src/app/shared/shared.module";

import { PastDueComponent } from "./past-due.component";
import { PastDuePage } from "./past-due.page";

@NgModule({
  declarations: [PastDueComponent, PastDuePage],
  imports: [
    CommonModule,
    DatumModule,
    MatButtonModule,
    ModalRouteModule,
    OptionModule,
    SharedModule,
  ],
})
export class PastDueModule {}
