import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { Store } from "@ngrx/store";

import { environment } from "src/environments/environment";

import {
  getMySubscriptions,
  selectMySubscription,
} from "src/app/core/ngrx/myqq";
@Component({
  selector: "myqq-past-due-page",
  templateUrl: "./past-due.page.html",
})
export class PastDuePage implements OnInit {
  readonly subscription$ = this.store$.select(selectMySubscription);
  readonly phoneNumber = environment.supportNumber;

  constructor(private store$: Store<any>, private router: Router) {}

  ngOnInit(): void {
    this.store$.dispatch(getMySubscriptions.pending(null));
  }

  editPaymentMethod() {
    this.router.navigate([{ outlets: { modal: "failed-payment-method" } }]);
  }
}
