<myqq-routed-modal>
  <span modal-header>Membership Temporarily Locked</span>
  <ng-container class="fld-col flg-5">
    <header class="vwcmx-p30 ta-c">
      <img class="mwt-4" src="{{ cdnHost }}/img/doctor-quackals.png" />
    </header>
    <section>
      <p class="ta-c pwx-4">
        Sorry! We can't update your membership at this time as your payment is
        currently past due.
      </p>

      <p class="ta-c pwx-4 pwb-4">
        Your payment of
        <b>{{ subscription.renewalAmount | currency: "USD":"$" }}</b> was due on
        <b>{{ subscription.renewalDate | date }}</b
        >.
      </p>

      <p class="ta-c pwx-4 pwb-4">
        Click below to update your payment method and unlock your membership.
      </p>
    </section>
    <footer class="qq-buttons">
      <button
        mat-raised-button
        class="update-payment-btn"
        color="primary"
        (click)="handleEditPaymentMethod()"
      >
        Update Payment Method
      </button>
    </footer>
  </ng-container>
</myqq-routed-modal>
