import { Component, EventEmitter, Input, Output } from "@angular/core";
import { SwapReason } from "src/app/core/services/myqq";

@Component({
  selector: "myqq-vehicle-swap-reason",
  templateUrl: "./vehicle-swap-reason.component.html",
  styleUrls: ["./vehicle-swap-reason.component.scss"],
})
export class VehicleSwapReasonComponent {
  @Input() swapReasons: SwapReason[] = [];
  @Output() selectedReason = new EventEmitter<SwapReason>();
  @Output() back = new EventEmitter();
  currentReason: SwapReason;
  selectReason(reason: SwapReason) {
    if (!!reason) {
      this.currentReason = reason;
    }
  }
  handleNext() {
    if (!!this.currentReason) {
      this.selectedReason.emit(this.currentReason);
    }
  }
}
