import { take as _take, sortBy as _sortBy } from "lodash-es";
import { Injectable } from "@angular/core";
import { Actions, createEffect } from "@ngrx/effects";
import { distinctUntilChanged, filter, map } from "rxjs/operators";
import * as O from "fp-ts/es6/Option";

import { notNil } from "@qqcw/qqsystem-util";

import { filterActions } from "src/app/shared/utilities/state/Operators";
import {
  accountLink,
  addVehicle,
  cancelMembership,
  clearAccountQuota,
  editVehicle,
  editVehicleIdentifier,
  getAccountInfo,
  getMarketingContent,
  getMyPaymentMethods,
  getMySubscriptions,
  getPriceTableByZip,
  getProfile,
  getVehicles,
  newAccount,
  postPaymentMethod,
  removeVehicle,
  setVehiclesOnOrder,
  submitOrder,
  swapMembership,
  updateAccount,
} from "./myqq.reducers";

import { setDefaultPlans } from "../ui";
import { UnleashService } from "src/app/shared/services/unleash.service";

/**
 * This service contains chaining side effects. For example, when we get a profile we
 * might also want to get the persons vehicles and coupons from other endpoints without
 * explicitely chaining those calls in the ui. This service contains those cascading
 * events.
 */
@Injectable()
export class MyQQChainEffects {
  /**
   * Refresh profile data after:
   * * Creating a new account
   * * Adding new vehicle
   */
  refreshProfileChains$ = createEffect(() =>
    this.actions$.pipe(
      filterActions(
        addVehicle.success,
        editVehicle.success,
        updateAccount.success,
        cancelMembership.success
      ),
      map(() => getProfile.pending(null))
    )
  );
  refreshProfileChainsNewAccount$ = createEffect(() =>
    this.actions$.pipe(
      filterActions(newAccount.success, accountLink.success),
      map(() => getProfile.pending({ track: true }))
    )
  );

  updateUnleashOnAccountInfoChange$ = createEffect(
    () =>
      this.actions$.pipe(
        filter(getAccountInfo.success.match),
        map(({ value: { result } }) => result),
        distinctUntilChanged(),
        map((accountInfo) =>
          this.unleashService.updateUnleashContextOnGetAccount(accountInfo)
        )
      ),
    { dispatch: false }
  );

  updateUnleashOnSubscriptionSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        filter(getMySubscriptions.success.match),
        map(({ value: { result } }) => result),
        filter(O.isSome),
        map(({ value }) => value),
        distinctUntilChanged(),
        map((subscription) =>
          this.unleashService.updateUnleashContextOnGetSubscription(
            subscription
          )
        )
      ),
    { dispatch: false }
  );

  refreshAccountChain$ = createEffect(() =>
    this.actions$.pipe(
      filterActions(
        newAccount.success,
        accountLink.success,
        cancelMembership.success
      ),
      map(() => {
        return getAccountInfo.pending(null);
      })
    )
  );

  /**
   * Get payment methods after postPaymentMethod success
   */
  getPaymentMethodsOnPostSuccess$ = createEffect(() =>
    this.actions$.pipe(
      filter(postPaymentMethod.success.match),
      map(() => getMyPaymentMethods.pending(null))
    )
  );

  /**
   * Currently, orders always affect the subscription object so
   * we should refresh the subscription state whenever a submit
   * order completes successfully.
   */
  refreshSubscriptionChains$ = createEffect(() =>
    this.actions$.pipe(
      filterActions(
        submitOrder.success,
        cancelMembership.success,
        accountLink.success
      ),
      map(() => getMySubscriptions.pending(null))
    )
  );

  refreshAccountOnOrderComplete$ = createEffect(() =>
    this.actions$.pipe(
      filter(submitOrder.success.match),
      map(() => getAccountInfo.pending(null))
    )
  );

  /**
   * Refresh vehicle list when one is added, edited, removed from the account or the membership
   */
  refreshVehiclesOnVehiclesAndMembershipChange$ = createEffect(() =>
    this.actions$.pipe(
      filterActions(
        removeVehicle.success,
        addVehicle.success,
        editVehicle.success,
        submitOrder.success,
        swapMembership.success,
        editVehicleIdentifier.success
      ),
      map(() => getVehicles.pending(null))
    )
  );

  clearAccountQuotaOnSwapAndEditLPVIN$ = createEffect(() =>
    this.actions$.pipe(
      filterActions(swapMembership.success, editVehicleIdentifier.success),
      map(() => clearAccountQuota(null))
    )
  );

  // temp workaround for handling cases where we don't want to initiate an order right after adding a vehicle
  // need to figure out how pass in more params in
  clearVehiclesOnOrderOnSwapSuccess$ = createEffect(() =>
    this.actions$.pipe(
      filterActions(swapMembership.success),
      map(() => setVehiclesOnOrder([]))
    )
  );

  setDefaultPlansOnGetPriceSuccess$ = createEffect(() =>
    this.actions$.pipe(
      filterActions(getPriceTableByZip.success),
      map(({ value: { result: value } }) => value),
      filter(notNil),
      map((value) => {
        return setDefaultPlans(value);
      })
    )
  );

  refreshMarketingContent$ = createEffect(() =>
    this.actions$.pipe(
      filterActions(
        submitOrder.success,
        cancelMembership.success,
        accountLink.success
      ),
      map(() => getMarketingContent.pending(null))
    )
  );

  constructor(
    readonly actions$: Actions,
    readonly unleashService: UnleashService
  ) {}
}
