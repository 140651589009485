import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

export const mapError = (fn: (error: unknown) => unknown) => <T>(
  obs: Observable<T>
): Observable<T> =>
  obs.pipe(
    catchError((error) => {
      return throwError(fn(error));
    })
  );
