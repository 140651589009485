import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Vehicle } from "src/app/core/services/myqq";
import { AccountQuotaWithRemaining } from "src/app/shared/utilities/types";

@Component({
  selector: "myqq-edit-vehicle-confirm",
  templateUrl: "./edit-vehicle-confirm.component.html",
  styleUrls: ["./edit-vehicle-confirm.component.scss"],
})
export class EditVehicleConfirmComponent {
  @Input() currentVehicle: Vehicle;
  @Input() newVehicle: Vehicle;
  @Input() accountQuota: AccountQuotaWithRemaining;
  @Input() showQuota: boolean = true;
  @Input() membershipBadgeColor: string;
  @Output() submit = new EventEmitter();
  @Output() showDisclaimer = new EventEmitter();
  @Output() back = new EventEmitter();
}
