<mat-card class="vehicle-row" [ngClass]="removeBorder ? 'no-outline' : ''">
  <div class="vehicle-image ta-c">
    <evox-vehicle-image [vehicle]="vehicle"></evox-vehicle-image>
  </div>
  <span
    *ngIf="showMembershipBadge"
    class="membership membership-badge ta-c"
    [ngStyle]="{
      'background-color': membershipBadgeColor
        ? membershipBadgeColor
        : !!vehicle.hasMembership && !vehicle.expiring
        ? '#017550'
        : '#979797'
    }"
  >
    {{ vehicle.expiring ? 'Expiring': (vehicle.hasMembership ? (vehicle.myQQShortDisplayName | titlecase )?? 'Member' : 'Non-Member' )}}
  </span>
  <span *ngIf="vehicle.make" class="make-model fw-u3">
    {{ vehicle | map: toMakeModel }}
  </span>
  <span
    class="lp-vin nowrap fls-1-1"
    [ngClass]="vehicle.make ? '' : 'shift-lp-vin'"
  >
    {{ vehicle | displayVehiclePlate }}
  </span>
  <mat-icon *ngIf="showExpandIcon" class="expand-icon"> expand_more</mat-icon>
</mat-card>
