import { RegionMenu } from "../../services/myqq/myqq.models";

/**
 * A model of persistent ui state across the application
 */
export interface UIState {
  readonly accountSetupStep: AccountSetupSteps;
  readonly vehicleSwapStep: VehicleSwapSteps;
  readonly cancelMembershipStep: CancelMembershipSteps;
  readonly updateFlockPresaleVehicleStep: UpdateFlockPresaleVehicleSteps;
  readonly salesPipeline: SalesPipeline;
  readonly existingMembership: ExistingMembership;
  readonly zipAttempt: ZipAttempt;
  readonly keycloakInitialized: boolean;
  readonly location: Location;
  readonly promoCode: string;
  readonly defaultPlans: RegionMenu;
  readonly dismissAddVehiclePromptInCart: boolean;
  readonly showFlockPresaleModal: boolean;
  readonly b2cSignUpDetails: b2cSignUpDetails;
}

export interface UIStepperState {
  state: UIStepperAction;
}

/**
 * An enum representing the possible steps in the account
 * setup stepper
 */
export enum AccountSetupSteps {
  enter_credit_card_details,
  last4lookup,
  confirm_account,
  enter_account_details,
  choose_location,
  complete,
  failed,
}

export enum VehicleSwapSteps {
  initial,
  choose_reason,
  confirm,
}

export enum CancelMembershipSteps {
  initial,
  offer,
  choose_reason,
  confirm,
  accepted_offer,
}

export enum UpdateFlockPresaleVehicleSteps {
  alert,
  edit,
}

export const UIStepperActions = Object.freeze({
  ACCOUNT_SETUP_STEP: AccountSetupSteps,
  VEHICLE_SWAP_STEP: VehicleSwapSteps,
  CANCEL_MEMBERSHIP_STEP: CancelMembershipSteps,
  UPDATE_FLOCK_PRESALE_VEHICLE_STEP: UpdateFlockPresaleVehicleSteps,
});

export const StepperActionKeys = (
  stepperAction: typeof UIStepperActions[keyof typeof UIStepperActions]
): keyof typeof UIStepperActions | undefined =>
  (<(keyof typeof UIStepperActions)[]>Object.keys(UIStepperActions)).find(
    (key) => UIStepperActions[key] === stepperAction
  );

export interface UIStepperAction {
  type: string;
  step: string;
}

/**
 * Sales Pipeline Types
 */

export interface SalesPipeline {
  isSalesPipeline: boolean;
  origination?: string;
  zipcode?: string;
  subscriptionSKU?: string;
  promo?: string;
}

export interface ExistingMembership {
  hasMembership: boolean;
}

export interface ZipAttempt {
  attempt: number;
}

export interface Location {
  zip?: string;
  lat?: number;
  lng?: number;
  pin?: boolean; // Drop a pin on the map?
}

/**
 * B2b Types
 */

export interface b2cSignUpDetails {
  benefitcode?: string;
  email?: string;
}
