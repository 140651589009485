<div class="fld-col flg-5 ai-stc pwa-5">
  <img
    [src]="
      (online | async) ? errorImg : '/assets/icon/offline_quackals_960x600.gif'
    "
    alt="Quackals"
    class="as-ctr vw-px100"
  />
  <h3 class="cf-error mwa-0 ta-c">
    {{ (online | async) ? title : "Offline" }}
  </h3>
  <p *ngIf="description | map: notNil" class="mwa-0 ta-c">
    {{
      (online | async)
        ? description
        : "We were unable to complete the requested action because you are offline.
    Please reconnect to the Internet and try again."
    }}
  </p>
  <ng-content></ng-content>
  <section
    *ngIf="showErrorDetail && (error | map: notNil)"
    class="fld-col flg-4 pwa-4 ct-light bra-1 ova-sc"
  >
    <h5 class="mwa-0 ta-c">Error Detail</h5>
    <pre class="mwa-0">{{
      error?.name === "ValidationError" ? error?.message : (error | json)
    }}</pre>
  </section>
</div>
