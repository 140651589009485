import { HttpClientModule } from "@angular/common/http";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { MatBottomSheetModule } from "@angular/material/bottom-sheet";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { UrlSerializer } from "@angular/router";
import { ServiceWorkerModule } from "@angular/service-worker";

import { environment } from "src/environments/environment";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AppUIConfigModule } from "./app-ui-config.module";
import { CoreModule } from "./core/core.module";
import { ModalUrlSerializer } from "./shared/components/routed-modal/routed-modal.serializer";
import { StripeModule } from "./shared/modules/stripe";
import { PwaService } from "./shared/services/pwa.service";

const disableAnimations =
  !("animate" in document.documentElement) ||
  (navigator && /iPhone OS (8|9|10|11|12|13)_/.test(navigator.userAgent));

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    CoreModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule.withConfig({ disableAnimations }),
    MatBottomSheetModule,
    MatSnackBarModule,
    AppUIConfigModule,
    StripeModule.init({ publicKey: environment.stripeConfig.publishableKey }),
    ServiceWorkerModule.register("ngsw-worker.js", {
      scope: "/",
      enabled: environment.production,
      registrationStrategy: "registerImmediately",
    }),
  ],
  exports: [AppUIConfigModule],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: UrlSerializer,
      useClass: ModalUrlSerializer,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (pwaService: PwaService) => () => pwaService.initPwaService(),
      deps: [PwaService],
      multi: true,
    },
  ],
})
export class AppModule {
  constructor() {}
}
