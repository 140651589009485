import { Component, Input } from "@angular/core";
import { parseISO } from "date-fns";
import { SubscriptionDetail } from "src/app/core/services/myqq";
import { MoneyRepresentation } from "src/app/shared/utilities/types";

@Component({
  selector: "myqq-cart-totals",
  templateUrl: "./cart-totals.component.html",
  styleUrls: ["./cart-totals.component.scss"],
})
export class CartTotalsComponent {
  @Input()
  readonly orderTotal?: string;
  @Input()
  readonly nextBillingAmount?: string;
  @Input()
  readonly currentSubscription?: SubscriptionDetail;
  @Input()
  readonly priceOverride?: MoneyRepresentation;
  @Input()
  readonly promoMessage?: string;

  readonly getNextBillingDate = (renewalDate?: string) =>
    (renewalDate ? parseISO(renewalDate) : new Date()).getDate();
}
