import { pipe } from "fp-ts/es6/pipeable";
import { Lens } from "src/app/shared/utilities/state/Optics";
import * as DE from "@nll/datum/DatumEither";
import { notNil } from "@qqcw/qqsystem-util";
import { GetProfileResponse } from "src/app/core/services/myqq";

import { MyQQState } from "./myqq.models";
import { mapLens } from "src/app/shared/utilities/state/DatumEither";

/**
 * Lenses are functional getter/setters against a known data structure.
 * Here we use a helper function called 'fromProp' and give it an interface.
 * fromProp returns a function that takes a key from the interface and returns
 * a new lens for that key. In this example the personLens can take a MyQQState
 * object and get, set, or modify the value at the 'person' key without changing
 * the rest of the object.
 *
 * @example
 * // personLens has type Lens<MyQQState, DatumEither<unknown, Person>
 * const personLens = Lens.fromProp<MyQQState>()('person');
 *
 * You use a lens in three ways:
 * 1. Get: personLens.get({ person: initial }) will return initial
 * 2. Set: personLens.set(pending)({ person: initial }) will return { person: pending }
 * 3. Modify:
 *    personLens
 *      .modify(person => isRefreshing(person) ? person : toRefresh(person))
 *      ({ person: initial })
 *    will return { person: pending }
 *
 * The real power in using lenses is that they are composable. If you have a lens from
 * MyQQState to person and a lens from DatumEither<unknown, Person> to personId you can
 * compose them:
 *
 * @example
 * const MyQQStateToPersonIdLens = personLens.composeOptional(personIdOptional);
 *
 * Here we used a special type of lens called an Optional. Since data might not be
 * populated, we might not have a personId yet, so an Optional lens signifies data
 * that may or may not exist. It has the same set, and modify methods but in the
 * case of get it instead has getOption, which returns an Option type.
 */
const myqqStateProps = Lens.fromProp<MyQQState>();

/**
 * Profile Helper Lenses
 */
type Membership = GetProfileResponse["membership"];

/**
 * Maps Valued<GetProfileResponse> to Valued<Membership> if membership is non-null.
 * Otherwise it returns failure. This is a somewhat interesting pattern that should
 * be revisited later.
 */
const membershipL = new Lens<
  DE.DatumEither<{ error: string }, GetProfileResponse>,
  DE.DatumEither<{ error: string }, Membership>
>(
  DE.chain((profile) =>
    notNil(profile.membership)
      ? DE.success(profile.membership)
      : DE.failure({ error: "No membership exists on this account." })
  ),
  (membershipDE) => (profileDE) =>
    pipe(
      DE.sequenceTuple(membershipDE, profileDE),
      DE.map(([membership, profile]) => ({ ...profile, membership }))
    )
);
const membershipProps = Lens.fromProp<Membership>();
const accountL = membershipProps("account");
const vehiclesL = membershipProps("vehicles");
const activeCouponsL = membershipProps("activeCoupons");

/**
 * Selector Lenses
 */
export const initialLoadLens = myqqStateProps("initialLoad");

export const accountInfoLens = myqqStateProps("accountInfo");
export const profileLens = myqqStateProps("profile");
export const membershipLens = profileLens.compose(membershipL);
export const accountLens = membershipLens.compose(mapLens(accountL));
export const newAccountLens = myqqStateProps("newAccount");
export const updateAccountLens = myqqStateProps("updateAccount");
export const vehiclesLens = membershipLens.compose(mapLens(vehiclesL));
export const couponsLens = membershipLens.compose(mapLens(activeCouponsL));
export const storesLens = myqqStateProps("stores");
export const regionsLens = myqqStateProps("regions");
export const subscriptionLens = myqqStateProps("subscription");
export const ordersLens = myqqStateProps("orders");
export const paymentMethodsLens = myqqStateProps("paymentMethods");
export const priceTableLens = myqqStateProps("priceTable");
export const postPaymentMethodLens = myqqStateProps("postPaymentMethod");
export const payInvoiceLens = myqqStateProps("payInvoice");

export const addVehicleLens = myqqStateProps("addVehicle");
export const getVehiclesLens = myqqStateProps("getVehicles");
export const editVehicleLens = myqqStateProps("editVehicle");
export const removeVehicleLens = myqqStateProps("removeVehicle");

export const accountLookupLens = myqqStateProps("accountLookupResponse");
export const linkAccountLens = myqqStateProps("accountLinkResponse");

export const getOrderByIdLens = myqqStateProps("getOrder");
export const pendingOrderLens = myqqStateProps("pendingOrder");
export const calculateOrderLens = myqqStateProps("calculateOrder");
export const submitOrderLens = myqqStateProps("submitOrder");
export const checkPromoCodeLens = myqqStateProps("checkPromo");
export const modifySubscriptionPlanLens = myqqStateProps(
  "modifySubscriptionPlan"
);
export const membershipPurchaseLens = myqqStateProps("membershipPurchase");
export const promoDetailsLens = myqqStateProps("promoDetails");
export const companyWidePromoDetailsLens = myqqStateProps(
  "companyWidePromoDetails"
);
export const swapReasonsLens = myqqStateProps("swapReasons");
export const accountQuotaLens = myqqStateProps("accountQuota");
export const swapMembershipLens = myqqStateProps("swapMembership");
export const editVehicleIdentifierLens = myqqStateProps(
  "editVehicleIdentifier"
);
export const cancelMembershipLens = myqqStateProps("cancelMembership");
export const checkEligibilityForRetentionOfferLens = myqqStateProps(
  "checkEligibilityForRetentionOffer"
);
export const acceptRetentionOfferLens = myqqStateProps("acceptRetentionOffer");
export const cancelReasonsLens = myqqStateProps("cancelReasons");
export const b2bBenefitLens = myqqStateProps("b2bBenefit");
export const linkB2CLens = myqqStateProps("linkB2C");

/**
 * Id Lenses
 */
export const getOrderIdLens = new Lens(
  (s: string) => s,
  (s) => (_) => s
);

export const appMinVersionLens = myqqStateProps("appMinVersion");
export const vehiclesOnOrderLens = myqqStateProps("vehiclesOnOrder");

export const marketingContentLens = myqqStateProps("marketingContent");
