import { Directive, forwardRef } from "@angular/core";
import {
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  AbstractControl,
  ControlValueAccessor,
  Validator,
  ValidationErrors,
} from "@angular/forms";
import { StripeElementDirective } from "../stripe-element";

/**
 * Bridges the StripeElementDirective with the Angular's form API implementing both a ControlValueAccessor
 * and a sync Validator enabling the use with FormControl
 */
@Directive({
  selector:
    "myqq-stripe-card[ngModel], myqq-stripe-card[formControl], myqq-stripe-card[formControlName]\
             myqq-stripe-card-number[ngModel], myqq-stripe-card-number[formControl], myqq-stripe-card-number[formControlName]\
             myqq-stripe-card-expiry[ngModel], myqq-stripe-card-expiry[formControl], myqq-stripe-card-expiry[formControlName]\
             myqq-stripe-card-cvc[ngModel], myqq-stripe-card-cvc[formControl], myqq-stripe-card-cvc[formControlName]\
             myqq-stripe-iban[ngModel], myqq-stripe-iban[formControl], myqq-stripe-iban[formControlName]\
             myqq-stripe-ideal[ngModel], myqq-stripe-ideal[formControl], myqq-stripe-ideal[formControlName]",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => StripeControl),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => StripeControl),
      multi: true,
    },
  ],
})
export class StripeControl implements ControlValueAccessor, Validator {
  constructor(readonly element: StripeElementDirective<any>) {}

  /**
   * Called by the forms API to write to the view when programmatic changes from model to view are requested.
   * NOTE: Only clearing the control is allowed
   */
  writeValue(value: any): void {
    !value && this.element.clear();
  }

  /**
   * Registers a callback function that is called when the control's value changes in the UI.
   * The value passed along the FormControl is the stripe Element instance to be used in the
   * payment_method object to setup or confirm the payment.
   */
  registerOnChange(fn: (_: any) => void): void {
    this.element.valueChange.subscribe((value) =>
      fn(value.complete ? this.element.element : null)
    );
  }

  /** Registers a callback function is called by the forms API on initialization to update the form model on blur. */
  registerOnTouched(fn: () => void): void {
    this.element.blurChange.subscribe(() => fn());
  }

  /**
   * Function that is called by the forms API when the control status changes to or from 'DISABLED'.
   * Depending on the status, it enables or disables the appropriate DOM element.
   */
  setDisabledState(disabled: boolean): void {
    this.element.update({ disabled });
  }

  /** Performs synchronous validation against the provided control. */
  validate(_: AbstractControl): ValidationErrors | null {
    const errorType = this.element.error && this.element.error.type;

    return errorType ? { [errorType]: true } : null;
  }
}
