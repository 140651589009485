import { Lens } from "src/app/shared/utilities/state/Optics";
import { UIState, UIStepperState } from "./ui.models";

const actionLensProp = Lens.fromProp<UIState>();

export const accountSetupStepLens = actionLensProp("accountSetupStep");
export const vehicleSwapStepLens = actionLensProp("vehicleSwapStep");
export const cancelMembershipStepLens = actionLensProp("cancelMembershipStep");
export const updateFlockPresaleVehicleStepLens = actionLensProp(
  "updateFlockPresaleVehicleStep"
);

const stateLensProp = Lens.fromProp<UIStepperState>();

export const stepChangeLens = stateLensProp("state");

export const salesPipelineLens = actionLensProp("salesPipeline");
export const existingMembershipLens = actionLensProp("existingMembership");
export const zipAttemptLens = actionLensProp("zipAttempt");
export const keycloakInitializedLens = actionLensProp("keycloakInitialized");

export const locationLens = actionLensProp("location");

export const b2cSignUpDetailsLens = actionLensProp("b2cSignUpDetails");
export const promoCode = actionLensProp("promoCode");
export const defaultPlans = actionLensProp("defaultPlans");

// use for non-urgent dismissable prompts
export const dismissAddVehiclePromptInCartLens = actionLensProp(
  "dismissAddVehiclePromptInCart"
);
export const showFlockPresaleModalLens = actionLensProp(
  "showFlockPresaleModal"
);
