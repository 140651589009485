import { Component, Input, OnInit } from "@angular/core";
import { SOCIAL_MEDIA_LINKS } from "src/app/core/services/myqq/myqq.consts";

@Component({
  selector: "myqq-social",
  templateUrl: "./social.component.html",
  styleUrls: ["./social.component.scss"],
})
export class SocialComponent implements OnInit {
  @Input() instagram: string;
  @Input() facebook: string;
  @Input() snapchat: string;
  @Input() twitter: string;
  @Input() youtube: string;

  @Input() color: string;

  accounts: {
    instagram: string;
    facebook: string;
    snapchat: string;
    twitter: string;
    youtube: string;
  };

  readonly uri = SOCIAL_MEDIA_LINKS;

  constructor() {}

  ngOnInit(): void {
    this.accounts = {
      instagram: this.instagram,
      facebook: this.facebook,
      snapchat: this.snapchat,
      twitter: this.twitter,
      youtube: this.youtube,
    };
  }
}
