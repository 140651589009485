<ng-template #content>
  <ng-content></ng-content>
</ng-template>
<ng-container *ngIf="!landingPage">
  <myqq-mobile-layout
    *ngIf="isMobile | async"
    [homeLink]="homeLink"
    [accountLinks]="mobileAccountLinks"
    [accountLinked]="accountLinked$ | async"
    [authenticated]="allowAccess$"
    [online]="online$ | async"
    [greenBackground]="greenBackground"
    (authenticate)="handleAuthentication($event)"
    (register)="handleRegister()"
  >
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </myqq-mobile-layout>

  <myqq-desktop-layout
    *ngIf="!(isMobile | async)"
    [homeLink]="homeLink"
    [accountLinks]="accountLinks"
    [accountLinked]="accountLinked$ | async"
    [authenticated]="allowAccess$"
    [online]="online$ | async"
    (authenticate)="handleAuthentication($event)"
    (register)="handleRegister()"
  >
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </myqq-desktop-layout>
</ng-container>
<ng-container *ngIf="landingPage">
  <myqq-mobile-layout
    *ngIf="isMobile | async"
    [homeLink]="homeLink"
    [accountLinks]="mobileAccountLinks"
    [accountLinked]="accountLinked$ | async"
    [authenticated]="allowAccess$"
    [online]="online$ | async"
    [greenBackground]="greenBackground"
    showQuickQuackHeader="true"
    (authenticate)="handleAuthentication($event)"
    (register)="handleRegister()"
  >
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </myqq-mobile-layout>

  <myqq-landing-layout-desktop
    *ngIf="!(isMobile | async)"
    [homeLink]="homeLink"
    [accountLinked]="accountLinked$ | async"
    [authenticated]="allowAccess$"
    [online]="online$ | async"
    (authenticate)="handleAuthentication($event)"
    (register)="handleRegister()"
  >
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </myqq-landing-layout-desktop>
</ng-container>
