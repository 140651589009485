import { Component, Inject, OnInit } from "@angular/core";
import { MessageBlockArray } from "../../utilities/types";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "myqq-generic-alert",
  templateUrl: "./generic-alert.component.html",
  styleUrls: ["./generic-alert.component.scss"],
})
export class GenericAlertComponent implements OnInit {
  isMessageBlockArray = false;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      message: string | string[] | MessageBlockArray;
      primaryActionButtonText: string;
    },
    public dialogRef: MatDialogRef<GenericAlertComponent>
  ) {}

  ngOnInit(): void {
    this.dialogRef.addPanelClass("myqq-generic-alert-dialog");
    this.isMessageBlockArray = this.checkMessageBlockArray(this.message);
  }

  checkMessageBlockArray(mba: any): mba is MessageBlockArray {
    if (Array.isArray(mba)) {
      return mba?.every(
        (mb) => mb.hasOwnProperty("heading") && mb.hasOwnProperty("content")
      );
    }
  }

  get title() {
    return (this.data && this.data.title) || "";
  }

  get message() {
    return (this.data && this.data.message) || "";
  }

  get messages() {
    if (!this.message) {
      return [""];
    }

    if (typeof this.message === "string") {
      return [this.message];
    }

    return this.message;
  }

  set messages(messages: string[] | MessageBlockArray) {
    this.data.message = messages;
  }

  close(value: boolean) {
    this.dialogRef.close(value);
  }
}
