import { A11yModule } from "@angular/cdk/a11y";
import { NgModule } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";

import { GenericAlertComponent } from "./generic-alert.component";
import { MatDialogModule } from "@angular/material/dialog";
import { CommonModule } from "@angular/common";

@NgModule({
  imports: [CommonModule, MatIconModule, MatDialogModule, A11yModule],
  exports: [GenericAlertComponent],
  declarations: [GenericAlertComponent],
})
export class GenericAlertModule {}
