<myqq-default-layout class="">
  <div
    class="fld-col fld-dsk-row vh-p100 vw-p100 ai-ctr jc-ctr flb-p100 fls-1-1"
  >
    <div role="alert" class="fs-u4 mwx-p10">
      <h3 class="fs-u5">404</h3>
      <p>
        Sorry friend, we can't find the page you're looking for…let's start
        again:
      </p>
      <button myqq-button color="primary" class="raised" (click)="refresh()">
        Go Home
      </button>
      <p>
        If you've found your way to this page twice --
        <a
          href="mailto:{{ supportEmail }}?subject=I'm having trouble with
          myQQ!"
          >please contact us</a
        >.
      </p>
    </div>
    <img
      src="{{ cdnHost }}/img/404.webp"
      alt="Paper airplane flies past
      Quackals the
      Duck"
    />
  </div>
</myqq-default-layout>
