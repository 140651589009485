import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { Store } from "@ngrx/store";

import { Observable, of, throwError } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { v4 as uuid } from "uuid";

import { fold } from "fp-ts/es6/Either";
import { fromNullable, none } from "fp-ts/es6/Option";
import { DecodeError, Decoder, draw, nullable } from "io-ts/Decoder";

import { log, LogLevels } from "src/app/core/ngrx/logger";
import { PaymentMethod } from "src/app/shared/modules/stripe/stripe-definitions/payment-method";

import { environment } from "src/environments/environment";

import {
  AcceptRetentionOfferResponse,
  Account,
  AccountCheckLast4PlateRequest,
  AccountCheckResponse,
  AccountInfoResponse,
  AccountQuota,
  CheckEligibilityForRetentionOfferResponse,
  CancelMembershipRequest,
  CancelMembershipResponse,
  CancelReason,
  CancelReasonArray,
  CustomerPaymentMethod,
  CustomerPaymentMethodArray,
  GetProfileResponse,
  GetSubscriptionResponse,
  Order,
  PayInvoiceResponse,
  PostCalculateOrderRequest,
  PostCalculateOrderResponse,
  PostPaymentMethodRequest,
  PostPaymentMethodResponse,
  PostSubmitOrderRequest,
  PostSubmitOrderResponse,
  Promo,
  PromoDetails,
  Region,
  RegionArray,
  StoreArray,
  SubscriptionDetail,
  SwapReason,
  SwapReasonArray,
  Vehicle,
  VehicleArray,
  VehicleSwapRequest,
  VehicleSwapResponse,
  RegionMenu,
  AppVersion,
  OrderSearchResult,
  MarketingContentArray,
  B2bBenefit,
  LinkB2CAccountResponse,
  LinkB2CAccountRequest,
} from "./myqq.models";
import {
  catchCodes,
  pager,
  ValidationError,
  version,
} from "src/app/shared/utilities/http";
import { ResultsPage } from "src/app/shared/utilities/types";

/**
 * This service only wraps the HTTP client. It keeps no state.
 */
@Injectable()
export class MyQQService {
  readonly url = environment.URLs.MyQQ;

  constructor(readonly http: HttpClient, readonly store$: Store<any>) {}

  throwAndLogError(error: DecodeError): Observable<any> {
    const err = new ValidationError(draw(error));

    this.store$.dispatch(
      log({
        level: LogLevels.ERROR,
        data: {
          error: err,
        },
        message: "Validation Error",
      })
    );
    return throwError(err);
  }

  // Raw HTTP response data to structured object
  decodeMap = <T>({ decode }: Decoder<unknown, T>) => (
    obs: Observable<unknown>
  ): Observable<T> =>
    obs.pipe(
      map(decode),
      switchMap(
        fold(
          (left) => this.throwAndLogError(left),
          (right) => of(right)
        )
      )
    );

  /**
   * Profile Endpoints
   * Keep in mind that getMyProfile returns both a keycloak profile and
   * potentially a Qsys account.
   */
  getMyProfile(): Observable<GetProfileResponse> {
    return this.http
      .get<GetProfileResponse>(`${this.url}/me/profile`)
      .pipe(this.decodeMap(GetProfileResponse));
  }

  newAccount(account: Account): Observable<Account> {
    return this.http
      .post<Account>(`${this.url}/me`, {
        personId: uuid(),
        ...account,
      })
      .pipe(this.decodeMap(Account));
  }

  updateAccount(account: Account): Observable<Account> {
    return this.http
      .patch<Account>(`${this.url}/me`, account)
      .pipe(this.decodeMap(Account));
  }

  /**
   * Region Endpoints
   */
  getAllRegions(): Observable<Partial<Region>[]> {
    return this.http
      .get<Region[]>(`${this.url}/regions`)
      .pipe(this.decodeMap(RegionArray));
  }

  /**
   * Vehicle Endpoints
   */
  getVehicles(): Observable<Vehicle[]> {
    return this.http
      .get<Vehicle[]>(`${this.url}/me/vehicles`, version(2))
      .pipe(this.decodeMap(VehicleArray));
  }
  patchVehicle(vehicle: Vehicle): Observable<Vehicle> {
    return this.http.patch<Vehicle>(
      `${this.url}/me/vehicles/${vehicle.vehicleId}`,
      vehicle
    );
  }
  // Runs various checks and if passes adds the vehicle
  // To add a vehicle with fewer chacks, use POST /me/vehicles with the same payload
  postVehicle(vehicle: Partial<Vehicle>): Observable<Vehicle> {
    return this.http.post<Vehicle>(
      `${this.url}/me/vehicles/check-add`,
      vehicle
    );
  }

  // TODO: add correct response type
  removeVehicle(req: Vehicle): Observable<unknown> {
    return this.http.post(`${this.url}/me/vehicles/remove`, {
      vehicles: [req.vehicleId],
    });
  }

  /**
   * Stripe Subscription
   */
  getMySubscriptions(): Observable<GetSubscriptionResponse> {
    return this.http
      .get<SubscriptionDetail>(`${this.url}/me/subscription`, version(2))
      .pipe(
        this.decodeMap(GetSubscriptionResponse),
        map((res) => fromNullable(res)),
        catchCodes([404], () => none)
      );
  }

  /**
   * Orders
   */
  getMyOrders(page: ResultsPage): Observable<OrderSearchResult> {
    return this.http
      .get<OrderSearchResult>(
        `${this.url}/me/orders/search`,
        pager(page, version(2))
      )
      .pipe(this.decodeMap(OrderSearchResult));
  }

  getOrderById(orderId: string): Observable<Order> {
    return this.http
      .get<Order>(`${this.url}/me/orders/${orderId}`)
      .pipe(this.decodeMap(Order));
  }

  /**
   * Payment Methods
   */
  getMyPaymentMethods(): Observable<CustomerPaymentMethodArray> {
    return this.http
      .get<CustomerPaymentMethodArray>(
        `${this.url}/me/paymentmethods`,
        version(2)
      )
      .pipe(this.decodeMap(nullable(CustomerPaymentMethodArray)));
  }
  postPaymentMethod(
    method: CustomerPaymentMethod
  ): Observable<CustomerPaymentMethod> {
    return this.http
      .post<CustomerPaymentMethod>(
        `${this.url}/me/paymentmethods/update`,
        method
      )
      .pipe(this.decodeMap(CustomerPaymentMethod));
  }

  newPaymentMethod(
    data: PostPaymentMethodRequest
  ): Observable<PostPaymentMethodResponse> {
    return this.http
      .post(`${this.url}/me/paymentmethods`, data)
      .pipe(this.decodeMap(PostPaymentMethodResponse));
  }

  /**
   * Account Linking Methods
   */
  accountLookup(
    paymentMethod: PaymentMethod
  ): Observable<AccountCheckResponse> {
    return this.http
      .get<AccountCheckResponse>(
        `${this.url}/account/check/${paymentMethod.id}`,
        version(2)
      )
      .pipe(this.decodeMap(AccountCheckResponse));
  }

  accountLookupLast4Plate(
    data: AccountCheckLast4PlateRequest
  ): Observable<AccountCheckResponse> {
    return this.http
      .post<AccountCheckResponse>(`${this.url}/account/check`, data, version(2))
      .pipe(this.decodeMap(AccountCheckResponse));
  }

  // TODO: Add correct response type
  linkAccount(accountId: string, data = {}): Observable<unknown> {
    return this.http.post(`${this.url}/account/link/${accountId}`, data);
  }

  // TODO: Add correct response type
  relinkAccount(accountId: string, data = {}): Observable<unknown> {
    return this.http.post(`${this.url}/me/relink/${accountId}`, data);
  }

  /**
   * Get price table (subscription and flock prices) for a user based on their
   * home store id (pulled from the jwt).
   */
  getPriceTable(): Observable<RegionMenu> {
    return this.http
      .get<RegionMenu>(`${this.url}/me/menu`, version(3))
      .pipe(this.decodeMap(RegionMenu));
  }

  getPriceTableByZip(zip: string): Observable<RegionMenu> {
    if (!zip || zip == "") {
      zip = "default";
    }
    return this.http
      .get<RegionMenu>(`${this.url}/menu/zip/${zip}`, version(4))
      .pipe(this.decodeMap(RegionMenu));
  }

  checkPromoCode(serialNo: string): Observable<Promo> {
    return this.http
      .get<Promo>(`${this.url}/promos/${serialNo}`)
      .pipe(this.decodeMap(Promo));
  }

  /**
   * Order Calculate
   */
  calculateOrder(
    order: PostCalculateOrderRequest
  ): Observable<PostCalculateOrderResponse> {
    return this.http
      .post<PostCalculateOrderResponse>(
        `${this.url}/me/orders/calculate`,
        order
      )
      .pipe(this.decodeMap(PostCalculateOrderResponse));
  }

  /**
   * Order Submit
   */
  submitOrder(
    order: PostSubmitOrderRequest
  ): Observable<PostSubmitOrderResponse> {
    return this.http
      .post<PostSubmitOrderResponse>(
        `${this.url}/me/orders/submit`,
        order,
        version(2)
      )
      .pipe(this.decodeMap(PostCalculateOrderResponse));
  }

  /**
   * Get AccountInfoNew
   */
  accountInfo(): Observable<AccountInfoResponse> {
    return this.http
      .get<AccountInfoResponse>(`${this.url}/me`)
      .pipe(this.decodeMap(AccountInfoResponse));
  }

  /*
   * Pay invoice
   */
  payInvoice(invoiceId: string): Observable<PayInvoiceResponse> {
    return this.http
      .post<PayInvoiceResponse>(`${this.url}/me/invoice/${invoiceId}/pay`, null)
      .pipe(this.decodeMap(PayInvoiceResponse));
  }

  /**
   * Store Endpoints
   */
  getAllStores(): Observable<StoreArray> {
    return this.http
      .get<StoreArray>(`${this.url}/stores`)
      .pipe(this.decodeMap(StoreArray));
  }

  /**
   * Promo Endpoints
   * @param code Promo code
   * @returns PromoDetails
   *
   * Dummy for now (endpoiint not yet implemented)
   * TODO: Make this real
   */
  getPromoDetails(code: string): Observable<PromoDetails> {
    // Immediately return empty if no code is passed in
    // (this makes life easier when there's no promo e.g. in plans page)
    if (!code) {
      return of({} as PromoDetails).pipe(this.decodeMap(PromoDetails));
    }
    return this.http
      .get<PromoDetails>(
        `${this.url}/promos/${code.toUpperCase()}/details`,
        version(2)
      )
      .pipe(this.decodeMap(PromoDetails));
  }

  getCompanyWidePromoDetails(): Observable<PromoDetails> {
    return this.http
      .get<PromoDetails>(`${this.url}/promos/companywide`, version(2))
      .pipe(this.decodeMap(PromoDetails));
  }

  /**
   * Swap Membership endpoints
   */
  getAccountQuota(): Observable<AccountQuota> {
    return this.http
      .get<AccountQuota>(`${this.url}/me/quota`)
      .pipe(this.decodeMap(AccountQuota));
  }

  getSwapReasons(): Observable<SwapReason[]> {
    return this.http
      .get<SwapReason[]>(`${this.url}/swapreasons`)
      .pipe(this.decodeMap(SwapReasonArray));
  }

  swapVehicleOnMembership(
    swapRequest: VehicleSwapRequest
  ): Observable<VehicleSwapResponse> {
    return this.http.post<VehicleSwapResponse>(
      `${this.url}/me/swapmembership`,
      swapRequest
    );
  }

  /**
   * Cancel Membership endpoints
   */
  cancelMembership(
    cancelRequest: CancelMembershipRequest
  ): Observable<CancelMembershipResponse> {
    return this.http.post<CancelMembershipResponse>(
      `${this.url}/me/orders/cancel`,
      cancelRequest
    );
  }

  checkEligibilityForRetentionOffer(
    startTimestamp: number
  ): Observable<CheckEligibilityForRetentionOfferResponse> {
    return this.http.get<CheckEligibilityForRetentionOfferResponse>(
      `${this.url}/retention/${startTimestamp}`
    );
  }

  acceptRetentionOffer(): Observable<AcceptRetentionOfferResponse> {
    return this.http.post<AcceptRetentionOfferResponse>(
      `${this.url}/retention`,
      { accept: true }
    );
  }

  getCancelReasons(): Observable<CancelReason[]> {
    return this.http
      .get<CancelReason[]>(`${this.url}/cancelreasons`)
      .pipe(this.decodeMap(CancelReasonArray));
  }

  getAppMinVersion(): Observable<AppVersion> {
    return this.http
      .get<AppVersion>(`${this.url}/versions`, version(2))
      .pipe(this.decodeMap(AppVersion));
  }

  getMarketingContents(): Observable<MarketingContentArray> {
    return this.http
      .get<MarketingContentArray>(`${this.url}/marketingcontent`)
      .pipe(this.decodeMap(MarketingContentArray));
  }

  /**
   * B2B Endpoints
   */

  getB2bBenefit(): Observable<B2bBenefit> {
    return this.http.get<B2bBenefit>(`${this.url}/me/b2b`, version(2));
  }

  linkB2cWithCode(
    request: LinkB2CAccountRequest
  ): Observable<LinkB2CAccountResponse> {
    return this.http.post<LinkB2CAccountResponse>(
      `${this.url}/me/b2b`,
      request
    );
  }
}
