<header [ngClass]="!!title ? '' : 'pwb-5'">
  <button mat-dialog-close id="close-button">
    <mat-icon>close</mat-icon>
  </button>
  <div class="mwx-6 mwy-5">{{ title }}</div>
</header>
<mat-dialog-content id="main" [class.multiline]="messages.length > 1">
  <ng-container *ngIf="isMessageBlockArray; else strings">
    <div *ngFor="let message of messages">
      <h4 *ngIf="message.heading">{{ message.heading }}</h4>
      <p>{{ message.content }}</p>
    </div>
  </ng-container>
  <ng-template #strings>
    <p *ngFor="let message of messages">
      {{ message }}
    </p>
  </ng-template>
</mat-dialog-content>
<mat-dialog-actions class="close-footer" mat-dialog-close>
  Close
</mat-dialog-actions>
