import { MessageBlockArray } from "src/app/shared/utilities/types";

export const quotaDisclaimer: MessageBlockArray = [
  {
    heading: "",
    content:
      "Every account is allotted two changes for License Plate/VIN Edits or Vehicle Swaps within a rolling 365-day window. For example if an update is used on Jan 1, 2022, the update will be renewed on Jan 2, 2023.",
  },
  {
    heading: "",
    content:
      "Updates are consumed each time one of the following actions occur:  ",
  },
  {
    heading: "",
    content: "• A vehicle is swapped from one vehicle to another.",
  },
  {
    heading: "",
    content: "• A license plate or VIN is edited on a membership vehicle.",
  },
  {
    heading: "",
    content:
      "We do not count Plate/VIN Edits for non-membership vehicles against the alloted change.",
  },
] as MessageBlockArray;

export const verifyWithQRCodeText: MessageBlockArray = [
  {
    heading: "Why do I need to verify my vehicle?",
    content:
      "We take privacy and safety surrounding your personal data seriously. Car wash details are excluded from the order history until the vehicle is verified. Verifying each of the cars with you directly the first time you bring them in to get washed after signing up provides you the best security possible.",
  },
  {
    heading: "How do I verify my vehicle?",
    content:
      "Next time you're onsite at one of our locations with your vehicle, inform the cashier you would like your vehicle verified and show your QR code. The QR code can be found in Vehicles -> Menu -> Vehicle Verification.",
  },
] as MessageBlockArray;

export const allowLocationPermissionText =
  "It looks like you haven't shared your location with myQQ. Please share your location in settings to enable myQQ to show your position on the map.";
