import { DefaultUrlSerializer, UrlSerializer, UrlTree } from "@angular/router";
import { routes } from "src/app/app-routing.module";

export class ModalUrlSerializer implements UrlSerializer {
  private modalRoutes: string[];
  private defaultUrlSerializer: DefaultUrlSerializer;

  constructor() {
    this.modalRoutes = routes
      .filter((r) => r.outlet === "modal")
      .map((r) => r.path);

    this.defaultUrlSerializer = new DefaultUrlSerializer();
  }

  parse(url: string) {
    const outlet = url.match(/\/([a-zA-Z0-9\-]+?$)/);
    if (outlet?.length === 2 && this.modalRoutes.includes(outlet[1])) {
      url = url.replace(outlet[0], `(modal:${outlet[1]})`);
    }

    return this.defaultUrlSerializer.parse(url);
  }

  serialize(tree: UrlTree) {
    let url = this.defaultUrlSerializer.serialize(tree);
    let outlet =
      url.match(/\(modal\:(.+?)\)/) || url.match(/\%28modal\:(.+?)\%29/);
    if (outlet?.length === 2 && this.modalRoutes.includes(outlet[1])) {
      // Replace url segment, then clean up extra slash (in case initial path was '/')
      url = url.replace(outlet[0], `/${outlet[1]}`).replace("//", "/");
    }
    return url;
  }
}
