import { Component, Input } from "@angular/core";
import { SubscriptionPlan } from "src/app/core/services/myqq";
import { getPlanFeaturesDifference } from "src/lib/util";

@Component({
  selector: "myqq-plans-difference",
  templateUrl: "./plans-difference.component.html",
  styleUrls: ["./plans-difference.component.scss"],
})
export class CartPlansDifferenceComponent {
  @Input() targetSubscriptionPlan: SubscriptionPlan;
  @Input() currentSubscriptionPlan: SubscriptionPlan;

  readonly isUpgrade = (): boolean =>
    this.targetSubscriptionPlan.washHierarchy >
    this.currentSubscriptionPlan.washHierarchy;

  readonly buildList = (): string[] => {
    if (
      !this.targetSubscriptionPlan.features ||
      !this.currentSubscriptionPlan.features
    ) {
      return [];
    }
    return getPlanFeaturesDifference(
      this.currentSubscriptionPlan,
      this.targetSubscriptionPlan,
      this.isUpgrade()
    );
  };
}
