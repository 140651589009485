import { Component, Input } from "@angular/core";
import { EvoxImageService, Vehicle } from "./evox.service";

@Component({
  selector: "evox-vehicle-image",
  templateUrl: "./evox.component.html",
  styleUrls: ["./evox.component.scss"],
})
export class EvoxVehicleImageComponent {
  @Input() vehicle: Vehicle = {};
  @Input() innerClass = "";

  constructor(readonly imgSvc: EvoxImageService) {}
}
