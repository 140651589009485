import {
  HttpErrorResponse,
  HttpHeaders,
  HttpRequest,
  HttpResponse,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { log } from "src/app/core/ngrx/logger";

@Injectable({
  providedIn: "root",
})
export class LogInterceptorService {
  constructor(private store$: Store) {}

  logSuccess(req: HttpRequest<any>, res: HttpResponse<any>) {
    const request = serializeHttpRequest(req);
    const response = serializeHttpResponse(res);
    this.store$.dispatch(
      log({
        message: `${req.method} ${req.url}`,
        data: { request, response },
      })
    );
  }

  logFailure(req: HttpRequest<any>, err: HttpErrorResponse) {
    const request = serializeHttpRequest(req);
    const response = serializeHttpErrorResponse(err);
    this.store$.dispatch(
      log({
        message: `${req.method} ${req.url}`,
        data: { request, response },
      })
    );
  }
}

const IGNORED_HEADER_KEYS = ["authorization"];

const filterHeaderKeys = (key) =>
  IGNORED_HEADER_KEYS.some(
    (ignore) => ignore.toLowerCase() !== key.toLowerCase()
  );

const serializeHttpHeaders = (headers: HttpHeaders): Record<string, string> =>
  headers
    .keys()
    .filter(filterHeaderKeys)
    .reduce((h, key) => {
      h[key] = headers.get(key);
      return h;
    }, {});

const serializeHttpRequest = (req: HttpRequest<any>) => ({
  method: req.method,
  url: req.url,
  headers: serializeHttpHeaders(req.headers),
});

const serializeHttpResponse = (res: HttpResponse<any>) => ({
  url: res.url,
  headers: serializeHttpHeaders(res.headers),
});

const serializeHttpErrorResponse = (err: HttpErrorResponse) => ({
  url: err.url,
  headers: serializeHttpHeaders(err.headers),
  error: err.error,
  message: err.message,
});
