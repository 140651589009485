<div class="fld-col fls-1-1 vh-p100 ova-au">
  <header>
    <mat-toolbar class="fld-row flg-5 ct-primary-rev mwt-6">
      <a tabindex="0" routerLink="">
        <img
          src="{{ cdnHost }}/icon/mainlogo.svg"
          alt="Quick Quack Car Wash Main Logo"
          class="main-logo"
        />
      </a>
      <span class="fls-1-1"> </span>
      <button
        mat-button
        aria-label="Log In"
        class="ff-roboto fw-u3"
        tabindex="0"
        (click)="handleAuthentication(authenticated.value)"
        data-cy="login-btn"
      >
        <ng-container *ngIf="(authenticated | async) === false">
          Log In</ng-container
        >
        <ng-container *ngIf="authenticated | async"> Logout</ng-container>
      </button>

      <button
        *ngIf="(authenticated | async) === false"
        mat-raised-button
        aria-label="Create Account"
        class="ff-roboto fw-u3 create-acct-btn"
        color="primary"
        tabindex="0"
        (click)="handleRegister()"
        data-cy="register-btn"
      >
        Create Account
      </button>
    </mat-toolbar>
  </header>
  <section id="content-section" class="mwa-5">
    <ng-content></ng-content>
  </section>
  <section class="fls-1-1"></section>
  <footer class="ct-primary jc-dsk-str mwa-0">
    <myqq-footer class="landing-page-footer"></myqq-footer>
  </footer>
</div>
