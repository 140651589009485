<myqq-routed-modal
  [useDatumEither]="postVehicleSwap$ | async"
  class="vh-p100"
  [hideFooter]="true"
>
  <span modal-header> Vehicle Swap </span>
  <ng-container *onInitial>
    <myqq-error
      *ngIf="!currentVehicle"
      title="Vehicle swap error"
      description="We've lost your vehicle selection. Please close and retry."
    >
    </myqq-error>
    <ng-container
      *ngIf="!!currentVehicle"
      [useDatumEither]="contextData$ | async"
    >
      <myqq-loading *onInitial class="fls-1-1">
        <h3>Initializing...</h3>
      </myqq-loading>
      <myqq-loading *onPending class="fls-1-1">
        <h3>Getting data...</h3>
      </myqq-loading>
      <myqq-error
        *onFailure="
        let errorResponse;
        let refreshingContextData = refreshing
      "
        title="Vehicle swap setup error"
        description="There was a problem getting your membership information"
        [error]="errorResponse"
      >
        <footer>
          <button
            myqq-button
            aria-label="refresh"
            id="refresh-button"
            class="save-btn raised"
            color="primary"
            [disabled]="refreshingContextData"
            (click)="handleGetData()"
            (keyup.enter)="handleGetData()"
            (keydown.enter)="$event.preventDefault()"
          >
            {{ refreshingContextData ? "Refreshing..." : "Refresh" }}
          </button>
        </footer>
      </myqq-error>
      <ng-container
        *onSuccess="let data; let refreshingContextData = refreshing"
        [ngSwitch]="currentStep$ | async"
      >
        <ng-container>
          <myqq-vehicle-swap-initial
            class="vh-p100"
            *ngSwitchCase="steps.initial"
            [currentVehicle]="currentVehicle"
            [membershipBadgeColor]="currentVehicle | map: getMembershipBadgeColor:data.menu"
            [nonMembershipVehicles]="data.nonMembershipVehicles"
            [accountQuota]="data.accountQuota"
            [refreshing]="refreshingContextData"
            (newVehicle)="handleSelectNewVehicle($event)"
            (addVehicle)="handleAddVehicle()"
            (nextStep)="handleNext(currentVehicle.isTemporaryIdentifier ?  steps.confirm : steps.choose_reason)"
            (showDisclaimer)="handleShowDisclaimer()"
          >
          </myqq-vehicle-swap-initial>
          <myqq-vehicle-swap-reason
            class="vh-p100"
            *ngSwitchCase="steps.choose_reason"
            [swapReasons]="data.swapReasons"
            (selectedReason)="handleSelectReason($event)"
            (back)="handleNext(steps.initial)"
          ></myqq-vehicle-swap-reason>
          <myqq-vehicle-swap-confirm
            class="vh-p100"
            *ngSwitchCase="steps.confirm"
            [currentVehicle]="currentVehicle"
            [newVehicle]="selectedNewVehicle"
            [membershipBadgeColor]="currentVehicle | map: getMembershipBadgeColor:data.menu"
            [accountQuota]="data.accountQuota"
            (submit)="handleSubmit()"
            (back)="handleNext(currentVehicle.isTemporaryIdentifier? steps.intial : steps.choose_reason)"
            (showDisclaimer)="handleShowDisclaimer()"
          >
          </myqq-vehicle-swap-confirm>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
  <myqq-loading *onPending class="fls-1-1">
    <h3>Swapping vehicle...</h3>
  </myqq-loading>
  <myqq-error
    *onFailure="
  let errorResponse;
  let refresh
"
    title="Vehicle swap error"
    description="We encountered an error while swapping your vehicles."
    [error]="errorResponse"
  >
    <footer>
      <button
        myqq-button
        aria-label="refresh"
        id="refresh-button"
        class="save-btn raised"
        color="primary"
        [disabled]="refresh"
        (click)="handleGetData()"
        (keyup.enter)="handleGetData()"
        (keydown.enter)="$event.preventDefault()"
      >
        {{ refresh ? "Refreshing..." : "Refresh" }}
      </button>
    </footer>
  </myqq-error>
</myqq-routed-modal>
