import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { SubscriptionDetail } from "src/app/core/services/myqq";
import { environment } from "src/environments/environment";

@Component({
  selector: "myqq-past-due",
  templateUrl: "./past-due.component.html",
})
export class PastDueComponent implements OnInit {
  @Input()
  subscription: SubscriptionDetail;

  @Output()
  editPaymentMethod = new EventEmitter();

  constructor() {}

  cdnHost = environment.cdnHost;

  ngOnInit(): void {}

  handleEditPaymentMethod() {
    this.editPaymentMethod.emit();
  }
}
