import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Vehicle } from "src/app/core/services/myqq";
import { AccountQuotaWithRemaining } from "src/app/shared/utilities/types";

@Component({
  selector: "myqq-vehicle-swap-confirm",
  templateUrl: "./vehicle-swap-confirm.component.html",
  styleUrls: ["./vehicle-swap-confirm.component.scss"],
})
export class VehicleSwapConfirmComponent {
  @Input() currentVehicle: Vehicle;
  @Input() newVehicle: Vehicle;
  @Input() accountQuota: AccountQuotaWithRemaining;
  @Input() membershipBadgeColor;
  @Output() submit = new EventEmitter();
  @Output() back = new EventEmitter();
  @Output() showDisclaimer = new EventEmitter();
}
