<ng-container *ngFor="let account of accounts | keyvalue">
  <a
    *ngIf="!!account.value && !!uri[account.key]"
    class="pwx-4"
    [style.color]="!!color ? color : ''"
    [href]="uri[account.key] + account.value"
    [target]="_blank"
    attr.rel="noopener noreferrer"
    attr.title="Quick Quack on {{ account.key | titlecase }}"
    ><mat-icon svgIcon="{{ account.key }}">{{ account.key }}</mat-icon></a
  >
</ng-container>
