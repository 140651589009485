import { NgModule } from "@angular/core";
import { StoreModule } from "@ngrx/store";
import { StripeModule } from "src/app/shared/modules/stripe";

import { MyqqStripeService } from "./myqq-stripe.service";

@NgModule({
  imports: [StripeModule, StoreModule],
  exports: [],
  declarations: [],
  providers: [MyqqStripeService],
})
export class MyqqStripeModule {}
