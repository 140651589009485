<myqq-routed-modal [useDatumEither]="submitVehicle$ | async">
  <span modal-header>
    Vehicle Information
  </span>
  <myqq-loading *onPending>
    <h3>Adding Vehicle...</h3>
  </myqq-loading>
  <ng-container
    *onFailure="let error; let refreshing= refreshing"
    [ngSwitch]="error?.error?.message"
  >
    <myqq-banner
      *ngSwitchCase="errVehicleAlreadyOnAccount"
      (dismiss)="handleDismissErrorBanner()"
      message="You've already added that vehicle!"
    >
    </myqq-banner>

    <ng-container *ngSwitchCase="errVehicleOwnership">
      <myqq-banner
        *ngIf="!(hasMembership$ | async)"
        (dismiss)="handleDismissErrorBanner()"
        message="A vehicle with this license plate or VIN already exists. Do you want to link your account to an existing membership?"
      >
        <button
          myqq-button
          id="find-membership"
          class="find-membership raised"
          color="tertiary"
          (click)="handleRelink()"
          (keydown.enter)="$event.preventDefault()"
          (keyup.enter)="handleRelink()"
          fill="clear"
          type="button"
          aria-label="Find membership"
        >
          Find Membership
        </button>
      </myqq-banner>
      <myqq-banner
        *ngIf="hasMembership$ | async"
        (dismiss)="handleDismissErrorBanner()"
        message="Unfortunately, we weren't able to add your vehicle."
      >
      </myqq-banner>
    </ng-container>

    <myqq-banner
      *ngSwitchDefault
      (dismiss)="handleDismissErrorBanner()"
      message="Unfortunately, we weren't able to add your vehicle."
    >
    </myqq-banner>
  </ng-container>
  <p class="pwx-5">
    Please provide your vehicle information for new membership. Save more by
    adding up to 5 vehicles on a membership plan.
  </p>
  <myqq-vehicle-form #addVehicleForm> </myqq-vehicle-form>
  <ng-template #footer>
    <button myqq-button id="cancel" class="cancel-btn" (click)="handleCancel()">
      Cancel
    </button>

    <button
      myqq-button
      id="save"
      class="save-btn"
      color="primary"
      [disabled]="!addVehicleForm?.valid ||
      refreshing"
      (click)="handleSubmit(addVehicleForm?.value)"
    >
      {{ refreshing ? "Saving…" : "Save" }}
    </button>
  </ng-template>

  <div modal-footer class="js-end qq-buttons">
    <ng-container *ngTemplateOutlet="footerTemplate | async"> </ng-container>
  </div>
</myqq-routed-modal>
