<mat-sidenav-container class="vh-p100 vw-p100">
  <mat-sidenav
    mode="side"
    opened
    *ngIf="authenticated | async"
    class="ct-primary"
  >
    <mat-nav-list class="vh-p100 nav-list fld-col">
      <img
        src="{{ cdnHost }}/icon/mainlogo.svg"
        alt="Quick Quack Car Wash"
        class="main-logo"
      />
      <span class="fls-1-1"> </span>
      <div>
        <a
          mat-list-item
          id="{{ homeLink.title | lowercase }}-link"
          [aria-label]="homeLink.title"
          [routerLink]="homeLink.url"
          attr.title="Go to {{ homeLink.title }}"
          [attr.data-cy]="homeLink.title"
          class="ff-roboto mwb-7"
        >
          <div class="txt-white">
            <mat-icon class="mwr-5 icon-display">{{
              homeLink.icon.name
            }}</mat-icon>
            <span class="fw-u3 fs-u4 mwl-2">{{ homeLink.title }}</span>
          </div>
        </a>

        <a
          mat-list-item
          id="{{ link.title | lowercase }}-link"
          *ngFor="let link of accountLinks"
          [routerLink]="
            !link.allowWithoutAccount && !accountLinked ? '' : link.url
          "
          attr.title="Go to {{ link.title }}"
          tabindex="0"
          [disabled]="!link.allowWithoutAccount && !accountLinked"
          class="ff-roboto mwy-7"
        >
          <div class="txt-white">
            <mat-icon class="mwr-5 icon-display">{{ link.icon.name }}</mat-icon>
            <span class="fw-u3 fs-u4 mwl-2">{{ link.title }}</span>
          </div>
        </a>
      </div>
      <span class="fls-1-1"> </span>
      <div>
        <a
          mat-list-item
          aria-label="Logout"
          class="ff-roboto"
          title="Logout"
          tabindex="0"
          [disabled]="!online"
          [class.offline-no-login]="!online"
          (click)="handleAuthentication(authenticated.value)"
          data-cy="logout-btn"
        >
          <div class="txt-white">
            <mat-icon class="mwr-5">logout</mat-icon
            ><span class="fw-u1 fs-u2 logout-label">Logout</span>
          </div>
        </a>
      </div>
      <span class="fls-1-1"> </span>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="fld-col fls-1-1 vh-p100 ova-au">
      <ng-container>
        <header>
          <mat-toolbar
            *ngIf="(authenticated | async) === false"
            class="fld-row flg-5 vh-4 ct-dsk"
          >
            <a tabindex="0" routerLink="">
              <img
                src="{{ cdnHost }}/icon/mainlogo.svg"
                alt="Quick Quack Car Wash Main Logob"
                class="main-logo"
              />
            </a>

            <span class="fls-1-1"> </span>
            <button
              mat-button
              aria-label="Log In"
              class="ff-roboto fw-u3"
              tabindex="0"
              (click)="handleAuthentication(authenticated.value)"
              data-cy="login-btn"
            >
              Log In
            </button>

            <button
              mat-raised-button
              aria-label="Create Account"
              class="ff-roboto fw-u3 create-acct-btn"
              color="primary"
              tabindex="0"
              (click)="handleRegister()"
              data-cy="register-btn"
            >
              Create Account
            </button>
          </mat-toolbar>
          <mat-toolbar
            *ngIf="authenticated | async"
            class="ct-dsk vh-4 jc-end mwr-7"
          >
            <a
              mat-raised-button
              role="button"
              aria-label="Create Account"
              class="ff-roboto fw-u3 profile-btn"
              color="primary"
              tabindex="0"
              routerLink="/account"
              [disabled]="!accountLinked"
              *ngIf="!isShowingAccountPage"
            >
              Account
            </a>
          </mat-toolbar>
        </header>
      </ng-container>
      <div *ngIf="!online" id="banner-container">
        <div id="offline-banner">
          <img
            id="banner-image"
            src="/assets/icon/offline_quackals_960x600.gif"
            alt="Quackals"
          />
          <div id="banner-message">
            <p>
              You are currently offline. You can still use myQQ, but your
              information may be out of date and some features will be
              unavailable.
            </p>
          </div>
        </div>
      </div>
      <section
        id="content-section"
        class="fls-2-1 fld-col flb-content ai-ctr vwc-p100 mwl-5 vh-p100"
      >
        <ng-content></ng-content>
      </section>
      <footer
        *ngIf="(authenticated | async) === false"
        class="mwa-0 vwc-p100 ct-primary fld-col ai-str"
      >
        <myqq-footer class="landing-page-footer"></myqq-footer>
      </footer>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
