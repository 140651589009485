<div class="fld-col flg-5 vh-p100">
  <myqq-vehicle-form
    #form
    [vehicle]="vehicle"
    [disableLicenseVin]="false"
    [hideMakeModelYear]="true"
  ></myqq-vehicle-form>
  <myqq-account-message-quota
    *ngIf="
      vehicle.hasMembership && !vehicle.isTemporaryIdentifier;
      else noQuota
    "
    [accountQuota]="accountQuota"
    [action]="'edit'"
    (showDisclaimer)="showDisclaimer.emit()"
  >
  </myqq-account-message-quota>
  <footer>
    <button
      myqq-button
      color="primary"
      class="raised"
      aria-label="Next"
      [disabled]="!form?.valid"
      (click)="handleNext(form?.value)"
      (keydown.enter)="$event.preventDefault()"
      (keyup.enter)="handleNext(form?.value)"
    >
      Next
    </button>
  </footer>
  <ng-template #noQuota>
    <span class="fls-1-1"></span>
  </ng-template>
</div>
