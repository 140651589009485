<li
  *ngIf="!!markDowns?.length"
  class="fls-0-0 cf-primary-rev fld-row flg-4 ai-ctr pwt-5 bwt-1 vwmx-p100"
>
  <span>Promo</span>
  <span class="fls-1-1">{{ markdownName }}</span>
  <a
    href="javascript:void(0)"
    (click)="clearPromoCode.emit()"
    class="cf-base pwr-5"
  >
    <mat-icon>delete</mat-icon>
  </a>
  <myqq-price [price]="totalMarkDowns || ''"></myqq-price>
</li>
