<form [formGroup]="form" class="form-grid">
  <mat-form-field id="first">
    <mat-label>First Name</mat-label>
    <input matInput placeholder="Quackals" formControlName="firstName" />
  </mat-form-field>

  <mat-form-field id="second">
    <mat-label>Last Name</mat-label>
    <input matInput placeholder="McQuack" formControlName="lastName" />
  </mat-form-field>

  <mat-form-field id="email">
    <mat-label>Email</mat-label>
    <input
      matInput
      placeholder="quackals@dontdrivedirty.com"
      formControlName="email"
    />
  </mat-form-field>

  <mat-form-field id="phone">
    <mat-label>Phone</mat-label>
    <input
      matInput
      placeholder="123-456-7890"
      formControlName="primaryPh"
      [patterns]="phoneMask.customPattern"
      [mask]="phoneMask.mask"
    />
  </mat-form-field>

  <mat-form-field id="birthdate">
    <mat-label>Birthdate</mat-label>
    <myqq-datepicker
      #birthdate
      formControlName="birthDate"
      placeholder="MM/DD/YYYY"
    >
      <ng-template let-date>
        <p [hidden]="_validDate(date) && _before(date, _18YearsAgo)">
          Must be at least 18.
        </p>
      </ng-template>
    </myqq-datepicker>
  </mat-form-field>

  <mat-form-field id="street">
    <mat-label>Street Line 1</mat-label>
    <input
      matInput
      placeholder="123 Main St"
      formControlName="street1"
      maxlength="50"
    />
    <mat-error *ngIf="form.controls.street1.errors?.notNullableIfNotNull"
      >Too short</mat-error
    >
  </mat-form-field>

  <mat-form-field id="street2">
    <mat-label>Street Line 2</mat-label>
    <input matInput placeholder="" formControlName="street2" maxlength="50" />
    <mat-error>{{
      form.controls.street2.errors?.disallowWithOut
        ? "Street Line 1 cannot be blank"
        : form.controls.street2.errors?.notNullableIfNotNull
        ? "Too short"
        : "Invalid"
    }}</mat-error>
  </mat-form-field>

  <mat-form-field id="city">
    <mat-label>City</mat-label>
    <input matInput placeholder="Anytown" formControlName="city" />
    <mat-error>{{
      form.controls.city.errors?.requireWith
        ? "Required"
        : form.controls.city.errors?.notNullableIfNotNull
        ? "Too short"
        : "Invalid"
    }}</mat-error>
  </mat-form-field>

  <mat-form-field id="state">
    <mat-label>State</mat-label>
    <select matNativeControl formControlName="state">
      <option value=""></option>
      <option *ngFor="let state of states" [value]="state.abbreviation">
        {{ state.abbreviation }}
      </option>
    </select>
    <mat-error *ngIf="form.controls.state.errors?.requireWith"
      >Required</mat-error
    >
  </mat-form-field>

  <mat-form-field id="zip">
    <mat-label>Zipcode</mat-label>
    <input
      matInput
      placeholder="12345"
      formControlName="zipCode"
      [mask]="zipMask"
    />
  </mat-form-field>
</form>
