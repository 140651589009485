import { Component, Output, EventEmitter, Input } from "@angular/core";

import { environment } from "src/environments/environment";

import {
  CustomerPaymentMethod,
  OrderTypesEnum,
} from "src/app/core/services/myqq";

@Component({
  selector: "myqq-cart-payment",
  templateUrl: "./cart-payment.component.html",
  styleUrls: ["./cart-payment.component.scss"],
})
export class CartPaymentComponent {
  @Input()
  readonly paymentMethods: CustomerPaymentMethod[] = [];
  @Input()
  readonly orderType?: OrderTypesEnum;
  @Input()
  readonly isMobile: boolean;

  @Output()
  readonly createPaymentMethod = new EventEmitter();

  readonly OrderTypes = OrderTypesEnum;
  supportNumber = environment.supportNumber;

  readonly addPaymentMethod = "add";

  readonly formatExpiry = (month: number, year: number) => {
    if (!month || !year) {
      return "";
    }
    return `${+month < 10 ? "0" : ""}${month}/${
      year > 2000 ? year - 2000 : year
    }`;
  };

  logoPath(paymentMethod: CustomerPaymentMethod) {
    const imagePath = `${environment.cdnHost}/img/`;

    switch (paymentMethod.brand) {
      case "visa":
        return imagePath + "visalogo.svg";
      case "mastercard":
        return imagePath + "mastercardlogo.svg";
      case "amex":
        return imagePath + "amexlogo.svg";
      case "discover":
        return imagePath + "discoverlogo.svg";
      default:
        return imagePath + "genericcardlogo.svg";
    }
  }
}
