import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  OnDestroy,
} from "@angular/core";

import { Vehicle } from "src/app/core/services/myqq";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { VehicleMakes, States, notNil } from "@qqcw/qqsystem-util";
import { licenseMask, vinMask } from "src/app/shared/utilities/ngx-mask-custom";
import { getModelYears } from "src/lib/util";

import {
  getMakeFromMakeId,
  rationalizeLicense,
  rationalizeVIN,
} from "src/lib/util";
import { vehicleIsValid } from "src/lib/validators";

import { defaults } from "lodash/fp";

import { Subscription } from "rxjs";

@Component({
  selector: "myqq-vehicle-form",
  templateUrl: "./vehicle-form.component.html",
  styleUrls: ["./vehicle-form.component.scss"],
})
export class VehicleFormComponent implements OnInit, OnDestroy {
  @Input()
  vehicle: Vehicle | null;
  @Input()
  disableLicenseVin: boolean = false;
  @Input()
  hideMakeModelYear: boolean = false;

  readonly makes = VehicleMakes.sort((a, b) => a.name.localeCompare(b.name));
  years: string[];
  readonly states = States;
  readonly licenseMask = licenseMask;
  readonly vinMask = vinMask;
  form: FormGroup<{
    state: FormControl<string>;
    license: FormControl<string>;
    vin: FormControl<string>;
    year: FormControl<string>;
    make: FormControl<string>;
    model: FormControl<string>;
  }>;

  models?: { nhtsaId: string; name: string }[];
  modelUpdateSub: Subscription;

  createForm() {
    this.form = this.fb.group({
      state: [],
      license: [],
      vin: [],
      year: [],
      make: [],
      model: [],
    });

    this.form.setValidators(vehicleIsValid);
  }

  constructor(
    private readonly fb: FormBuilder,
    readonly cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.createForm();
    this.years = getModelYears();

    if (notNil(this.vehicle)) {
      this.form.patchValue(this.vehicle);

      if (this.disableLicenseVin && this.vehicle.license?.length > 0) {
        this.form.get("state").disable();
        this.form.get("license").disable();
      }
      if (this.disableLicenseVin && this.vehicle.vin?.length > 0) {
        this.form.get("vin").disable();
      }
      if (this.vehicle.make) {
        this.models = this.getModelsFromMake(this.vehicle.make);
      }
    }
    this.modelUpdateSub = this.form
      .get("make")
      .valueChanges.subscribe((value) => {
        if (notNil(value) && value.length > 0) {
          this.models = this.getModelsFromMake(value);
        } else {
          this.models = undefined;
        }

        if (notNil(this.models)) {
          this.form.get("model").enable({ emitEvent: false });
        } else {
          this.form.get("model").disable({ emitEvent: false });
        }

        this.cdr.detectChanges();
      });
  }

  ngOnDestroy() {
    if (typeof this.modelUpdateSub?.unsubscribe === "function") {
      this.modelUpdateSub.unsubscribe();
    }
  }
  public get valid() {
    return this.form.valid && !this.form.pristine;
  }

  public get value() {
    const { state, year, make, model } = this.form.getRawValue();
    const license = rationalizeLicense(this.form.get("license").value || "");
    const vin = rationalizeVIN(this.form.get("vin").value || "");

    const updatedVehicle = defaults(this.vehicle, {
      state,
      license,
      vin,
    });

    if (this.hideMakeModelYear) {
      return updatedVehicle;
    } else {
      const updatedVehicle2 = defaults(updatedVehicle, { year, make, model });
      if (
        make !== this.vehicle?.make &&
        (!model || model === this.vehicle?.model)
      ) {
        // the make has been changed and the model left unchanged, so overwrite
        updatedVehicle.model = "";
      }
      return updatedVehicle2;
    }
  }

  getModelsFromMake(make: string): { nhtsaId: string; name: string }[] {
    const selectedMake = getMakeFromMakeId(make);
    return selectedMake?.models.sort((a, b) => a.name.localeCompare(b.name));
  }
}
