import { Component, EventEmitter, Input, Output } from "@angular/core";
import { PostCalculateOrderResponse } from "src/app/core/services/myqq";
import { toArray } from "@qqcw/qqsystem-util";

@Component({
  selector: "myqq-cart-applied-promo-code",
  templateUrl: "./promo-code-applied.component.html",
  styleUrls: ["./promo-code-applied.component.scss"],
})
export class CartPromoCodeAppliedComponent {
  @Input() markDowns?: PostCalculateOrderResponse["markDowns"];
  @Input() totalMarkDowns?: PostCalculateOrderResponse["totalMarkDowns"];
  @Output() clearPromoCode = new EventEmitter();

  get markdownName() {
    if (!this.markDowns) {
      return "";
    }

    return toArray(this.markDowns)[0]?.serialNo ?? "";
  }
}
