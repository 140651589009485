<span class="fld-col flg-5 pwt-6 fls-1-1">
  <p class="ta-c fs-u0" [ngSwitch]="action">
    <ng-container *ngSwitchCase="'edit'">
      Did you get a new vehicle or license plate? You can edit your license
      plate or VIN.
    </ng-container>
    <ng-container *ngSwitchCase="'swap'">
      Did you get a new vehicle? You can swap your membership to a different
      vehicle.
    </ng-container>
    <ng-container *ngSwitchDefault>
      By tapping confirm, you agree that a change will be used.
    </ng-container>
    Each account is allotted
    {{ accountQuota.quotaAllowed }} changes within a rolling 365-day window.
    <mat-icon
      tabindex="0"
      class="icon-display"
      aria-label="more info"
      (click)="showDisclaimer.emit()"
      (keydown.enter)="$event.preventDefault()"
      (keyup.enter)="showDisclaimer.emit()"
      >info_outline</mat-icon
    >
  </p>

  <p class="ta-c fw-u3 fs-u0">
    You have
    <span class="blue-txt">
      {{ accountQuota.quotaRemaining > 0 ? accountQuota.quotaRemaining : 0 }}
    </span>
    {{ accountQuota.quotaRemaining > 1 ? "changes" : "change" }} remaining on
    your account.
  </p>
</span>
