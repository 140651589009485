// THIS FILE IS AUTOGENERATED

export interface Version {
  commit: string;
  version: string;
  app: string;
}

export const version = {
  app: "myqq",
  commit: "5f43fe650e4c929bece7fc0ed54f1ebdefe8ad6f",
  version: "1.104.1",
};
