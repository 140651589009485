import {
  HttpResponse,
  HttpErrorResponse,
  HttpInterceptorFn,
} from "@angular/common/http";

import { tap } from "rxjs/operators";
import { v4 as uuid } from "uuid";

import { environment } from "src/environments/environment";
import { LogInterceptorService } from "./http-logger-interceptor.service";
import { inject } from "@angular/core";
import { version } from "src/environments/version";

export const loggerInterceptor: HttpInterceptorFn = (req, next) => {
  const logInterceptorService = inject(LogInterceptorService);
  const url_pattern = new RegExp(environment.URLs.MyQQ);
  if (req.url.match(url_pattern)) {
    // Add trace header to myqq-api requests only
    req = req.clone({
      headers: req.headers
        .set("x-request-trace-id", uuid())
        .set("X-Myqq-Web-Version", version.version),
    });
  }

  return next(req).pipe(
    tap(
      (res) => {
        if (
          req.url !== `${environment.URLs.MyQQ}/log` &&
          res instanceof HttpResponse
        ) {
          logInterceptorService.logSuccess(req, res);
        }
      },
      (err) => {
        if (
          req.url !== `${environment.URLs.MyQQ}/log` &&
          err instanceof HttpErrorResponse
        ) {
          logInterceptorService.logFailure(req, err);
        }
      }
    )
  );
};
