<section class="fld-col ai-stc">
  <button
    myqq-button
    class="outlined"
    matIcon="add_circle_outline"
    iconColor="primary"
    cdkFocusInitial
    *ngIf="paymentMethods?.length === 0"
    aria-label="Add Payment Method"
    (click)="createPaymentMethod.emit()"
    (keyup.enter)="createPaymentMethod.emit()"
    (keydown.enter)="$event.preventDefault()"
  >
    Add Payment Method
  </button>

  <ng-container *ngIf="paymentMethods?.length > 0">
    <h5>Payment Method</h5>

    <span
      *ngFor="let paymentMethod of paymentMethods"
      class="fld-row flg-5 ai-ctr pwy-5 pwr-5"
      [ngClass]="
        !isMobile &&
        (orderType === OrderTypes.REMOVESUBVEHICLE ||
          orderType === OrderTypes.ADDMEMBERSHIP)
          ? ''
          : 'bra-1 bwa-1 pwl-5'
      "
    >
      <img
        class="cc-logo"
        src="{{ logoPath(paymentMethod) }}"
        alt="credit card Logo"
      />
      <span class="fls-1-1"> **** {{ paymentMethod.last4 }} </span>
      <span
        >Exp
        {{ formatExpiry(paymentMethod.expMonth, paymentMethod.expYear) }}</span
      >
      <a
        *ngIf="
          !isMobile &&
            (orderType === OrderTypes.REMOVESUBVEHICLE ||
              orderType === OrderTypes.ADDMEMBERSHIP);
          else defaultChange
        "
        tabindex="0"
        class="ai-ctr fld-row"
        aria-label="Add payment method"
        (click)="createPaymentMethod.emit()"
        (keyup.enter)="createPaymentMethod.emit()"
        (keydown.enter)="$event.preventDefault()"
      >
        <mat-icon>add</mat-icon>
        <mat-icon>credit_card</mat-icon>
        <span class="pwl-3 blue-txt"> Add new credit or debit card </span>
      </a>
    </span>
  </ng-container>
</section>

<ng-template #defaultChange>
  <span
    tabindex="0"
    class="cf-primary-rev"
    aria-label="Change Payment Method"
    (click)="createPaymentMethod.emit()"
    (keyup.enter)="createPaymentMethod.emit()"
    (keydown.enter)="$event.preventDefault()"
  >
    Change
  </span>
</ng-template>
