import { Component, Input } from "@angular/core";
import { Vehicle } from "src/app/core/services/myqq";
import { getMakeModelFromMakeIdModel } from "src/lib/util";

@Component({
  selector: "myqq-vehicle-display",
  templateUrl: "./vehicle-display.component.html",
  styleUrls: ["./vehicle-display.component.scss"],
})
export class VehicleDisplayComponent {
  @Input() vehicle: Vehicle;
  @Input() removeBorder = false;
  @Input() showMembershipBadge = true;
  @Input() showExpandIcon = false;
  @Input() membershipBadgeColor: string;

  readonly toMakeModel = ({ year, make, model, color }: Vehicle) =>
    [year, color, ...getMakeModelFromMakeIdModel(make, model)]
      .filter(Boolean)
      .join(" ");
}
