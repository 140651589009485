<ng-container [ngSwitch]="orderType">
  <ng-container *ngSwitchCase="OrderTypes.NEWMEMBERSHIP">
    <myqq-cart-vehicle-empty
      *ngIf="!vehicles?.length"
    ></myqq-cart-vehicle-empty>
    <myqq-cart-vehicle
      *ngFor="let vehicle of vehicles | map: sortVehicles"
      [vehicle]="vehicle"
      [orderType]="orderType"
      [disabled]="vehicle | map: vehicleShouldBeDisabled:vehicles:orderItems"
      [orderItems]="orderItems"
      (vehicleSelected)="handleVehicleSelection(vehicle, vehicles)"
    >
    </myqq-cart-vehicle>
  </ng-container>

  <ng-container *ngSwitchCase="OrderTypes.ADDMEMBERSHIP">
    <myqq-cart-vehicle
      *ngFor="let vehicle of vehicles | map: vehiclesNotOnMembership"
      [vehicle]="vehicle"
      [disabled]="vehicle | map: vehicleShouldBeDisabled:vehicles:orderItems"
      [orderItems]="orderItems"
      (vehicleSelected)="handleVehicleSelection(vehicle, vehicles)"
    >
    </myqq-cart-vehicle>
  </ng-container>

  <ng-container *ngSwitchCase="OrderTypes.REMOVESUBVEHICLE">
    <h5 class="fw-u3 fs-u1">
      Deactivating Vehicle{{
        (vehicles | map: vehiclesOnOrder).length > 1 ? "s" : ""
      }}:
    </h5>

    <myqq-cart-vehicle
      *ngFor="let vehicle of vehicles | map: vehiclesOnOrder"
      [hideToggle]="true"
      [vehicle]="vehicle"
      [disabled]="vehicle | map: vehicleShouldBeDisabled:vehicles:orderItems"
      (vehicleSelected)="handleVehicleSelection(vehicle, vehicles)"
    >
    </myqq-cart-vehicle>
  </ng-container>
</ng-container>

<p *ngIf="maxFlockReached()">
  You've reached the maximum active vehicle limit of {{ MaxVehiclesOnFlock }}.
</p>

<button
  myqq-button
  class="outlined vw-p100"
  matIcon="add_circle_outline"
  iconColor="primary"
  *ngIf="vehicles | map: canAddVehiclesToAccount"
  cdkFocusInitial
  (click)="handleCreateVehicle()"
  (keydown.enter)="$event.preventDefault()"
  (keyup.enter)="handleCreateVehicle()"
  aria-label="Add Vehicle"
>
  <div class="ds-fl ai-ctr">
    <span>Add Vehicle</span>
  </div>
</button>
