import { Location } from "@angular/common";
import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  TemplateRef,
} from "@angular/core";
import { Store } from "@ngrx/store";
import { isSuccess } from "@nll/datum/DatumEither";
import { notNil } from "@qqcw/qqsystem-util";
import { merge, Subject } from "rxjs";
import { delay, first, map } from "rxjs/operators";

import {
  getMyPaymentMethods,
  postPaymentMethod,
  clearPostPaymentMethod,
  selectPostPaymentMethod,
} from "src/app/core/ngrx/myqq";
import { MyqqStripeService } from "src/app/core/services/myqq-stripe/myqq-stripe.service";
import { StripeError } from "src/app/shared/modules/stripe/stripe-definitions/error";

import { PaymentMethodData } from "src/app/shared/modules/stripe/stripe-definitions/payment-method";
import { environment } from "src/environments/environment";

@Component({
  selector: "myqq-add-payment-method-page",
  templateUrl: "./add-payment-method.page.html",
  styleUrls: ["./add-payment-method.page.scss"],
})
export class AddPaymentMethodPage implements OnInit, OnDestroy {
  constructor(
    readonly store$: Store<any>,
    readonly stripe: MyqqStripeService,
    readonly location: Location
  ) {}

  error$ = new Subject<StripeError | Error>();
  stripePending = false;
  supportNumber = environment.supportNumber;

  /**
   * Success depends on two remote requests… we watch the second here for now
   */
  readonly paymentMethodSaved$ = this.store$.select(selectPostPaymentMethod);

  /**
   * Close modal by navigating back when postPaymentMethod succeeds
   */
  private readonly paymentMethodResponseSub = this.paymentMethodSaved$
    .pipe(first(isSuccess))
    .subscribe(() => this.location.back());

  @ViewChild("initialFooter", { static: false })
  private initialFooterTemplate?: TemplateRef<any>;
  @ViewChild("stripeErrorFooter", { static: false })
  private stripeErrorFooterTemplate?: TemplateRef<any>;
  @ViewChild("errorFooter", { static: false })
  private errorFooterTemplate?: TemplateRef<any>;
  readonly footerTemplate = merge(
    this.error$.asObservable(),
    this.paymentMethodSaved$
  ).pipe(
    delay(1),
    map(
      () =>
        this.stripeErrorFooterTemplate ||
        this.errorFooterTemplate ||
        this.initialFooterTemplate ||
        null
    )
  );

  ngOnInit() {
    this.handleReset();
  }

  /**
   * Clear the postPaymentMethod and getPaymentMethods when this
   * modal closes.
   */
  ngOnDestroy() {
    this.paymentMethodResponseSub.unsubscribe();
    this.store$.dispatch(getMyPaymentMethods.pending());
    this.handleReset();
  }

  handleCancel() {
    this.location.back();
  }

  handleReset() {
    this.error$.next(undefined);
    this.store$.dispatch(clearPostPaymentMethod(null));
  }

  async handleSubmit(data: PaymentMethodData) {
    this.stripePending = true;

    try {
      const { paymentMethod } = await this.stripe.createPaymentMethod(data);

      if (notNil(paymentMethod)) {
        this.store$.dispatch(postPaymentMethod.pending(paymentMethod.id));
      } else {
        // TODO: !!! handle errors here (but through the selected datums, not with component props)
        throw new Error(
          "There was a problem adding this payment method. Please verify your payment details."
        );
      }
    } catch (error) {
      this.error$.next(error);
    }

    this.stripePending = false;
  }

  trimBlankErrorCode(message: string) {
    // If stripe/myqq-api doesn't return the error code, just trim that out of the message.
    // e.g. 'Card was declined with code: ' happens sometimes, so just display 'Card was declined.'
    return message?.replace(/\ with\ code\:\s$/g, ".");
  }
}
