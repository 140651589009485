<myqq-routed-modal [useDatumEither]="updateAccount$ | async">
  <span modal-header>
    Edit Profile
  </span>

  <ng-container *onInitial [useDatumEither]="account$ | async">
    <myqq-loading *onInitial>
      <h3>…</h3>
    </myqq-loading>

    <myqq-loading *onPending>
      <h3>Loading…</h3>
    </myqq-loading>

    <ng-container *onSuccess="let account; let refreshing = refreshing">
      <myqq-edit-account-info
        #accountInfoForm
        [details]="account"
      ></myqq-edit-account-info>

      <ng-template #footer>
        <button
          myqq-button
          class="cancel-btn"
          id="cancel"
          (click)="handleCancel()"
        >
          Cancel
        </button>

        <button
          myqq-button
          class="save-btn"
          color="primary"
          [disabled]="! accountInfoForm?.valid || refreshing"
          (click)="handleSubmit(accountInfoForm?.value)"
          id="save"
        >
          {{ refreshing ? "Saving…" : "Save" }}
        </button>
      </ng-template>
    </ng-container>
  </ng-container>

  <myqq-loading *onPending>
    <h3>Saving Profile…</h3>
  </myqq-loading>

  <myqq-error
    *onFailure="let error; let refreshing = refreshing"
    title="Profile Update Error"
    description="We encountered an error while updating your profile."
    [error]="error"
  >
    <footer>
      <button
        myqq-button
        class="large raised"
        color="danger"
        [disabled]="refreshing"
        (click)="handleReset()"
      >
        {{ refreshing ? "Refreshing…" : "Go Back" }}
      </button>
    </footer>
  </myqq-error>

  <div modal-footer class="js-end qq-buttons">
    <ng-container *ngTemplateOutlet="footerTemplate | async"> </ng-container>
  </div>
</myqq-routed-modal>
