<label>Add a promo code </label>

<form
  [formGroup]="discountForm"
  (submit)="submitPromo(discountForm.value)"
  class="fld-row flg-5 ai-str"
>
  <mat-form-field
    appearance="outline"
    floatLabel="always"
    class="fls-1-1"
    [useDatumEither]="checkPromo$ | async"
  >
    <input
      matInput
      formControlName="promoCode"
      id="promo-input"
      [mask]="promoMask"
    />
    <mat-hint *onFailure>
      {{ invalidMessage }}
    </mat-hint>
    <ng-container *onSuccess="let promo">
      <mat-hint *ngIf="promo | map: isExpired">
        This promo code is expired.
      </mat-hint>
      <mat-hint *ngIf="promo | map: isTooSoon">
        This promo code is not active yet.
      </mat-hint>
    </ng-container>
  </mat-form-field>

  <button
    myqq-button
    class="raised apply-button fls-0-0"
    aria-label="Apply Promo"
    color="primary"
    [disabled]="
      discountForm.invalid || (checkPromo$ | async | map: isInProgress)
    "
  >
    Apply
  </button>
</form>
