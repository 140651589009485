import { NgModule } from "@angular/core";

import { MapPipe } from "./map.pipe";

@NgModule({
  imports: [],
  exports: [MapPipe],
  declarations: [MapPipe],
  providers: [],
})
export class MapPipeModule {}
