import { NgModule } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";

import { MyQQService } from "./myqq.service";
import { environment } from "src/environments/environment";
import { MyQQMockService } from "./myqq.service.mock";

/**
 * This module provides the raw http calls to the myqq api microservice.
 */
@NgModule({
  imports: [HttpClientModule],
  exports: [],
  declarations: [],
  providers: [
    {
      provide: MyQQService,
      useClass: environment.useMockHttpServices ? MyQQMockService : MyQQService,
    },
  ],
})
export class MyQQServiceModule {}
