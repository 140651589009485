import { take as _take, sortBy as _sortBy } from "lodash-es";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Actions, createEffect } from "@ngrx/effects";
import { Router } from "@angular/router";
import {
  distinctUntilChanged,
  distinctUntilKeyChanged,
  filter,
  map,
  delay,
  switchMap,
  first,
  tap,
} from "rxjs/operators";
import * as O from "fp-ts/es6/Option";
import { combineLatest } from "rxjs";
import { getSpecificWashLevel } from "src/lib/util";

import {
  addFlockOrder,
  addMembershipOrder,
  addVehicle,
  calculateOrder,
  changeMembershipOrder,
  checkPromoCode,
  getMySubscriptions,
  getPriceTable,
  getVehicles,
  removeFlockOrder,
  setMembershipPurchase,
  setModifySubscriptionPlan,
  setPendingOrder,
  setPendingOrderPromoCode,
  setVehiclesOnOrder,
} from "../myqq.reducers";
import {
  selectCompanyWidePromo,
  selectMembershipPurchase,
  selectMySubscription,
  selectPendingOrder,
  selectPriceTable,
  selectPromoDetails,
  selectVehicles,
  selectVehiclesOnOrder,
} from "../myqq.selectors";
import {
  findMenuPair,
  vehiclesToOrderItems,
  buildChangeOrder,
  buildMembershipItems,
} from "../myqq.utilities";
import {
  MembershipPurchase,
  MenuPair,
  OrderTypesEnum,
  PostCalculateOrderRequest,
  PromoDetails,
  SubscriptionDetail,
  SubscriptionPlan,
  Vehicle,
} from "../../../services/myqq";
import { environment } from "src/environments/environment";
import {
  displayOrderVehiclePlate,
  displayVehiclePlate,
} from "src/app/shared/pipes/display-vehicle-plate.pipe";
import { isInitial, isSuccess } from "@nll/datum/DatumEither";
import { getSpecificPlanOverride } from "src/lib/util";
import { filterSuccessMapToRightValue } from "src/lib/datum-either";
import { notNil } from "@qqcw/qqsystem-util";

/**
 * This service contains chaining side effects. For example, when we get a profile we
 * might also want to get the persons vehicles and coupons from other endpoints without
 * explicitely chaining those calls in the ui. This service contains those cascading
 * events.
 */
@Injectable()
export class MyQQOrderChainEffects {
  /**
   * Set promo code on promo code valid response
   */
  setPendingOrderPromoCodeOnCheckPromoSuccess$ = createEffect(() =>
    this.actions$.pipe(
      filter(checkPromoCode.success.match),
      filter(
        ({
          value: {
            result: { expiresAt },
          },
        }) => new Date(expiresAt) > new Date()
      ),
      filter(
        ({
          value: {
            result: { effectiveAt },
          },
        }) => new Date(effectiveAt) < new Date()
      ),
      map(({ value: { params } }) =>
        setPendingOrderPromoCode({ serialNo: params })
      )
    )
  );

  setPendingOrderOnAddVehicleSuccess$ = createEffect(() =>
    this.actions$.pipe(
      filter(addVehicle.success.match),
      map(({ value: { result } }) => result),
      switchMap((newVehicle) => {
        const orderInfo = combineLatest([
          this.store$.select(selectPendingOrder),
          this.store$.select(selectMembershipPurchase),
          this.store$.select(selectVehiclesOnOrder),
          this.store$.select(selectMySubscription).pipe(
            tap((subscription) => {
              if (isInitial(subscription)) {
                this.store$.dispatch(getMySubscriptions.pending());
              }
            }),
            filterSuccessMapToRightValue
          ),

          this.store$.select(selectPriceTable).pipe(
            tap((priceTable) => {
              if (isInitial(priceTable)) {
                this.store$.dispatch(getPriceTable.pending(null));
              }
            }),
            filterSuccessMapToRightValue
          ),
          this.store$.select(selectVehicles).pipe(
            tap((vehicles) => {
              if (isInitial(vehicles)) {
                this.store$.dispatch(getVehicles.pending(null));
              }
            }),
            filterSuccessMapToRightValue
          ),
        ]);
        return orderInfo.pipe(
          first(),
          map(
            ([
              pendingOrderOption,
              membershipPurchaseOption,
              vehiclesOnOrder,
              subscriptionOption,
              priceTable,
              vehicles,
            ]) =>
              this.handleAddVehicleToOrder(
                pendingOrderOption,
                membershipPurchaseOption,
                vehiclesOnOrder,
                subscriptionOption,
                priceTable,
                vehicles,
                newVehicle
              )
          )
        );
      }),
      filter(notNil),
      distinctUntilKeyChanged("vehicles"),
      map(({ orderType, wash, subscriptionPlan, vehicles }) => {
        return orderType === OrderTypesEnum.NEWMEMBERSHIP
          ? addMembershipOrder({ wash, vehicles })
          : addFlockOrder({ subscriptionPlan, vehicles });
      })
    )
  );

  setVehiclesOnOrderOnAddFlockOrder$ = createEffect(() =>
    this.actions$.pipe(
      filter(addFlockOrder.match),
      map(({ value: { vehicles } }) => {
        return setVehiclesOnOrder(vehicles);
      })
    )
  );

  setVehiclesOnOrderOnAddmembershipOrder$ = createEffect(() =>
    this.actions$.pipe(
      filter(addMembershipOrder.match),
      map(({ value: { vehicles } }) => {
        return setVehiclesOnOrder(vehicles);
      })
    )
  );

  /**
   * Submit calculate order when pendingOrder changes; apply any promos returned by
   */
  i = 0;
  calculateOrderOnPendingOrderChange$ = createEffect(() =>
    this.store$.select(selectPendingOrder).pipe(
      filter(O.isSome), // Only emit if order is not none
      distinctUntilChanged(), // Only emit when order object changes

      delay(0), // Prevents order from being stuck in Refresh when recalculating immediately after deleting a promo
      map((order) => calculateOrder.pending(order.value)) // Map to calculateOrder pending action
    )
  );

  /**
   * Set Order Chains
   */
  public deletedPromo: boolean; // Flag so we don't immediately reapply the promo when user removes it.

  addFlockOrder$ = createEffect(() =>
    this.actions$.pipe(
      filter(addFlockOrder.match),
      switchMap((val) => {
        const orderPromo = val.value.promo;
        const orderType = OrderTypesEnum.ADDMEMBERSHIP;
        return combineLatest([
          this.store$.select(selectPromoDetails),
          this.store$.select(selectCompanyWidePromo),
        ]).pipe(
          map(([specificPromoDetail, companyWidePromo]) => {
            if (
              isSuccess(specificPromoDetail) &&
              !!specificPromoDetail.value.right.code
            ) {
              return specificPromoDetail.value.right;
            } else if (isSuccess(companyWidePromo)) {
              return companyWidePromo.value.right;
            } else {
              return {} as PromoDetails;
            }
          }),
          first(),
          map((promoDetails) => {
            return {
              ...val,
              value: {
                ...val.value,
                promo: this.autoApplyPromo(
                  promoDetails,
                  orderPromo,
                  val.value.subscriptionPlan.base.sku,
                  orderType
                ),
              },
            };
          })
        );
      }),
      map(({ value: { subscriptionPlan, vehicles, promo } }) => {
        const menuPair = findMenuPair(subscriptionPlan);
        const items = vehiclesToOrderItems(menuPair.flock, vehicles);
        return setPendingOrder({
          items,
          orderType: OrderTypesEnum.ADDMEMBERSHIP,
          markDowns: promo ? [{ serialNo: promo }] : [],
        });
      })
    )
  );

  removeFlockOrder$ = createEffect(() =>
    this.actions$.pipe(
      filter(removeFlockOrder.match),
      map(({ value: { subscriptionPlan, vehicles, reasonCode } }) => {
        const menuPair = findMenuPair(subscriptionPlan);
        const items = vehiclesToOrderItems(menuPair.flock, vehicles, -1);
        return setPendingOrder({
          items,
          orderType: OrderTypesEnum.REMOVESUBVEHICLE,
          createReasonCode: reasonCode,
        });
      })
    )
  );

  setModifySubscriptionPlan$ = createEffect(() =>
    this.actions$.pipe(
      filter(changeMembershipOrder.match),
      map(({ value: { targetSubscription } }) =>
        setModifySubscriptionPlan(targetSubscription)
      )
    )
  );

  changeMembershipOrder$ = createEffect(() =>
    this.actions$.pipe(
      filter(changeMembershipOrder.match),
      switchMap((val) => {
        const orderType = OrderTypesEnum.UPGRADESUBSCRIPTION;
        return combineLatest([
          this.store$.select(selectPromoDetails),
          this.store$.select(selectCompanyWidePromo),
        ]).pipe(
          map(([specificPromoDetail, companyWidePromo]) => {
            if (
              isSuccess(specificPromoDetail) &&
              !!specificPromoDetail.value.right.code
            ) {
              return specificPromoDetail.value.right;
            } else if (isSuccess(companyWidePromo)) {
              return companyWidePromo.value.right;
            } else {
              return {} as PromoDetails;
            }
          }),
          first(),
          map((promoDetails) => {
            const promo = this.autoApplyPromo(
              promoDetails,
              null,
              val.value.targetSubscription.base.sku,
              orderType
            );
            return {
              ...val,
              value: {
                ...val.value,
                promo,
              },
            };
          })
        );
      }),
      map(
        ({
          value: { vehicles, targetSubscription, currentSubscription, promo },
        }) =>
          setPendingOrder({
            ...buildChangeOrder(
              vehicles,
              currentSubscription,
              targetSubscription
            ),
            waiveUpgradeFee: !!promo,
          })
      )
    )
  );

  setMembershipPurchase$ = createEffect(() =>
    this.actions$.pipe(
      filter(addMembershipOrder.match),
      map(({ value: { wash, vehicles } }) =>
        setMembershipPurchase(O.some({ wash, vehicles }))
      )
    )
  );

  addMembership$ = createEffect(() =>
    this.actions$.pipe(
      filter(addMembershipOrder.match),
      switchMap((val) => {
        const orderPromo = val.value.promo;
        const sku = val.value.wash?.base?.sku;
        const orderType = OrderTypesEnum.NEWMEMBERSHIP;
        return combineLatest([
          this.store$.select(selectPromoDetails),
          this.store$.select(selectCompanyWidePromo),
        ]).pipe(
          map(([specificPromoDetail, companyWidePromo]) => {
            if (
              isSuccess(specificPromoDetail) &&
              !!specificPromoDetail.value.right.code
            ) {
              return specificPromoDetail.value.right;
            } else if (isSuccess(companyWidePromo)) {
              return companyWidePromo.value.right;
            } else {
              return {} as PromoDetails;
            }
          }),
          first(),
          map((promoDetails) => {
            return {
              ...val,
              value: {
                ...val.value,
                promo: this.autoApplyPromo(
                  promoDetails,
                  orderPromo,
                  sku,
                  orderType
                ),
              },
            };
          })
        );
      }),
      map(({ value: { wash, vehicles, promo } }) => {
        const menuPair: MenuPair = {
          base: wash.base,
          flock: wash.flock,
        };
        return setPendingOrder({
          orderType: OrderTypesEnum.NEWMEMBERSHIP,
          items: buildMembershipItems(
            menuPair,
            _take(
              _sortBy(vehicles, displayOrderVehiclePlate),
              environment.limits.maxVehiclesOnSubscription
            )
          ),
          markDowns: promo ? [{ serialNo: promo }] : [],
        });
      })
    )
  );

  private autoApplyPromo(
    promoDetails: PromoDetails,
    orderPromo: string | null,
    sku: string,
    orderType: OrderTypesEnum
  ): string | null {
    let finalPromoCode = orderPromo;
    if (!environment.unleash.enable || this.deletedPromo) {
      return null;
    }
    // promo exists in order, promo could be user override, or auto applied promo
    if (
      promoDetails?.planSpecificOverride?.length > 0 &&
      ![
        OrderTypesEnum.DOWNGRADESUBSCRIPTION,
        OrderTypesEnum.REMOVESUBVEHICLE,
      ].includes(orderType) &&
      (!promoDetails.autoApplyOrderTypes?.length || // No whitelist of order types => apply to all
        promoDetails.autoApplyOrderTypes?.includes(orderType)) // Whitelist of order types => only apply if order type is in whitelist
    ) {
      const planSpecificPromoCode = getSpecificPlanOverride(
        sku,
        promoDetails?.planSpecificOverride
      )?.serialNo;
      finalPromoCode = orderPromo || planSpecificPromoCode;
    }
    return finalPromoCode;
  }

  private handleAddVehicleToOrder(
    pendingOrderOption: O.Option<PostCalculateOrderRequest>,
    membershipPurchaseOption: O.Option<MembershipPurchase>,
    vehiclesOnOrder: Vehicle[],
    subscriptionOption: O.Option<SubscriptionDetail>,
    priceTable,
    vehicles: Vehicle[],
    newVehicle: Vehicle
  ) {
    const currentSubscriptionPlan: SubscriptionPlan = O.isSome(
      subscriptionOption
    )
      ? getSpecificWashLevel(
          subscriptionOption.value.washLevelId,
          priceTable.plans
        )
      : null;

    const pendingOrderPromo: string =
      O.isSome(pendingOrderOption) &&
      pendingOrderOption.value?.markDowns?.length > 0
        ? pendingOrderOption.value?.markDowns[0]?.serialNo
        : null;
    // handle max vehicles subscription for existing and new membership: don't do anything.
    const vehiclesWithExistingMembership = vehicles?.filter(
      (v) => v.hasMembership
    );
    if (
      vehiclesWithExistingMembership?.length + vehiclesOnOrder?.length >=
      environment.limits.maxVehiclesOnSubscription
    ) {
      return;
    }
    // handle existing pending order is upgrade, downgrade, removeVehicle: create add flock order
    // also handle existing pending order is addmembership: add vehicle to flock order
    if (
      O.isSome(pendingOrderOption) &&
      [
        OrderTypesEnum.DOWNGRADESUBSCRIPTION,
        OrderTypesEnum.UPGRADESUBSCRIPTION,
        OrderTypesEnum.REMOVESUBVEHICLE,
        OrderTypesEnum.ADDMEMBERSHIP,
      ].includes(pendingOrderOption.value.orderType) &&
      O.isSome(subscriptionOption)
    ) {
      return {
        orderType: OrderTypesEnum.ADDMEMBERSHIP,
        vehicles: _sortBy(
          [...vehiclesOnOrder, newVehicle],
          displayVehiclePlate
        ),
        subscriptionPlan: currentSubscriptionPlan,
        promo: pendingOrderPromo,
      };
    }
    // handle existing pending order is newMembership: add vehicle to new membership order
    if (
      O.isSome(pendingOrderOption) &&
      pendingOrderOption.value.orderType == OrderTypesEnum.NEWMEMBERSHIP &&
      O.isSome(membershipPurchaseOption)
    ) {
      return {
        orderType: OrderTypesEnum.NEWMEMBERSHIP,
        wash: membershipPurchaseOption.value.wash,
        vehicles: _sortBy(
          [...vehiclesOnOrder, newVehicle],
          displayVehiclePlate
        ),
        promo: pendingOrderPromo,
      };
    }
    // handle add vehicle to existing membership
    if (O.isNone(pendingOrderOption) && O.isSome(subscriptionOption)) {
      return {
        orderType: OrderTypesEnum.ADDMEMBERSHIP,
        vehicles: [newVehicle],
        subscriptionPlan: currentSubscriptionPlan,
        promo: pendingOrderPromo,
      };
    }
    //handle new membership order
    if (
      O.isNone(pendingOrderOption) &&
      O.isNone(subscriptionOption) &&
      O.isSome(membershipPurchaseOption)
    ) {
      return {
        orderType: OrderTypesEnum.NEWMEMBERSHIP,
        wash: membershipPurchaseOption.value.wash,
        vehicles: _sortBy(
          [...vehiclesOnOrder, newVehicle],
          displayVehiclePlate
        ),
        promo: pendingOrderPromo,
      };
    }
  }
  constructor(
    readonly actions$: Actions,
    readonly store$: Store<any>,
    readonly router: Router
  ) {}
}
