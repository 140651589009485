import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { map } from "rxjs/operators";
import { routeChange } from "./router.reducers";
import { RouterNavigatedAction, ROUTER_NAVIGATED } from "@ngrx/router-store";

@Injectable()
export class RouterChainEffects {
  constructor(private readonly actions$: Actions) {}

  navigated$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_NAVIGATED),
      map((a: RouterNavigatedAction) => routeChange(a.payload.routerState))
    )
  );
}
