import { Subject, ReplaySubject, Observable, BehaviorSubject } from "rxjs";
import { VehicleMakes, range, notIn } from "@qqcw/qqsystem-util";
import {
  PlanOverride,
  PromoDetails,
  SubscriptionPlan,
} from "src/app/core/services/myqq";

export type Omit<Type, Key> = Pick<Type, Exclude<keyof Type, Key>>;

export const EmailRegexp =
  // tslint:disable-next-line:max-line-length
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export type UUID = string;
export type QQ_UUID = UUID;

export type Fn<Arguments extends any[] = any[], ReturnType = any> = (
  ...params: Arguments
) => ReturnType;

export type CallbackFn<CallbackData = any> = Fn<[CallbackData], void>;

export function wrapInObservable<
  CallbackDataType,
  Arguments extends any[] = []
>(fn: Fn, ...args: Arguments) {
  return wrapCallbacksWithSubject<CallbackDataType, Arguments>(
    fn,
    new Subject(),
    ...args
  );
}

export function wrapInReplayObservable<
  CallbackDataType,
  Arguments extends any[] = []
>(fn: Fn, ...args: Arguments) {
  return wrapCallbacksWithSubject<CallbackDataType, Arguments>(
    fn,
    new ReplaySubject(1),
    ...args
  );
}

export function wrapObservableInBehaviorSubject<T>(
  observable: Observable<T>,
  initValue: T
): BehaviorSubject<T> {
  const subject = new BehaviorSubject<T>(initValue);

  observable.subscribe({
    complete: () => subject.complete(),
    error: (x) => subject.error(x),
    next: (x) => subject.next(x),
  });

  return subject;
}

export function wrapCallbacksWithSubject<
  CallbackDataType,
  Arguments extends any[]
>(fn: Fn, callbackObserver: Subject<CallbackDataType>, ...args: Arguments) {
  const [success, fail] = [
    (...results: any[]) => {
      callbackObserver.next(results.length === 1 ? results[0] : results);
    },
    (error: any) => {
      callbackObserver.error(error);
    },
  ];

  fn(...args, success, fail);

  return callbackObserver.asObservable();
}

export function exists<T>(
  obj: T
): obj is Exclude<T, "" | false | undefined | null | 0> {
  if (!obj) {
    return false;
  }
  return true;
}

export function rationalizeVIN(vin: string) {
  if (vin && vin.length > 0) {
    vin = vin.toUpperCase().replace(/[^A-HJ-NPR-Z0-9]{17}/g, "");
    if (vin.length > 17) {
      vin = vin.substring(0, 17);
    }
  }
  return vin;
}

// strips non-alphanumeric chars and trims to max 10 chars
export function rationalizeLicense(license: string): string {
  if (license && license.length > 0) {
    license = license.toUpperCase().replace(/[^0-9A-Z]/g, "");
    if (license.length > 10) {
      license = license.substring(0, 10);
    }
  }
  return license;
}

export function getParams(
  uri: string
): { path: string; params?: { [key: string]: string } } {
  const [path, paramString] = uri.split("?");
  if (!paramString) {
    return { path: path };
  }

  const params = {};
  paramString.split("&").forEach((p) => {
    const [key, value] = p.split("=");
    params[key] = value;
  });

  return { path: path, params: params };
}

export const getMakeFromMakeId = (makeId: string) =>
  VehicleMakes.find(
    (make) => make?.makeId?.toLowerCase() === makeId?.toLowerCase()
  );

export const getMakeModelFromMakeIdModel = (makeId: string, model: string) => {
  const make = VehicleMakes.find(
    (mk) => mk?.makeId?.toLowerCase() === makeId?.toLowerCase()
  );
  const mdl = make?.models?.find(
    (mo) => mo?.name?.toLowerCase() === model?.toLowerCase()
  );

  return [make?.name, mdl?.name];
};
export const getModelYears = (startYear = 1980): string[] => {
  const yearsSince1980 = new Date().getUTCFullYear() - startYear;

  return range(yearsSince1980 + 1, startYear + 1)
    .reverse()
    .map((y) => y.toString());
};

export function omit(obj: Object, ...props: string[]) {
  const result = { ...obj };
  props.forEach((p) => delete result[p]);

  return result;
}

export function getPlanPromoImg(
  sku: string,
  promoDetail: PromoDetails
): string {
  return promoDetail?.planSpecificOverride?.find(
    (override) => override.sku.toUpperCase() == sku.toUpperCase()
  )?.imageUrl;
}

export function toTitleCase(txt: string): string {
  if (txt == null) return null;

  return txt.replace(/\w\S*/g, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
  });
}

export function getPlanFeaturesDifference(
  currentPlan: SubscriptionPlan,
  nextPlan: SubscriptionPlan,
  isUpgrade: boolean
): string[] {
  if (isUpgrade) {
    return nextPlan.features?.filter(
      notIn(currentPlan.features),
      (a, b) => a === b
    );
  } else
    return currentPlan.features?.filter(
      notIn(nextPlan.features),
      (a, b) => a === b
    );
}

export function getSpecificPlanOverride(
  sku: string,
  plans: PlanOverride[]
): PlanOverride {
  if (!!sku && !!plans && plans?.length > 0) {
    return plans.find((plan) => plan.sku.toUpperCase() === sku.toUpperCase());
  }
}

export function getSpecificWashLevel(
  washLevelId: string,
  plans: SubscriptionPlan[]
): SubscriptionPlan {
  if (!!washLevelId && !!plans && plans?.length > 0) {
    return plans.find((plan) => plan.washLevelId === washLevelId);
  }
}

export function getPlanMembershipBadgeColor(
  washLevelId: string,
  plans: SubscriptionPlan[]
): string {
  return plans?.find((plan) => plan.washLevelId == washLevelId)
    ?.washLevelBadgeColor;
}
