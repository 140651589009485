<div class="fld-col vh-p100">
  <button
    mat-mini-fab
    class="back-btn"
    color="primary"
    aria-label="Go back"
    (click)="back.emit()"
    (keydown.enter)="$event.preventDefault()"
    (keyup.enter)="back.emit()"
  >
    <mat-icon class="back-arrow">arrow_back</mat-icon>
  </button>
  <p class="ta-c">
    Reason for vehicle swap?
  </p>
  <mat-card
    *ngFor="let reason of swapReasons"
    data-cy="reason"
    (click)="selectReason(reason)"
    (keydown.enter)="$event.preventDefault()"
    (keyup.enter)="selectReason(reason)"
    class="mwy-5"
    [ngClass]="reason.id === currentReason?.id ? 'selected-card' : ''"
  >
    <mat-card-content>
      {{ reason.name }}
    </mat-card-content>
  </mat-card>
  <span class="fls-1-1"></span>
  <footer>
    <button
      myqq-button
      color="primary"
      class="raised as-ctr confirm-btn"
      aria-label="Next"
      [disabled]="!currentReason"
      (click)="handleNext()"
      (keydown.enter)="$event.preventDefault()"
      (keyup.enter)="handleNext()"
    >
      Next
    </button>
  </footer>
</div>
