import { Component, Input, OnInit } from "@angular/core";
import { Money } from "@qqcw/qqsystem-util";
import { BehaviorSubject } from "rxjs";
import { MoneyRepresentation } from "../../utilities/types";

@Component({
  selector: "myqq-price",
  templateUrl: "./price-display.component.html",
  styleUrls: ["./price-display.component.scss"],
})
export class PriceDisplayComponent implements OnInit {
  @Input() price?: null | string | number | Money;
  @Input() prepend?: string;
  @Input() append?: string;
  @Input() priceOverride?: MoneyRepresentation;
  @Input() crossOutDefaultPrice?: boolean = false;
  @Input() customPriceImgUrl?: string;

  free$ = new BehaviorSubject<boolean>(false);

  ngOnInit() {
    this.free$.next(this.free(this.priceOverride));
  }

  free = (price: MoneyRepresentation) => {
    try {
      switch (typeof price) {
        case "string":
          return parseFloat(price) == 0;
        case "number":
          return price == 0;
        case "object":
          return price?.value == 0;
        default:
          return false;
      }
    } catch {
      return false;
    }
  };

  dollars = (price: MoneyRepresentation) => {
    if (!price && price !== 0) {
      return "";
    }

    return `${Money.getWholeDollars(price)}`;
  };

  cents = (price: MoneyRepresentation) => {
    if (!price && price !== 0) {
      return "";
    }

    return `${Money.getFractionalCents(Money.absolute(price))}`;
  };
}
