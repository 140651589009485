export const errors = {
  myqqApi: {
    errBadUrl: "Bad URL",
    errBadRequest: "Bad Request",
    errGetOrder: "Failed to fetch order",
    errGetProfile: "Failed to fetch profile",
    errCreateProfile: "Failed to create profile",
    errGetPaymentMethods: "Failed to fetch payment methods",
    errGetItemPricing: "Failed to fetch item pricing",
    errGetStore: "Failed to fetch store",
    errGetRegions: "Failed to fetch regions",
    errGetCoupon: "Failed to fetch coupon",
    errGetSubscription: "Failed to fetch subscription information",
    errInvalidOrder: "Invalid order request",
    errInvalidCoupon: "Invalid coupon",
    errInvalidLicensePlate: "Invalid License Plate",
    errInvalidVin: "Invalid Vin",
    errInvalidInvoiceId: "Invalid invoice id",
    errPayInvoice: "Failed to pay invoice",
    errProfileNoMembership: "Membership not found",
    errProfileUpdate: "Failed to update profile",
    errAccountNoMembership: "Account not found on membership",
    errAccountNoStripeCustomer: "Subscription information not found",
    errAccountNoHomeStore: "Home store not found on account",
    errAccountExists: "Account already exists",
    errAccountCreate: "Failed to create account",
    errAccountLink: "Failed to link account",
    errAccountAlreadyLinked: "Account already linked to a different account",
    errAccountOwnership: "Account not owned by user",
    errVehicleAlreadyOnAccount: "Vehicle already on account",
    errAccountUpdate: "Failed to update person",
    errAccountNotFound: "Account not found",
    errVehicleCreate: "Failed to create vehicle",
    errVehicleLimit: "Vehicle limit reached on account",
    errVehicleOwnership: "Vehicle not owned by user",
    errVehicleUpdate: "Failed to update vehicle",
    errVehicleGet: "Failed to fetch vehicle",
    errVehicleRemove: "Failed to remove vehicle(s) from account",
    errVehicleMembershipStatus: "Failed to get vehicle membership status",
    errGetMembership: "Failed to get membership information",
    errGetAccountInfo: "Failed to get account information",
    errRequireLicenseOrVin: "License or VIN required for vehicle",
    errEvoxError: "Upstream evox service error",
    errEmailOnMembership: "Email is associated with an active membership",
  },
};
