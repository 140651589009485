import { APP_INITIALIZER, NgModule } from "@angular/core";
import { Store } from "@ngrx/store";

import { KeycloakAngularModule, KeycloakService } from "keycloak-angular";
import { environment } from "src/environments/environment";

import { setKeycloakInitialized } from "../ngrx/ui";
import { CanAuthGuard } from "./keycloak.guard";

import {
  getRedirectUri,
  handleKeycloakAfterInit,
  handleKeycloakInitError,
  handleManualRegistration,
} from "./keycloak.utils";

export function initializer(
  keycloak: KeycloakService,
  store$: Store<any>
): () => Promise<any> {
  const { redirectUri, sales } = getRedirectUri();

  // If the user is coming from the ddd website, just redirect them to the
  // registration url immediately
  if (sales) {
    handleManualRegistration(redirectUri, store$);

    return () => Promise.resolve();
  }

  return (): Promise<any> => {
    return new Promise<string | void>(async (resolve, reject) => {
      if (!window?.navigator?.onLine) {
        store$.dispatch(setKeycloakInitialized(false));
        resolve();

        return;
      }

      keycloak
        .init({
          config: environment.keycloak.config,
          loadUserProfileAtStartUp: true,
          initOptions: {
            ...environment.keycloak.initOptions,
            redirectUri: redirectUri,
          },
          enableBearerInterceptor: true,
          bearerExcludedUrls: ["/assets", environment.cdnHost],
        })
        .then(() => {
          handleKeycloakAfterInit(keycloak, store$);
        })
        .catch((e) => handleKeycloakInitError(e, store$));

      resolve();
    });
  };
}

@NgModule({
  imports: [KeycloakAngularModule],
  exports: [KeycloakAngularModule],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializer,
      multi: true,
      deps: [KeycloakService, Store],
    },
    CanAuthGuard,
  ],
})
export class KeycloakModule {}
