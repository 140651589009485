import { RequireSome } from "@qqcw/qqsystem-util";
import {
  MenuPair,
  MenuItem,
  OrderItem,
  PostCalculateOrderRequest,
  Order,
  OrderTypesEnum,
  Vehicle,
  SubscriptionPlan,
} from "src/app/core/services/myqq";

/**
 * Given a subscription detail and a price menu, return the price menu for
 * the current subscription.
 */
export const findMenuPair = (subscriptionPlan: SubscriptionPlan): MenuPair => {
  return {
    base: subscriptionPlan.base,
    flock: subscriptionPlan.flock,
  };
};

/**
 * Creates order items from a menuItem and a list of vehicles
 */
export const vehiclesToOrderItems = (
  { itemId }: MenuItem,
  vehicles: { vehicleId: string }[],
  orderedQty: number = 1
): RequireSome<OrderItem, "vehicleId" | "orderedQty" | "itemId">[] =>
  vehicles.map(({ vehicleId }) => ({ itemId, orderedQty, vehicleId }));

/**
 * Compares current and target subscription type and returns
 * the appropriate orderType
 */
export const getOrderType = (
  currentSubscription: SubscriptionPlan,
  targetSubscription: SubscriptionPlan
): Order["orderType"] => {
  return targetSubscription.washHierarchy > currentSubscription.washHierarchy
    ? OrderTypesEnum.UPGRADESUBSCRIPTION
    : OrderTypesEnum.DOWNGRADESUBSCRIPTION;
};

/**
 * Build Membership Items from menuPair and vehicle list.
 * Optionally set orderedQty (for removals with value -1)
 */
export const buildMembershipItems = (
  { base, flock }: MenuPair,
  vehicles: { vehicleId: string }[],
  orderedQty: number = 1
): RequireSome<OrderItem, "vehicleId" | "orderedQty" | "itemId">[] => {
  const clone = [...vehicles]; // Clone array since we mutate it.
  const baseItems = vehiclesToOrderItems(base, [clone.shift()], orderedQty);
  const flockItems = vehiclesToOrderItems(flock, clone, orderedQty);
  return [...baseItems, ...flockItems];
};

/**
 * Creates a pending order from a current subscription, the price menu,
 * and a target subscription type
 */
export const buildChangeOrder = (
  vehicles: Vehicle[],
  currentSubscription: SubscriptionPlan,
  targetSubscription: SubscriptionPlan
): RequireSome<PostCalculateOrderRequest, "items" | "orderType"> => {
  const current = findMenuPair(currentSubscription);
  const target = findMenuPair(targetSubscription);
  const orderType = getOrderType(currentSubscription, targetSubscription);
  const removedItems = buildMembershipItems(current, vehicles, -1);
  const addedItems = buildMembershipItems(target, vehicles);

  const items = [...removedItems, ...addedItems];
  return { orderType, items };
};
