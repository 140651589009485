import { NgModule } from "@angular/core";
import { StoreModule } from "@ngrx/store";

import { uiReducer } from "./ui.reducers";
import { uiFeatureKey } from "./ui.consts";
import { EffectsModule } from "@ngrx/effects";
import { UIChainEffects } from "./ui.chains";

@NgModule({
  imports: [
    StoreModule.forFeature(uiFeatureKey, uiReducer),
    EffectsModule.forFeature([UIChainEffects]),
  ],
})
export class NgrxUIModule {}
