import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject } from "rxjs";
import { environment } from "src/environments/environment";

import { Link } from "../default-layout.page";

@Component({
  selector: "myqq-desktop-layout",
  templateUrl: "./desktop-layout.component.html",
  styleUrls: ["./desktop-layout.component.scss"],
})
export class DesktopLayoutComponent {
  @Input() homeLink: Link[];
  @Input() accountLinks: Link[];
  @Input() accountLinked = false;
  @Input() authenticated: BehaviorSubject<boolean>;
  @Input() online = false;

  @Output() authenticate: EventEmitter<boolean> = new EventEmitter();
  @Output() register: EventEmitter<void> = new EventEmitter(null);

  accountClicked = false;
  cdnHost = environment.cdnHost;

  constructor(readonly router: Router) {}

  get isShowingAccountPage() {
    return this.router.url.match("/account");
  }

  handleAuthentication(loggedIn): void {
    this.authenticate.emit(loggedIn);
  }

  handleRegister() {
    this.register.emit();
  }

  flipArrow() {
    this.accountClicked = !this.accountClicked;
  }
}
