import { Component, EventEmitter, Input, Output } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { environment } from "src/environments/environment";
import { Link } from "../default-layout.page";

@Component({
  selector: "myqq-landing-layout-desktop",
  templateUrl: "./landing-layout-desktop.component.html",
  styleUrls: ["./landing-layout-desktop.component.scss"],
})
export class LandingLayoutDesktopComponent {
  @Input() homeLink: Link[];
  @Input() accountLinks: Link[];
  @Input() accountLinked = false;
  @Input() authenticated: BehaviorSubject<boolean>;
  @Input() online = false;

  @Output() authenticate: EventEmitter<boolean> = new EventEmitter();
  @Output() register: EventEmitter<void> = new EventEmitter(null);

  cdnHost = environment.cdnHost;

  handleAuthentication(loggedIn): void {
    this.authenticate.emit(loggedIn);
  }

  handleRegister() {
    this.register.emit();
  }
}
