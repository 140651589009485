import { NgModule } from "@angular/core";
import {
  routerReducer,
  RouterStateSerializer,
  StoreRouterConnectingModule,
} from "@ngrx/router-store";

import { StoreModule } from "@ngrx/store";
import { routerStateConfig } from "./router.consts";
import { MyqqRouterSerializer } from "./router.serializer";
import { RouterChainEffects } from "./router.chains";
import { EffectsModule } from "@ngrx/effects";

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature(routerStateConfig.stateKey, routerReducer),
    StoreRouterConnectingModule.forRoot(routerStateConfig),
    EffectsModule.forFeature([RouterChainEffects]),
  ],
  providers: [
    {
      provide: RouterStateSerializer,
      useClass: MyqqRouterSerializer,
    },
  ],
})
export class RouterModule {}
