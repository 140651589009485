import { take as _take, sortBy as _sortBy } from "lodash-es";
import { Injectable } from "@angular/core";
import { Actions, createEffect } from "@ngrx/effects";
import { Router } from "@angular/router";
import { filter, map, distinctUntilKeyChanged } from "rxjs/operators";

import { notNil } from "@qqcw/qqsystem-util";

import { filterActions } from "src/app/shared/utilities/state/Operators";
import {
  clearFailedPromoCheck,
  clearPromoCode,
  getPromoDetails,
} from "./myqq.reducers";

import { setLocation, setUIPromoCode } from "../ui";

/**
 * This service contains chaining side effects. For example, when we get a profile we
 * might also want to get the persons vehicles and coupons from other endpoints without
 * explicitely chaining those calls in the ui. This service contains those cascading
 * events.
 */
@Injectable()
export class MyQQPromoChainEffects {
  setLocationOnGetPromoDetails$ = createEffect(() =>
    this.actions$.pipe(
      filter(getPromoDetails.success.match),
      map(
        ({
          value: {
            result: { location },
          },
        }) => location
      ),
      filter(notNil),
      map((location) => setLocation(location))
    )
  );

  removePromoOnPromoDetailsFailure$ = createEffect(() =>
    this.actions$.pipe(
      filterActions(getPromoDetails.failure),
      map(() => setUIPromoCode(null))
    )
  );

  getPromoDetailsOnSetUIPromo$ = createEffect(() =>
    this.actions$.pipe(
      filter(setUIPromoCode.match),
      distinctUntilKeyChanged("value"),
      map(({ value }) => getPromoDetails.pending(value))
    )
  );

  clearCheckedPromoOnClearPromoFromOrder$ = createEffect(() =>
    this.actions$.pipe(
      filter(clearPromoCode.match),
      map(() => clearFailedPromoCheck(null))
    )
  );

  constructor(readonly actions$: Actions, readonly router: Router) {}
}
