<div class="fld-col">
  <nav class="links">
    <a
      *ngFor="let link of links"
      class="footer-link"
      id="{{ link.id }}-link"
      attr.title="Go To {{ link.title }}"
      [href]="link.url"
      target="_blank"
      rel="noopener noreferrer"
      >{{ link.title }}</a
    >
  </nav>
  <ng-content></ng-content>

  <myqq-social
    class="social-links"
    [instagram]="'quickquackcarwash'"
    [facebook]="'quickquack'"
    [twitter]="'quickquack'"
    [youtube]="'QuickQuackCarWash'"
    [color]="'var(--qq-color-yellow)'"
  ></myqq-social>

  <p class="copyright-notice">
    {{ copyrightNotice }}
  </p>
</div>

<div class="fld-dsk-row badge" *ngIf="showBadge">
  <p class="ds-fl ai-ctr jc-ctr" id="download-app">
    Download the App:
  </p>
  <div class="ds-fl ai-ctr" id="app-badges">
    <a
      href="https://play.google.com/store/apps/details?id=com.dontdrivedirty.myqq.twa&referrer=utm_source%3Dwebsite%26utm_medium%3Dfooter&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
    >
      <img
        alt="Get it on Google Play"
        src="https://cdn.dontdrivedirty.com/myqq/img/play_store.svg"
        class="google"
    /></a>
    <a
      href="https://apps.apple.com/app/apple-store/id1618979301?pt=123851995&ct=Website%20footer%20app%20badge&mt=8"
    >
      <img
        src="https://cdn.dontdrivedirty.com/myqq/img/app_store.svg"
        alt="Download on the App Store"
        class="apple"
    /></a>
  </div>
</div>
