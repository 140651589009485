import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Vehicle } from "src/app/core/services/myqq";
import { AccountQuotaWithRemaining } from "src/app/shared/utilities/types";

@Component({
  selector: "myqq-edit-vehicle-initial",
  templateUrl: "./edit-vehicle-initial.component.html",
  styleUrls: ["./edit-vehicle-initial.component.scss"],
})
export class EditVehicleInitialComponent {
  @Input() vehicle: Vehicle;
  @Input() accountQuota: AccountQuotaWithRemaining;
  @Output() modifiedVehicle = new EventEmitter<Vehicle>();
  @Output() showDisclaimer = new EventEmitter();

  handleNext(vehicle: Vehicle) {
    this.modifiedVehicle.emit(vehicle);
  }
}
