import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatIconModule } from "@angular/material/icon";

import { SocialComponent } from "./social.component";

@NgModule({
  declarations: [SocialComponent],
  imports: [CommonModule, MatIconModule],
  exports: [SocialComponent],
})
export class SocialModule {}
