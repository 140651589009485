import { Component, Output, EventEmitter, OnDestroy } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { Store } from "@ngrx/store";

import { take, tap } from "rxjs/operators";

import {
  DatumEither,
  isFailure,
  isPending,
  isRefresh,
} from "@nll/datum/DatumEither";
import { parseISO } from "date-fns";
import { selectCheckPromo } from "src/app/core/ngrx/myqq";
import { Promo } from "src/app/core/services/myqq";
import { errors } from "src/app/core/services/myqq/myqq.errors";
import { promoMask } from "src/app/shared/utilities/ngx-mask-custom";

const DEFAULT_INVALID_MESSAGE = "This promo code is not valid.";
@Component({
  selector: "myqq-cart-promo-code",
  templateUrl: "./promo-code.component.html",
  styleUrls: ["./promo-code.component.scss"],
})
export class CartPromoCodeComponent implements OnDestroy {
  constructor(
    private readonly fb: FormBuilder,
    private readonly store$: Store<any>
  ) {}
  @Output()
  tryPromo = new EventEmitter<string>();
  @Output()
  clearPromoCode = new EventEmitter<null>();
  readonly promoMask = promoMask;
  readonly checkPromo$ = this.store$.select(selectCheckPromo).pipe(
    tap((p) => {
      if (isFailure(p)) {
        const errorMessage = (p.value?.left as any)?.error?.message;
        if (
          errorMessage === errors.myqqApi.errGetCoupon ||
          errors.myqqApi.errInvalidCoupon
        ) {
          // Display the failed promo code so the user knows it's invalid
          const promo = (p.value?.left as any)?.url
            .split("/")
            .pop()
            .toUpperCase();
          this.invalidMessage = `${promo} is not a valid code.`;
        } else {
          this.invalidMessage = DEFAULT_INVALID_MESSAGE;
        }
      }
    })
  );

  invalidMessage = DEFAULT_INVALID_MESSAGE;
  readonly discountForm = this.fb.group({
    promoCode: ["", [Validators.required]],
  });

  readonly isInProgress = (de: DatumEither<unknown, Promo>) =>
    isPending(de) || isRefresh(de);

  readonly isExpired = (promo: Promo) => parseISO(promo.expiresAt) < new Date();
  readonly isTooSoon = (promo: Promo) =>
    parseISO(promo.effectiveAt) > new Date();

  ngOnDestroy() {
    this.discountForm.reset();
    this.store$
      .select(selectCheckPromo)
      .pipe(take(1))
      .subscribe((p) => {
        if (isFailure(p)) {
          this.clearPromoCode.emit();
        }
      });
  }

  submitPromo({ promoCode }: Partial<{ promoCode: string | null }>) {
    this.tryPromo.emit(promoCode.toUpperCase());
  }
}
