<myqq-routed-modal>
  <span modal-header>Fix Failed Payment</span>

  <ng-container *ngIf="error$ | async">
    <myqq-error
      title="Payment Method Error"
      description="There was a problem updating your payment method."
      [error]="error"
    >
    </myqq-error>

    <p class="pwa-5">{{ error?.message }}</p>

    <button
      myqq-button
      id="add-button"
      class="large raised reset-btn"
      color="danger"
      (click)="handleReset()"
    >
      Try Again
    </button>

    <ng-template #stripeErrorFooter>
      <button
        myqq-button
        id="cancel-button"
        class="cancel-btn"
        (click)="handleCancel()"
      >
        Cancel
      </button>
    </ng-template>
  </ng-container>

  <ng-container
    [useDatumEither]="subscription$ | async"
    *ngIf="!(error$ | async)"
  >
    <myqq-loading *onInitial></myqq-loading>
    <myqq-loading *onPending></myqq-loading>
    <myqq-error
      *onFailure="let error"
      title="Error Finding Subscription"
      description="There was a problem loading your membership while setting up
      failed payment recovery."
      [error]="error"
    ></myqq-error>

    <ng-container *onSuccess="let data" [useOption]="data">
      <h3 *onNone>No Subscription</h3>
      <p *onNone>It doesn't look like you have a subscription to fix!</p>
      <ng-container
        *onSome="let subscription"
        [useDatumEither]="payFailedInvoice$ | async"
      >
        <ng-container *onInitial>
          <myqq-add-payment-method
            [description]="description"
            [overdueAmount]="subscription.invoiceAmount"
            #addPaymentMethodForm
          ></myqq-add-payment-method>
          <ng-template #initialFooter>
            <button
              myqq-button
              id="cancel-button"
              class="cancel-btn"
              (click)="handleCancel()"
            >
              Cancel
            </button>

            <button
              myqq-button
              id="add-button"
              color="primary"
              (click)="handleSubmit({card: addPaymentMethodForm.formValue,
              invoiceId: subscription.invoiceId })"
              [disabled]="!addPaymentMethodForm.valid || stripePending"
            >
              Add
            </button>
          </ng-template>
        </ng-container>
        <myqq-loading *onPending>
          <h2 class="ta-c">
            Updating payment method and paying invoice...
          </h2>
        </myqq-loading>

        <article
          class="fls-1-1 fld-col flg-7 ai-ctr pwa-5 ova-au"
          *onSuccess="let response; let refreshing= refreshing"
        >
          <h2 class="ta-c">Everything looks good!​</h2>

          <img
            src="{{ cdnHost }}/img/quackals-heart.png"
            alt="Quackals the duck with a heart"
            class="fls-1-1 as-ctr vh-4 heart-image"
          />
          <footer class="qq-buttons">
            <button mat-raised-button color="primary" (click)="goHome()">
              Go Home
            </button>
          </footer>
        </article>

        <ng-container *onFailure="let error">
          <myqq-error
            title="Payment Method Error"
            description="There was a problem updating your payment method"
            [error]="error"
          >
          </myqq-error>
          <p class="pwa-5 ta-c">
            {{ (error?.error?.error?.message ||
            error?.error?.error?.error?.message) | map: trimBlankErrorCode }}
          </p>
          <p class="pwa-5 ta-c">
            If you believe this is an error, please contact Customer Care at {{
            supportNumber | phone }}
          </p>
          <button
            myqq-button
            id="add-button"
            class="large raised reset-btn"
            color="danger"
            (click)="handleReset()"
          >
            Try Again
          </button>

          <ng-template #errorFooter>
            <button
              myqq-button
              id="cancel-button"
              class="cancel-btn"
              (click)="handleCancel()"
            >
              Cancel
            </button>
          </ng-template>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
  <div modal-footer class="qq-buttons">
    <ng-container *ngTemplateOutlet="(footerTemplate | async) || defaultFooter">
    </ng-container>

    <ng-template #defaultFooter></ng-template>
  </div>
</myqq-routed-modal>
