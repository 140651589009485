import { Component, Input } from "@angular/core";
import { SubscriptionPlan } from "src/app/core/services/myqq";
import { MoneyRepresentation } from "src/app/shared/utilities/types";

@Component({
  selector: "myqq-cart-plan-detail",
  templateUrl: "./cart-plan-detail.component.html",
  styleUrls: ["./cart-plan-detail.component.scss"],
})
export class CartPlanDetailComponent {
  @Input() readonly plan: SubscriptionPlan;
  @Input() readonly price: MoneyRepresentation;
  @Input() readonly priceOverride: MoneyRepresentation;
  @Input() readonly customOverride: string;
  @Input() readonly crossOutDefaultPrice: boolean;
}
