import { A11yModule } from "@angular/cdk/a11y";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { MyqqButton } from "./button.component";
import { MatIconModule } from "@angular/material/icon";
import { MyqqAnchor } from "./anchor.component";

@NgModule({
  imports: [A11yModule, CommonModule, MatIconModule],
  exports: [MyqqButton, MyqqAnchor],
  declarations: [MyqqButton, MyqqAnchor],
  providers: [],
})
export class MyqqButtonModule {}
