<myqq-routed-modal *ngIf="vehicleId" [useDatumEither]="vehicle$ | async">
  <span modal-header>
    Update Vehicle
  </span>

  <myqq-loading *onInitial>
    <h3>Initializing Vehicle...</h3>
  </myqq-loading>

  <myqq-loading *onPending>
    <h3>Loading Vehicle...</h3>
  </myqq-loading>

  <div
    *onSuccess="let vehicle; let refreshing= refreshing"
    class="vwcmx-rem0 fld-col jc-ctr"
    [useDatumEither]="editVehicle$ |
      async"
  >
    <myqq-loading *onPending>
      <h3>Updating Vehicle...</h3>
    </myqq-loading>
    <ng-container> </ng-container>
    <ng-container
      *onFailure="let error; let refreshing= refreshing"
      [ngSwitch]="error?.error?.message"
    >
      <myqq-banner
        *ngSwitchCase="errVehicleAlreadyOnAccount"
        (dismiss)="handleDismissErrorBanner()"
        message="You've already added that vehicle!"
      >
      </myqq-banner>
      <ng-container *ngSwitchCase="errVehicleOwnership">
        <myqq-banner
          *ngIf="!(hasMembership$ | async)"
          (dismiss)="handleDismissErrorBanner()"
          message="This vehicle's License/VIN already exists. Do you have a membership?"
        >
          <button
            myqq-button
            id="find-membership"
            class="find-membership raised"
            color="tertiary"
            (click)="handleRelink()"
            (keydown.enter)="$event.preventDefault()"
            (keyup.enter)="handleRelink()"
            fill="clear"
            type="button"
            aria-label="Find membership"
          >
            Find Membership
          </button>
        </myqq-banner>
        <myqq-banner
          *ngIf="hasMembership$ | async"
          message="There was an error updating your vehicle. Please review the information
    you provided and try again."
          (dismiss)="handleDismissErrorBanner()"
        >
        </myqq-banner>
      </ng-container>

      <myqq-banner
        *ngSwitchDefault
        message="There was an error updating your vehicle. Please review the information
      you provided and try again."
        (dismiss)="handleDismissErrorBanner()"
      >
      </myqq-banner>
    </ng-container>

    <div class="vehicle-edit-image ds-dsk-only ta-c">
      <evox-vehicle-image [vehicle]="vehicle"></evox-vehicle-image>
    </div>
    <p class="pwx-6 ds-mbl-only">
      Please provide your updated vehicle information
    </p>
    <myqq-vehicle-form
      #editVehicleForm
      [vehicle]="vehicle"
      [disableLicenseVin]="!vehicle.isTemporaryIdentifier"
    ></myqq-vehicle-form>
    <ng-template #footer>
      <button
        myqq-button
        class="cancel-btn"
        id="cancel"
        (click)="handleCancel()"
      >
        Cancel
      </button>

      <button
        myqq-button
        class="save-btn"
        color="primary"
        [disabled]="!editVehicleForm?.valid || refreshing"
        (click)="handleEditVehicle(editVehicleForm?.value)"
        id="save"
      >
        {{ refreshing ? "Saving…" : "Save" }}
      </button>
    </ng-template>
  </div>

  <myqq-error
    *onFailure="let error; let refreshing= refreshing"
    title="Vehicle Detail Error"
    description="There was an issue loading your vehicle details."
    [error]="error"
  >
    <footer>
      <button
        myqq-button
        class="large raised"
        color="danger"
        [disabled]="refreshing"
        (click)="handleGetVehicleData()"
      >
        {{ refreshing ? "Refreshing..." : "Refresh" }}
      </button>
    </footer>
  </myqq-error>

  <div modal-footer class="js-end qq-buttons">
    <ng-container *ngTemplateOutlet="footerTemplate | async"> </ng-container>
  </div>
</myqq-routed-modal>
