import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import {
  getSwapReasons,
  getVehicles,
  selectSwapMembership,
  selectSwapReasons,
  selectNonMemberVehicles,
  selectAccountQuota,
  getAccountQuota,
  clearSwapMembership,
  swapMembership,
  selectPriceTable,
  getPriceTable,
} from "src/app/core/ngrx/myqq";
import {
  OrderTypesEnum,
  RegionMenu,
  SwapReason,
  Vehicle,
  VehicleSwapRequest,
} from "src/app/core/services/myqq";
import { combineS } from "src/lib/datum-either";
import { Location } from "@angular/common";
import {
  selectVehicleSwapStep,
  setVehicleSwapStep,
  VehicleSwapSteps,
} from "src/app/core/ngrx/ui";
import { map, first } from "rxjs/operators";
import { map as mapDE, isSuccess } from "@nll/datum/DatumEither";
import { Subscription } from "rxjs";
import { GenericAlertComponent } from "src/app/shared/components/generic-alert/generic-alert.component";
import { MatDialog } from "@angular/material/dialog";
import { quotaDisclaimer } from "src/app/shared/constants";
import { getPlanMembershipBadgeColor } from "src/lib/util";

@Component({
  templateUrl: "./vehicle-swap.page.html",
})
export class VehicleSwapPage implements OnInit, OnDestroy {
  readonly currentVehicle: Vehicle = this.router.getCurrentNavigation()?.extras
    .state?.currentVehicle;
  readonly postVehicleSwap$ = this.store$.select(selectSwapMembership);
  readonly contextData$ = combineS({
    nonMembershipVehicles: this.store$.select(selectNonMemberVehicles),
    swapReasons: this.store$.select(selectSwapReasons),
    accountQuota: this.store$.select(selectAccountQuota).pipe(
      map(
        mapDE((data) => ({
          ...data,
          quotaRemaining: data.quotaAllowed - data.quotaConsumed,
        }))
      )
    ),
    menu: this.store$.select(selectPriceTable),
  });
  readonly currentStep$ = this.store$.select(selectVehicleSwapStep);
  readonly steps = VehicleSwapSteps;
  selectedNewVehicle: Vehicle;
  swapReason: SwapReason;
  private subs: Subscription[] = [];

  ngOnInit() {
    if (this.currentVehicle) {
      this.handleGetData();
    } else {
      this.router.navigateByUrl("/vehicles");
    }
  }
  ngOnDestroy(): void {
    this.store$.dispatch(clearSwapMembership(null));
    this.handleNext(this.steps.initial);
    this.subs?.forEach((s) => s.unsubscribe());
  }
  handleGetData() {
    this.store$.dispatch(getVehicles.pending(null));
    this.store$.dispatch(getSwapReasons.pending(null));
    this.store$.dispatch(getAccountQuota.pending(null));
    this.store$.dispatch(getPriceTable.pending(null));
  }

  handleAddVehicle() {
    this.router.navigate([{ outlets: { modal: "add-vehicle" } }]);
  }
  onSuccess() {
    this.store$.dispatch(clearSwapMembership(null));
    this.handleNext(this.steps.initial);
    this.router.navigate([{ outlets: { primary: "thank-you", modal: null } }], {
      state: {
        orderType: OrderTypesEnum.ADDMEMBERSHIP,
        showSubscriptionInfo: true,
      },
    });
  }
  handleSelectNewVehicle(vehicle: Vehicle) {
    this.selectedNewVehicle = vehicle;
  }
  handleSelectReason(reason: SwapReason) {
    this.swapReason = reason;
    this.handleNext(this.steps.confirm);
  }
  handleSubmit() {
    const swapMembershipRequest: VehicleSwapRequest = {
      currentVehicleId: this.currentVehicle.vehicleId,
      newVehicleId: this.selectedNewVehicle.vehicleId,
      reasonId: this.swapReason?.id,
    };
    this.store$.dispatch(swapMembership.pending(swapMembershipRequest));
    this.subs.push(
      this.postVehicleSwap$
        .pipe(first(isSuccess))
        .subscribe(() => this.onSuccess())
    );
  }
  handleNext(step: VehicleSwapSteps) {
    this.store$.dispatch(setVehicleSwapStep(step));
  }

  async handleShowDisclaimer() {
    const dialog = await this.dialog.open(GenericAlertComponent, {
      disableClose: false,
      data: {
        title: "Disclaimer",
        message: quotaDisclaimer,
      },
    });
    await dialog.afterClosed().toPromise();
  }
  readonly getMembershipBadgeColor = (
    vehicle: Vehicle,
    menu: RegionMenu
  ): string => {
    return getPlanMembershipBadgeColor(vehicle.washLevelId, menu.plans);
  };
  constructor(
    readonly store$: Store<any>,
    readonly location: Location,
    readonly router: Router,
    readonly dialog: MatDialog
  ) {}
}
