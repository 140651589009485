import { Pipe, PipeTransform } from "@angular/core";
import { Vehicle } from "src/app/core/services/myqq";

// todo: replace with actual enum
type StateCode = string;

export interface VehiclePlate {
  license?: string;
  state?: StateCode;
  vin?: string;
}

export interface OrderVehiclePlate {
  vehicleLicense?: string;
  vehicleState?: StateCode;
  vehicleVin?: string;
}

@Pipe({
  name: "displayVehiclePlate",
})
export class DisplayVehiclePlatePipe implements PipeTransform {
  transform(vehicleInfo: Vehicle): any {
    return displayVehiclePlate(vehicleInfo as VehiclePlate);
  }
}

// tslint:disable-next-line: max-classes-per-file
@Pipe({
  name: "displayOrderVehiclePlate",
})
export class DisplayOrderVehiclePlatePipe implements PipeTransform {
  transform(vehicleInfo: Vehicle): any {
    return displayVehiclePlate(vehicleInfo as VehiclePlate);
  }
}

export function displayVehiclePlate(
  vehicle: VehiclePlate & { vehicleKey?: string },
  { separator = " · " } = {}
): string {
  return vehicle?.state && vehicle?.license
    ? `${vehicle.state}${separator}${vehicle.license}`
    : vehicle?.vin
    ? `VIN${separator}${vehicle.vin}`
    : reformatVehicleKey(vehicle?.vehicleKey) || "";
}

export function displayOrderVehiclePlate(
  order: OrderVehiclePlate & { vehicleKey?: string },
  { separator = " · " } = {}
): string {
  return order?.vehicleState && order?.vehicleLicense
    ? `${order.vehicleState}${separator}${order.vehicleLicense}`
    : order?.vehicleVin
    ? `VIN${separator}${order.vehicleVin}`
    : reformatVehicleKey(order?.vehicleKey) || "";
}

export function reformatVehicleKey(
  vehicleKey: string,
  separator = " · "
): string {
  if (vehicleKey && vehicleKey.includes("-")) {
    return vehicleKey.replace(/-/gi, separator);
  } else {
    return vehicleKey;
  }
}
