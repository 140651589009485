import { ModuleWithProviders, NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";

import { LoggingEffects } from "./logging.effects";
import {
  LOGGING_CONFIG_PROVIDER,
  LOGGING_REDUCER_TOKEN,
  LoggingConfig,
  LOGGING_FEATURE_KEY,
} from "./logging.models";
import { loggingReducer } from "./logging.reducers";
import { LoggingService } from "./logging.service";
import { INIT_LOGGING_STATE } from "./logging.store";

export function getLoggingReducers() {
  return loggingReducer;
}

@NgModule({
  imports: [
    StoreModule.forFeature(LOGGING_FEATURE_KEY, LOGGING_REDUCER_TOKEN, {
      initialState: INIT_LOGGING_STATE,
    }),
    EffectsModule.forFeature([LoggingEffects]),
  ],
  providers: [LoggingService],
})
export class NgrxLoggingModule {
  public static forRoot(
    config: LoggingConfig
  ): ModuleWithProviders<NgrxLoggingModule> {
    return {
      ngModule: NgrxLoggingModule,
      providers: [
        {
          provide: LOGGING_CONFIG_PROVIDER,
          useValue: config,
        },
        {
          provide: LOGGING_REDUCER_TOKEN,
          useFactory: getLoggingReducers,
        },
      ],
    };
  }
}
