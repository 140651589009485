import { DatumEither, map, sequenceTuple } from "@nll/datum/DatumEither";

import { pipe } from "fp-ts/es6/pipeable";
import { Lens } from "./Optics";

/**
 * Lens within a datumEither
 */
export const mapLens = <E, D, O>(
  lens: Lens<D, O>
): Lens<DatumEither<E, D>, DatumEither<E, O>> =>
  new Lens(map(lens.get), (a) => (s) =>
    pipe(
      sequenceTuple(a, s),
      map(([o, d]) => lens.set(o)(d))
    )
  );
