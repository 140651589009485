import { Inject, Injectable } from "@angular/core";
import { Router, RouterStateSnapshot } from "@angular/router";
import { Store } from "@ngrx/store";
import { BehaviorSubject, from, fromEvent, merge, timer } from "rxjs";
import {
  distinctUntilChanged,
  filter,
  map,
  mapTo,
  skip,
  switchMap,
  take,
} from "rxjs/operators";

import jwt_decode from "jwt-decode";
import { KeycloakTokenParsed, KeycloakInstance } from "keycloak-js";
import { KeycloakService } from "keycloak-angular";

import { environment } from "src/environments/environment";
import { WINDOW } from "./window.service";
import { selectKeycloakInitialized } from "../ngrx/ui";

const refreshTokenKey = environment.refreshTokenKey;
type Status = "online" | "offline" | "login" | "logout";

@Injectable({
  providedIn: "root",
})
export class OfflineAuthService {
  private _online$: BehaviorSubject<boolean> = new BehaviorSubject(
    this.window?.navigator?.onLine
  );
  private _access$: BehaviorSubject<boolean> = new BehaviorSubject(null);
  private _auth$: BehaviorSubject<boolean> = new BehaviorSubject(null).pipe(
    skip(1)
  ) as BehaviorSubject<boolean>;
  state: RouterStateSnapshot;

  keycloakInstance: KeycloakInstance;

  private keycloakInitialized = true;

  constructor(
    private keycloak: KeycloakService,
    private router: Router,
    private store$: Store<any>,
    @Inject(WINDOW) private window: Window
  ) {
    merge(
      this.store$.select(selectKeycloakInitialized).pipe(
        filter((init) => init !== null),
        take(1)
      ),
      timer(5000).pipe(mapTo(false))
    )
      .pipe(take(1))
      .subscribe((init) => {
        this.keycloakInstance = keycloak.getKeycloakInstance();
        this.keycloakInitialized = init || !!this.keycloakInstance;
        if (this.keycloakInitialized) {
          this.afterGettingKeycloakInstance();
        } else {
          // handle rare case where keycloak is not fully initialized before this service is created.
          let timeout = 1;
          let retries = 0;
          const retryKeycloak = () =>
            setTimeout(() => {
              this.keycloakInstance = keycloak.getKeycloakInstance();
              if (this.keycloakInstance) {
                this.keycloakInitialized = true;
                this.afterGettingKeycloakInstance();
              } else if (retries++ < 3) {
                timeout *= 10;
                retryKeycloak();
              }
            }, timeout);

          retryKeycloak();
        }
      });
    this.state = this.router.routerState.snapshot;

    // Monitor online status
    merge(
      fromEvent(this.window, "online").pipe(mapTo(true)),
      fromEvent(this.window, "offline").pipe(mapTo(false))
    ).subscribe((online) => this._online$.next(online));

    // Monitor keycloak & online status changes
    merge(
      this.store$.select(selectKeycloakInitialized).pipe(
        filter((init) => init),
        switchMap(() =>
          from(this.keycloak.isLoggedIn()).pipe(
            map((loggedIn) => (loggedIn ? "login" : "logout"))
          )
        )
      ),

      this._auth$.pipe(
        distinctUntilChanged(),
        map((loggedIn) => (loggedIn ? "login" : "logout"))
      ),
      this._online$.pipe(
        skip(1),
        distinctUntilChanged(),
        map((online) => (online ? "online" : "offline"))
      )
    ).subscribe((status: Status) => this.handleAllowAccessStatusChange(status));
  }

  afterGettingKeycloakInstance() {
    if (this.keycloakInstance.authenticated) {
      this._auth$.next(true);
    }
    this.handleKeycloakCallbacks();
  }

  handleKeycloakCallbacks() {
    // onAuthRefreshError was already assigned in keycloak init
    // override but keep base function.
    const baseOnAuthRefreshError = this.keycloakInstance.onAuthRefreshError;
    this.keycloakInstance.onAuthRefreshError = () => {
      this.handleAuthRefreshFailure();

      return baseOnAuthRefreshError();
    };
    this.keycloakInstance.onAuthSuccess = () => this._auth$.next(true);
    this.keycloakInstance.onAuthError = () => this._auth$.next(false);
    this.keycloakInstance.onAuthLogout = () => this._auth$.next(false);
  }

  handleAllowAccessStatusChange(status: Status) {
    // When online status changes or a login/logout event happens, reset allowAccess$ accordingly`
    if (!this.keycloakInitialized) {
      // Handle the case where we skipped keycloak initialization because the app was offline
      this.attemptReauth();
    }
    if (status === "login") {
      this._access$.next(true);

      return;
    }
    this.keycloak
      .isLoggedIn()
      .then((loggedIn) => {
        if (loggedIn) {
          this._access$.next(true);

          return;
        }

        switch (status) {
          case "offline":
            this._access$.next(
              this.savedRefreshToken.exists &&
                !this.isRefreshTokenExpired(this.savedRefreshToken.parsed)
            );

            break;
          case "online":
            this.attemptReauth().then(() => {
              this.keycloak.isLoggedIn().then((li) => this._access$.next(li));
            });

            break;
          case "logout":
            this._access$.next(false);

            break;
        }
      })
      .catch((e) => {
        this._access$.next(
          !this._online$.value &&
            this.savedRefreshToken.exists &&
            !this.isRefreshTokenExpired(this.savedRefreshToken.parsed)
        );
      });
  }

  handleAuthRefreshFailure() {
    if (
      !this._online$.value &&
      this.keycloakInstance?.refreshToken &&
      !this.isRefreshTokenExpired(this.keycloakInstance?.refreshTokenParsed)
    ) {
      this.window.sessionStorage?.setItem?.(
        refreshTokenKey,
        this.keycloakInstance.refreshToken
      );
    }
  }

  get online$(): BehaviorSubject<boolean> {
    return this._online$;
  }

  get loggedIn(): Promise<boolean> {
    return this.keycloak.isLoggedIn().catch((_) => false);
  }

  get allowAccess$(): BehaviorSubject<boolean> {
    return this._access$;
  }

  get savedRefreshToken() {
    const rt: string = this.window?.sessionStorage?.getItem?.(refreshTokenKey);

    const ret: {
      exists: boolean;
      raw?: string;
      parsed?: KeycloakTokenParsed;
    } = { exists: !!rt };

    if (ret.exists) {
      ret.raw = rt;
      ret.parsed = jwt_decode(rt);
    }

    return ret;
  }

  async attemptReauth(): Promise<boolean> {
    if (this._online$.value || !this.keycloakInitialized) {
      const updated = await this.keycloak.updateToken(-1).catch(() => false);
      return !!updated;
    }

    return false;
  }

  isRefreshTokenExpired(parsedToken: KeycloakTokenParsed) {
    if (!parsedToken?.iat) {
      return true;
    }

    const issued = new Date(parsedToken.iat);
    const expires = new Date();
    expires.setDate(
      issued.getDate() + environment.keycloak.offlineTokenDuration
    );

    const expired = expires < new Date();

    if (expired) {
      this.window?.sessionStorage?.removeItem(refreshTokenKey);
    }

    return expired;
  }

  isSavedTokenValid() {
    return (
      this.savedRefreshToken.exists &&
      !this.isRefreshTokenExpired(this.savedRefreshToken.parsed)
    );
  }
}
