<main class="fld-col ai-ctr jc-ctr vh-p100">
  <myqq-error
    [title]="online.value ? state?.title : 'You are offline'"
    [description]="online.value ? state?.description : 'This operation requires access to the Internet. Please try again when you are online.'"
    [error]="state?.error"
    class="mwtr-p10"
  >
    <button
      myqq-button
      aria-label="Refresh myQQ"
      class="raised as-ctr"
      color="primary"
      (click)="handleRefresh()"
      (keyup.enter)="handleRefresh()"
      (keydown.enter)="$event.preventDefault()"
    >
      Refresh myQQ
    </button>
  </myqq-error>
</main>
