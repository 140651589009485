import { createFeatureSelector, createSelector } from "@ngrx/store";

import { uiFeatureKey } from "./ui.consts";
import { UIState } from "./ui.models";
import * as L from "./ui.lenses";

export const selectUIState = createFeatureSelector<UIState>(uiFeatureKey);

export const selectAccountSetupStep = createSelector(
  selectUIState,
  L.accountSetupStepLens.get
);

export const selectVehicleSwapStep = createSelector(
  selectUIState,
  L.vehicleSwapStepLens.get
);

export const selectCancelMembershipStep = createSelector(
  selectUIState,
  L.cancelMembershipStepLens.get
);

export const selectUpdateFlockPresaleVehicleStep = createSelector(
  selectUIState,
  L.updateFlockPresaleVehicleStepLens.get
);

export const selectSalesPipeline = createSelector(
  selectUIState,
  L.salesPipelineLens.get
);

export const selectExistingMembership = createSelector(
  selectUIState,
  L.existingMembershipLens.get
);

export const selectZipAttempt = createSelector(
  selectUIState,
  L.zipAttemptLens.get
);

export const selectKeycloakInitialized = createSelector(
  selectUIState,
  L.keycloakInitializedLens.get
);

export const selectLocation = createSelector(selectUIState, L.locationLens.get);

export const selectPromoCode = createSelector(selectUIState, L.promoCode.get);

export const selectDefaultPlans = createSelector(
  selectUIState,
  L.defaultPlans.get
);

export const selectDismissAddVehiclePromptInCart = createSelector(
  selectUIState,
  L.dismissAddVehiclePromptInCartLens.get
);
export const selectShowFlockPresaleModal = createSelector(
  selectUIState,
  L.showFlockPresaleModalLens.get
);

export const selectB2cSignUpDetails = createSelector(
  selectUIState,
  L.b2cSignUpDetailsLens.get
);
