<div class="fld-col flg-3 vh-p100">
  <button
    mat-mini-fab
    class="back-btn"
    color="primary"
    aria-label="Go back"
    (click)="back.emit()"
    (keydown.enter)="$event.preventDefault()"
    (keyup.enter)="back.emit()"
  >
    <mat-icon>arrow_back</mat-icon>
  </button>
  <p class="current-label">Current</p>
  <myqq-vehicle-display
    [vehicle]="currentVehicle"
    [membershipBadgeColor]="membershipBadgeColor"
  >
  </myqq-vehicle-display>

  <mat-icon class="as-ctr">arrow_downward </mat-icon>

  <span class="new-label"> New </span>
  <myqq-vehicle-display
    [vehicle]="newVehicle"
    [membershipBadgeColor]="membershipBadgeColor"
  >
  </myqq-vehicle-display>
  <myqq-account-message-quota
    *ngIf="
      currentVehicle.hasMembership &&
        showQuota &&
        !currentVehicle.isTemporaryIdentifier;
      else noquota
    "
    [accountQuota]="accountQuota"
    [action]="'confirm'"
    (showDisclaimer)="showDisclaimer.emit()"
  >
  </myqq-account-message-quota>

  <footer>
    <button
      myqq-button
      color="primary"
      class="raised as-ctr"
      aria-label="Confirm"
      (click)="submit.emit()"
      (keydown.enter)="$event.preventDefault()"
      (keyup.enter)="submit.emit()"
    >
      Confirm
    </button>
  </footer>
</div>

<ng-template #noquota>
  <div class="fls-1-1 ta-c pwt-6">
    <p *ngIf="!currentVehicle.isTemporaryIdentifier">
      This change will not consume your allotted changes.
    </p>
  </div>
</ng-template>
