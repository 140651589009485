<input
  class="native-input"
  [disabled]="disabled && !readonly"
  inputmode="numeric"
  matInput
  [name]="name"
  (blur)="updateValue()"
  (keyup)="updateValueOnKeyup()"
  pattern="[0-9]*"
  [placeholder]="placeholder"
  [readonly]="readonly"
  [required]="required"
  [mask]="dateMask.mask"
  [patterns]="dateMask.customPattern"
  #theNativeInput
  type="text"
/>
<mat-icon
  class="close"
  color="medium"
  [hidden]="!theNativeInput.value"
  *ngIf="!disabled && !readonly"
  (click)="reset()"
>
  close
</mat-icon>

<mat-hint color="danger" *ngIf="invalidDate">
  This is not a valid date
</mat-hint>

<mat-hint color="danger" *ngIf="max && value && value > max">
  Date must be before {{ max | date: "MM/dd/yyyy" }}
</mat-hint>

<mat-hint color="danger" *ngIf="min && value && value < min">
  Date must be after {{ min | date: "MM/dd/yyyy" }}
</mat-hint>

<div class="external-hint" *ngIf="hintsTemplate">
  <ng-template
    [ngTemplateOutlet]="hintsTemplate"
    [ngTemplateOutletContext]="{ $implicit: isValid(value) ? value : null }"
  ></ng-template>
</div>
