import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "phone",
})
export class PhonePipe implements PipeTransform {
  transform(phoneNumber: string): unknown {
    let ph = phoneNumber?.replace(/[^\d]/g, "");

    if (ph.charAt(0) === "1") {
      ph = ph.slice(1);
    }

    if (ph.length === 10) {
      ph = "(" + ph.slice(0, 3) + ") " + ph.slice(3, 6) + "-" + ph.slice(6, 10);
    } else if (ph.length === 7) {
      ph = ph.slice(3, 5) + "-" + ph.slice(6, 10);
    } else {
      ph = phoneNumber;
    }

    return ph;
  }
}
