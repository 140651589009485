import { notNil, RequireSome } from "@qqcw/qqsystem-util";
import { version } from "src/environments/version";

import {
  Action,
  Success,
  Failure,
} from "src/app/shared/utilities/state/Actions";

import { log } from "./logging.actions";
import {
  ActionType,
  LoggingConfig,
  LogItem,
  LogLevels,
} from "./logging.models";

/**
 * Serialization Guards and Helpers
 */
const isObject = (o: unknown): o is {} => notNil(o) && typeof o === "object";

const isSuccessAction = (
  a: Action<unknown>
): a is Action<Success<unknown, unknown>> =>
  isObject(a) &&
  a.hasOwnProperty("value") &&
  isObject(a.value) &&
  a.value.hasOwnProperty("params") &&
  a.value.hasOwnProperty("result");

const isFailureAction = (
  a: Action<unknown>
): a is Action<Failure<unknown, unknown>> =>
  isObject(a.value) &&
  a.value.hasOwnProperty("params") &&
  a.value.hasOwnProperty("error");

const serializeActionData = (a: Action<unknown>) => {
  if (isSuccessAction(a)) {
    return {
      params: a.value.params,
      result: a.value.result,
      meta: a.meta,
    };
  }

  if (isFailureAction(a)) {
    return {
      params: a.value.params,
      error: a.value.error,
      meta: a.meta,
    };
  }

  return {
    params: a.value,
    meta: a.meta,
  };
};

const splitActionType = (a: Action<unknown>): ActionType => {
  const split = a.type.split("/");
  switch (split.length) {
    case 0:
      return { name: "unknown" };
    case 1:
      return { name: split[0] };
    case 2:
      return { name: split[1], namespace: split[0] };
    case 3:
      return { name: split[1], namespace: split[0], type: split[2] };
    default:
      return { name: a.type };
  }
};

export function defaultActionSerializer(a: Action<unknown>): LogItem {
  if (log.match(a)) {
    return {
      level: LogLevels.TRACE,
      timestamp: new Date().valueOf(),
      actionType: splitActionType(a),
      component: "myqq",
      data: undefined,
      ...version,
      ...a.value, // Payload is Partial<LogItem> and it overrides defaults
    };
  }

  return {
    level: a.error ? LogLevels.WARNING : LogLevels.TRACE,
    timestamp: new Date().valueOf(),
    message: a.type,
    component: "myqq",
    actionType: splitActionType(a),
    data: serializeActionData(a),
    ...version,
  };
}

export const DEFAULT_LOGGING_CONFIG: RequireSome<
  LoggingConfig,
  "sendInterval" | "throttleSend"
> = {
  sendInterval: 10 * 1000,
  throttleSend: 5 * 1000,
};
