import { Component, EventEmitter, Input, Output } from "@angular/core";

import { Location } from "@angular/common";
import { RouteHistoryService } from "../../services/route-history.service";
import { Router } from "@angular/router";

/**
 * This is a modal component intended to be used with the `modal` route outlet.
 */
@Component({
  selector: "myqq-routed-modal",
  exportAs: "modal",
  templateUrl: "./routed-modal.component.html",
  styleUrls: ["./routed-modal.component.scss"],
})
export class RoutedModalComponent<T = unknown> {
  constructor(
    readonly location: Location,
    readonly routeHistoryService: RouteHistoryService,
    readonly router: Router
  ) {}

  @Input() hideFooter = false;
  @Input() overrideCloseModal = false;

  @Output() modalClose: EventEmitter<T> = new EventEmitter<T>();

  closeModal($event?: T) {
    if (!this.overrideCloseModal) {
      // when the user first loaded page is a modal, previousUrl does not exist. just go home
      if (!!this.routeHistoryService.getPreviousUrl) {
        this.location.back();
      } else {
        this.router.navigateByUrl("");
      }
    } else {
      this.modalClose.next($event);
    }
  }

  articleClick($event: MouseEvent) {
    $event.stopImmediatePropagation();
  }
}
