import { NgModule } from "@angular/core";
import {
  MatDialog,
  MatDialogModule,
  MAT_DIALOG_DEFAULT_OPTIONS,
} from "@angular/material/dialog";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { environment } from "src/environments/environment";
import { EvoxImageModule } from "./shared/modules/evox/evox.module";

// Additional Icons
const iconNames = [
  "facebook",
  "instagram",
  "snapchat",
  "twitter",
  "youtube",
  "ios-add-btn",
  "ios-menu-btn",
  "mainlogo",
  "exit",
];

/**
 * This module is meant to have default configurations for external
 * libraries.
 */
@NgModule({
  imports: [
    MatDialogModule,
    EvoxImageModule.forRoot({
      url: `${environment.URLs.MyQQ}/images/vehicle`,
      fallbackImage: `${environment.cdnHost}/img/vehicle-fallback.png`,
    }),
  ],
  exports: [],
  declarations: [],
  providers: [
    MatDialog,
    // Default settings for material dialog
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        width: "100%",
        maxWidth: "500px",
        minWidth: "300px",
        height: "auto",
        maxHeight: "800px",
        hasBackdrop: true,
        closeOnNavigation: true,
      },
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { floatLabel: "always", appearance: "outline" },
    },
  ],
})
export class AppUIConfigModule {
  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    iconNames.forEach((iconName) =>
      iconRegistry.addSvgIcon(
        iconName,
        sanitizer.bypassSecurityTrustResourceUrl(`assets/icon/${iconName}.svg`)
      )
    );
  }
}
