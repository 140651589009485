import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";

import { Observable, of } from "rxjs";
import { WINDOW } from "../../services/window.service";

import {
  BatchLogRequest,
  BatchLogResponse,
  LOGGING_CONFIG_PROVIDER,
  LoggingConfig,
} from "./logging.models";

@Injectable()
export class LoggingService {
  constructor(
    private http: HttpClient,
    @Inject(WINDOW) private window: Window,
    @Inject(LOGGING_CONFIG_PROVIDER) private config: LoggingConfig
  ) {}

  sendLogs({ logs }: BatchLogRequest): Observable<BatchLogResponse> {
    return !this.config.disableLogging &&
      this.window?.navigator?.onLine &&
      logs.length > 0
      ? this.http.post<BatchLogResponse>(`${this.config.apiUrl}/log`, {
          entries: logs,
        })
      : of({});
  }
}
