import { identity } from "fp-ts/es6/function";
import { MetaReducer } from "@ngrx/store";

function isErrorAction(t: unknown): t is { error: true } {
  return (<any>t)?.error === true;
}

function hasErrorMessage(t: {
  error: true;
}): t is { error: true; value: { error: string } } {
  return typeof (<any>t)?.value?.error === "string";
}

export const logger = <S>(key = "SHOW_LOG"): MetaReducer<S> => {
  const showLogs = window.localStorage.getItem(key) === "true";
  if (!showLogs) {
    return identity;
  }
  return (reducer) => {
    return (state, action) => {
      const newState = reducer(state, action);

      console.groupCollapsed(action.type);
      if (isErrorAction(action)) {
        if (hasErrorMessage(action)) {
          console.error(action.value.error);
        }
        console.log("Error Action", action);
      } else {
        console.log("Action", action);
      }
      console.log("State Before", state);
      console.log("State After", newState);
      console.groupEnd();

      return newState;
    };
  };
};
