import * as O from "fp-ts/es6/Option";
import { v4 as uuid } from "uuid";

import { actionCreatorFactory } from "src/app/shared/utilities/state/Actions";
import * as DE from "@nll/datum/DatumEither";
import * as MS from "src/app/core/services/myqq";
import {
  asyncEntityFactory,
  asyncReducerFactory,
  caseFn,
  reducerDefaultFn,
} from "src/app/shared/utilities/state/Reducers";

import {
  MyQQState,
  AddFlockOrder,
  RemoveFlockOrder,
  ChangeMembershipOrder,
  AddMembershipOrder,
} from "./myqq.models";
import * as LS from "./myqq.lenses";
import { notNil, RequireSome } from "@qqcw/qqsystem-util";
import { ResultsPage } from "src/app/shared/utilities/types";

export const myqqFeatureKey = "MYQQ";

export const myqqAction = actionCreatorFactory(myqqFeatureKey);

export const INITIAL_STATE: MyQQState = {
  initialLoad: true,
  accountInfo: DE.initial,
  profile: DE.initial,
  stores: DE.initial,
  regions: DE.initial,
  subscription: DE.initial,
  orders: DE.initial,
  paymentMethods: DE.initial,
  priceTable: DE.initial,

  postPaymentMethod: DE.initial,
  payInvoice: DE.initial,

  getVehicles: DE.initial,
  addVehicle: DE.initial,
  editVehicle: DE.initial,
  removeVehicle: DE.initial,

  accountLookupResponse: DE.initial,
  accountLinkResponse: DE.initial,

  newAccount: DE.initial,
  updateAccount: DE.initial,

  getOrder: {},

  pendingOrder: O.none,
  calculateOrder: DE.initial,
  submitOrder: DE.initial,
  checkPromo: DE.initial,
  modifySubscriptionPlan: null,
  membershipPurchase: O.none,

  promoDetails: DE.initial,
  companyWidePromoDetails: DE.initial,
  accountQuota: DE.initial,
  swapReasons: DE.initial,
  swapMembership: DE.initial,
  editVehicleIdentifier: DE.initial,
  cancelMembership: DE.initial,
  checkEligibilityForRetentionOffer: DE.initial,
  acceptRetentionOffer: DE.initial,
  cancelReasons: DE.initial,

  appMinVersion: DE.initial,
  vehiclesOnOrder: [],
  marketingContent: DE.initial,
  b2bBenefit: DE.initial,
  linkB2C: DE.initial,
};
/**
 * Track if this is the first route load. Used to handle redirects by InitialRouteGuard
 */
export const setInitialLoad = myqqAction.simple("SET_INITIAL_LOAD_FLAG");
const setInitialLoadReducer = caseFn(
  setInitialLoad,
  LS.initialLoadLens.set(false)
);
/**
 * Get AccountInfo
 */
export const getAccountInfo = myqqAction.async<null, MS.AccountInfoResponse>(
  "GET_ACCOUNT_INFO"
);
const getAccountInfoReducer = asyncReducerFactory(
  getAccountInfo,
  LS.accountInfoLens
);

export const clearAccountInfo = myqqAction.simple<null, MS.AccountInfoResponse>(
  "CLEAR_ACCOUNT_INFO"
);

const clearAccountInfoReducer = caseFn(
  clearAccountInfo,
  LS.accountInfoLens.set(DE.initial)
);

/**
 * Get Profile
 */
export const getProfile = myqqAction.async<
  { track: boolean } | null,
  MS.GetProfileResponse
>("GET_PROFILE");
const getProfileReducer = asyncReducerFactory(getProfile, LS.profileLens);

export const clearProfile = myqqAction.simple("CLEAR_PROFILE");
const clearProfileReducer = caseFn(
  clearProfile,
  LS.accountLens.set(DE.initial)
);

/**
 * New Account
 */
export const newAccount = myqqAction.async<MS.Account, MS.Account>(
  "NEW_ACCOUNT"
);
const newAccountReducer = asyncReducerFactory(newAccount, LS.newAccountLens);

export const clearNewAccount = myqqAction.simple("CLEAR_NEW_ACCOUNT");
const clearNewAccountReducer = caseFn(
  clearNewAccount,
  LS.newAccountLens.set(DE.initial)
);

/**
 * Update Account
 */
export const updateAccount = myqqAction.async<MS.Account, MS.Account>(
  "UPDATE_ACCOUNT"
);
const updateAccountReducer = asyncReducerFactory(
  updateAccount,
  LS.updateAccountLens
);

export const clearUpdateAccount = myqqAction.simple("CLEAR_UPDATE_ACCOUNT");
const clearUpdateAccountReducer = caseFn(
  clearUpdateAccount,
  LS.updateAccountLens.set(DE.initial)
);

/**
 * Get All Regions
 */
export const getAllRegions = myqqAction.async<null, MS.Region[]>(
  "GET_ALL_REGIONS"
);
const getAllRegionsReducer = asyncReducerFactory(getAllRegions, LS.regionsLens);

/**
 * Add Vehicle
 */
export const getVehicles = myqqAction.async<null, MS.Vehicle[]>("GET_VEHICLES");
const getVehiclesReducer = asyncReducerFactory(getVehicles, LS.getVehiclesLens);

export const addVehicle = myqqAction.async<Partial<MS.Vehicle>, MS.Vehicle>(
  "ADD_VEHICLE"
);
const addVehicleReducer = asyncReducerFactory(addVehicle, LS.addVehicleLens);
// Adds vehicle to vehicles cache on add success (instead of chaining another call)
// const addVehicleSuccessReducer = caseFn(
//   addVehicle.success,
//   (state: MyQQState, { value: { result } }) =>
//     LS.vehiclesLens.modify(DE.map((vs) => vs.concat(result)))(state)
// );
export const clearAddVehicle = myqqAction.simple("CLEAR_ADD_VEHICLE");
const clearAddVehicleReducer = caseFn(
  clearAddVehicle,
  LS.addVehicleLens.set(DE.initial)
);
/**
 * Edit Vehicle
 */
export const editVehicle = myqqAction.async<MS.Vehicle, MS.Vehicle>(
  "EDIT_VEHICLE"
);
const editVehicleReducer = asyncReducerFactory(editVehicle, LS.editVehicleLens);

export const clearEditVehicle = myqqAction.simple("CLEAR_EDIT_VEHICLE");
const clearEditVehicleReducer = caseFn(
  clearEditVehicle,
  LS.editVehicleLens.set(DE.initial)
);

/**
 * Remove Vehicle
 */
export const removeVehicle = myqqAction.async<MS.Vehicle, unknown>(
  "REMOVE_VEHICLE"
);
const removeVehicleReduce = asyncReducerFactory(
  removeVehicle,
  LS.removeVehicleLens
);

/**
 * Clear post/put calls for vehicle
 */
export const clearVehiclePostPut = myqqAction.simple("CLEAR_VEHICLE_POST_PUT");
const clearVehiclePostPutReducer = caseFn(
  clearVehiclePostPut,
  (state: MyQQState) => ({
    ...state,
    addVehicle: DE.initial,
    editVehicle: DE.initial,
  })
);

/**
 * Get Orders By Person Id
 */
export const getMyOrders = myqqAction.async<ResultsPage, MS.OrderSearchResult>(
  "GET_ORDERS_BY_PERSON_ID"
);
const getMyOrdersReducer = asyncReducerFactory(getMyOrders, LS.ordersLens);

/**
 * Get Order
 */
export const getOrderById = myqqAction.async<string, MS.Order>("GET_ORDER");
const getOrderByIdReducer = asyncEntityFactory(
  getOrderById,
  LS.getOrderByIdLens,
  LS.getOrderIdLens
);

/**
 * Get Payment Methods By Person Id
 */
export const getMyPaymentMethods = myqqAction.async<
  void,
  MS.CustomerPaymentMethod[]
>("GET_PAYMENT_METHODS");
const getMyPaymentMethodsReducer = asyncReducerFactory(
  getMyPaymentMethods,
  LS.paymentMethodsLens
);

/**
 * Account Linking Calls
 */
export const accountLookup = myqqAction.async<
  MS.AccountCheckRequest,
  MS.AccountCheckResponse
>("ACCOUNT_LOOKUP");
const accountLookupReducer = asyncReducerFactory(
  accountLookup,
  LS.accountLookupLens
);

export const accountLookupLast4Plate = myqqAction.async<
  MS.AccountCheckLast4PlateRequest,
  MS.AccountCheckResponse
>("ACCOUNT_LOOKUP_LAST4");
const accountLookupLast4PlateReducer = asyncReducerFactory(
  accountLookupLast4Plate,
  LS.accountLookupLens
);

export const clearAccountLookup = myqqAction.simple("CLEAR_ACCOUNT_LOOKUP");
const clearAccountLookupReducer = caseFn(
  clearAccountLookup,
  LS.accountLookupLens.set(DE.initial)
);

export const accountRelink = myqqAction.async<string, MS.GetProfileResponse>(
  "ACCOUNT_RELINK"
);
const accountRelinkReducer = asyncReducerFactory(
  accountRelink,
  LS.linkAccountLens
);

export const accountLink = myqqAction.async<string, MS.GetProfileResponse>(
  "ACCOUNT_LINK"
);
const accountLinkReducer = asyncReducerFactory(accountLink, LS.linkAccountLens);

export const getAllStores = myqqAction.async<null, MS.Store[]>(
  "GET_ALL_STORES"
);
const getAllStoresReducer = asyncReducerFactory(getAllStores, LS.storesLens);

/**
 * Pricing Endpoint
 */
export const getPriceTable = myqqAction.async<null, MS.RegionMenu>(
  "PRICE_TABLE_LOOKUP"
);
const getPriceTableReducer = asyncReducerFactory(
  getPriceTable,
  LS.priceTableLens
);

export const getPriceTableByZip = myqqAction.async<string, MS.RegionMenu>(
  "PRICE_TABLE_LOOKUP_BY_ZIP"
);
const getPriceTableByZipReducer = asyncReducerFactory(
  getPriceTableByZip,
  LS.priceTableLens
);

export const clearPriceTable = myqqAction.simple("CLEAR_PRICE_TABLE_LOOKUP");
const clearPriceTableByZipReducer = caseFn(
  clearPriceTable,
  LS.priceTableLens.set(DE.initial)
);

/**
 * Order Calculate
 */
export const calculateOrder = myqqAction.async<
  MS.PostCalculateOrderRequest,
  MS.PostCalculateOrderResponse
>("CALCULATE_ORDER");
const calculateOrderReducer = asyncReducerFactory(
  calculateOrder,
  LS.calculateOrderLens
);

/**
 * Order Submt
 */
export const submitOrder = myqqAction.async<
  MS.PostSubmitOrderRequest,
  MS.PostSubmitOrderResponse
>("SUBMIT_ORDER");
const submitOrderReducer = asyncReducerFactory(submitOrder, LS.submitOrderLens);

/**
 * Clear submit order
 */
export const clearSubmitOrder = myqqAction.simple("CLEAR_SUBMIT_ORDER");
const clearSubmitOrderReducer = caseFn(
  clearSubmitOrder,
  LS.submitOrderLens.set(DE.initial)
);

/**
 * Clear submit order and generate newOrderId for new order
 */
export const clearSubmitOrderAndGenerateNewOrderId = myqqAction.simple(
  "CLEAR_SUBMIT_ORDER_GENERATE_NEW_ID"
);
const clearSubmitOrderAndGenerateNewOrderIdReducer = caseFn(
  clearSubmitOrderAndGenerateNewOrderId,
  (s: MyQQState) => ({
    ...s,
    pendingOrder: O.isSome(s.pendingOrder)
      ? O.some({ ...s.pendingOrder.value, orderId: uuid() })
      : O.none,
    submitOrder: DE.initial,
  })
);

/**
 * Clear Order Slices
 */
export const clearOrderProgress = myqqAction.simple("CLEAR_ORDER");
const clearOrderProgressReducer = caseFn(
  clearOrderProgress,
  (s: MyQQState) => ({
    ...s,
    pendingOrder: O.none,
    calculateOrder: DE.initial,
    submitOrder: DE.initial,
  })
);

/**
 * Get Subscriptions By Person Id
 */
export const getMySubscriptions = myqqAction.async<
  void,
  MS.GetSubscriptionResponse
>("GET_SUBSCRIPTION");
const getMySubscriptionReducer = asyncReducerFactory(
  getMySubscriptions,
  LS.subscriptionLens
);
// This is used to clear subscription for return to membership after purchase
export const clearMySubscription = myqqAction.simple("CLEAR_SUBSCRIPTION");
const clearMySubscriptionReducer = caseFn(
  clearMySubscription,
  LS.subscriptionLens.set(DE.initial)
);

/**
 * Add Payment Method
 */
export const postPaymentMethod = myqqAction.async<
  string,
  [MS.CustomerPaymentMethod]
>("POST_PAYMENT_METHOD");
const postPaymentMethodReducer = asyncReducerFactory(
  postPaymentMethod,
  LS.postPaymentMethodLens
);

export const clearPostPaymentMethod = myqqAction.simple(
  "CLEAR_POST_PAYMENT_METHOD"
);
const clearPostPaymentMethodReducer = caseFn(
  clearPostPaymentMethod,
  LS.postPaymentMethodLens.set(DE.initial)
);

/**
 * Pending Order actions
 *
 * Helper actions for set/clear pending Order, Promo Code, and Payment Method
 */
export const setPendingOrder = myqqAction.simple<
  RequireSome<MS.PostCalculateOrderRequest, "items" | "orderType">
>("SET_PENDING_ORDER");
const setPendingOrderReducer = caseFn(
  setPendingOrder,
  (s: MyQQState, { value }) =>
    // Populate pending order with a new orderId (if necessary) and a customerId if we have one
    LS.pendingOrderLens.set(
      O.some({
        ...value,
        submissionAt: new Date().toISOString(), // TODO REMOVE
        orderId: notNil(value.orderId) ? value.orderId : uuid(),
        customerId: DE.isSuccess(s.profile)
          ? s.profile.value.right.membership?.account.personId
          : DE.isSuccess(s.accountInfo)
          ? s.accountInfo.value.right.profile.info.qsysAccount
          : null,
      })
    )(s)
);

export const clearPendingOrder = myqqAction.simple("CLEAR_PENDING_ORDER");
const clearPendingOrderReducer = caseFn(
  clearPendingOrder,
  LS.pendingOrderLens.set(O.none)
);

/**
 * Pending Order actions
 *
 * Helper actions for set/clear pending Order, Promo Code, and Payment Method
 */
export const setModifySubscriptionPlan = myqqAction.simple<MS.SubscriptionPlan>(
  "SET_MODIFY_SUBSCRIPTION_TYPE"
);
const setModifySubscriptionPlanReducer = caseFn(
  setModifySubscriptionPlan,
  (currentState: MyQQState, { value }) =>
    LS.modifySubscriptionPlanLens.set(value)(currentState)
);

export const clearModifySubscriptionPlan = myqqAction.simple(
  "CLEAR_MODIFY_SUBSCRIPTION_TYPE"
);
const clearModifySubscriptionPlanReducer = caseFn(
  clearModifySubscriptionPlan,
  LS.modifySubscriptionPlanLens.set(null)
);

export const setMembershipPurchase = myqqAction.simple<
  O.Option<MS.MembershipPurchase>
>("SET_MEMBERSHIP_PURCHASE");
const setMembershipPurchaseReducer = caseFn(
  setMembershipPurchase,
  (currentState: MyQQState, { value }) =>
    LS.membershipPurchaseLens.set(value)(currentState)
);

export const clearMembershipPurchase = myqqAction.simple(
  "CLEAR_MEMBERSHIP_PURCHASE"
);
const clearMembershipPurchaseReducer = caseFn(
  clearMembershipPurchase,
  LS.membershipPurchaseLens.set(O.none)
);

export const setPaymentMethod = myqqAction.simple<MS.OrderPayment>(
  "SET_PAYMENT_METHOD"
);
const setPaymentMethodReducer = caseFn(
  setPaymentMethod,
  (s: MyQQState, { value }) =>
    LS.pendingOrderLens.modify(
      O.map((order) => ({ ...order, payments: [value] }))
    )(s)
);

export const clearPaymentMethod = myqqAction.simple("CLEAR_PAYMENT_METHOD");
const clearPaymentMethodReducer = caseFn(
  clearPaymentMethod,
  LS.pendingOrderLens.modify(O.map((order) => ({ ...order, payments: [] })))
);

export const setPendingOrderPromoCode = myqqAction.simple<
  Partial<MS.OrderMarkDown>
>("SET_PENDING_ORDER_PROMO_CODE");
const setPendingPromoCodeReducer = caseFn(
  setPendingOrderPromoCode,
  (s: MyQQState, { value }) =>
    LS.pendingOrderLens.modify(
      O.map((order) => ({ ...order, markDowns: [value] }))
    )(s)
);

export const clearPromoCode = myqqAction.simple("CLEAR_PROMO_CODE");
const clearPromoCodeReducer = caseFn(
  clearPromoCode,
  LS.pendingOrderLens.modify(
    O.map((order) => ({ ...order, waiveUpgradeFee: false, markDowns: [] }))
  )
);

export const setOrderReason = myqqAction.simple<string>("SET_ORDER_REASON");
const setOrderReasonReducer = caseFn(
  setOrderReason,
  (s: MyQQState, { value }) =>
    LS.pendingOrderLens.modify(
      O.map((order) => ({ ...order, createReasonCode: value }))
    )(s)
);

/**
 * Order Builder Actions
 * These actions are chained to setPendingOrder actions via chain effects
 * in myqq.chains.ts
 */
export const addFlockOrder = myqqAction.simple<AddFlockOrder>("ADD_FLOCK");
export const removeFlockOrder = myqqAction.simple<RemoveFlockOrder>(
  "REMOVE_FLOCK"
);
export const changeMembershipOrder = myqqAction.simple<ChangeMembershipOrder>(
  "CHANGE_MEMBERSHIP"
);
export const addMembershipOrder = myqqAction.simple<AddMembershipOrder>(
  "ADD_MEMBERSHIP"
);

/**
 * Promo code check
 */
export const checkPromoCode = myqqAction.async<string, MS.Promo>(
  "CHECK_PROMO_CODE"
);
const checkPromoCodeReducer = asyncReducerFactory(
  checkPromoCode,
  LS.checkPromoCodeLens
);

/**
 * Pay Invoice
 */
export interface PayFailedInvoice {
  paymentMethodId: string;
  invoiceId: string;
}

export const payFailedInvoice = myqqAction.async<
  PayFailedInvoice,
  MS.PayInvoiceResponse
>("PAY_FAILED_INVOICE");
const payInvoiceReducer = asyncReducerFactory(
  payFailedInvoice,
  LS.payInvoiceLens
);

export const clearPayFailedInvoice = myqqAction.simple(
  "CLEAR_PAY_FAILED_INVOICE"
);
const clearPayFailedInvoiceReducer = caseFn(
  clearPayFailedInvoice,
  LS.payInvoiceLens.set(DE.initial)
);

export const getPromoDetails = myqqAction.async<string, MS.PromoDetails>(
  "PROMO_DETAILS"
);
const getPromoDetailsReducer = asyncReducerFactory(
  getPromoDetails,
  LS.promoDetailsLens
);

export const getCompanyWidePromoDetails = myqqAction.async<
  null,
  MS.PromoDetails
>("COMPANY_WIDE_PROMO_CODE");
const getCompanyWidePromoDetailsReducer = asyncReducerFactory(
  getCompanyWidePromoDetails,
  LS.companyWidePromoDetailsLens
);

export const emptyPromoDetails = myqqAction.simple("CLEAR_PROMO_DETAILS");
const emptyPromoDetailsReducer = caseFn(
  emptyPromoDetails,
  LS.promoDetailsLens.set(DE.success({} as MS.PromoDetails))
);

export const clearFailedPromoCheck = myqqAction.simple(
  "CLEAR_FAILED_PROMO_CHECK"
);
const clearFailedPromoCheckReducer = caseFn(
  clearFailedPromoCheck,
  LS.checkPromoCodeLens.set(DE.initial)
);

/**
 * Swap membership or edit license plate or VIN
 */
export const getAccountQuota = myqqAction.async<null, MS.AccountQuota>(
  "GET_ACCOUNT_QUOTA"
);
const getAccountQuotaReducer = asyncReducerFactory(
  getAccountQuota,
  LS.accountQuotaLens
);

export const clearAccountQuota = myqqAction.simple("CLEAR_ACCOUNT_QUOTA");
const clearAccountQuotaReducer = caseFn(
  clearAccountQuota,
  LS.accountQuotaLens.set(DE.initial)
);

export const getSwapReasons = myqqAction.async<null, MS.SwapReason[]>(
  "GET_SWAP_REASONS"
);
const getSwapReasonsReducer = asyncReducerFactory(
  getSwapReasons,
  LS.swapReasonsLens
);

export const swapMembership = myqqAction.async<
  MS.VehicleSwapRequest,
  MS.VehicleSwapResponse
>("SWAP_MEMBERSHIP");
const swapMembershipReducer = asyncReducerFactory(
  swapMembership,
  LS.swapMembershipLens
);

export const clearSwapMembership = myqqAction.simple("CLEAR_SWAP_MEMBERSHIP");
const clearSwapMembershipReducer = caseFn(
  clearSwapMembership,
  LS.swapMembershipLens.set(DE.initial)
);

export const editVehicleIdentifier = myqqAction.async<MS.Vehicle, MS.Vehicle>(
  "EDIT_VEHICLE_IDENTIFIER"
);
const editVehicleIdentifierReducer = asyncReducerFactory(
  editVehicleIdentifier,
  LS.editVehicleIdentifierLens
);

export const clearEditVehicleIdentifier = myqqAction.simple(
  "CLEAR_EDIT_VEHICLE_IDENTIFIER"
);
const clearEditVehicleIdentifierReducer = caseFn(
  clearEditVehicleIdentifier,
  LS.editVehicleIdentifierLens.set(DE.initial)
);

/**
 * Cancel Membership
 */
export const cancelMembership = myqqAction.async<
  MS.CancelMembershipRequest,
  MS.CancelMembershipResponse
>("CANCEL_MEMBERSHIP");
const cancelMembershipReducer = asyncReducerFactory(
  cancelMembership,
  LS.cancelMembershipLens
);

export const clearCancelMembership = myqqAction.simple(
  "CLEAR_CANCEL_MEMBERSHIP"
);
const clearCancelMembershipReducer = caseFn(
  clearCancelMembership,
  LS.cancelMembershipLens.set(DE.initial)
);

export const checkEligibilityForRetentionOffer = myqqAction.async<
  number,
  MS.CheckEligibilityForRetentionOfferResponse
>("CHECK_ELIGIBILITY_FOR_RETENTION_OFFER");

const checkEligibilityForRetentionOfferReducer = asyncReducerFactory(
  checkEligibilityForRetentionOffer,
  LS.checkEligibilityForRetentionOfferLens
);

export const clearCheckEligibilityForRetentionOffer = myqqAction.simple(
  "CLEAR_CHECK_ELIGIBILITY_FOR_RETENTION_OFFER"
);
const clearCheckEligibilityForRetentionOfferReducer = caseFn(
  clearCheckEligibilityForRetentionOffer,
  LS.checkEligibilityForRetentionOfferLens.set(DE.initial)
);

export const acceptRetentionOffer = myqqAction.async<
  null,
  MS.AcceptRetentionOfferResponse
>("ACCEPT_RETENTION_OFFER");
const acceptRetentionOfferReducer = asyncReducerFactory(
  acceptRetentionOffer,
  LS.acceptRetentionOfferLens
);

export const getCancelReasons = myqqAction.async<null, MS.CancelReason[]>(
  "GET_CANCEL_REASONS"
);
const getCancelReasonsReducer = asyncReducerFactory(
  getCancelReasons,
  LS.cancelReasonsLens
);

export const getAppMinVersion = myqqAction.async<null, MS.AppVersion>(
  "GET_APP_MIN_VERSION"
);
const getAppMinVersionReducer = asyncReducerFactory(
  getAppMinVersion,
  LS.appMinVersionLens
);

export const setVehiclesOnOrder = myqqAction.simple<MS.Vehicle[]>(
  "SET_VEHICLES_ON_ORDER"
);
const setVehiclesOnOrderReducer = caseFn(
  setVehiclesOnOrder,
  (s: MyQQState, { value }) => LS.vehiclesOnOrderLens.set(value)(s)
);

export const getMarketingContent = myqqAction.async<
  null,
  MS.MarketingContent[]
>("GET_MARKETING_CONTENT");
const getMarketingContentReducer = asyncReducerFactory(
  getMarketingContent,
  LS.marketingContentLens
);

/**
 * B2B
 */

export const getB2bBenefit = myqqAction.async<null, MS.B2bBenefit>(
  "GET_B2B_BENEFIT"
);
const getB2bReducer = asyncReducerFactory(getB2bBenefit, LS.b2bBenefitLens);

export const linkB2C = myqqAction.async<
  MS.LinkB2CAccountRequest,
  MS.LinkB2CAccountResponse
>("LINK_B2C");
const linkB2CReducer = asyncReducerFactory(linkB2C, LS.linkB2CLens);

/**
 * MyQQ Reducer
 *
 * This composes all of the individual reducers with each other.
 */

export const myqqReducer = reducerDefaultFn(
  INITIAL_STATE,
  setInitialLoadReducer,

  getAccountInfoReducer,
  clearAccountInfoReducer,
  getProfileReducer,
  clearProfileReducer,
  newAccountReducer,
  clearNewAccountReducer,
  updateAccountReducer,
  clearUpdateAccountReducer,
  clearAccountLookupReducer,

  getAllRegionsReducer,

  getMyOrdersReducer,
  getMyPaymentMethodsReducer,
  getMySubscriptionReducer,
  clearMySubscriptionReducer,
  getPriceTableReducer,
  getPriceTableByZipReducer,
  clearPriceTableByZipReducer,

  postPaymentMethodReducer,
  clearPostPaymentMethodReducer,
  payInvoiceReducer,
  clearPayFailedInvoiceReducer,

  getVehiclesReducer,
  addVehicleReducer,
  editVehicleReducer,
  clearAddVehicleReducer,
  clearEditVehicleReducer,
  removeVehicleReduce,

  clearVehiclePostPutReducer,
  accountLookupReducer,
  accountLookupLast4PlateReducer,
  accountLinkReducer,
  accountRelinkReducer,
  getAllStoresReducer,

  getOrderByIdReducer,
  calculateOrderReducer,
  submitOrderReducer,
  clearSubmitOrderReducer,
  clearSubmitOrderAndGenerateNewOrderIdReducer,
  clearOrderProgressReducer,

  setPendingOrderReducer,
  clearPendingOrderReducer,
  setPaymentMethodReducer,
  clearPaymentMethodReducer,
  setPendingPromoCodeReducer,
  clearPromoCodeReducer,
  checkPromoCodeReducer,
  setOrderReasonReducer,
  setModifySubscriptionPlanReducer,
  clearModifySubscriptionPlanReducer,
  setMembershipPurchaseReducer,
  clearMembershipPurchaseReducer,

  getPromoDetailsReducer,
  getCompanyWidePromoDetailsReducer,
  emptyPromoDetailsReducer,

  clearFailedPromoCheckReducer,

  getAccountQuotaReducer,
  clearAccountQuotaReducer,
  getSwapReasonsReducer,
  swapMembershipReducer,
  clearSwapMembershipReducer,

  editVehicleIdentifierReducer,
  clearEditVehicleIdentifierReducer,

  cancelMembershipReducer,
  clearCancelMembershipReducer,
  checkEligibilityForRetentionOfferReducer,
  clearCheckEligibilityForRetentionOfferReducer,
  acceptRetentionOfferReducer,
  getCancelReasonsReducer,

  getAppMinVersionReducer,
  setVehiclesOnOrderReducer,
  getMarketingContentReducer,
  getB2bReducer,
  linkB2CReducer
);
