<mat-drawer-container [hasBackdrop]="true" class="vh-p100">
  <mat-drawer #drawer mode="over" class="mobile-menu">
    <mat-icon
      class="close-btn"
      (click)="drawer.toggle()"
      aria-label="Close Side Menu"
      color="primary"
      >cancel</mat-icon
    >

    <mat-nav-list
      (click)="drawer.close()"
      class="ff-roboto mwl-3 nav-list fld-col vh-p100"
    >
      <ng-container *ngIf="(authenticated | async) === false">
        <div>
          <img
            mat-list-item
            src="{{ cdnHost }}/icon/mainlogo.svg"
            alt="Quick Quack Car Wash"
            class="image"
            tabindex="0"
            routerLink=""
          />
        </div>
        <div class="pwl-5">
          <button
            mat-raised-button
            aria-label="Create Account"
            class="ff-roboto btn register-btn"
            color="primary"
            (click)="handleRegister()"
            data-cy="register-btn"
          >
            Create Account
          </button>
        </div>
        <div class="pwl-5 pwt-4">
          <button
            mat-button
            aria-label="Login"
            color="primary"
            class="ff-roboto cf-primary-rev btn"
            title="Login"
            [disabled]="!online"
            [class.offline-no-login]="!online"
            (click)="handleAuthentication(authenticated.value)"
            data-cy="login-btn"
          >
            Log In
          </button>
        </div>
        <div class="pwa-5">
          <mat-divider class="ct-primary"></mat-divider>
        </div>
      </ng-container>
      <ng-container *ngIf="authenticated | async">
        <h3 class="mwy-0 pwl-5 mwr-7 vh-2 fld-row ds-fl ai-ctr">
          <span class="fw-u3 ct-primary-rev"> myQQ </span>
          <span class="fls-1-1"></span>
          <span>
            <button
              mat-list-item
              aria-label="Logout"
              class="ff-roboto mwr-5"
              title="Logout"
              [disabled]="!online"
              [class.offline-no-login]="!online"
              (click)="handleAuthentication(authenticated.value)"
              data-cy="logout-btn"
            >
              <mat-icon svgIcon="exit" class="mwr-4"></mat-icon
              ><span class="logout-txt">Logout</span>
            </button>
          </span>
        </h3>
        <div class="pwt-3 pwb-2 pwx-2">
          <mat-divider class="ct-primary"></mat-divider>
        </div>
        <div class="fld-row vw-p100">
          <a
            mat-list-item
            [aria-label]="homeLink.title"
            [routerLink]="homeLink.url"
            [routerLinkActive]="'ct-light'"
            data-cy="Home"
          >
            <span class="vw-p60 fw-u1">{{ homeLink.title }}</span
            ><i class="material-icons-outlined vw-p40">{{
              homeLink.icon.name
            }}</i>
          </a>
        </div>

        <div class="pwa-1 pwb-5">
          <mat-divider class="ct-primary"></mat-divider>
        </div>
        <a
          mat-list-item
          id="{{ link.title | lowercase }}-link"
          *ngFor="let link of accountLinks"
          [routerLink]="
            !link.allowWithoutAccount && !accountLinked ? '' : link.url
          "
          [routerLinkActive]="'ct-light'"
          [attr.data-cy]="link.title + '-btn'"
          [disabled]="!link.allowWithoutAccount && !accountLinked"
          ><span class="vw-p60 fw-u1">{{ link.title }}</span>
          <i class="material-icons-outlined vw-p40">{{ link.icon.name }}</i>
        </a>
      </ng-container>

      <myqq-footer class="pwt-5 mobile-menu-footer"></myqq-footer>
    </mat-nav-list>
  </mat-drawer>

  <mat-drawer-content class="vh-p100 vhmx-p100">
    <main class="fls-1-1 fld-col ai-ctr vw-p100 vwc-p100 cb-base">
      <header
        [ngClass]="!!greenBackground ? 'cb-primary' : 'cb-primary-rev'"
        class="fls-0-0 vh-2 cb-primary vw-p100 vwcmx-px768 fld-row flg-4 ai-ctr jc-ctr"
        style="z-index: 10;"
      >
        <div class="fld-row flg-4 ai-ctr fls-1-1 menu-btn">
          <button
            mat-icon-button
            class="fls-0-0"
            (click)="drawer.toggle()"
            aria-label="Open Side Menu"
            data-cy="menu-btn"
          >
            <mat-icon
              [ngClass]="!!greenBackground ? 'white-icon' : 'green-icon'"
              >menu</mat-icon
            >
          </button>
        </div>
        <div class="fls-1-1 main-logo">
          <a
            class="logo-link"
            tabindex="0"
            routerLink=""
            *ngIf="showQuickQuackHeader"
          >
            <img
              src="{{ cdnHost }}/icon/mainlogo.svg"
              alt="Quick Quack Car Wash Main Logo"
              class="main-logo"
            />
          </a>
        </div>

        <div *ngIf="(authenticated | async) === false">
          <button
            mat-button
            aria-label="Log In"
            id="login-btn"
            class="ff-roboto fw-u3"
            color="primary"
            tabindex="0"
            (click)="handleAuthentication(authenticated.value)"
            data-cy="login-btn"
          >
            Log In
          </button>
        </div>
      </header>

      <section class="fls-1-1 fld-col ai-ctr vwc-p100 vwcmx-rem0 ova-au">
        <div *ngIf="!online" id="banner-container">
          <div id="offline-banner">
            <img
              id="banner-image"
              src="/assets/icon/offline_quackals_960x600.gif"
              alt="Quackals"
            />
            <div id="banner-message">
              <p>
                You are currently offline. You can still use myQQ, but your
                information may be out of date and some features will be
                unavailable.
              </p>
            </div>
          </div>
        </div>
        <ng-content></ng-content>
      </section>
    </main>
  </mat-drawer-content>
</mat-drawer-container>
