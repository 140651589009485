import { Component, Inject, Input, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";

import { Store } from "@ngrx/store";

import { BehaviorSubject, Observable } from "rxjs";

import { environment } from "src/environments/environment";
import { KeycloakService } from "keycloak-angular";

import { selectAccountLinked } from "src/app/core/ngrx/myqq";

import { WINDOW } from "src/app/core/services/window.service";
import { OfflineAuthService } from "src/app/core/services/offline-auth.service";
import { PwaService } from "src/app/shared/services/pwa.service";
import { ResizeService } from "../../services/resize.service";
import { getRedirectUri } from "src/app/core/keycloak/keycloak.utils";

export interface Link {
  url: string;
  title: string;
  classes?: string;
  allowUnauth: boolean;
  allowWithoutAccount: boolean;
  icon?: {
    svg: boolean;
    name: string;
    noText: boolean;
  };
}

@Component({
  selector: "myqq-default-layout",
  templateUrl: "./default-layout.page.html",
})
export class DefaultLayoutComponent implements OnInit {
  @Input()
  pageTitle = "myQQ";
  @Input()
  greenBackground: boolean;
  @Input()
  landingPage = false;
  @Input()
  loginRedirectUri?: string;
  @Input()
  logoutRedirectUri?: string;
  @Input()
  registerRedirectUri?: string;

  isMobile: BehaviorSubject<boolean>;

  readonly accountLinked$ = this.store$.select(selectAccountLinked);

  readonly homeLink: Link = {
    title: "Home",
    url: "/",
    classes: "mainlogo",
    allowUnauth: true,
    allowWithoutAccount: true,
    icon: {
      svg: true,
      name: "home",
      noText: false,
    },
  };

  readonly profileLink: Link = {
    title: "Account",
    url: "/account",
    allowUnauth: false,
    allowWithoutAccount: false,
    icon: {
      svg: true,
      name: "account_circle",
      noText: false,
    },
  };

  readonly accountLinks: Link[] = [
    {
      title: "Vehicles",
      url: "/vehicles",
      allowUnauth: false,
      allowWithoutAccount: false,
      icon: {
        svg: true,
        name: "directions_car",
        noText: false,
      },
    },
    {
      title: "Wallet",
      url: "/membership",
      allowUnauth: false,
      allowWithoutAccount: false,
      icon: {
        svg: true,
        name: "wallet",
        noText: false,
      },
    },
    {
      title: "Location",
      url: "/locations",
      allowUnauth: true,
      allowWithoutAccount: true,
      icon: {
        svg: true,
        name: "place",
        noText: false,
      },
    },
  ];
  readonly mobileAccountLinks: Link[] = [
    this.profileLink,
    ...this.accountLinks,
  ];

  allowAccess$: BehaviorSubject<boolean>;
  online$: Observable<boolean>;

  constructor(
    readonly keycloakSvc: KeycloakService,
    readonly offlineAuth: OfflineAuthService,
    readonly store$: Store<any>,
    readonly promptService: PwaService,
    private titleService: Title,
    private resize: ResizeService,
    @Inject(WINDOW) private window: Window
  ) {
    this.isMobile = this.resize.isMobile$;
    this.allowAccess$ = this.offlineAuth.allowAccess$;
    this.online$ = this.offlineAuth.online$;
  }

  ngOnInit() {
    this.titleService.setTitle(`${this.pageTitle} | myQQ`);
  }

  handleAuthentication(loggedIn: boolean) {
    const { redirectUri } = getRedirectUri();

    if (loggedIn) {
      this.keycloakSvc.logout(
        this.logoutRedirectUri ?? this.window.location.origin
      );
    } else {
      const options = environment.keycloak.loginOptions;
      options.redirectUri = this.loginRedirectUri || redirectUri;
      this.keycloakSvc.login(options);
    }
  }

  handleRegister() {
    const { redirectUri } = getRedirectUri();
    this.keycloakSvc.register({
      redirectUri: this.registerRedirectUri || redirectUri,
    });
  }
}
