<span class="fld-row">
  <p class="mwy-0 mwl-4 fs-d1 agree-btn">
    By purchasing, you agree to Quick Quack’s
    <a
      href="https://www.dontdrivedirty.com/terms-of-service/"
      target="_blank"
      rel="noopener noreferrer"
      >Terms of Service Agreement</a
    >, including the disclaimer of warranties (Section 6), the Limitation of
    Liabilities (Section 7), the Privacy Policy (Section 9.1), and the Agreement
    to Arbitrate (Section 9.3).
  </p>
</span>
