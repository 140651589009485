import { Location } from "@angular/common";
import {
  Component,
  Inject,
  OnInit,
  OnDestroy,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { notNil } from "@qqcw/qqsystem-util";
import { merge, Subject } from "rxjs";
import { delay, map } from "rxjs/operators";

import {
  getMyPaymentMethods,
  clearPayFailedInvoice,
  selectStripeSubscription,
  selectPayFailedInvoice,
  payFailedInvoice,
  getAccountInfo,
} from "src/app/core/ngrx/myqq";
import { MyqqStripeService } from "src/app/core/services/myqq-stripe/myqq-stripe.service";
import { WINDOW } from "src/app/core/services/window.service";
import { StripeError } from "src/app/shared/modules/stripe/stripe-definitions/error";
import { PaymentMethodData } from "src/app/shared/modules/stripe/stripe-definitions/payment-method";
import { environment } from "src/environments/environment";

@Component({
  selector: "myqq-failed-payment-method-page",
  templateUrl: "./failed-payment-method.page.html",
  styleUrls: ["./failed-payment-method.page.scss"],
})
export class FailedPaymentMethodPage implements OnInit, OnDestroy {
  destroyed = false;
  stripePending = false;

  supportNumber = environment.supportNumber;
  error$ = new Subject<StripeError | Error>();

  cdnHost = environment.cdnHost;

  readonly description =
    "It looks like your payment method may be expired. If you'd like to continue using our service, please add a new payment method below.";

  readonly subscription$ = this.store$.select(selectStripeSubscription);
  readonly payFailedInvoice$ = this.store$.select(selectPayFailedInvoice);

  @ViewChild("initialFooter", { static: false })
  private initialFooterTemplate?: TemplateRef<any>;
  @ViewChild("stripeErrorFooter", { static: false })
  private stripeErrorFooterTemplate?: TemplateRef<any>;
  @ViewChild("errorFooter", { static: false })
  private errorFooterTemplate?: TemplateRef<any>;
  readonly footerTemplate = merge(
    this.error$.asObservable(),
    this.payFailedInvoice$
  ).pipe(
    delay(1),
    map(
      () =>
        this.stripeErrorFooterTemplate ||
        this.errorFooterTemplate ||
        this.initialFooterTemplate ||
        null
    )
  );

  constructor(
    readonly store$: Store<any>,
    readonly stripe: MyqqStripeService,
    readonly location: Location,
    readonly router: Router,
    @Inject(WINDOW) private window: Window
  ) {}

  ngOnInit() {
    this.handleReset();
  }

  /**
   * Clear the postPaymentMethod and getPaymentMethods when this
   * modal closes.
   */
  ngOnDestroy() {
    this.handleReset();
    this.store$.dispatch(getMyPaymentMethods.pending());
    this.store$.dispatch(getAccountInfo.pending(null));
    this.destroyed = true;
  }

  handleCancel() {
    this.location.back();
  }

  handleReset() {
    this.error$.next(undefined);
    this.store$.dispatch(clearPayFailedInvoice(null));
  }

  goHome() {
    // Force reload to make sure failed payment card is not displayed
    this.router.navigateByUrl("").then(() => this.window.location.reload());
  }

  async handleSubmit({
    card,
    invoiceId,
  }: {
    card: PaymentMethodData;
    invoiceId: string;
  }) {
    this.stripePending = true;
    try {
      const { paymentMethod } = await this.stripe.createPaymentMethod(card);
      if (notNil(paymentMethod && !this.destroyed)) {
        this.store$.dispatch(
          payFailedInvoice.pending({
            paymentMethodId: paymentMethod.id,
            invoiceId,
          })
        );
      } else {
        throw new Error(
          "There was a problem adding this payment method. Please verify your payment details."
        );
      }
    } catch (error) {
      this.error$.next(error);
    }
    this.stripePending = false;
  }

  trimBlankErrorCode(message: string) {
    // If stripe/myqq-api doesn't return the error code, just trim that out of the message.
    // e.g. 'Card was declined with code: ' happens sometimes, so just display 'Card was declined.'
    return message?.replace(/\ with\ code\:\s$/g, ".");
  }
}
