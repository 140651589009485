import { NgModule } from "@angular/core";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";

import { MyQQEffects } from "./myqq.effects";
import { MyQQChainEffects } from "./myqq.chains";
import { myqqFeatureKey, myqqReducer } from "./myqq.reducers";
import { MyQQServiceModule } from "../../services/myqq/myqq.module";
import { MyQQOrderChainEffects } from "./cart/myqq-cart.chains";
import { MyQQPromoChainEffects } from "./promo.chains";

@NgModule({
  imports: [
    StoreModule.forFeature(myqqFeatureKey, myqqReducer),
    EffectsModule.forFeature([
      MyQQEffects,
      MyQQChainEffects,
      MyQQOrderChainEffects,
      MyQQPromoChainEffects,
    ]),
    MyQQServiceModule,
  ],
})
export class NgrxMyQQModule {}
