import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SegmentChainEffects } from "./segment.chains";
import { EffectsModule } from "@ngrx/effects";

@NgModule({
  declarations: [],
  imports: [CommonModule, EffectsModule.forFeature([SegmentChainEffects])],
})
export class SegmentModule {}
