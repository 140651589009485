import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Vehicle } from "src/app/core/services/myqq";
import { AccountQuotaWithRemaining } from "src/app/shared/utilities/types";

@Component({
  selector: "myqq-vehicle-swap-initial",
  templateUrl: "./vehicle-swap-initial.component.html",
  styleUrls: ["./vehicle-swap-initial.component.scss"],
})
export class VehicleSwapInitialComponent {
  @Input() currentVehicle: Vehicle;
  @Input()
  set nonMembershipVehicles(vehicles: Vehicle[]) {
    this.vehicles = vehicles;
    if (this.vehicles?.length == 1) {
      this.selectedNewVehicle = this.vehicles[0];
      this.newVehicle.emit(this.vehicles[0]);
    }
  }
  private vehicles?: Vehicle[];
  get nonMembershipVehicles() {
    return this.vehicles;
  }
  @Input() accountQuota: AccountQuotaWithRemaining;
  @Input() refreshing: boolean;
  @Input() membershipBadgeColor;
  @Output() newVehicle = new EventEmitter<Vehicle>();
  @Output() addVehicle = new EventEmitter();
  @Output() nextStep = new EventEmitter();
  @Output() showDisclaimer = new EventEmitter();

  showVehiclesDropDown = false;
  selectedNewVehicle: Vehicle;

  setShowVehiclesDropdown(val: boolean) {
    this.showVehiclesDropDown = val;
  }

  selectVehicle(vehicle: Vehicle) {
    this.showVehiclesDropDown = false;
    this.selectedNewVehicle = vehicle;
    this.newVehicle.emit(vehicle);
  }
}
