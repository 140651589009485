<div class="fld-col content bra-1">
  <span class="fw-u3 name" *ngIf="!!plan">
    {{ plan.myQQShortDisplayName | titlecase }} {{ plan.flavorText }}
  </span>
  <span> Unlimited Monthly </span>
  <myqq-price
    [price]="price"
    [priceOverride]="priceOverride"
    [crossOutDefaultPrice]="crossOutDefaultPrice"
    [customPriceImgUrl]="customOverride"
    prepend="$"
    class="fs-u1 ds-dsk-only"
  >
  </myqq-price>
  <span class="divider mwt-5"> </span>
</div>
