import { Inject, Injectable } from "@angular/core";
import { Platform } from "@angular/cdk/platform";
import { Router } from "@angular/router";
import { SwUpdate } from "@angular/service-worker";

import { Store } from "@ngrx/store";
import { interval, Observable } from "rxjs";

import { log, LogAction, LogLevels } from "src/app/core/ngrx/logger";
import { WINDOW } from "src/app/core/services/window.service";
import { environment } from "src/environments/environment";

import { PwaUpdatePromptService } from "./pwa-update-prompt.service";
import { isReplete } from "@nll/datum/Datum";
import { isSuccess } from "@nll/datum/DatumEither";
import { getAppMinVersion, selectAppMinVersion } from "src/app/core/ngrx/myqq";
import { filter, skip, take } from "rxjs/operators";
import { version } from "src/environments/version";
import * as semver from "semver";
import { UnleashService } from "./unleash.service";

interface RelatedApp {
  id: string;
  platform: string;
  url?: string;
}

const pwaMode = environment.pwaMode;
const twaId = environment.twaId;
const checkUpdateInterval = 1; // Hours

/**
 * This service handles various ancillary PWA-related tasks, such as:
 * - Prompt users to install, as appropriate for device, in not-too-naggy intervals
 * - Check for updates and prompt to reload if using an old version
 * - Try to determine if myQQ is running as a PWA/TWA (=== in standalone mode) and if the TWA binary is installed on the device.
 *
 * @todo unit test
 */

@Injectable({
  providedIn: "root",
})
export class PwaService {
  public pwaMode: string;
  public isInStandaloneMode: boolean;
  public isMobile: boolean;
  public device: "ios" | "android" | "unknown";
  public isInstalled: boolean = null; // Is the TWA installed on this device, regardless of whether it is being used now?

  constructor(
    private swUpdate: SwUpdate,
    private platform: Platform,
    private store$: Store<any>,
    private pwaUpdatePrompt: PwaUpdatePromptService,
    private router: Router,
    private unleashService: UnleashService,
    @Inject(WINDOW) private window: Window
  ) {
    this.isInStandaloneMode =
      (pwaMode in (this.window?.navigator ?? {}) &&
        this.window?.navigator?.[pwaMode]) || // iOS only
      this.window?.matchMedia?.(`(display-mode: ${pwaMode})`)?.matches; // Chrome

    // Handle the case where user changes display mode after launching app
    this.window?.sessionStorage?.setItem?.(
      "wasInStandaloneMode",
      this.isInStandaloneMode ? "true" : "false"
    );

    this.isMobile = this.platform.IOS || this.platform.ANDROID;

    this.device = this.platform.IOS
      ? "ios"
      : this.platform.ANDROID
      ? "android"
      : "unknown";

    // getInstalledRelatedApps will return a list of native apps installed on the device IF:
    // - The app id is specified in the website manifest
    // - The website domain is specified in the native app strings.xml
    // See https://medium.com/dev-channel/detect-if-your-native-app-is-installed-from-your-web-site-2e690b7cb6fb
    if (
      twaId &&
      typeof (this.window?.navigator as any)?.getInstalledRelatedApps ===
        "function"
    ) {
      (this.window.navigator as any)
        .getInstalledRelatedApps()
        .then((apps: RelatedApp[]) => {
          this.isInstalled =
            Array.isArray(apps) && apps.map((app) => app.id).includes(twaId);
        });
    }
  }

  readonly enableAndroidFlag$: Observable<boolean> = this.unleashService
    .enableAndroidRelease$;

  public initPwaService() {
    this.handleCheckUpdates();
  }

  public get standalone() {
    return (
      this.isInStandaloneMode ||
      this.window?.sessionStorage?.getItem?.("wasInStandaloneMode") === "true"
    );
  }

  public get installed() {
    return this.isInstalled;
  }

  handleCheckUpdates() {
    this.store$.dispatch(getAppMinVersion.pending(null));
    // When the service worker detects an update, prompt user to reload the application
    this.swUpdate.versionUpdates.subscribe((evt) => {
      switch (evt.type) {
        case "VERSION_DETECTED":
          this.pwaLog(`Downloading new app version: ${evt.version.hash}`);
          break;
        case "VERSION_READY":
          this.pwaLog(
            `Current app version: ${evt.currentVersion.hash}, ${evt.latestVersion.hash}`
          );
          this.store$
            .select(selectAppMinVersion)
            .pipe(filter(isReplete))
            .subscribe((resp) => {
              this.enableAndroidFlag$
                .pipe(skip(1), take(1))
                .subscribe((androidFlag) => {
                  if (androidFlag && this.device == "android") {
                    this.pwaUpdatePrompt.promptForAndroidDownload();
                  } else {
                    if (
                      isSuccess(resp) &&
                      semver.lt(version.version, resp.value.right?.web?.minimum)
                    ) {
                      this.pwaLog(
                        `Forced Update: ${version.version},  ${resp.value.right?.web?.minimum}`
                      );
                      this.pwaUpdatePrompt.forceUpdate();
                    } else {
                      this.pwaLog(`Optional Update: ${version.version}`);
                      //This will show when flag is off
                      this.pwaUpdatePrompt.promptForUpdate();
                    }
                  }
                });
            });
          break;
        case "VERSION_INSTALLATION_FAILED":
          this.pwaLog(
            `Failed to install app version '${evt.version.hash}': ${evt.error}`
          );
          break;
      }
    });

    // Poll for updates.
    interval(checkUpdateInterval * 60 * 1000).subscribe(() => {
      this.swUpdate.checkForUpdate().catch((e) => {
        this.pwaLog("Failed to check for update.", e, LogLevels.WARNING);
      });
    });
  }

  pwaLog(msg: string, error?: Error, level: LogLevels = LogLevels.INFO) {
    const logContents: LogAction = {
      level: level,
      message: msg,
      actionType: {
        name: "PWA_SERVICE",
        type: [LogLevels.ERROR, LogLevels.WARNING, LogLevels.FATAL].includes(
          level
        )
          ? "FAILURE"
          : "SUCCESS",
        namespace: "MYQQ",
      },
    };

    if (error) {
      logContents.data = {
        error: error,
      };
    }

    this.store$.dispatch(log(logContents));
  }

  public updateAndReload() {
    this.swUpdate
      .checkForUpdate()
      .then(() => {
        return this.swUpdate.activateUpdate();
      })
      .then(() => this.router.navigate(["/"]))
      .then(() => this.window.location.reload())
      .catch((e) => {
        this.pwaLog("Failed to update and reload", e, LogLevels.ERROR);
        this.router.navigate(["/"]);
      });
  }
}
