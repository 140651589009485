import { Routes } from "@angular/router";

import { ErrorPage } from "./error.page";

export const routes: Routes = [
  {
    path: "",
    component: ErrorPage,
  },
];
