import { HttpErrorResponse } from "@angular/common/http";

import {
  BatchLogRequest,
  BatchLogResponse,
  LogAction,
  LogItem,
  LOGGING_FEATURE_KEY,
} from "./logging.models";
import { actionCreatorFactory } from "src/app/shared/utilities/state/Actions";

const loggingActionFactory = actionCreatorFactory(LOGGING_FEATURE_KEY);

export const log = loggingActionFactory.simple<LogAction>("LOG");
export const addLogItem = loggingActionFactory.simple<LogItem>("ADD_LOGITEM");

export const sendLogs = loggingActionFactory.async<
  BatchLogRequest,
  BatchLogResponse,
  HttpErrorResponse
>("SEND_LOGS");
export const clearLogs = loggingActionFactory.simple("CLEAR_LOGS");
