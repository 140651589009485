import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { ModalRouteModule } from "src/app/shared/components/routed-modal";
import { DatumModule } from "src/app/shared/directives/datum";
import { SharedModule } from "src/app/shared/shared.module";

import { EditVehicleIdentifiersPage } from "./edit-vehicle-identifiers.page";
import { MyqqButtonModule } from "src/app/shared/components/button/button.module";
import { MatIconModule } from "@angular/material/icon";

import { EditVehicleInitialComponent } from "./edit-vehicle-initial/edit-vehicle-initial.component";
import { EditVehicleConfirmComponent } from "./edit-vehicle-confirm/edit-vehicle-confirm.component";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { VehicleDisplayModule } from "src/app/shared/components/vehicle-display/vehicle-display.module";
import { MatButtonModule } from "@angular/material/button";
import { MapPipeModule } from "src/app/shared/pipes/map";
import { AccountQuotaMessageModule } from "src/app/shared/components/account-quota-message/account-quota-message.module";
import { VehicleFormModule } from "src/app/shared/components/vehicle-form/vehicle-form.module";
import { GenericAlertModule } from "src/app/shared/components/generic-alert/generic-alert.module";

@NgModule({
  declarations: [
    EditVehicleConfirmComponent,
    EditVehicleIdentifiersPage,
    EditVehicleInitialComponent,
  ],
  imports: [
    MatButtonModule,
    CommonModule,
    DatumModule,
    MyqqButtonModule,
    ModalRouteModule,
    SharedModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    VehicleDisplayModule,
    MapPipeModule,
    AccountQuotaMessageModule,
    VehicleFormModule,
    GenericAlertModule,
  ],
  exports: [EditVehicleIdentifiersPage],
})
export class EditVehicleIdentifiersModule {}
