import { createFeatureSelector, createSelector } from "@ngrx/store";
import { notNil } from "@qqcw/qqsystem-util";
import * as O from "fp-ts/es6/Option";
import * as DE from "@nll/datum/DatumEither";

import { StripeStatusEnum, Vehicle } from "src/app/core/services/myqq";

import { AccountAndMembershipStatus, MyQQState } from "./myqq.models";
import { myqqFeatureKey } from "./myqq.reducers";
import * as LS from "./myqq.lenses";
import { environment } from "src/environments/environment";

export const selectMyQQState = createFeatureSelector<MyQQState>(myqqFeatureKey);

/**
 * This are selectors. They are effectively just the get part of a lens. The use
 * of createSelector is for memoization purposes. It's likely not necessary, but
 * can be considered good practice.
 */
export const selectInitialLoad = createSelector(
  selectMyQQState,
  LS.initialLoadLens.get
);

export const selectAccountInfo = createSelector(
  selectMyQQState,
  LS.accountInfoLens.get
);
export const selectProfile = createSelector(
  selectMyQQState,
  LS.profileLens.get
);
export const selectMembership = createSelector(
  selectMyQQState,
  LS.membershipLens.get
);
export const selectMembershipVehicles = createSelector(
  selectMembership,
  DE.map((membership) => membership.vehicles)
);
export const selectAccount = createSelector(
  selectMyQQState,
  LS.accountLens.get
);
export const selectNewAccount = createSelector(
  selectMyQQState,
  LS.newAccountLens.get
);
export const selectUpdateAccount = createSelector(
  selectMyQQState,
  LS.updateAccountLens.get
);
export const selectStores = createSelector(selectMyQQState, LS.storesLens.get);
export const selectRegions = createSelector(
  selectMyQQState,
  LS.regionsLens.get
);
export const selectMySubscription = createSelector(
  selectMyQQState,
  LS.subscriptionLens.get
);
export const selectOrders = createSelector(selectMyQQState, LS.ordersLens.get);
export const selectPriceTable = createSelector(
  selectMyQQState,
  LS.priceTableLens.get
);

export const selectPaymentMethods = createSelector(
  selectMyQQState,
  LS.paymentMethodsLens.get
);
export const selectPostPaymentMethod = createSelector(
  selectMyQQState,
  LS.postPaymentMethodLens.get
);
export const selectVehicles = createSelector(
  selectMyQQState,
  LS.getVehiclesLens.get
);

export const selectVehiclesWithTemporaryIdentifier = createSelector(
  selectVehicles,
  DE.map((vehicles) =>
    vehicles.filter((vehicle) => vehicle.isTemporaryIdentifier)
  )
);

export const selectAddVehicle = createSelector(
  selectMyQQState,
  LS.addVehicleLens.get
);
export const selectEditVehicle = createSelector(
  selectMyQQState,
  LS.editVehicleLens.get
);

export const selectGetOrderById = createSelector(
  selectMyQQState,
  LS.getOrderByIdLens.get
);

export const selectOrderById = (id: string) =>
  createSelector(selectGetOrderById, (record) => record[id] ?? DE.initial);

export const selectAccountLookup = createSelector(
  selectMyQQState,
  LS.accountLookupLens.get
);

export const selectLinkAccount = createSelector(
  selectMyQQState,
  LS.linkAccountLens.get
);

export const selectCalculateOrder = createSelector(
  selectMyQQState,
  LS.calculateOrderLens.get
);

export const selectSubmitOrder = createSelector(
  selectMyQQState,
  LS.submitOrderLens.get
);

export const selectPendingOrder = createSelector(
  selectMyQQState,
  LS.pendingOrderLens.get
);

export const selectModifySubscriptionPlan = createSelector(
  selectMyQQState,
  LS.modifySubscriptionPlanLens.get
);

export const selectMembershipPurchase = createSelector(
  selectMyQQState,
  LS.membershipPurchaseLens.get
);

export const selectCheckPromo = createSelector(
  selectMyQQState,
  LS.checkPromoCodeLens.get
);

export const selectPayFailedInvoice = createSelector(
  selectMyQQState,
  LS.payInvoiceLens.get
);

/**
 * Select Stripe Subscription
 */
export const selectStripeSubscription = createSelector(
  selectAccountInfo,
  DE.map((accountInfo) =>
    O.fromNullable(accountInfo?.account?.stripeSubscription)
  )
);

/**
 * Regions, Stores, and LookupByZip
 */

/**
 * Combines the asynchronous calls for person, vehicles, and coupons.
 */
export const selectPersonData = createSelector(
  selectProfile,
  selectOrders,
  selectPaymentMethods,
  selectMySubscription,
  (profile, orders, paymentMethods, subscription) =>
    DE.sequenceStruct({ profile, orders, paymentMethods, subscription })
);

/**
 * Combines MyProfile and MySubscriptions
 */
export const selectProfileAndSubscription = createSelector(
  selectProfile,
  selectMySubscription,
  (profile, subscription) => DE.sequenceStruct({ profile, subscription })
);

/**
 * Gets a vehicle by id from the vehicle array
 */
export const selectVehicleById = (id: string) =>
  createSelector(selectMembershipVehicles, (vehiclesDatumEither) => {
    return DE.chain((vehicles: Vehicle[]) => {
      const foundVehicle = vehicles.find((v) => v.vehicleId === id);
      if (notNil(foundVehicle)) {
        return DE.success(foundVehicle);
      }
      return DE.failure({
        error: `Vehicle with id ${id} does not exist in account.`,
      } as any);
    })(vehiclesDatumEither);
  });

/**
 * Gets the user's first and/or last name
 */
export const selectUserFullName = createSelector(
  selectProfile,
  DE.map(({ profile, membership }) => {
    if (
      notNil(membership?.account?.firstName) ||
      notNil(membership?.account?.lastName)
    ) {
      return [membership.account.firstName, membership.account.lastName].join(
        " "
      );
    }
    return profile.name;
  })
);

/**
 * Gets some sort of name for the user
 */

export const selectUsername = createSelector(
  selectProfile,
  DE.map(({ profile, membership }) => {
    if (notNil(membership?.account?.firstName)) {
      return membership?.account.firstName;
    }

    if (notNil(membership?.account?.lastName)) {
      return membership.account.lastName;
    }

    return profile.name;
  })
);

/**
 * Select default payment method
 *
 * Tries to find payment method with isDefault true, otherwise chooses
 * the first payment method in the list, if none exists defaults to undefined.
 * DatumEither<unknown, CustomerPaymentMethod[]>
 *   => DatumEither<unknown, CustomerPaymentMethod | undefined>
 */
export const selectDefaultPaymentMethod = createSelector(
  selectPaymentMethods,
  DE.map(
    (paymentMethods) =>
      paymentMethods.find((pm) => pm.isDefault) ?? paymentMethods[0]
  )
);

/**
 * Select Membership Information
 */
export const selectMembershipInfo = createSelector(
  selectMySubscription,
  selectAccountInfo,
  selectPriceTable,
  (subscription, account, menu) =>
    DE.sequenceStruct({
      subscription,
      account,
      menu,
    })
);

/**
 * Select accountLinked is used to determine if a qsys account
 * has been linked to a keycloak account. Only returns true
 * if the geteMyProfile request is complete and the membership
 * property is defined.
 */
export const selectAccountLinked = createSelector(
  selectMembership,
  DE.squash(
    () => false,
    () => false,
    () => true
  )
);

/**
 * Select failed invoice information
 */
export const selectFailedInvoice = createSelector(
  selectAccountInfo,
  DE.map((account) => {
    const stripeSubscription = account?.account?.stripeSubscription;
    return stripeSubscription?.status === StripeStatusEnum.PAST_DUE
      ? {
          title: "Failed Payment",
          description: `We tried to charge your card for $${stripeSubscription?.invoiceAmount} but failed. Would you like to tap here to update your payment method?`,
          amount: stripeSubscription.invoiceAmount,
          invoiceId: stripeSubscription.invoiceId,
          route: [{ outlets: { modal: "failed-payment-method" } }],
        }
      : null;
  })
);

/**
 * Select soft cancel status information
 */
export const selectSoftCancel = createSelector(
  selectAccountInfo,
  DE.map((account) =>
    !!account?.account?.stripeSubscription?.cancelAtPeriodEnd
      ? {
          title: "Cancellation Pending",
          description: `Your membership is expiring soon. Please click here to contact us via ${environment.supportEmail} if you wish to reactivate.`,
        }
      : null
  )
);

export const selectAllStores = createSelector(
  selectMyQQState,
  LS.storesLens.get
);

export const selectPromoDetails = createSelector(
  selectMyQQState,
  LS.promoDetailsLens.get
);

export const selectCompanyWidePromo = createSelector(
  selectMyQQState,
  LS.companyWidePromoDetailsLens.get
);

export const selectAccountAndMembershipStatus = createSelector(
  selectAccountInfo,
  DE.map((profileAndAccount) => {
    let status: AccountAndMembershipStatus = {
      hasAccount: false,
      hasMembership: false,
    };
    if (!!profileAndAccount?.profile?.info?.qsysAccount) {
      status.hasAccount = true;
    }
    const stripeStatus = profileAndAccount?.account?.stripeSubscription?.status;
    if (
      stripeStatus === StripeStatusEnum.ACTIVE ||
      stripeStatus === StripeStatusEnum.PAST_DUE ||
      stripeStatus === StripeStatusEnum.TRIALING
    ) {
      status.hasMembership = true;
    }
    return status;
  })
);

export const selectStayAndSaveSubscriptionDiscount = createSelector(
  selectAccountInfo,
  DE.map((profileAndAccount) => {
    const discount = profileAndAccount?.subscription?.discount?.stayAndSave;
    return !!discount ? discount : null;
  })
);
/**
 * Swap membership
 */
export const selectAccountQuota = createSelector(
  selectMyQQState,
  LS.accountQuotaLens.get
);

export const selectSwapReasons = createSelector(
  selectMyQQState,
  LS.swapReasonsLens.get
);

export const selectSwapMembership = createSelector(
  selectMyQQState,
  LS.swapMembershipLens.get
);

export const selectNonMemberVehicles = createSelector(
  selectVehicles,
  DE.map((vehicles) =>
    vehicles.filter((vehicle) => !vehicle.hasMembership && !vehicle.expiring)
  )
);

export const selectEditVehicleIdentifier = createSelector(
  selectMyQQState,
  LS.editVehicleIdentifierLens.get
);

/**
 * Cancel membership
 */

export const selectCancelMembership = createSelector(
  selectMyQQState,
  LS.cancelMembershipLens.get
);

export const selectIsEligibleForRetentionOffer = createSelector(
  selectMyQQState,
  LS.checkEligibilityForRetentionOfferLens.get
);

export const selectRetentionOfferAcceptance = createSelector(
  selectMyQQState,
  LS.acceptRetentionOfferLens.get
);

export const selectCancelReasons = createSelector(
  selectMyQQState,
  LS.cancelReasonsLens.get
);

export const selectAppMinVersion = createSelector(
  selectMyQQState,
  LS.appMinVersionLens.get
);

export const selectVehiclesOnOrder = createSelector(
  selectMyQQState,
  LS.vehiclesOnOrderLens.get
);

export const selectMarketingContents = createSelector(
  selectMyQQState,
  LS.marketingContentLens.get
);

export const selectB2bBenefit = createSelector(
  selectMyQQState,
  LS.b2bBenefitLens.get
);

export const selectLinkB2C = createSelector(
  selectMyQQState,
  LS.linkB2CLens.get
);
