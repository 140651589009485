import { isAfter, parse, subYears } from "date-fns";
import { merge } from "rxjs";
import { delay, first, map } from "rxjs/operators";
import { Location } from "@angular/common";
import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { isSuccess } from "@nll/datum/DatumEither";
import { Store } from "@ngrx/store";

import {
  clearUpdateAccount,
  getAccountInfo,
  selectAccount,
  selectUpdateAccount,
  updateAccount,
} from "src/app/core/ngrx/myqq";
import { Account } from "src/app/core/services/myqq";
import { GenericAlertComponent } from "src/app/shared/components/generic-alert/generic-alert.component";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "myqq-edit-account-info-page",
  templateUrl: "./edit-account-info.page.html",
  styleUrls: ["./edit-account-info.page.scss"],
})
export class EditAccountInfoPage implements OnInit, OnDestroy {
  constructor(
    private readonly store$: Store<any>,
    private readonly dialogController: MatDialog,
    private readonly location: Location
  ) {}

  readonly account$ = this.store$.select(selectAccount);
  readonly updateAccount$ = this.store$.select(selectUpdateAccount);

  private updateSub = this.updateAccount$
    .pipe(first(isSuccess))
    .subscribe(() => this.onSuccess());

  @ViewChild("footer", { static: false }) private _footerTemplate: TemplateRef<
    any
  >;
  readonly footerTemplate = merge(this.account$, this.updateAccount$).pipe(
    delay(1),
    map(() => this._footerTemplate)
  );

  ngOnInit() {
    this.clearUpdateState();
  }

  ngOnDestroy() {
    this.clearUpdateState();
    this.updateSub.unsubscribe();
  }

  handleSubmit(data: Account) {
    if (
      isAfter(
        parse(data.birthDate, "yyyy-MM-dd", new Date()),
        // 18 years ago
        subYears(new Date(), 18)
      )
    ) {
      return this.exitWithBirthdateError();
    }

    this.store$.dispatch(updateAccount.pending(data));
  }

  handleCancel() {
    this.close();
  }

  handleReset() {
    this.clearUpdateState();
  }

  private onSuccess() {
    this.refreshAccountData();
    this.close();
  }

  private clearUpdateState() {
    this.store$.dispatch(clearUpdateAccount(null));
  }

  private close() {
    this.location.back();
  }

  private refreshAccountData() {
    this.store$.dispatch(getAccountInfo.pending(null));
  }

  private async exitWithBirthdateError() {
    const birthDateError = await this.dialogController.open(
      GenericAlertComponent,
      {
        disableClose: true,
        data: {
          message:
            "Sorry, you must be at least 18 years old to have a myQQ account.",
        },
      }
    );

    await birthDateError.afterClosed().toPromise();
  }
}
