import { trigger, transition, style, animate } from "@angular/animations";

export const fadeInOut = (name: string, milliseconds: number = 250) =>
  trigger(name, [
    transition(":enter", [
      style({ opacity: 0 }),
      animate(`${milliseconds}ms`, style({ opacity: 1 })),
    ]),
    transition(":leave", [animate(`${milliseconds}ms`, style({ opacity: 0 }))]),
  ]);

export const rollDownUp = (name: string, milliseconds: number = 250) =>
  trigger(name, [
    transition(":enter", [
      style({ height: "0px", opacity: 0 }),
      animate(`${milliseconds}ms`, style({ height: "*", opacity: 1 })),
    ]),
    transition(":leave", [
      animate(`${milliseconds}ms`, style({ height: "0px", opacity: 0 })),
    ]),
  ]);

export const scaleInOut = (name: string, milliseconds: number = 250) =>
  trigger(name, [
    transition(":enter", [
      style({ transform: "scale(0.5)", opacity: 0 }),
      animate(
        `${milliseconds}ms`,
        style({ transform: "scale(1)", opacity: 1 })
      ),
    ]),
    transition(":leave", [
      animate(
        `${milliseconds}ms`,
        style({ transform: "scale(0.5)", opacity: 0 })
      ),
    ]),
  ]);
