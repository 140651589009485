import { HttpErrorResponse, HttpHeaders } from "@angular/common/http";

import { Observable, of, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { ResultsPage } from "./types";

export class ValidationError extends Error {
  constructor(message) {
    super(message);
    this.name = "ValidationError";
    this.message = message;
  }

  toJSON() {
    return {
      name: this.name,
      message: this.message,
    };
  }
}

export const catchCodes = <T>(
  codes: number[],
  recover: (code: number, error: HttpErrorResponse) => T
) => (obs: Observable<T>): Observable<T> =>
  obs.pipe(
    catchError((err) =>
      err instanceof HttpErrorResponse && codes.some((c) => err.status === c)
        ? of(recover(err.status, err))
        : throwError(err)
    )
  );

export const version = (
  v: number,
  options?: { headers: HttpHeaders }
): { headers: HttpHeaders } => {
  const h =
    options && options.headers && options.headers instanceof HttpHeaders
      ? options.headers
      : new HttpHeaders();

  return { headers: h.set("Accept-Version", `v${v}`) };
};

export const pager = (
  { offset, limit }: ResultsPage,
  options?: { headers: HttpHeaders }
): { headers: HttpHeaders } => {
  const h =
    options && options.headers && options.headers instanceof HttpHeaders
      ? options.headers
      : new HttpHeaders();

  return {
    headers: h
      .set("X-Paging-Offset", offset.toString())
      .set("X-Paging-Page-Size", limit.toString()),
  };
};
