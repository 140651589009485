import { Inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";

import { Store as NgrxStore } from "@ngrx/store";

import { EMPTY, Observable, of, throwError } from "rxjs";
import { delay } from "rxjs/operators";
import { some, none } from "fp-ts/es6/Option";
import { Decoder } from "io-ts/lib/Decoder";

import { environment } from "src/environments/environment";
import { shortid } from "@qqcw/qqsystem-util";

import { MyQQService } from "./myqq.service";
import {
  Vehicle,
  Order,
  OrderHeader,
  CustomerPaymentMethod,
  GetProfileResponse,
  QsysProfile,
  Account,
  PostCalculateOrderRequest,
  PostCalculateOrderResponse,
  PostSubmitOrderRequest,
  PostSubmitOrderResponse,
  GetSubscriptionResponse,
  Promo,
  Region,
  AccountCheckResponse,
  AccountInfoResponse,
  PayInvoiceResponse,
  PostPaymentMethodResponse,
  PostPaymentMethodRequest,
  AccountCheckLast4PlateRequest,
  Store,
  PromoDetails,
  AccountQuota,
  SwapReason,
  VehicleSwapRequest,
  VehicleSwapResponse,
  RegionMenu,
  AppVersion,
  OrderSearchResult,
  MarketingContent,
  B2bBenefit,
} from "./myqq.models";
import {
  MOCK_STORE,
  MOCK_ORDER_HEADER_1,
  MOCK_ORDER_HEADER_2,
  MOCK_ORDER_HEADER_3,
  MOCK_ORDER_HEADER_4,
  MOCK_ORDER_1,
  MOCK_CUSTOMER_PAYMENT_METHOD,
  MOCK_VEHICLES,
  MOCK_ACCOUNT,
  MOCK_QSYS_ACCOUNT,
  MOCK_PRICE_TABLE,
  MOCK_SUBSCRIPTION,
  MOCK_PROMO,
  MOCK_REGIONS,
  MOCK_ACCOUNT_INFO,
  MOCK_PROMO_DETAILS,
  MOCK_ACCOUNT_QUOTA,
  MOCK_SWAP_REASONS,
  MOCK_VEHICLE_SWAP_RESPONSE,
  MOCK_CANCEL_REASONS,
  MOCK_PRICE_TABLE_WITHOUT_PRICE,
  MOCK_GRANDOPENING_PROMO,
  MOCK_B2B_BENEFIT,
} from "./myqq.consts";
import { PaymentMethod } from "src/app/shared/modules/stripe/stripe-definitions/payment-method";
import { WINDOW } from "../window.service";
import { OfflineAuthService } from "../offline-auth.service";

/**
 * Local Cache
 */
const orders: OrderHeader[] = [
  MOCK_ORDER_HEADER_1,
  MOCK_ORDER_HEADER_2,
  MOCK_ORDER_HEADER_3,
  MOCK_ORDER_HEADER_4,
  MOCK_ORDER_HEADER_1,
  MOCK_ORDER_HEADER_2,
  {
    ...MOCK_ORDER_HEADER_1,
    submissionAt: "2020-07-16T00:00:00Z",
    submissionTime: "2020-07-16T17:29:04Z",
  },
  {
    ...MOCK_ORDER_HEADER_2,
    submissionAt: "2020-07-16T00:00:00Z",
    submissionTime: "2020-07-16T17:29:04Z",
  },
  {
    ...MOCK_ORDER_HEADER_3,
    submissionAt: "2020-07-16T00:00:00Z",
    submissionTime: "2020-07-16T17:29:04Z",
  },
  {
    ...MOCK_ORDER_HEADER_4,
    submissionAt: "2020-07-16T00:00:00Z",
    submissionTime: "2020-07-16T17:29:04Z",
  },
  {
    ...MOCK_ORDER_HEADER_1,
    submissionAt: "2020-08-16T00:00:00Z",
    submissionTime: "2020-08-16T17:29:04Z",
  },
  {
    ...MOCK_ORDER_HEADER_2,
    submissionAt: "2020-08-16T00:00:00Z",
    submissionTime: "2020-08-16T17:29:04Z",
  },
  {
    ...MOCK_ORDER_HEADER_3,
    submissionAt: "2020-08-16T00:00:00Z",
    submissionTime: "2020-08-16T17:29:04Z",
  },
  {
    ...MOCK_ORDER_HEADER_4,
    submissionAt: "2020-08-16T00:00:00Z",
    submissionTime: "2020-08-16T17:29:04Z",
  },
  {
    ...MOCK_ORDER_HEADER_1,
    submissionAt: "2020-10-16T00:00:00Z",
    submissionTime: "2020-10-16T17:29:04Z",
  },
  {
    ...MOCK_ORDER_HEADER_2,
    submissionAt: "2020-10-16T00:00:00Z",
    submissionTime: "2020-10-16T17:29:04Z",
  },
  {
    ...MOCK_ORDER_HEADER_3,
    submissionAt: "2020-10-16T00:00:00Z",
    submissionTime: "2020-10-16T17:29:04Z",
  },
  {
    ...MOCK_ORDER_HEADER_4,
    submissionAt: "2020-10-16T00:00:00Z",
    submissionTime: "2020-10-16T17:29:04Z",
  },
];

const profile: QsysProfile = MOCK_QSYS_ACCOUNT;
let account: Account = MOCK_ACCOUNT;
let vehicles: Vehicle[] = MOCK_VEHICLES;
let b2bBenefit: B2bBenefit = MOCK_B2B_BENEFIT;
let paymentMethods: CustomerPaymentMethod[] = [MOCK_CUSTOMER_PAYMENT_METHOD];
// let paymentMethods: CustomerPaymentMethod[] = [];
let linked = true;
const hasMembership = true;

const getMembership = () =>
  linked
    ? {
        account,
        vehicles,
        activeCoupons: [],
      }
    : undefined;

const getProfile: () => GetProfileResponse = () => ({
  profile,
  membership: getMembership(),
});

function ofDelay<T>(t: T, delayTimeInMs: number = 500) {
  return of(t).pipe(delay(delayTimeInMs));
}

/**
 * A Mock Service to Test against
 */
@Injectable()
export class MyQQMockService implements MyQQService {
  readonly url = environment.URLs.MyQQ;
  decodeMap: <T>({
    decode,
  }: Decoder<unknown, T>) => (obs: Observable<unknown>) => Observable<T>;
  throwAndLogError = (err: any) => err;

  handleOfflineGet = () => EMPTY;
  async offlineError() {
    return Promise.resolve();
  }

  constructor(
    readonly http: HttpClient,
    readonly router: Router,
    readonly dialogController: MatDialog,
    readonly offlineAuth: OfflineAuthService,
    readonly store$: NgrxStore<any>,
    @Inject(WINDOW) readonly window: Window
  ) {}

  /**
   * Profile Endpoints
   */
  getMyProfile(): Observable<GetProfileResponse> {
    return ofDelay(getProfile());
  }

  newAccount(p: Account) {
    account = p;
    return ofDelay(account);
  }

  updateAccount(p: Account): Observable<Account> {
    account = { ...account, ...p };
    return ofDelay(account);
  }

  /**
   * Region Endpoints
   */
  getAllRegions(): Observable<Partial<Region>[]> {
    return ofDelay(MOCK_REGIONS);
  }

  /**
   * Vehicle Endpoints
   */
  getVehicles(): Observable<Vehicle[]> {
    return ofDelay(vehicles);
  }

  postVehicle(vehicle: Partial<Vehicle>): Observable<Vehicle> {
    const newVehicle = {
      ...vehicle,
      isActive: false,
      vehicleId: shortid(),
      hasMembership: false,
      isTemporaryIdentifier: false,
      verificationCode: "",
    };
    vehicles = vehicles.concat(newVehicle);
    return ofDelay(newVehicle);
  }

  removeVehicle(req: Vehicle): Observable<unknown> {
    vehicles = vehicles.filter(({ vehicleId }) => vehicleId !== req.vehicleId);
    return ofDelay(undefined);
  }

  /**
   * Stripe Subscription
   */
  getMySubscriptions(): Observable<GetSubscriptionResponse> {
    return ofDelay(hasMembership ? some(MOCK_SUBSCRIPTION) : none);
  }

  /**
   * Orders
   */
  getMyOrders(): Observable<OrderSearchResult> {
    return ofDelay({
      orders: [...orders],
      recordCount: 1,
      offset: 0,
      pageSize: 20,
      pageCount: 1,
      pageNo: 1,
    });
  }

  getOrderById(orderId: string): Observable<Order> {
    return orderId === MOCK_ORDER_1.orderId
      ? ofDelay(MOCK_ORDER_1)
      : throwError(new Error("Bad Request"));
  }

  /**
   * Payment Methods
   */
  getMyPaymentMethods(): Observable<CustomerPaymentMethod[]> {
    return ofDelay(paymentMethods);
  }
  postPaymentMethod(
    method: CustomerPaymentMethod
  ): Observable<CustomerPaymentMethod> {
    paymentMethods = paymentMethods.concat(method);
    return ofDelay(method);
  }
  newPaymentMethod(
    _: PostPaymentMethodRequest
  ): Observable<PostPaymentMethodResponse> {
    throw new Error("Not Implemented");
  }

  /**
   * Account Linking Methods
   */
  accountLookup(_: PaymentMethod): Observable<AccountCheckResponse> {
    linked = true;
    return ofDelay({ type: "found_one_no_link", ...getProfile() });
  }
  accountLookupLast4Plate(
    _: AccountCheckLast4PlateRequest
  ): Observable<AccountCheckResponse> {
    linked = true;
    return ofDelay({ type: "found_one_no_link", ...getProfile() });
  }

  linkAccount(): Observable<any> {
    throw new Error("Not implemented!");
  }

  relinkAccount(): Observable<any> {
    throw new Error("Not implemented!");
  }
  /**
   * Get price table
   */
  getPriceTable(): Observable<RegionMenu> {
    return ofDelay(MOCK_PRICE_TABLE);
  }
  getPriceTableByZip(zip: string): Observable<RegionMenu> {
    if (!zip || zip == "") {
      zip = "default";
      return ofDelay(MOCK_PRICE_TABLE_WITHOUT_PRICE);
    }
    return ofDelay(MOCK_PRICE_TABLE);
  }

  /**
   * Check if promo code exists
   */
  checkPromoCode(serialNo: string): Observable<Promo> {
    return ofDelay(serialNo === "12345" ? (MOCK_PROMO as Promo) : null);
  }

  /**
   * Order Calculate
   */
  calculateOrder(
    _: PostCalculateOrderRequest
  ): Observable<PostCalculateOrderResponse> {
    return ofDelay(MOCK_ORDER_1);
  }

  /**
   * Order Submit
   */
  submitOrder(_: PostSubmitOrderRequest): Observable<PostSubmitOrderResponse> {
    return throwError(
      new Error("Not Implemented: MyQQMockService#submitOrder")
    );
  }

  /**
   * Get AccountInfo New
   */
  accountInfo(): Observable<AccountInfoResponse> {
    return ofDelay(MOCK_ACCOUNT_INFO);
  }

  /*
   * Pay invoice
   */
  payInvoice(_: string): Observable<PayInvoiceResponse> {
    return throwError(new Error("Not Implemented: MyQQMockService#payInvoice"));
  }

  /**
   * Store Endpoints
   */
  getAllStores(): Observable<Store[]> {
    return ofDelay([MOCK_STORE]);
  }

  /**
   * Promo code Endpoint
   */
  getPromoDetails(_: string): Observable<PromoDetails> {
    return ofDelay(MOCK_PROMO_DETAILS);
  }

  getCompanyWidePromoDetails(): Observable<PromoDetails> {
    return ofDelay(MOCK_GRANDOPENING_PROMO);
  }

  getAccountQuota(): Observable<AccountQuota> {
    return ofDelay(MOCK_ACCOUNT_QUOTA);
  }

  getSwapReasons(): Observable<SwapReason[]> {
    return ofDelay(MOCK_SWAP_REASONS);
  }

  swapVehicleOnMembership(
    swapRequest: VehicleSwapRequest
  ): Observable<VehicleSwapResponse> {
    return ofDelay(MOCK_VEHICLE_SWAP_RESPONSE);
  }

  patchVehicle(vehicle: Vehicle): Observable<Vehicle> {
    return ofDelay(vehicle);
  }

  cancelMembership(cancelRequest: {
    cancelReason: "BLAH";
  }): Observable<{ subscriptionId: string }> {
    return ofDelay({ subscriptionId: "e7c95c4d-1845-4447-9e2d-31d4637ba1a5" });
  }

  checkEligibilityForRetentionOffer(
    startTimestamp: number
  ): Observable<{ eligible: boolean }> {
    return ofDelay({ eligible: true });
  }

  acceptRetentionOffer(): Observable<{ success: boolean }> {
    return ofDelay({ success: true });
  }

  getCancelReasons(): Observable<{ message: string; code: string }[]> {
    return ofDelay(MOCK_CANCEL_REASONS);
  }

  getAppMinVersion(): Observable<AppVersion> {
    return ofDelay({
      api: "MISSING",
      ios: {
        minimum: "1.0.0",
      },
      android: {
        minimum: "1.0.0",
      },
      web: {
        minimum: "1.0.0",
      },
    });
  }

  getMarketingContents(): Observable<MarketingContent[]> {
    return ofDelay([
      {
        vehiclesPageCardImage:
          "https://cdn.sanity.io/images/srielb7g/production/4c9aee2de2a39f8226231b87c053adc2bffe6907-680x96.png",
        isDismissable: true,
        showIfUnauth: false,
        link: "/vehicles",
        layout: "Layout 1",
        homePageCardImage:
          "https://cdn.sanity.io/images/srielb7g/production/46421bf45b75997adc1e33f775b202662e801abd-1435x1256.png",
      },
    ]);
  }

  /**
   * B2B Endpoints
   */

  getB2bBenefit(): Observable<B2bBenefit> {
    return ofDelay(b2bBenefit);
  }

  linkB2cWithCode(): Observable<any> {
    return ofDelay({
      personId: "1231654",
      signUpCode: "33333",
    });
  }
}
