import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";

import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatListModule } from "@angular/material/list";
import { MatToolbarModule } from "@angular/material/toolbar";

import { DefaultLayoutComponent } from "./default-layout.page";
import { DesktopLayoutComponent } from "./desktop-layout/desktop-layout.component";
import { MobileLayoutComponent } from "./mobile-layout/mobile-layout.component";

import { FooterModule } from "../footer/footer.module";
import { SocialModule } from "../social/social.module";
import { LandingLayoutDesktopComponent } from "./landing-layout-desktop/landing-layout-desktop.component";

export const COMPONENTS = [
  DefaultLayoutComponent,
  DesktopLayoutComponent,
  MobileLayoutComponent,
  LandingLayoutDesktopComponent,
];

@NgModule({
  imports: [
    CommonModule,
    FooterModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    MatMenuModule,
    MatSidenavModule,
    MatToolbarModule,
    RouterModule,
    SocialModule,
  ],
  exports: [...COMPONENTS],
  declarations: [...COMPONENTS],
  providers: [],
})
export class LayoutsModule {}
