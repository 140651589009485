import { Component, Input, Output, EventEmitter } from "@angular/core";
import { Option } from "fp-ts/es6/Option";
import { getPlanPromoImg, getSpecificPlanOverride } from "src/lib/util";

import {
  Vehicle,
  PostCalculateOrderResponse,
  OrderTypesEnum,
  CustomerPaymentMethod,
  SubscriptionDetail,
  MembershipPurchase,
  PromoDetails,
  RegionMenu,
  SubscriptionPlan,
} from "src/app/core/services/myqq";
import { toArray } from "@qqcw/qqsystem-util";

import { environment } from "src/environments/environment";

import { ResizeService } from "src/app/shared/services/resize.service";
import { errors } from "src/app/core/services/myqq/myqq.errors";

@Component({
  selector: "myqq-cart-details-display",
  templateUrl: "./cart-details.display.component.html",
  styleUrls: ["./cart-details.display.component.scss"],
})
export class CartDetailsDisplayComponent {
  constructor(private readonly resize: ResizeService) {}
  @Input() membershipPurchaseOption?: Option<MembershipPurchase>;
  @Input() currentSubscription?: SubscriptionDetail | null;
  @Input() currentSubscriptionPlan?: SubscriptionPlan;
  @Input() modifySubscriptionPlan?: SubscriptionPlan;
  @Input() newSubscriptionPlan?: SubscriptionPlan;
  @Input() order?: PostCalculateOrderResponse;

  @Input() refreshing = false;
  @Input() priceTable?: RegionMenu;
  @Input() vehicles?: Vehicle[];
  @Input() paymentMethods?: CustomerPaymentMethod[];
  @Input() promoDetails?: PromoDetails;
  @Input() calculateOrderError: string = null;

  @Output() addVehicle = new EventEmitter<void>();
  @Output() recalculateOrderWithVehicles = new EventEmitter<Vehicle[]>();
  @Output() clearPromoCode = new EventEmitter<void>();
  @Output() createVehicle = new EventEmitter<Vehicle[]>();
  @Output() createPaymentMethod = new EventEmitter<void>();
  @Output() tryPromoCode = new EventEmitter<string>();

  supportNumber = environment.supportNumber;
  orderTypes = OrderTypesEnum;
  readonly invalidCouponErr = errors.myqqApi.errInvalidCoupon;

  readonly isMobile = this.resize._isMobile$.asObservable();

  readonly getCustomPlanOverrideImg = (
    plan: SubscriptionPlan,
    promoDetail: PromoDetails
  ): string => {
    return getPlanPromoImg(plan.myQQShortDisplayName, promoDetail);
  };

  readonly getpriceOverride = (
    plan: SubscriptionPlan,
    promoDetail: PromoDetails
  ): string => {
    return getSpecificPlanOverride(
      plan.base.sku,
      promoDetail?.planSpecificOverride
    )?.fixedBasePrice;
  };

  readonly isValidForPromo = ({
    orderType,
    markDowns = [],
  }: PostCalculateOrderResponse) =>
    toArray(markDowns).length === 0 &&
    (orderType === OrderTypesEnum.NEWMEMBERSHIP ||
      orderType === OrderTypesEnum.ADDMEMBERSHIP);

  readonly isModifySubscriptionOrder = () =>
    !!this.modifySubscriptionPlan &&
    (this.order?.orderType === this.orderTypes.DOWNGRADESUBSCRIPTION ||
      this.order?.orderType === this.orderTypes.UPGRADESUBSCRIPTION);
}
