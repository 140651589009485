import { Component, EventEmitter, Input, Output } from "@angular/core";
import { AccountQuotaWithRemaining } from "src/app/shared/utilities/types";

@Component({
  selector: "myqq-account-message-quota",
  templateUrl: "./account-quota-message.component.html",
  styleUrls: ["./account-quota-message.component.scss"],
})
export class AccountQuotaMessageComponent {
  @Input() accountQuota: AccountQuotaWithRemaining;
  @Input() action: string;
  @Output() showDisclaimer = new EventEmitter();
}
