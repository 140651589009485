import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { PwaService } from "src/app/shared/services/pwa.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "myqq-fourohfour",
  templateUrl: "./fourohfour.component.html",
  styleUrls: ["./fourohfour.component.scss"],
})
export class FourohfourComponent {
  supportEmail = environment.supportEmail;
  cdnHost = environment.cdnHost;

  constructor(private pwa: PwaService, private router: Router) {}

  refresh() {
    try {
      this.pwa.updateAndReload();
    } catch (e) {
      console.error(e);
      this.router.navigate(["/"]);
    }
  }
}
