import { A11yModule } from "@angular/cdk/a11y";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatCardModule } from "@angular/material/card";

import { MatIconModule } from "@angular/material/icon";
import { EvoxImageModule } from "../../modules/evox/evox.module";
import { MapPipeModule } from "../../pipes/map/map-pipe.module";
import { SharedModule } from "../../shared.module";
import { VehicleDisplayComponent } from "./vehicle-display.component";

@NgModule({
  imports: [
    A11yModule,
    CommonModule,
    MatIconModule,
    SharedModule,
    EvoxImageModule,
    MatCardModule,
    MapPipeModule,
  ],
  exports: [VehicleDisplayComponent],
  declarations: [VehicleDisplayComponent],
  providers: [],
})
export class VehicleDisplayModule {}
