import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MapPipeModule } from "../../pipes/map";
import { PriceDisplayComponent } from "./price-display.component";

@NgModule({
  imports: [CommonModule, MapPipeModule],
  exports: [PriceDisplayComponent],
  declarations: [PriceDisplayComponent],
  providers: [],
})
export class PriceDisplayModule {}
