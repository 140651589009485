import { Injectable } from "@angular/core";
import { Actions, createEffect } from "@ngrx/effects";
import { catchError, filter, switchMap, map } from "rxjs/operators";
import { of } from "rxjs";

import { asyncExhaustMap } from "src/app/shared/utilities/state/Operators";
import { MyQQService } from "src/app/core/services/myqq/myqq.service";

import * as crm from "./myqq.reducers";
import { mapError } from "src/app/shared/utilities/rxjs";
/**
 * This service encapsulates "side effects". This is to say that events that
 * are not synchronous, rely on data outside of the app, or can fail are linked
 * up here.
 *
 * The asyncExhaustMap rxjs operator effectively bookends asynchronous events.
 * For example, the crm.updatePerson async action package has pending, success, and
 * failure actions. asyncExhaustMap will listen for updatePerson.pending, when that
 * action happens it will pull out the value inside the action (in this case a person
 * object) and pass it to the http service that updates a person on the backend. If
 * the http call is successful it will take the result and wrap it in
 * updatePerson.success and pass it back into the application. If an error is thrown
 * in the observable error channel (ie. 404, 503, or anything else) it will
 * wrap the error in updatePerson.failure and pass it back into the applcation.
 *
 * In this way the beginning and end of each request becomes a single action
 * in the application.
 */
@Injectable()
export class MyQQEffects {
  /**
   * Person Effects
   */
  getAccountInfo$ = createEffect(() =>
    this.actions$.pipe(
      asyncExhaustMap(crm.getAccountInfo, () => this.myqqSvc.accountInfo())
    )
  );

  getProfile$ = createEffect(() =>
    this.actions$.pipe(
      asyncExhaustMap(crm.getProfile, () => this.myqqSvc.getMyProfile())
    )
  );

  newAccount$ = createEffect(() =>
    this.actions$.pipe(
      asyncExhaustMap(crm.newAccount, (person) =>
        this.myqqSvc.newAccount(person)
      )
    )
  );

  updateAccount$ = createEffect(() =>
    this.actions$.pipe(
      asyncExhaustMap(crm.updateAccount, (person) =>
        this.myqqSvc.updateAccount(person)
      )
    )
  );

  /**
   * Region Effects
   */
  getAllRegions$ = createEffect(() =>
    this.actions$.pipe(
      asyncExhaustMap(crm.getAllRegions, () => this.myqqSvc.getAllRegions())
    )
  );

  /**
   * Vehicle Effects
   */
  getVehicles$ = createEffect(() =>
    this.actions$.pipe(
      asyncExhaustMap(crm.getVehicles, () => this.myqqSvc.getVehicles())
    )
  );
  addVehicle$ = createEffect(() =>
    this.actions$.pipe(
      asyncExhaustMap(crm.addVehicle, (vehicle) =>
        this.myqqSvc.postVehicle(vehicle)
      )
    )
  );
  editVehicle$ = createEffect(() =>
    this.actions$.pipe(
      asyncExhaustMap(crm.editVehicle, (vehicle) =>
        this.myqqSvc.patchVehicle(vehicle)
      )
    )
  );

  removeVehicle$ = createEffect(() =>
    this.actions$.pipe(
      asyncExhaustMap(crm.removeVehicle, (req) =>
        this.myqqSvc.removeVehicle(req)
      )
    )
  );
  /**
   * Order Effects
   */
  getMyOrders$ = createEffect(() =>
    this.actions$.pipe(
      asyncExhaustMap(crm.getMyOrders, (page) => this.myqqSvc.getMyOrders(page))
    )
  );

  getOrderById$ = createEffect(() =>
    this.actions$.pipe(
      asyncExhaustMap(crm.getOrderById, (orderId) =>
        this.myqqSvc.getOrderById(orderId)
      )
    )
  );

  /**
   * Payment Method Effects
   */
  getMyPaymentMethods$ = createEffect(() =>
    this.actions$.pipe(
      asyncExhaustMap(crm.getMyPaymentMethods, () =>
        this.myqqSvc.getMyPaymentMethods()
      )
    )
  );

  /**
   * Post Payment Method Effects
   */
  postPaymentMethod$ = createEffect(() =>
    this.actions$.pipe(
      asyncExhaustMap(crm.postPaymentMethod, (paymentMethodId) =>
        this.myqqSvc.newPaymentMethod({ paymentMethodId })
      )
    )
  );

  /**
   * Account Linking Effects
   */
  accountLookup$ = createEffect(() =>
    this.actions$.pipe(
      asyncExhaustMap(crm.accountLookup, (token) =>
        this.myqqSvc.accountLookup(token)
      )
    )
  );

  accountLookupLast4Plate = createEffect(() =>
    this.actions$.pipe(
      asyncExhaustMap(crm.accountLookupLast4Plate, (token) =>
        this.myqqSvc.accountLookupLast4Plate(token)
      )
    )
  );

  accountLink$ = createEffect(() =>
    this.actions$.pipe(
      asyncExhaustMap(crm.accountLink, (personId) =>
        this.myqqSvc.linkAccount(personId)
      )
    )
  );
  accountRelink$ = createEffect(() =>
    this.actions$.pipe(
      asyncExhaustMap(crm.accountRelink, (personId) =>
        this.myqqSvc.relinkAccount(personId)
      )
    )
  );
  /**
   * Get Price Table Effects
   */
  getPriceTable$ = createEffect(() =>
    this.actions$.pipe(
      asyncExhaustMap(crm.getPriceTable, () => this.myqqSvc.getPriceTable())
    )
  );

  getPriceTableByZip$ = createEffect(() =>
    this.actions$.pipe(
      asyncExhaustMap(crm.getPriceTableByZip, (zip) =>
        this.myqqSvc.getPriceTableByZip(zip)
      )
    )
  );

  /**
   * Calculate Order Effect
   */
  calculateOrder$ = createEffect(() =>
    this.actions$.pipe(
      asyncExhaustMap(crm.calculateOrder, (order) =>
        this.myqqSvc.calculateOrder(order)
      )
    )
  );

  /**
   * Submit Order Effect
   */
  submitOrder$ = createEffect(() =>
    this.actions$.pipe(
      asyncExhaustMap(crm.submitOrder, (order) =>
        this.myqqSvc.submitOrder(order)
      )
    )
  );

  /**
   * Check Promo Code Effect
   */
  checkPromo$ = createEffect(() =>
    this.actions$.pipe(
      asyncExhaustMap(crm.checkPromoCode, (serialNum) =>
        this.myqqSvc.checkPromoCode(serialNum)
      )
    )
  );

  /**
   * Subscriptions Effects
   */
  getMySubscriptions$ = createEffect(() =>
    this.actions$.pipe(
      asyncExhaustMap(crm.getMySubscriptions, () =>
        this.myqqSvc.getMySubscriptions()
      )
    )
  );

  /**
   * Pay Failed Invoice Effects
   *
   * This is a compound action that is composed of first updating
   * the payment method on the account and then asking myqq-api to
   * retry the stripe payment with the new payment method.
   */
  payFailedInvoice$ = createEffect(() =>
    this.actions$.pipe(
      filter(crm.payFailedInvoice.pending.match),
      switchMap(({ value: params }) =>
        this.myqqSvc
          .newPaymentMethod({
            paymentMethodId: params.paymentMethodId,
            createReason: "FAILEDINVOICE",
          })
          .pipe(
            mapError((error) => ({
              message: "Unable to add new payment method",
              error,
            })),
            switchMap(() => this.myqqSvc.payInvoice(params.invoiceId)),
            mapError((error) => ({
              message: "Unable to pay invoice with new paymentMethod",
              error,
            })),
            map(() => crm.payFailedInvoice.success({ params, result: null })),
            catchError((error) =>
              of(crm.payFailedInvoice.failure({ params, error }))
            )
          )
      )
    )
  );

  /**
   * Store Effects
   */
  getAllStores$ = createEffect(() =>
    this.actions$.pipe(
      asyncExhaustMap(crm.getAllStores, () => this.myqqSvc.getAllStores())
    )
  );

  /**
   * Get details about promotions from myqq-api
   */
  getPromoDetails$ = createEffect(() =>
    this.actions$.pipe(
      asyncExhaustMap(crm.getPromoDetails, (promoCode) =>
        this.myqqSvc.getPromoDetails(promoCode)
      )
    )
  );

  /**
   * Get details about autoapply promotions from myqq-api
   */
  getCompanyWidePromoDetails$ = createEffect(() =>
    this.actions$.pipe(
      asyncExhaustMap(crm.getCompanyWidePromoDetails, () =>
        this.myqqSvc.getCompanyWidePromoDetails()
      )
    )
  );

  /**
   * Swap/Edit LP Effects
   */

  getAccountQuota$ = createEffect(() =>
    this.actions$.pipe(
      asyncExhaustMap(crm.getAccountQuota, () => this.myqqSvc.getAccountQuota())
    )
  );
  getSwapReasons$ = createEffect(() =>
    this.actions$.pipe(
      asyncExhaustMap(crm.getSwapReasons, () => this.myqqSvc.getSwapReasons())
    )
  );
  swapVehicle$ = createEffect(() =>
    this.actions$.pipe(
      asyncExhaustMap(crm.swapMembership, (swapRequest) =>
        this.myqqSvc.swapVehicleOnMembership(swapRequest)
      )
    )
  );
  editVehicleIdentifier$ = createEffect(() =>
    this.actions$.pipe(
      asyncExhaustMap(crm.editVehicleIdentifier, (vehicle) =>
        this.myqqSvc.patchVehicle(vehicle)
      )
    )
  );

  /**
   * Cancel membership effects
   */
  cancelMembership$ = createEffect(() =>
    this.actions$.pipe(
      asyncExhaustMap(crm.cancelMembership, (cancelRequest) =>
        this.myqqSvc.cancelMembership(cancelRequest)
      )
    )
  );

  checkEligibityForRetentionOffer$ = createEffect(() =>
    this.actions$.pipe(
      asyncExhaustMap(
        crm.checkEligibilityForRetentionOffer,
        (createTimestamp) =>
          this.myqqSvc.checkEligibilityForRetentionOffer(createTimestamp)
      )
    )
  );

  acceptRetentionOffer$ = createEffect(() =>
    this.actions$.pipe(
      asyncExhaustMap(crm.acceptRetentionOffer, () =>
        this.myqqSvc.acceptRetentionOffer()
      )
    )
  );

  getCancelReasons$ = createEffect(() =>
    this.actions$.pipe(
      asyncExhaustMap(crm.getCancelReasons, () =>
        this.myqqSvc.getCancelReasons()
      )
    )
  );

  getAppMinVersion$ = createEffect(() =>
    this.actions$.pipe(
      asyncExhaustMap(crm.getAppMinVersion, () =>
        this.myqqSvc.getAppMinVersion()
      )
    )
  );

  getMarketingContent$ = createEffect(() =>
    this.actions$.pipe(
      asyncExhaustMap(crm.getMarketingContent, () =>
        this.myqqSvc.getMarketingContents()
      )
    )
  );

  /**
   * B2b Effects
   */

  getB2bBenefit$ = createEffect(() =>
    this.actions$.pipe(
      asyncExhaustMap(crm.getB2bBenefit, () => this.myqqSvc.getB2bBenefit())
    )
  );

  linkB2CEffects$ = createEffect(() =>
    this.actions$.pipe(
      asyncExhaustMap(crm.linkB2C, (linkRequest) =>
        this.myqqSvc.linkB2cWithCode(linkRequest)
      )
    )
  );

  constructor(private actions$: Actions, private myqqSvc: MyQQService) {}
}
