import { FormControl, FormGroup } from "@angular/forms";
import { subYears } from "date-fns";
import { rationalizeLicense, rationalizeVIN } from "./util";

/**
 * Vehicles
 */
export const vinIsValid = (
  vinField: FormControl<string>,
  allowEmpty = false
) => {
  const vin = vinField.value;

  return (vin?.length > 0 && rationalizeVIN(vin)) ||
    (vin?.length === 0 && allowEmpty)
    ? null
    : { invalid: true };
};

export const licenseIsValid = (lpField: FormControl<string>) => {
  const lp = lpField.value;

  return lp?.length > 0 && rationalizeLicense(lp) ? null : { invalid: true };
};

export const vehicleIsValid = (
  vehicleFormGroup: FormGroup<{
    license: FormControl<string>;
    state: FormControl<string>;
    vin: FormControl<string>;
  }>
) => {
  const license = vehicleFormGroup.get("license").value;
  const state = vehicleFormGroup.get("state").value;
  const vin = vehicleFormGroup.get("vin").value;

  const licenseValid =
    state?.length === 2 && license?.length > 0 && !!rationalizeLicense(license);
  const vinValid = vin?.length == 17 && !!rationalizeVIN(vin);
  return !license === !state && (licenseValid || vinValid)
    ? null
    : { invalid: true };
};

/**
 * People
 */
export const dobValidator = (dobField: FormControl, min = 18, max = 123) => {
  const dob =
    dobField.value instanceof Date ? dobField.value : new Date(dobField.value);
  const now = new Date();

  return !!dob && dob < subYears(now, min) && dob > subYears(now, max)
    ? null
    : { invalid: true };
};

export const oldEnough = (dobField: FormControl, min = 18) => {
  const dob =
    dobField.value instanceof Date ? dobField.value : new Date(dobField.value);
  const now = new Date();

  return !!dob && dob < subYears(now, min) ? null : { invalid: true };
};

export const notDead = (dobField: FormControl, max = 123) => {
  const dob =
    dobField.value instanceof Date ? dobField.value : new Date(dobField.value);
  const now = new Date();

  return !!dob && dob > subYears(now, max) ? null : { invalid: true };
};
