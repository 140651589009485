import { Component, EventEmitter, Input, Output } from "@angular/core";
import {
  MembershipPurchase,
  PromoDetails,
  SubscriptionPlan,
} from "src/app/core/services/myqq";
import { getPlanPromoImg, getSpecificPlanOverride } from "src/lib/util";

@Component({
  selector: "myqq-cart-new-membership",
  templateUrl: "./cart-new-membership.component.html",
  styleUrls: ["./cart-new-membership.component.scss"],
})
export class CartNewMembershipComponent {
  @Input() readonly membershipPurchase?: MembershipPurchase | null;
  @Input() readonly promoDetails?: PromoDetails | null;

  @Output() readonly addVehicle = new EventEmitter();

  readonly getCustomPlanOverrideImg = (
    subscriptionPlan: SubscriptionPlan,
    promoDetail: PromoDetails
  ): string => {
    return getPlanPromoImg(subscriptionPlan.myQQShortDisplayName, promoDetail);
  };

  readonly getPriceOverride = (
    subscriptionPlan: SubscriptionPlan,
    promoDetail: PromoDetails
  ): string => {
    return getSpecificPlanOverride(
      subscriptionPlan.base.sku,
      promoDetail?.planSpecificOverride
    )?.fixedBasePrice;
  };
}
