import { Component, Input } from "@angular/core";
import { environment } from "src/environments/environment";

@Component({
  selector: "myqq-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent {
  @Input()
  public showBadge: boolean = true;

  readonly copyrightNotice = `© ${new Date()
    .getFullYear()
    .toString()} Quick Quack Car Wash. All rights reserved.`;

  readonly links = [
    {
      title: "Terms of Use",
      url: environment.termsOfUse,
      id: "terms",
    },
    {
      title: "Privacy Policy",
      url: environment.privacyPolicy,
      id: "privacy",
    },
    {
      title: "CCPA (CA Consumers)",
      url: environment.ccpa,
      id: "ccpa",
    },
  ];
}
