<myqq-routed-modal
  [useDatumEither]="contextData$ | async"
  class="vh-p100"
  [hideFooter]="true"
>
  <span modal-header> Edit License Plate/VIN </span>
  <myqq-loading *onInitial class="fls-1-1">
    <h3>Initializing...</h3>
  </myqq-loading>
  <myqq-loading *onPending class="fls-1-1">
    <h3>Getting data...</h3>
  </myqq-loading>
  <myqq-error
    *onFailure="let errorResponse"
    title="Edit vehicle license plate/vin setup error"
    description="There was a problem getting your account information"
    [error]="errorResponse"
  >
    <footer>
      <button
        myqq-button
        aria-label="refresh"
        id="refresh-button"
        class="save-btn raised"
        color="primary"
        (click)="handleRefresh()"
        (keyup.enter)="handleRefresh()"
        (keydown.enter)="$event.preventDefault()"
      >
        Refresh
      </button>
    </footer>
  </myqq-error>
  <ng-container
    *onSuccess="let data"
    [useDatumEither]="postEditVehicle$ | async"
  >
    <myqq-banner
      *ngIf="showVehicleOnAccountError"
      (dismiss)="handleDismissErrorBanner()"
      message="This vehicle is on your account and already has a membership."
      [showContactCustomerSupport]="false"
    >
    </myqq-banner>
    <myqq-loading *onPending class="fls-1-1">
      <h3>Editing vehicle...</h3>
    </myqq-loading>
    <ng-container
      *onFailure="let error; let refreshing= refreshing"
      [ngSwitch]="error?.error?.message"
    >
      <ng-container *ngSwitchCase="errVehicleOwnership">
        <myqq-banner
          *ngIf="!(hasMembership$ | async)"
          (dismiss)="handleDismissErrorBanner()"
          message="A vehicle with this license plate or VIN already exists. Do you want to link your account to an existing membership?"
        >
          <button
            myqq-button
            id="find-membership"
            class="find-membership raised"
            color="tertiary"
            (click)="handleRelink()"
            (keydown.enter)="$event.preventDefault()"
            (keyup.enter)="handleRelink()"
            fill="clear"
            type="button"
            aria-label="Find membership"
          >
            Find Membership
          </button>
        </myqq-banner>
        <myqq-banner
          *ngIf="hasMembership$ | async"
          (dismiss)="handleDismissErrorBanner()"
          message="Unfortunately, we weren't able to change the license plate or VIN."
        >
        </myqq-banner>
      </ng-container>

      <myqq-banner
        *ngSwitchDefault
        (dismiss)="handleDismissErrorBanner()"
        message="Unfortunately, we weren't able to change the license plate or VIN."
      >
      </myqq-banner>
    </ng-container>

    <myqq-edit-vehicle-initial
      class="vh-p100"
      *ngIf="!showConfirmStep"
      [vehicle]="currentVehicle"
      [accountQuota]="data.accountQuota"
      [refreshing]="refreshingData"
      (modifiedVehicle)="handleSetModVehicle($event, data.vehicles)"
      (showDisclaimer)="handleShowDisclaimer()"
    >
    </myqq-edit-vehicle-initial>
    <myqq-edit-vehicle-confirm
      class="vh-p100"
      *ngIf="showConfirmStep"
      [currentVehicle]="currentVehicle"
      [membershipBadgeColor]="currentVehicle | map: getMembershipBadgeColor:data.menu "
      [newVehicle]="modVehicle"
      [accountQuota]="data.accountQuota"
      [showQuota]="showQuotaWillBeConsumed"
      (submit)="handleSubmit()"
      (showDisclaimer)="handleShowDisclaimer()"
      (back)="handleBack()"
    >
    </myqq-edit-vehicle-confirm>
  </ng-container>
</myqq-routed-modal>
